import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from './Modal'
import { ButtonClose } from '../Buttons'
import { ArrowLeft, ArrowRight } from '../Icons'
import { addToast } from '../../store/global'
import { PurchaseModalCard } from './PurchaseModalCard'

// hardcoded for now as this is also hardcoded on the websites, and wont change very often
const features = {
  seo: [
    'Up to 35 Keywords | Launch Link Building',
    'Up to 60 Keywords | Launch Link Building',
    'Up to 80 Keywords | Accelerate Link Building'
  ],
  google: [
    'Search Network Only | Ad Spends up to $2,500 | Setup by Digital Marketing Specialists',
    'Shopping Network Only | Ad Spends up to $2,500 | Setup by Digital Marketing Specialists',
    'Search + Shopping Networks | Ad Spends up to $5,000 | Setup by Digital Marketing Specialists'
  ],
  facebook: [
    '1 Campaign | Setup by Digital Marketing Specialists',
    '2 Campaigns | Re-marketing Consultation | Setup by Digital Marketing Specialists',
    '4 Campaigns | Re-marketing Consultation | Ad Split Testing & Optimisation'
  ]
}

export function PurchaseModal({ show, onClose, onClick, packageList = [], platform }) {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({ subject: '', description: '' })
  const [errors, setErrors] = useState({ subject: false, description: false })
  const [cardIdx, setCardIdx] = useState(0)
  const [isExiting, setIsExiting] = useState(false)

  const handleClose = () => {
    setIsExiting(true) // start exit animation
    setTimeout(onClose, 150) // delay to match exit animation duration
  }

  const proto = useSelector((store) => store.global.proto)
  const domain = useSelector((store) => store.global.brand.domain)
  const currency = useSelector((store) => store.global.currency)

  const page = useSelector((store) => store.global.page)
  const refBasic = useRef(null)
  const refAdvanced = useRef(null)
  const refPlatinum = useRef(null)
  const refs = [refBasic, refAdvanced, refPlatinum]

  const { ticketsubmit, modal } = window.t?.dashboard || {}
  const { headerText, subject, subjectTitle, message, messageTitle, save } = modal || {}

  const dispatch = useDispatch()

  const onChange = (event) => {
    const { name, value } = event.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const validateForm = () => {
    const formErrors = {
      subject: formData.subject === '',
      description: formData.description === ''
    }

    setErrors(formErrors)

    return Object.values(formErrors).some((value) => !value)
  }

  const onSubmit = async () => {
    if (!validateForm()) {
      return
    }

    setLoading(true)

    const response = await submitForm(formData, page.name)
    if (response === null || response.error) {
      setLoading(false)
      dispatch(
        addToast({
          title: ticketsubmit.error,
          subtext: ticketsubmit.errorSubtext,
          type: 'error'
        })
      )
      return
    }
    setLoading(false)
    dispatch(
      addToast({
        title: ticketsubmit.success,
        subtext: ticketsubmit.successSubtext,
        type: 'success'
      })
    )
    handleClose()
  }

  const handleClick = (direction) => {
    const newCardIdx = cardIdx + direction
    if (newCardIdx >= 0 && newCardIdx < refs.length) {
      setCardIdx(newCardIdx)
      refs[newCardIdx].current?.scrollIntoView({ behavior: 'smooth' })
    }
  }
  const handleClickLeft = () => handleClick(-1)
  const handleClickRight = () => handleClick(1)

  const handleAccountManager = () => {
    onClick()
    handleClose()
  }

  return (
    <Modal
      handleClose={handleClose}
      isExiting={isExiting}
      extraRules="w-[83%] sm:w-auto sm:max-w-[887px] bg-gray-100 dark:bg-gray-700 px-[8px] py-[4px] sm:p-[24px] self-center"
    >
      <div className="z-50 flex flex-col gap-[7px] pt-[8px] bg-gray-100 dark:bg-gray-700">
        <header className="flex justify-between">
          <h3 className="text-center sm:text-start text-gray-900 dark:text-gray-100 flex flex-grow font-inter font-[600] text-[25px] sm:text-[30px] justify-center leading-9">
            {platform === 'seo'
              ? platform?.toUpperCase()
              : platform?.charAt(0).toUpperCase() + platform?.slice(1) + ' Ads'}{' '}
            Packages
          </h3>
          <ButtonClose onClick={handleClose} />
        </header>
        <p className="hidden sm:block dark:text-gray-400 text-gray-600 text-center text-[16px] font-[400] leading-5 pb-[0px] sm:pb-[10px] my-[0px] sm:my-[8px]">
          Elevate your digital marketing strategy with our{' '}
          {platform === 'seo'
            ? platform?.toUpperCase()
            : platform?.charAt(0).toUpperCase() + platform?.slice(1) + ' Ads'}{' '}
          packages.<br></br>
          Boost your brand's visibility or spark conversations through targeted advertising.
        </p>
        <div className="flex flex-row gap-[16px] mx-[2px] sm:mx-[24px] my-[8px] sm:my-[16px] overflow-x-auto">
          <div ref={refBasic} className="flex-shrink-0 w-full sm:w-min">
            <PurchaseModalCard
              title="Basic"
              price={packageList[0]?.productDisplayPrice || null}
              disclaimer={packageList[0]?.productName.split(' ').slice(1).join(' ') || null}
              description={platform ? features[platform][0].split(' | ') : '~'}
              link={`/checkout?do=addPackage&locale=${currency}&package=${packageList[0]?.productId}%2C${packageList[0]?.setupProductId}`}
            />
          </div>
          <div ref={refAdvanced} className="flex-shrink-0 w-full sm:w-min">
            <PurchaseModalCard
              title="Advanced"
              price={packageList[1]?.productDisplayPrice || null}
              disclaimer={packageList[1]?.productName.split(' ').slice(1).join(' ') || null}
              description={platform ? features[platform][1].split(' | ') : '~'}
              recommended
              link={`/checkout?do=addPackage&locale=${currency}&package=${packageList[1]?.productId}%2C${packageList[1]?.setupProductId}`}
            />
          </div>
          <div ref={refPlatinum} className="flex-shrink-0 w-full sm:w-min">
            <PurchaseModalCard
              title="Advanced Plus"
              price={packageList[2]?.productDisplayPrice || null}
              disclaimer={packageList[2]?.productName.split(' ').slice(1).join(' ') || null}
              description={platform ? features[platform][2].split(' | ') : '~'}
              link={`/checkout?do=addPackage&locale=${currency}&package=${packageList[2]?.productId}%2C${packageList[2]?.setupProductId}`}
            />
          </div>
        </div>
        <div className="flex flex-row gap-[8px] visible sm:hidden justify-end">
          <div onClick={handleClickLeft}>
            <ArrowLeft />
          </div>
          <div onClick={handleClickRight}>
            <ArrowRight />
          </div>
        </div>
        <p className="hidden sm:visible dark:text-gray-400 text-gray-600 text-center text-[14px] font-[400] leading-5">
          Please reach out to your&nbsp;
          <span
            onClick={handleAccountManager}
            className="cursor-pointer select-none text-primary-600 dark:text-primary-400 font-[500] underline"
          >
            Account Manager
          </span>
          &nbsp;for any assistance or to learn more about our Meta Ads Packages
        </p>
      </div>
    </Modal>
  )
}
