import confetti from 'canvas-confetti'

let inProgress = false

export const showConfetti = async () => {
  if (inProgress) {
    return false
  }

  inProgress = true

  await confetti({
    particleCount: 50,
    startVelocity: 30,
    spread: 360,
    origin: {
      x: Math.random(),
      y: Math.random() - 0.2
    }
  })

  inProgress = false
}
