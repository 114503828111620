import React from 'react'
import { CloseIcon } from '../Icons'

export function ButtonClose({ onClick }) {
  return (
    <button
      type="button"
      className="justify-center dark:hover:text-gray-200 duration-150 flex focus:shadow-none h-8 hover:text-gray-700 items-center justify-end rounded text-base-400 transition-colors w-8"
      aria-label="close"
      onClick={onClick}
    >
      <CloseIcon />
    </button>
  )
}
