import React from 'react'
import { ReportHeader } from '../ReportHeader'
import { LoadingChartLTR } from '../../Loading'
import { PerformanceChart } from '../../Chart/PerformanceChart'

export function PerformanceChartPanel({ seoProduct, title, tooltip, loading, list }) {
  return (
    <div className="flex flex-col justify-between gap-[20px] p-[24px] w-full animate-slide-left bg-white dark:bg-gray-800 dark:text-white rounded-[16px]">
      <ReportHeader title={title} help={tooltip} />
      {loading && (
        <div className="w-full items-center justify-end pt-12">
          <LoadingChartLTR />
        </div>
      )}

      {!loading && <PerformanceChart seoProduct={seoProduct} data={list} />}
    </div>
  )
}
