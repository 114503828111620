import React from 'react'
import { percentageFormatter } from '../../helpers/percentageFormatter'

interface ProgressBarMiniProps {
  title: string
  value: number
  target: number
}

export function ProgressBarMini({ title, value, target }: ProgressBarMiniProps) {
  if (value <= 0) {
    return null
  }

  const percentage = percentageFormatter(value / target)

  return (
    <div className="flex flex-col gap-[8px]">
      <span className="font-inter font-[500] text-[14px] text-gray-600 dark:text-gray-400 leading-[20px] italic">
        {title}
      </span>
      <div className="flex flex-col gap-[8px]">
        <div className="h-[12px] w-fit-content bg-gray-200 rounded-lg overflow-hidden">
          <div
            className="bg-primary-700 dark:bg-primary-600 h-full rounded-s-lg transition-all delay-500 duration-1000"
            style={{ width: percentage }}
          />
        </div>
        <div
          className="w-[68px] py-[4px] px-[12px] bg-white dark:bg-gray-600 text-center rounded-[8px] font-inter font-[600] text-[12px] text-gray-700 dark:text-gray-300 leading-[18px]"
          style={{ left: `calc(${percentage} - 30px)` }}
        >
          {value.toLocaleString()}
        </div>
      </div>
    </div>
  )
}
