import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { LockIcon } from '../Icons'
import { Tooltip } from '@mui/material'
import { extractAccessKey } from '../../helpers/extractAccessKey'
import { useCheckAccessKeyQuery } from '../../services/cpanel'
import { isProductActive } from '../../helpers/isProductActive'

const accessKey = extractAccessKey()

export function SideBarItem({
  item,
  expanded,
  locked,
  setPlatform = null,
  setPriceLock = null,
  setAuthLock = null,
  authLock = null,
  onClick = null,
  setFullSize
}) {
  const { href, name, icon } = item
  const NavIcon = icon

  const accessKeyValid = useCheckAccessKeyQuery(accessKey)

  // check if customer is logged in
  const customer = useSelector((store) => store.global.customer)

  // check for any active/paused products otherwise the standard way
  const allProducts = useSelector((store) => store.global.customer?.activeProducts)
  const activeGoogleProduct =
    allProducts?.find(
      (product) => product?.adWordsManagement && isProductActive(product.adWordsManagement.status)
    ) || undefined
  const activeFacebookProduct =
    allProducts?.find(
      (product) => product?.socialMarketing && isProductActive(product.socialMarketing?.status)
    ) || undefined
  const activeSEOProduct =
    allProducts?.find((product) => product?.seo && isProductActive(product.seo.status)) || undefined

  // extract notification data from redux store
  const notifCount = useSelector((store) => store.notifications.messages.length)

  const onItemClick = (event) => {
    if (
      (!activeFacebookProduct && name === 'Meta Ads') ||
      (!activeGoogleProduct && name === 'Google Ads') ||
      (!activeSEOProduct && name === 'SEO')
    ) {
      setPlatform(name.toLowerCase().split(' ')[0])
      if (accessKeyValid.data) {
        setPriceLock(false)
      } else {
        setPriceLock(true)
        event.preventDefault() // Prevent default behavior (e.g., redirect)
      }
    }
    if (customer.temp && name === 'Settings') {
      setAuthLock(true)
      event.preventDefault()
    }
    if (name === 'Notifications') {
      setFullSize(true)
      event.preventDefault()
    }
    if (onClick instanceof Function) {
      onClick(item)
    }
  }

  return (
    <div className="group transition-colors duration-500 transform">
      <NavLink
        end
        to={href}
        onClick={onItemClick}
        className={({ isActive }) =>
          [
            'flex justify-between items-center gap-[12px] p-[12px] mb-[4px] max-h-[40px] rounded-[6px]',
            isActive
              ? 'font-normal bg-gray-200 dark:bg-gray-700 transform group-hover:bg-gray-300 dark:text-gray-200 dark:group-hover:bg-primary-700'
              : 'transform text-base-700 font-light dark:text-gray-200 group-hover:bg-gray-100 dark:group-hover:bg-primary-800'
          ].join(' ')
        }
      >
        {({ isActive }) => (
          <>
            <div className="flex items-center gap-[12px]">
              <Tooltip title={expanded ? '' : name}>
                <span>
                  <NavIcon />
                </span>
              </Tooltip>
              {expanded && name && (
                <span
                  className={`capitalize font-inter text-base transition-all duration-75 font-semibold text-gray-700 dark:text-gray-200 group-hover:text-black dark:group-hover:text-white 
                  ${expanded ? 'visible' : 'invisible'}
                  ${isActive ? 'text-primary' : 'text-default'}
                `}
                >
                  {name}
                </span>
              )}
            </div>
            {/* Lock Icon */}
            {expanded &&
              ((!activeFacebookProduct && name === 'Meta Ads') ||
                (!activeGoogleProduct && name === 'Google Ads') ||
                (!activeSEOProduct && name === 'SEO') ||
                (customer.temp && name === 'Settings')) && <LockIcon />}
            {!expanded &&
              ((!activeFacebookProduct && name === 'Meta Ads') ||
                (!activeGoogleProduct && name === 'Google Ads') ||
                (!activeSEOProduct && name === 'SEO') ||
                (customer.temp && name === 'Settings')) && (
                <LockIcon className="absolute bg-gray-300 dark:bg-gray-400 w-[16px] h-[16px] rounded-[16px] p-[1px] left-[64%] top-[4%]" />
              )}
            {/* Notification Number */}
            {name === 'Notifications' && notifCount > 0 && (
              <span
                className={`font-inter text-white text-[12px] font-[500] leading-[18px] rounded-[16px] border-[1px] border-error-600 bg-error-600 ${
                  expanded
                    ? 'px-[8px] py-[2px]'
                    : 'relative left-[-22px] top-[-10px] user-select-none px-[5px] py-0'
                }`}
              >
                {notifCount}
              </span>
            )}
          </>
        )}
      </NavLink>
    </div>
  )
}
