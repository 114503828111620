import React from 'react'
import { useSelector } from 'react-redux'
import { ToastSystem } from '../Notifications/ToastSystem'
import type { RootStatePub } from '../../store'

interface AccountLayoutProps {
  children: React.ReactElement
}

export function AccountLayout({ children }: AccountLayoutProps) {
  const brand = useSelector((store: RootStatePub) => store.global.brand)
  const darkMode = useSelector((store: RootStatePub) => store.global.darkMode)

  const isJDM = brand.name === 'jdm'

  return (
    <>
      <div className="absolute z-[101]">
        <ToastSystem />
      </div>
      <div className="flex items-center justify-center min-h-screen w-full">
        <div className="bg-white dark:bg-gray-900 flex items-start min-h-screen w-full">
          <div className="hidden lg:flex w-full dark:opacity-80 h-screen items-center justify-center">
            <div className="w-full hidden lg:flex justify-stretch z-10  ">
              {isJDM && (
                <img
                  className="w-full object-cover h-[100vh] max-w-none"
                  src="/images/login_bg.jpg"
                  alt="login background"
                />
              )}
              {!isJDM && (
                <>
                  <img
                    className="w-full object-cover h-[100vh] max-w-none dark:hidden"
                    src="/images/login_bg.svg"
                    alt="light login background"
                  />
                  <img
                    className="w-full object-cover h-[100vh] max-w-none hidden dark:block"
                    src="/images/login_bg_dark.svg"
                    alt="dark login background"
                  />
                </>
              )}
            </div>
            {!isJDM && (
              <div className="w-full hidden lg:flex flex-row gap-[3%] items-center justify-center lg:absolute z-10">
                <img
                  className="w-[253px] aspect-ratio-container max-w-none dark:hidden"
                  src="/images/phone1.png"
                  alt="light mode image"
                />
                <img
                  className="w-[295px] aspect-ratio-container max-w-none dark:hidden"
                  src="/images/phone2.png"
                  alt="light mode image"
                />
                <img
                  className="w-[253px] aspect-ratio-container max-w-none dark:hidden"
                  src="/images/phone3.png"
                  alt="light mode image"
                />
                <img
                  className="w-[253px] aspect-ratio-container max-w-none hidden dark:block"
                  src="/images/phone1-dark.png"
                  alt="dark mode image"
                />
                <img
                  className="w-[295px] aspect-ratio-container max-w-none hidden dark:block"
                  src="/images/phone2-dark.png"
                  alt="dark mode image"
                />
                <img
                  className="w-[253px] aspect-ratio-container max-w-none hidden dark:block"
                  src="/images/phone3-dark.png"
                  alt="dark mode image"
                />
              </div>
            )}
          </div>

          <div className="w-full bg-white dark:bg-gray-900 flex flex-col h-[100vh] items-start content-center z-[100] selection:text-base-900">
            <div className="relative top-[32px] left-[32px] w-[162px] h-[32px]">
              <img
                className="h-16 w-auto"
                src={darkMode ? brand.logoDark : brand.logo}
                alt={`${brand.displayName} Logo`}
              />
            </div>
            <div className="p-[5%] sm:p-0 w-auto sm:w-[360px] h-full flex flex-col justify-center self-center gap-[32px]">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
