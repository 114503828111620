import React from 'react'

export function LoadingChartLTR() {
  return (
    <div role="status" className="grid grid-rows-4 gap-[32px] animate-pulse">
      {['20%', '60%', '90%', '40%'].map((value, i) => (
        <div key={i} className={`w-[${value}] h-[30px] bg-gray-200 rounded-lg dark:bg-gray-500`} />
      ))}
      <span className="sr-only">Loading Contents</span>
    </div>
  )
}
