import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PreferenceOption } from './PreferenceOption'
import { ToggleButton } from '../../Buttons'
import { hideMessages as dashboardHideMessages } from '../../../store/dashboard'
import { hideMessages as adsHideMessages } from '../../../store/ads'
import { hideMessages as seoHideMessages } from '../../../store/seo'

export function HideMessagesOption() {
  const seo = useSelector((store) => store.seo.messages.hide)
  const ads = useSelector((store) => store.ads.messages.hide)
  const dashboard = useSelector((store) => store.dashboard.messages.hide)

  const dispatch = useDispatch()

  const onToggle = (event, action) => {
    const { checked } = event.target
    dispatch(action(checked))
  }

  return (
    <PreferenceOption
      title="Hide Messages"
      description="Toggle off the messages appearing on each page"
    >
      <div className="flex flex-col gap-[4px]">
        <ToggleButton
          label="Dashboard"
          checked={dashboard}
          onToggle={(event) => onToggle(event, dashboardHideMessages)}
        />
        <ToggleButton
          label="Ads"
          checked={ads}
          onToggle={(event) => onToggle(event, adsHideMessages)}
        />
        <ToggleButton
          label="SEO"
          checked={seo}
          onToggle={(event) => onToggle(event, seoHideMessages)}
        />
      </div>
    </PreferenceOption>
  )
}
