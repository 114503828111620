export const sortList = (list, sortBy, asc = true) =>
  [...list].sort((a, b) => {
    if (a[sortBy] > b[sortBy]) {
      return asc ? 1 : -1
    }

    if (a[sortBy] < b[sortBy]) {
      return asc ? -1 : 1
    }

    return 0
  })
