import React from 'react'

export function LoadingTable() {
  return (
    <div
      role="status"
      className="p-4 space-y-4 max-w-none border-0 border-transparent divide-y divide-gray-300  animate-pulse dark:divide-base-700 md:p-6 dark:border-base-700 w-full"
    >
      {[...Array(5)].map((_, i) => (
        <div key={i} className="flex justify-between items-center">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-base-900 w-24 mb-2.5" />
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-500" />
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-12" />
        </div>
      ))}
      <span className="sr-only">Loading Contents</span>
    </div>
  )
}
