import React from 'react'
import { dateToRelativeFormat } from '../../../helpers/dateToRelativeFormat'
import { LoadingTable } from '../../Loading'

export function Panel({
  title,
  loading,
  children,
  toggleText = null,
  onToggleClick = () => {},
  dateFrom = null
}) {
  return (
    <div className="rounded-[16px] bg-white p-4 dark:border-none dark:bg-gray-800 sm:p-6 xl:p-8 mb-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="font-inter text-[18px] font-[600] leading-[28px] text-gray-900 dark:text-base-50">
          {title}
        </h3>
        <h5 className="font-inter text-[14px] font-[400] leading-[28px] text-gray-500 dark:text-gray-400">
          {dateFrom ? dateToRelativeFormat(dateFrom) : ''}
        </h5>
      </div>
      <div className="flex flex-col">
        {loading && <LoadingTable />}
        {!loading && children}
      </div>
    </div>
  )
}
