export const getPaginationRange = (page, perPage, total) => {
  const from = (page - 1) * perPage
  let to = page * perPage
  if (to > total) {
    to = total
  }

  return {
    from,
    to
  }
}
