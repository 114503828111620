import { store } from '../store'

export const percentageFormatter = (value) => {
  const locale = store.getState().global.locale
  const fractionDigits = value >= 1 ? 0 : 2

  return new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  }).format(value)
}
