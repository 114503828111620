import React from 'react'
import { KeywordRatingPanel } from './KeywordRatingPanel'
import { HashTagIcon } from '../../Icons'

export function KeywordRating({ loading, keywordsSummary, newlyRanking }) {
  const { TOP1, TOP10, TOP20 } = keywordsSummary || {}
  const newlyRankingCount = newlyRanking.length

  const titleWithHashTag = (title) => (
    <>
      {title}
      <HashTagIcon />
      <span className="text-lg -ml-1 pr-1">1</span>
    </>
  )

  return (
    <div className="bg-white dark:bg-gray-800 dark:text-white rounded-[16px] dark:divide-base-700 divide-gray-200 divide-y grid grid-cols-1 md:divide-x md:divide-y-0 md:grid-cols-4 overflow-hidden">
      <KeywordRatingPanel
        loading={loading}
        tooltip="position1"
        title={titleWithHashTag('Position')}
        value={TOP1?.current}
        description="SEO Keywords summary Page 1"
        isUpTrend={TOP1?.change >= 0}
        trendValue={TOP1?.change ? `${TOP1?.change}%` : '-'}
        animate={TOP1?.change > 10}
      />
      <KeywordRatingPanel
        loading={loading}
        tooltip="page1"
        title={titleWithHashTag('Page')}
        value={TOP10?.current}
        description="SEO Keywords summary Top 10"
        isUpTrend={TOP10?.change >= 0}
        trendValue={TOP10?.change ? `${TOP10?.change}%` : '-'}
        animate={TOP10?.change > 8}
      />
      <KeywordRatingPanel
        loading={loading}
        tooltip="top20"
        title="Top 20"
        value={TOP20?.current}
        description="SEO Keywords summary Top 20"
        isUpTrend={TOP20?.change >= 0}
        trendValue={TOP20?.change ? `${TOP20?.change}%` : '-'}
        animate={TOP20?.change > 20}
      />
      <KeywordRatingPanel
        loading={loading}
        tooltip="newlyRanking"
        title="Newly Ranking"
        value={newlyRankingCount}
        description="SEO Keywords summary"
        isUpTrend={newlyRankingCount > 0}
        trendValue={newlyRankingCount > 0 ? newlyRankingCount : '-'}
      />
    </div>
  )
}
