import React from 'react'
import { ProductMessage } from '../../ProductMessage'

interface FirstMonthProps {
  onHideClick: () => void
}

export function FirstMonth({ onHideClick }: FirstMonthProps) {
  return (
    <ProductMessage title="The Ads Foundations" onHideClick={onHideClick}>
      <>
        <p className="paragraph-text">
          Your setup is now complete and we're working hard to get you results.
        </p>
        <p className="paragraph-text">
          We're conducting some product research and ensuring conversion tracking is working
          correctly. Over the month, we'll monitor your impressions and get in touch with you to
          discuss any new activity on your account. Make sure to check your dashboard regularly to
          see any updates or account changes.
        </p>
      </>
    </ProductMessage>
  )
}
