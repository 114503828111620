import React from 'react'
import { TableRowCell } from '../../Table'

interface Campaign {
  adSpend: null | number
  budget: null | number
  campaignName: string
  campaign_name: string
  clicks: null | number
  impressions: null | number
  reach: null | number
  channel: string
  convValue: null | number
  convValueRoas: null | number
  conversions: null | number
  cpc: null | number
  cr: null | number
  spend: null | number
}

interface TableRowProps {
  campaign: Campaign
  keys: string[]
}

const decimalPlacesMap: Record<string, number> = {
  clicks: 0,
  impressions: 0,
  conversions: 0,
  reach: 0,
  ctr: 2,
  cr: 2,
  cpc: 2,
  budget: 0,
  adSpend: 2,
  spend: 2,
  convValue: 2
}

const formatValue = (value: number | null, metric: string) => {
  if (value === null) return '-'
  if (value === 0) return '0'
  const decimalPlaces = decimalPlacesMap[metric]
  if (decimalPlaces === 0) {
    return Math.round(value).toLocaleString()
  } else {
    return parseFloat(value).toFixed(decimalPlaces)
  }
}

export function TableRow({ campaign, keys }: TableRowProps) {
  const campaignName = campaign.campaign_name || campaign.campaignName || ''

  return (
    <tr className="table-tr">
      <TableRowCell label={campaignName} className="p-4 text-left" />
      {keys.map((dataKey, index) => (
        <TableRowCell
          label={
            dataKey === 'ctr' || dataKey === 'cr'
              ? `${formatValue(campaign[dataKey], dataKey)}%`
              : `${dataKey === 'budget' || dataKey === 'adSpend' || dataKey === 'spend' || dataKey === 'convValue' ? '$' : ''}${formatValue(campaign[dataKey], dataKey)}`
          }
          key={index}
        />
      ))}
    </tr>
  )
}
