import React from 'react'
import { useDispatch } from 'react-redux'
import { Table } from '../../Table'
import { TableRow } from './TableRow'
import { TableHead } from './TableHead'
import { Panel } from '../Panel'
import { Pagination, paginate } from '../../Pagination'
import { updatePagination } from '../../../store/seo'

export function TargetedKeywords({ rankings, list, pagination, loading }) {
  const { targetedKeywords, targetedKeywordsHelp } = rankings

  const dispatch = useDispatch()

  const changePage = (page) => {
    dispatch(
      updatePagination({
        name: 'targetedKeywords',
        page,
        perPage
      })
    )
  }

  const handlePerPageChange = (newPerPage) => {
    dispatch(
      updatePagination({
        name: 'targetedKeywords',
        page,
        perPage: newPerPage
      })
    )
  }

  const { page, perPage } = pagination
  const { total, paginated } = paginate(list, page, perPage)

  return (
    <Panel title={targetedKeywords} help={targetedKeywordsHelp} loading={loading}>
      <>
        <Table
          head={<TableHead />}
          rows={paginated.map((keyword) => (
            <TableRow key={keyword.Id} keyword={keyword} rankings={rankings} />
          ))}
        />
        <Pagination
          total={total}
          page={page}
          perPage={perPage}
          onClick={changePage}
          handlePerPageChange={handlePerPageChange}
        />
      </>
    </Panel>
  )
}
