import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { UnlockProfileModal } from '../components/Modal'

export function withAuth(WrappedComponent) {
  const WithAuth = (props) => {
    const customer = useSelector((store) => store.global.customer)
    const [openModal, setOpenModal] = useState(true)

    if (customer.temp) {
      return openModal && <UnlockProfileModal onClose={() => setOpenModal(false)} />
    }

    return <WrappedComponent {...props} />
  }

  WithAuth.displayName = `withAuth(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`
  return WithAuth
}
