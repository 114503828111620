import { useState } from 'react'

type Pagination<T> = {
  page: number
  setPage: (page: number) => void
  itemsPerPage: number
  setItemsPerPage: (itemsPerPage: number) => void
  total: number
  paginated: T[]
}

const DEFAULT_PAGE = 1
const DEFAULT_ITEMS_PER_PAGE = 10

export const usePagination = <T>(list: T[]): Pagination<T> => {
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE)

  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage

  return {
    page,
    setPage,
    itemsPerPage,
    setItemsPerPage,
    total: list.length,
    paginated: list.slice(startIndex, endIndex)
  }
}
