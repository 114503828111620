import React from 'react'

export function PreferenceOption({ title, description, children }) {
  return (
    <div className="flex flex-col sm:flex-row justify-between sm:items-center py-4 gap-[8px]">
      <div className="flex flex-col gap-[4px]">
        <span className="font-sans font-[500] text-xl text-neutral-800 dark:text-white tracking-[-0.5px] leading-[28px]">
          {title}
        </span>
        <span className="font-inter font-[400] text-sm text-gray-600 dark:text-white leading-[20px]">
          {description}
        </span>
      </div>
      {children}
    </div>
  )
}
