import { createSlice } from '@reduxjs/toolkit'
import { formatGoogleAdsId } from '../helpers/formatGoogleAdsId'

const adsIdFromURL = window.location.pathname?.split('ads/')[1]?.split('/campaign')[0] || null
const campaignIdFromURL = window.location.pathname?.split('/').pop() || null

const getMessagesStateFromLocalStorage = () => {
  const messages = localStorage.getItem('messages')
    ? JSON.parse(localStorage.getItem('messages'))
    : {}

  return !!messages?.ads?.hide
}

const setMessagesStateInLocalStorage = (hide) => {
  const messages = localStorage.getItem('messages')
    ? JSON.parse(localStorage.getItem('messages'))
    : {}

  localStorage.setItem(
    'messages',
    JSON.stringify({
      ...messages,
      ads: { hide }
    })
  )
}

const initialState = {
  selectedAccountId: adsIdFromURL
    ? formatGoogleAdsId(adsIdFromURL)
    : JSON.parse(localStorage.getItem('selectedAdsId')) || window.customer?.adsAccounts?.[0],
  selectedCampaign: campaignIdFromURL,
  messages: {
    hide: getMessagesStateFromLocalStorage()
  },
  pagination: {
    campaigns: {
      page: 1,
      perPage: 10
    },
    shopping: {
      page: 1,
      perPage: 10
    },
    adGroups: {
      page: 1,
      perPage: 10
    },
    topAds: {
      page: 1,
      perPage: 10
    },
    keywords: {
      page: 1,
      perPage: 10
    }
  }
}

const changePage = (state, name, page, perPage) => ({
  ...state,
  pagination: {
    ...state.pagination,
    [name]: {
      perPage,
      page
    }
  }
})

const slice = createSlice({
  name: 'ads',
  initialState,
  reducers: {
    updatePagination: (state, { payload }) => {
      const { name, page, perPage } = payload
      return changePage(state, name, page, perPage)
    },
    hideMessages: (state, { payload }) => {
      const hide = !!payload

      setMessagesStateInLocalStorage(hide)
      return {
        ...state,
        messages: {
          hide
        }
      }
    },
    selectAccount: (state, { payload }) => ({
      ...state,
      selectedAccountId: payload
    }),
    selectCampaign: (state, { payload }) => ({
      ...state,
      selectedCampaign: payload
    })
  }
})

export const { updatePagination, hideMessages, selectAccount, selectCampaign } = slice.actions

export const { reducer: ads } = slice
