import React, { useState } from 'react'
import { ProductMessage } from '../../ProductMessage'
import { SendMessageModal } from '../../Modal'

export function Journey({ onHideClick }) {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <>
      <ProductMessage
        title="Your SEO Journey"
        className="text-body xl:lead dark:text-base-50"
        onHideClick={onHideClick}
      >
        <p className="paragraph-text">
          This rankings report updates as we work, so don't forget to check back regularly to gain
          an in-depth understanding of your account's performance. As always, if you have any
          questions or need any assistance understanding your dashboard, get in touch with us via
          the{' '}
          <a
            className="text-primary-600 hover:text-primary-700 hover:cursor-pointer"
            onClick={openModal}
          >
            booking link
          </a>{' '}
          our team can assist you with all your needs.
        </p>
      </ProductMessage>

      {open && <SendMessageModal onClose={closeModal} />}
    </>
  )
}
