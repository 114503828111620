import React from 'react'
import { useSelector } from 'react-redux'

export function Footer() {
  const brand = useSelector((store) => store.global.brand)
  const { proto, domain, displayName } = brand
  const { copyRightStartYear } = window.t?.branding || {}

  return (
    <p className="font-inter text-center font-[400] text-[14px] text-slate-600 dark:text-gray-300 mb-4 mt-2 p-[10px]">
      &copy; {copyRightStartYear}-{new Date().getFullYear()}{' '}
      <a
        href={`${proto}${domain}`}
        className="hover:underline transition-all duration-100"
        target="_blank"
      >
        {displayName}
      </a>
      . All rights reserved.
    </p>
  )
}
