import React from 'react'
import { useSelector } from 'react-redux'
import { FormTitle } from './FormTitle'
import { SkipButton } from './Button'
import { MicrosoftIcon } from '../Icons'
import { ConnectItem } from './ConnectItem'
import { ConnectGoogle } from './ConnectGoogle'
import { ConnectFacebook } from './ConnectFacebook'
import { SubmitButton } from '../Buttons/index'

interface ConnectAccountsProps {
  onComplete: () => void
}

export function ConnectAccounts({ onComplete }: ConnectAccountsProps) {
  const brand = useSelector((state) => state.global.brand.name)

  return (
    <>
      <FormTitle
        title="Let's connect your accounts to your dashboard"
        description="This gives our experts easy access to detailed data within each of your accounts"
      />
      <div className="w-[344px] flex flex-col gap-[24px] pt-[16px]">
        <div className="flex flex-col bg-white dark:bg-base-900 rounded-lg">
          <div className="px-[16px] *:border-b-[1px] *:border-gray-300 *:border-opacity-[40%] last:border-none">
            <ConnectGoogle brand={brand} />
            <ConnectFacebook brand={brand} />
            <ConnectItem
              disabled
              name="Microsoft"
              icon={<MicrosoftIcon />}
              isLoading={false}
              onClick={() => {}}
            />
          </div>
        </div>
        <SubmitButton label="Complete Set Up" onClick={onComplete} />
      </div>
      <SkipButton onClick={onComplete} />
    </>
  )
}
