import React from 'react'
import { TableRowCell } from '../../Table'
import { addOrdinalSuffix } from '../../../helpers/addOrdinalSuffix'
import { keywordDifficulty } from '../../../helpers/keywordDifficulty'
import { keywordDifficultyToColor } from '../../../helpers/keywordDifficultyToColor'

export function TableRow({ keyword, rankings }) {
  const {
    keyword_merged: key,
    volume_merged: vol,
    keyword_difficulty_merged: keyDifficulty,
    cpc_merged: cpc,
    best_position: bestPosition,
    best_position_diff: bestPositionDiff,
    sum_traffic: traffic,
    sum_traffic_prev: trafficPrev,
    last_update: lastUpdate,
    last_update_prev: lastUpdatePrev,
    serp_target_positions_count: SERPcount,
    best_position_url: URL
  } = keyword

  return (
    <tr className="table-tr">
      <TableRowCell label={key || ''} className="p-4 text-left" />
      <TableRowCell label={vol || ''} />
      <TableRowCell
        label={keyDifficulty ? `${keywordDifficulty(keyDifficulty)} (${keyDifficulty})` : ''}
        className={'font-[500] ' + keywordDifficultyToColor(keyDifficulty)}
      />
      <TableRowCell label={bestPosition ? addOrdinalSuffix(bestPosition) : ''} />
      <TableRowCell
        label={bestPositionDiff ? (bestPositionDiff === 0 ? '~' : bestPositionDiff) : ''}
      />
      <TableRowCell label={traffic ? `${traffic < 10 ? 10 : traffic}` : ''} />
      <TableRowCell label={trafficPrev ? (trafficPrev === traffic ? '' : trafficPrev) : ''} />
      <TableRowCell label={URL || ''} className="p-4 text-right float-end" isLink />
    </tr>
  )
}
