import React from 'react'
import { NoData } from '../NoData'

export function NoNewKeywords() {
  return (
    <>
      <NoData
        title="No new keywords have ranked"
        subTextOne="Hold on tight while we wait for new keywords to rank!"
        subtextTwo=""
        showIcon={true}
      />
    </>
  )
}
