import React from 'react'
import { AdScheduleTable } from './AdScheduleTable'
import { Panel } from '../Panel'
import { NewPagination, usePagination } from '../../Pagination'
import { AdScheduleChart } from '../../Chart/AdScheduleChart'
import { AdScheduleMetric } from '../types'

interface AdScheduleProps {
  list: AdScheduleMetric[]
  loading: boolean
  dateFrom: string
}

export function AdSchedule({ loading, list, dateFrom }: AdScheduleProps) {
  const pagination = usePagination<AdScheduleMetric>(list)

  return (
    <Panel title="Ad Schedule" loading={loading} dateFrom={dateFrom}>
      <>
        <AdScheduleChart list={list} />
        <AdScheduleTable list={pagination.paginated} />
        <NewPagination
          total={pagination.total}
          page={pagination.page}
          itemsPerPage={pagination.itemsPerPage}
          setPage={pagination.setPage}
          setItemsPerPage={pagination.setItemsPerPage}
        />
      </>
    </Panel>
  )
}
