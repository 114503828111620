import React from 'react'
import { useSelector } from 'react-redux'
import ReactECharts from 'echarts-for-react'
import { getGraphColorSets, convertGraphColor } from './getGraphColorSets'
import { NoData } from '../NoData'

const ops = {
  renderer: 'canvas',
  useCoarsePointer: null
}

const getOptions = (
  darkMode,
  seriesData,
  seoProduct,
  firstPart,
  secondPart,
  thirdPart,
  setUpIdx
) => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: darkMode ? '#282828' : '#6a7985',
        formatter: (params) =>
          params.axisDimension === 'x'
            ? new Date(params.value).toLocaleDateString('en-AU')
            : Math.round(params.value)
      }
    }
  },
  color: getGraphColorSets(darkMode).slice(15, 18),
  backgroundColor: 'transparent',
  grid: {
    containLabel: true,
    left: -5,
    right: 10,
    top: 10,
    bottom: 0
  },
  xAxis: [
    {
      type: 'time',
      data: seriesData.x,
      axistick: {
        alignWithLabel: true
      },
      axisLine: {
        onZero: false,
        lineStyle: {
          color: '#D1D5DB' // controls the color of the x-axis
        }
      },
      axisLabel: {
        color: darkMode ? '#fff' : '#475467',
        fontFamily: 'inter',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: 18
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      alignticks: true,
      position: 'left',
      axisLabel: {
        color: darkMode ? '#fff' : '#344054',
        margin: 20,
        align: 'center',
        fontFamily: 'inter',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: 18
      },
      splitLine: {
        lineStyle: {
          color: darkMode ? '#303740' : '#f4f5f7' // Color of y-axis grid lines
        }
      }
    }
  ],
  series: [
    //* SERIES
    // Greyed out, first half of the graph
    {
      name: '1-3',
      data: firstPart[0],
      symbol: 'none',
      type: 'line',
      connectNulls: true,
      smooth: false,
      animationEasing: 'quadraticIn',
      lineStyle: {
        width: 2,
        color: convertGraphColor(darkMode ? 'text-[#475467]' : 'text-[#D0D5DD]') // getGraphColorSets(darkMode)[0]
      },
      emphasis: {
        focus: 'series'
      }
    },
    {
      name: '4-10',
      data: firstPart[1],
      symbol: 'none',
      type: 'line',
      connectNulls: true,
      smooth: false,
      animationEasing: 'quadraticIn',
      lineStyle: {
        width: 2,
        color: convertGraphColor(darkMode ? 'text-[#3E4A5E]' : 'text-[#E0E3E9]') // getGraphColorSets(darkMode)[1]
      },
      emphasis: {
        focus: 'series'
      }
    },
    {
      name: '11+',
      data: firstPart[2],
      symbol: 'none',
      type: 'line',
      connectNulls: true,
      smooth: false,
      animationEasing: 'quadraticIn',
      lineStyle: {
        width: 2,
        color: convertGraphColor(darkMode ? 'text-[#354155]' : 'text-[#ECEEF2]') // getGraphColorSets(darkMode)[2]
      },
      emphasis: {
        focus: 'series'
      }
    },
    // Colored, second half of the graph (after sign up date)
    {
      name: '1-3',
      data: secondPart[0],
      symbol: 'none',
      type: 'line',
      connectNulls: true,
      smooth: false,
      animationEasing: 'quadraticIn',
      lineStyle: {
        width: 2,
        color: convertGraphColor(darkMode ? 'text-primary-200' : 'text-primary-400'), // getGraphColorSets(darkMode)[15],
        opacity: thirdPart ? 0.5 : 1
      },
      emphasis: {
        focus: 'series'
      }
    },
    {
      name: '4-10',
      data: secondPart[1],
      symbol: 'none',
      type: 'line',
      connectNulls: true,
      smooth: false,
      animationEasing: 'quadraticIn',
      lineStyle: {
        width: 2,
        color: convertGraphColor(darkMode ? 'text-primary-400' : 'text-primary-600'), // getGraphColorSets(darkMode)[16],
        opacity: thirdPart ? 0.5 : 1
      },
      emphasis: {
        focus: 'series'
      }
    },
    {
      name: '11+',
      data: secondPart[2],
      symbol: 'none',
      type: 'line',
      connectNulls: true,
      smooth: false,
      animationEasing: 'quadraticIn',
      lineStyle: {
        width: 2,
        color: convertGraphColor(darkMode ? 'text-primary-600' : 'text-primary-800'), // getGraphColorSets(darkMode)[17],
        opacity: thirdPart ? 0.5 : 1
      },
      emphasis: {
        focus: 'series'
      }
    },
    // Colored, third half of the graph (after set up completed date)
    {
      name: '1-3',
      data: thirdPart[0],
      symbol: 'none',
      type: 'line',
      connectNulls: true,
      smooth: false,
      animationEasing: 'quadraticIn',
      lineStyle: {
        width: 2,
        color: convertGraphColor(darkMode ? 'text-primary-200' : 'text-primary-400') // getGraphColorSets(darkMode)[15],
      },
      emphasis: {
        focus: 'series'
      }
    },
    {
      name: '4-10',
      data: thirdPart[1],
      symbol: 'none',
      type: 'line',
      connectNulls: true,
      smooth: false,
      animationEasing: 'quadraticIn',
      lineStyle: {
        width: 2,
        color: convertGraphColor(darkMode ? 'text-primary-400' : 'text-primary-600') // getGraphColorSets(darkMode)[16],
      },
      emphasis: {
        focus: 'series'
      }
    },
    {
      name: '11+',
      data: thirdPart[2],
      symbol: 'none',
      type: 'line',
      connectNulls: true,
      smooth: false,
      animationEasing: 'quadraticIn',
      lineStyle: {
        width: 2,
        color: convertGraphColor(darkMode ? 'text-primary-600' : 'text-primary-800') // getGraphColorSets(darkMode)[17],
      },
      emphasis: {
        focus: 'series'
      }
    },
    //* VERTICAL LINES
    {
      // vertical line label
      type: 'line',
      symbol: 'none',
      label: { show: false }, // hide the label
      data: [Math.min(seriesData.x), Math.max(seriesData.x)],
      markLine: {
        symbol: 'none', // hide the arrow at the top
        data: [
          // sign up line
          {
            symbol: 'none', // hide the circle at the bottom
            xAxis: firstPart[0].length === 0 ? 0 : firstPart[0][firstPart[0].length - 1][0],
            lineStyle: {
              type: 'solid',
              width: 2,
              color: convertGraphColor(darkMode ? 'text-gray-600' : 'text-gray-200') // getGraphColorSets(darkMode)[8]
            }
          },
          // set up complete vertical line
          {
            symbol: 'none', // hide the circle at the bottom
            xAxis: secondPart[0].length === 0 ? 0 : secondPart[0][secondPart[0].length - 1][0],
            lineStyle: {
              type: 'solid',
              width: setUpIdx === -1 ? null : 2,
              color: convertGraphColor(darkMode ? 'text-gray-600' : 'text-gray-200') // getGraphColorSets(darkMode)[8]
            }
          }
        ],
        label: {
          show: false
        }
      }
    },
    //* CIRCLE LABELS
    {
      // sign up date label
      type: 'line',
      markPoint: {
        data: [
          {
            coord:
              firstPart[2].length === 0
                ? 0
                : [
                    firstPart[2][firstPart[2].length - 1][0],
                    Math.max(...seriesData.y[2].map((item) => item[1])) / 2
                  ],
            symbol: 'circle',
            symbolSize: 16,
            label: {
              show: false,
              position:
                firstPart[2].length === 0
                  ? 0
                  : firstPart[2][firstPart[2].length - 1][0] >
                      seriesData.x[parseInt((seriesData.x.length - 1) / 2)]
                    ? [-90, 7]
                    : [7, 7]
            },
            itemStyle: {
              color: convertGraphColor(darkMode ? 'text-indigo-500' : 'text-indigo-500'), // getGraphColorSets(darkMode)[10],
              borderWidth: 8,
              borderColor: convertGraphColor(darkMode ? 'text-indigo-100' : 'text-indigo-100'), // getGraphColorSets(darkMode)[9],
              shadowColor: 'rgba(16, 24, 40, 0.10)',
              shadowBlur: 3,
              shadowOffsetY: 1
            },
            emphasis: {
              label: {
                backgroundColor: convertGraphColor(
                  darkMode ? 'text-indigo-100' : 'text-indigo-100'
                ), // getGraphColorSets(darkMode)[9],
                padding: [0, 7, 0, 5],
                borderWidth: 7,
                borderColor: convertGraphColor(darkMode ? 'text-indigo-100' : 'text-indigo-100'), // getGraphColorSets(darkMode)[9],
                borderRadius: 8,
                formatter: 'Account Created',
                show: true,
                color: convertGraphColor(darkMode ? 'text-indigo-600' : 'text-indigo-600'), //  getGraphColorSets(darkMode)[11],
                fontSize: 12,
                fontFamily: 'inter',
                fontWeight: 500,
                shadowColor: 'rgba(16, 24, 40, 0.10)',
                shadowBlur: 3,
                shadowOffsetY: 1
              }
            }
          },
          {
            coord:
              setUpIdx === -1
                ? null
                : secondPart[2].length === 0
                  ? 0
                  : [
                      secondPart[2][secondPart[2].length - 1][0],
                      Math.max(...seriesData.y[2].map((item) => item[1])) / 1.5
                    ],
            symbol: 'circle',
            symbolSize: 16,
            label: {
              show: false,
              position:
                setUpIdx === -1
                  ? null
                  : firstPart[2].length === 0
                    ? 0
                    : firstPart[2][firstPart[2].length - 1][0] >
                        seriesData.x[parseInt((seriesData.x.length - 1) / 2)]
                      ? [-90, 7]
                      : [7, 7]
            },
            itemStyle: {
              color: convertGraphColor(darkMode ? 'text-indigo-500' : 'text-indigo-500'), // getGraphColorSets(darkMode)[10],
              borderWidth: 8,
              borderColor: convertGraphColor(darkMode ? 'text-indigo-100' : 'text-indigo-100'), // getGraphColorSets(darkMode)[9],
              shadowColor: 'rgba(16, 24, 40, 0.10)',
              shadowBlur: 3,
              shadowOffsetY: 1
            },
            emphasis: {
              label: {
                backgroundColor: convertGraphColor(
                  darkMode ? 'text-indigo-100' : 'text-indigo-100'
                ), // getGraphColorSets(darkMode)[9],
                padding: [0, 7, 0, 5],
                borderWidth: 7,
                borderColor: convertGraphColor(darkMode ? 'text-indigo-100' : 'text-indigo-100'), // getGraphColorSets(darkMode)[9],
                borderRadius: 8,
                formatter: 'Setup Complete',
                show: true,
                color: convertGraphColor(darkMode ? 'text-indigo-600' : 'text-indigo-600'), //  getGraphColorSets(darkMode)[11],
                fontSize: 12,
                fontFamily: 'inter',
                fontWeight: 500,
                shadowColor: 'rgba(16, 24, 40, 0.10)',
                shadowBlur: 3,
                shadowOffsetY: 1
              }
            }
          }
        ]
      }
    }
  ],
  animationEasing: 'elasticOut'
})

export function KeywordsChart({ data = [], seoProduct }) {
  const darkMode = useSelector((store) => store.global.darkMode)

  const getData = () => {
    const yData = []
    if (data.length > 0) {
      for (let i = 0; i < Object.keys(data[0]).length; i++) {
        if (Object.keys(data[0])[i] === 'date') continue
        yData.push(data.map((item) => [item.date, parseInt(item[Object.keys(data[0])[i]] || null)]))
      }
    }
    return {
      x: data.map((item) => item.date),
      y: yData
    }
  }

  // split the data into three series based on sign up, and set up complete dates
  const seriesData = getData()
  const signUpIdx =
    seoProduct?.signupDate && seriesData.y.length > 0 && seriesData.y[0]
      ? seriesData.y[0].findIndex(
          ([dateString]) => new Date(dateString) >= new Date(seoProduct.signupDate)
        )
      : -1
  const setUpIdx =
    seoProduct?.setupCompletedDate && seriesData.y.length > 0 && seriesData.y[0]
      ? seriesData.y[0].findIndex(
          ([dateString]) => new Date(dateString) >= new Date(seoProduct.setupCompletedDate)
        )
      : -1

  const firstPart = []
  const secondPart = []
  const thirdPart = []

  for (let i = 0; i < seriesData.y.length; i++) {
    if (signUpIdx !== -1) {
      firstPart.push(seriesData.y[i].slice(0, signUpIdx))
      secondPart.push(seriesData.y[i].slice(signUpIdx, setUpIdx))
      thirdPart.push(seriesData.y[i].slice(setUpIdx))
    } else {
      firstPart.push(seriesData.y[i])
      secondPart.push(seriesData.y[i].slice(signUpIdx))
      thirdPart.push(seriesData.y[i])
    }
  }

  if (firstPart.length === 0 && secondPart.length === 0 && thirdPart.length === 0) {
    return (
      <NoData
        title="Keyword Ratings Unavailable"
        subTextOne="No Keyword Rating data was detected."
        showIcon={false}
      />
    )
  }

  const options = getOptions(
    darkMode,
    seriesData,
    seoProduct,
    firstPart,
    secondPart,
    thirdPart,
    setUpIdx
  )

  return <ReactECharts option={options} ops={ops} />
}
