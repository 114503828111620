import React from 'react'
import { LoadingTable } from '../../Loading'

interface TableRowEmptyProps {
  loading?: boolean
}

export function TableRowEmpty({ loading = false }: TableRowEmptyProps) {
  return (
    <tr className="table-tr">
      <td
        colSpan={7}
        className="p-4 text-sm font-normal text-base-900 whitespace-nowrap dark:text-base-50 text-center w-full"
      >
        {loading ? <LoadingTable /> : <span className="text-semibold">No data available yet.</span>}
      </td>
    </tr>
  )
}
