import React from 'react'
import { useDispatch } from 'react-redux'
import { Table } from '../../Table'
import { TableRow } from './TableRow'
import { TableHead } from './TableHead'
import { Panel } from '../Panel'
import { Pagination, paginate } from '../../Pagination'
import { updatePagination } from '../../../store/seo'
import { LoadingTable } from '../../Loading'
import { NoNewKeywords } from '../NoNewKeywords'

export function RankingKeywords({ rankings, list, pagination, loading }) {
  const { rankingKeywords, rankingKeywordsHelp } = rankings

  const dispatch = useDispatch()

  const changePage = (page) => {
    dispatch(
      updatePagination({
        name: 'rankingKeywords',
        page,
        perPage
      })
    )
  }

  const handlePerPageChange = (newPerPage) => {
    dispatch(
      updatePagination({
        name: 'rankingKeywords',
        page,
        perPage: newPerPage
      })
    )
  }

  if (loading) {
    return (
      <div className="flex flex-col">
        <LoadingTable />
      </div>
    )
  }

  const { page, perPage } = pagination
  const { total, paginated } = paginate(Object.values(list), page, perPage)

  return (
    <Panel title={rankingKeywords} help={rankingKeywordsHelp} loading={loading}>
      <>
        {paginated.length === 0 && <NoNewKeywords />}
        {paginated.length > 0 && (
          <Table
            head={<TableHead />}
            rows={paginated.map((keyword, idx) => (
              <TableRow key={idx} keyword={keyword} rankings={rankings} />
            ))}
          />
        )}
        <Pagination
          total={total}
          page={page}
          perPage={perPage}
          onClick={changePage}
          handlePerPageChange={handlePerPageChange}
        />
      </>
    </Panel>
  )
}
