import React from 'react'

export function TabItem({ label, isActive, onClick, isNew = false }) {
  const activeClassName = isActive
    ? [
        'underline',
        'underline-offset-2',
        'sm:underline-offset-8',
        'decoration-2',
        'decoration-primary-600'
      ]
    : ['decoration-red-400']

  const className = [
    'py-[10px]',
    'sm:py-2',
    'my-1',
    'pr-2',
    'md:pr-4',
    'lg:pr-6',
    'inline-flex',
    'items-center',
    'justify-center',
    'font-medium',
    'hover:underline',
    'hover:underline-offset-2',
    'sm:hover:underline-offset-8',
    'hover:decoration-2',
    'hover:decoration-primary-400',
    'text-center',
    'text-base-900',
    'text-sm',
    'sm:text-base',
    'capitalize',
    'focus:border-none',
    'focus:ring-0',
    'dark:text-base-50',
    ...activeClassName
  ].join(' ')

  return (
    <>
      <button className={className} onClick={onClick}>
        {label}
      </button>
      {isNew && (
        <span className="ml-2 relative left-[-33px] top-[-5px]">
          <span className="text-xs text-white bg-red-500 rounded-full px-2 py-1 font-semibold tracking-wide">
            New
          </span>
        </span>
      )}
    </>
  )
}
