/**
 * Convert a keywords trend to tailwind styling
 *
 * @param {number} isDownTrend The trend
 * @returns {string} The styling
 */
export const downTrendStyling = (isDownTrend: boolean): string => {
  const baseStyle = 'px-[12px] py-[4px] rounded-[12px] '
  const styleTrend = isDownTrend
    ? 'bg-red-200 text-red-800 dark:bg-red-400'
    : 'bg-green-100 dark:bg-green-200 text-green-800'
  return baseStyle + styleTrend
}
