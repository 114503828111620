import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import spacetime from 'spacetime'
import { addToast } from '../../store/global'
import { FacebookIcon } from '../Icons'
import { ConnectItem } from './ConnectItem'
import { ConnectFacebookItem } from './ConnectFacebookItem'
import { useOAuthURLMutation, useConnectFacebookAdsMutation } from '../../services/onboarding'
import { getFacebookTimezoneId } from './helpers/getFacebookTimezoneId'

interface ConnectFacebookProps {
  brand: string
}

type ConnectionState = Record<
  string,
  {
    name: string
    connected: boolean
  }
>

type FacebookParams = {
  pageNames: string[]
  pageIds: string[]
  adAccountNames: string[]
  adAccountIds: string[]
  businessIds: string[]
  ownerId: string
}

const getFacebookParams = (): FacebookParams => {
  const params = new URLSearchParams(window.location.search)

  return {
    pageNames: (params.get('pageNames') || '').split(',').filter(Boolean),
    pageIds: (params.get('pageIds') || '').split(',').filter(Boolean),
    adAccountNames: (params.get('adAccountNames') || '').split(',').filter(Boolean),
    adAccountIds: (params.get('adAccountIds') || '').split(',').filter(Boolean),
    businessIds: (params.get('businessIds') || '').split(',').filter(Boolean),
    ownerId: params.get('ownerId') || ''
  }
}

export function ConnectFacebook({ brand }: ConnectFacebookProps) {
  const dispatch = useDispatch()

  const [mainAccountConnecting, setMainAccountConnecting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [facebookPages, setFacebookPages] = useState<ConnectionState>({})
  const [facebookAdAccounts, setFacebookAdAccounts] = useState<ConnectionState>({})

  const [oAuthURL] = useOAuthURLMutation()
  const [connectFacebookAds] = useConnectFacebookAdsMutation()

  const params = getFacebookParams()

  useEffect(() => {
    const pages = params.pageNames.reduce((acc, name, index) => {
      acc[params.pageIds[index]] = {
        name,
        connected: false
      }
      return acc
    }, {})

    const adAccounts = params.adAccountNames.reduce((acc, name, index) => {
      acc[params.pageIds[index]] = {
        name: name.replace(/act_/, ''),
        connected: false
      }
      return acc
    }, {})

    setFacebookPages(pages)
    setFacebookAdAccounts(adAccounts)

    if (params.ownerId !== '') {
      connectAccount()
    }
  }, [])

  const connectMainAccount = async () => {
    setMainAccountConnecting(true)

    const response = await oAuthURL({ brand, type: 'fblogin' })
    const url = response.data?.url || ''

    window.location.href = url
  }

  const connectAccount = async () => {
    if (isLoading) {
      return
    }

    setIsLoading(true)

    try {
      const { error, response } = await connectFacebookAds({
        pageIds: params.pageIds.join(','),
        businessIds: params.businessIds.join(','),
        adAccountIds: params.adAccountIds.join(','),
        ownerId: params.ownerId,
        timezone: getFacebookTimezoneId(spacetime.now().tz).toString(),
        bscope: brand,
        // FIXME: find better way to get currency
        currency: JSON.parse(localStorage.getItem('ads.reports'))
          ? JSON.parse(localStorage.getItem('ads.reports')).data[0]?.data?.customer?.currencyCode
          : 'AUD'
      })

      if (error) {
        throw new Error(
          error?.data?.error ||
            'An error occurred while trying to connect your account. Please try again'
        )
      }

      const pages = { ...facebookPages }
      const accounts = { ...facebookAdAccounts }

      for (const pageId in response.pageStatus) {
        pages[pageId].connected = response.pageStatus[pageId].status === 200
        setFacebookPages(pages)
      }
      for (const adAccId in response.adAccountStatus) {
        accounts[adAccId].connected = response.adAccountStatus[adAccId].status === 200
        setFacebookAdAccounts(accounts)
      }

      dispatch(
        addToast({
          title: 'Success',
          subtext: `Your Facebook Ads has been successfully connected`,
          type: 'success'
        })
      )
    } catch (error) {
      dispatch(
        addToast({
          title: 'Error',
          subtext: error.message,
          type: 'error'
        })
      )
    }

    setIsLoading(false)
  }

  return (
    <div>
      <ConnectItem
        disabled
        name="Facebook"
        connected={params.ownerId !== ''}
        icon={<FacebookIcon />}
        isLoading={mainAccountConnecting}
        onClick={connectMainAccount}
      />
      {params.ownerId !== '' && (
        <div className="flex flex-col gap-3 justify-between bg-neutral-100 dark:bg-neutral-700 rounded-lg p-4 mb-4 mx-4 text-gray-700 dark:text-gray-100 text-sm leading-5">
          <ConnectFacebookItem
            name="Pages"
            isLoading={isLoading}
            items={Object.values(facebookPages)}
          />
          <ConnectFacebookItem
            name="Ad Accounts"
            isLoading={isLoading}
            items={Object.values(facebookAdAccounts)}
          />
        </div>
      )}
    </div>
  )
}
