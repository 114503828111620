import { api } from './api'
import { Tag } from './tags'

export const productsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProducts: build.query({
      query: ({ platform, brand }) => ({
        url: 'cpanel/getProducts',
        method: 'POST',
        body: {
          platform,
          brand
        }
      }),
      providesTags: (response, error, id) => [{ type: Tag.Products, id }],
      transformResponse: (response, _, arg) => {
        const { platform, brand } = arg
        const { sectors = [] } = response

        const sector =
          sectors.find((sector) => sector.sectorName === 'Trade') ||
          sectors.find((sector) => sector.sectorName === 'Ecommerce') ||
          sectors[0]

        const { products } =
          sector?.productTerms?.find((term) => term.termName === 'No Fixed Term') || {}

        const result = (products || [])
          .filter((product) => product.setupProductId) // filter out empty products
          .sort((a, b) => a.productDisplayPrice - b.productDisplayPrice) // sort by price

        // cut out certain packages from SEO, for different brands
        if (platform === 'SEO') {
          return result.filter((product) => !product.productName.includes('Standard'))
        }

        return result
      }
    })
  })
})

export const { useGetProductsQuery } = productsApi
