import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PurchaseModal } from '../Modal/PurchaseModal'
import { useGetProductsQuery } from '../../services/products'
import type { RootState } from '../../store'
import { extractAccessKey } from '../../helpers/extractAccessKey'
import { isProductActive } from '../../helpers/isProductActive'
import { useCheckAccessKeyQuery } from '../../services/cpanel'

const accessKey = extractAccessKey()

interface PurchasePopupHandlerProps {
  pageType: string
}

export function PurchasePopupHandler({ pageType }: PurchasePopupHandlerProps) {
  const [openPurchasePopup, setOpenPurchasePopup] = useState(false)
  const [packages, setPackages] = useState({ google: [], facebook: [], seo: [] })
  const activeProducts = useSelector((store: RootState) => store.global.customer.activeProducts)
  const brand = useSelector((store: RootState) => store.global.brand.displayName)

  const accessKeyValid = useCheckAccessKeyQuery(accessKey)
  const googlePackages = useGetProductsQuery({ platform: 'Google Ads', brand })
  const facebookPackages = useGetProductsQuery({ platform: 'Meta', brand })
  const seoPackages = useGetProductsQuery({ platform: 'SEO', brand })

  useEffect(() => {
    if (googlePackages.isLoading || facebookPackages.isLoading || seoPackages.isLoading) {
      return
    }

    setPackages({
      google: googlePackages.data,
      facebook: facebookPackages.data,
      seo: seoPackages.data
    })
  }, [googlePackages, facebookPackages, seoPackages])

  useEffect(() => {
    let isOpen =
      (pageType === 'Ads' &&
        !activeProducts.find(
          (product) =>
            product.adWordsManagement && isProductActive(product.adWordsManagement?.status)
        )) ||
      (pageType === 'Facebook' &&
        !activeProducts.find(
          (product) => product.socialMarketing && isProductActive(product.socialMarketing?.status)
        )) ||
      (pageType === 'Seo' &&
        !activeProducts.find((product) => product.seo && isProductActive(product.seo?.status)))

    // Override isOpen if accessKey matches
    if (accessKeyValid.data) {
      isOpen = false
    }

    if (!accessKeyValid.isLoading) {
      setOpenPurchasePopup(isOpen)
    }
  }, [packages, accessKeyValid])

  useEffect(() => {
    if (accessKeyValid.data) {
      setOpenPurchasePopup(false)
    }
  }, [])

  if (!accessKeyValid.data) {
    // To ensure popup does not load on initial render before url param is extracted
    return (
      openPurchasePopup && (
        <PurchaseModal
          onClose={() => setOpenPurchasePopup(false)}
          packageList={packages[pageType.toLowerCase()]}
          platform={pageType.toLowerCase()}
        />
      )
    )
  }
}
