import React from 'react'

interface FormTitleProps {
  title: string
  description: string
}

export function FormTitle({ title, description }: FormTitleProps) {
  return (
    <div className="dark:text-white grid gap-[7px] text-center">
      <span className="font-bold text-2xl leading-8 tracking-[-0.6px]">{title}</span>
      <span className="font-normal text-lg leading-6">{description}</span>
    </div>
  )
}
