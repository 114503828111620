import React from 'react'
import { ArrowDownIcon, ArrowUpIcon } from '../../Icons'

export function TableHead({ onSortClick, sortBy, sortAsc }) {
  const getSortIcon = (sortByName) => {
    if (sortByName !== sortBy) {
      return null
    }
    return sortAsc ? (
      <ArrowDownIcon className="w-[16px] h-[16px]" />
    ) : (
      <ArrowUpIcon className="w-[16px] h-[16px]" />
    )
  }

  return (
    <tr className="bg-gray-50 dark:bg-gray-700 border-b-[1px] border-b-gray-200 dark:border-b-gray-700 text-gray-600 dark:text-gray-300 text-left text-[12px] leading-[18px]">
      <th
        className="cursor-pointer whitespace-nowrap py-[12px] px-[24px] font-[500] hover:bg-gray-100 dark:hover:bg-gray-800 transition duration-100"
        onClick={() => onSortClick('invoiceNumber')}
      >
        <div className="flex flex-row gap-[4px]">
          <span>Invoice</span>
          {getSortIcon('invoiceNumber')}
        </div>
      </th>
      <th
        className="cursor-pointer whitespace-nowrap py-[12px] px-[24px] font-[500] hover:bg-gray-100 dark:hover:bg-gray-800 transition duration-100"
        onClick={() => onSortClick('productName')}
      >
        <div className="flex flex-row gap-[4px]">
          <span>Product</span>
          {getSortIcon('productName')}
        </div>
      </th>
      <th
        className="cursor-pointer whitespace-nowrap py-[12px] px-[24px] font-[500] hover:bg-gray-100 dark:hover:bg-gray-800 transition duration-100"
        onClick={() => onSortClick('issueDate')}
      >
        <div className="flex flex-row gap-[4px]">
          <span>Date Issued</span>
          {getSortIcon('issueDate')}
        </div>
      </th>
      <th
        className="cursor-pointer whitespace-nowrap py-[12px] px-[24px] font-[500] hover:bg-gray-100 dark:hover:bg-gray-800 transition duration-100"
        onClick={() => onSortClick('dueDate')}
      >
        <div className="flex flex-row gap-[4px]">
          <span>Due Date</span>
          {getSortIcon('dueDate')}
        </div>
      </th>
      <th
        className="cursor-pointer whitespace-nowrap py-[12px] px-[24px] font-[500] hover:bg-gray-100 dark:hover:bg-gray-800 transition duration-100"
        onClick={() => onSortClick('totalDisplay')}
      >
        <div className="flex flex-row gap-[4px]">
          <span>Amount</span>
          {getSortIcon('totalDisplay')}
        </div>
      </th>
      <th
        className="cursor-pointer whitespace-nowrap py-[12px] px-[24px] font-[500] hover:bg-gray-100 dark:hover:bg-gray-800 transition duration-100"
        onClick={() => onSortClick('status')}
      >
        <div className="flex flex-row gap-[4px]">
          <span>Status</span>
          {getSortIcon('status')}
        </div>
      </th>
      <th
        className="cursor-pointer whitespace-nowrap py-[12px] px-[24px] font-[500] hover:bg-gray-100 dark:hover:bg-gray-800 transition duration-100"
        onClick={() => onSortClick('totalDisplay')}
      >
        <div className="flex flex-row gap-[4px]">
          <span>Balance</span>
          {getSortIcon('totalDisplay')}
        </div>
      </th>
    </tr>
  )
}
