import React, { useState } from 'react'
import { Modal } from './Modal'
import { ButtonClose } from '../Buttons'
import { UnlockIcon, LoadingIcon } from '../Icons'
import { useLoginMutation } from '../../services/auth'

export function UnlockProfileModal({ onClose }) {
  const [{ username, password }, setLoginDetails] = useState({
    username: '',
    password: ''
  })
  const [isExiting, setIsExiting] = useState(false)

  const isUsernameValid = () => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(username)
  const isPasswordValid = () => password.length > 3

  const [login, { isLoading, isSuccess }] = useLoginMutation()

  const onSubmit = () => {
    if (isUsernameValid() && isPasswordValid()) {
      login({ username, password })
    }
  }

  if (isSuccess) {
    window.location.reload()
  }

  const onChange = (event) => {
    const { name, value } = event.target

    setLoginDetails({
      username,
      password,
      [name]: value
    })
  }

  const handleClose = () => {
    setIsExiting(true) // start exit animation
    setTimeout(onClose, 150) // delay to match exit animation duration
  }

  return (
    <Modal handleClose={handleClose} isExiting={isExiting} extraRules="w-full sm:w-[400px] bg-white dark:bg-gray-800">
      <header className="flex justify-between">
        <h3 className="text-gray-900 dark:text-gray-100 flex flex-grow font-inter font-[600] text-[24px] justify-center leading-8">
          Log in to your account
        </h3>
        <ButtonClose onClick={handleClose} />
      </header>
      <div className="text-base-600 bg-white dark:bg-base-800 dark:text-base-400 rounded-[16px] pt-[8px]">
        <div className="bg-white dark:bg-gray-800 dark:text-white rounded-[16px]">
          <p className="text-center text-[14px] font-[400] text-gray-600 dark:text-gray-400">
            Once logged in, you'll be able to access and utilise the features tailored to your
            account.
          </p>
          <div className="mt-[20px] space-y-[16px]">
            <div className="max-h-24">
              <label
                htmlFor="email"
                className="flex justify-start mb-2 gap-[10px] font-[500] text-[14px] text-gray-700 dark:text-gray-300 leading-5"
              >
                Email
              </label>
              <input
                type="email"
                autoComplete="username"
                name="username"
                id="username"
                value={username}
                onChange={onChange}
                placeholder="Enter your email"
                className={`w-full font-[400] text-[16px] font-inter leading-[24px] dark:bg-gray-900 text-base dark:text-neutral-50 text-gray-900 py-[10px] px-[14px] border-[2px] rounded-[8px] shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] transition-all duration-200
                  ${username === '' || isUsernameValid() ? 'border-gray-300 hover:border-gray-400 dark:border-gray-700 hover:dark:border-primary-800 focus:border-[#1c64f2]' : 'border-error-300 hover:border-error-500 focus:border-error-600 dark:border-error-500  dark:hover:border-error-600 dark:focus:border-error-700 focus:ring-0 focus:ring-offset-0'}
                `}
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="flex justify-start mb-2 gap-[10px] font-[500] text-[14px] text-gray-700 dark:text-gray-300 leading-5"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                autoComplete="current-password"
                id="password"
                placeholder="••••••••"
                value={password}
                onChange={onChange}
                className={`w-full font-[400] text-[16px] font-inter leading-[24px] dark:bg-gray-900 text-base dark:text-neutral-50 text-gray-900 py-[10px] px-[14px] border-[2px] rounded-[8px] shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] transition-all duration-200
                  ${password === '' || isPasswordValid() ? 'border-gray-300 hover:border-gray-400 dark:border-gray-700 hover:dark:border-primary-800 focus:border-[#1c64f2]' : 'border-error-300 hover:border-error-500 focus:border-error-600 dark:border-error-500  dark:hover:border-error-600 dark:focus:border-error-700 focus:ring-0 focus:ring-offset-0'}
                `}
              />
            </div>
            <div className="flex items-start">
              <a
                href="/forgot-password"
                className="mt-[4px] mb-[32px] font-[600] leading-[20px] hover:underline hover:underline-offset-4 hover:decorati4n-primary-500 ml-auto text-[14px] text-primary-500 hover:text-primary-400 dark:text-primary-400"
              >
                Forgot Password
              </a>
            </div>
          </div>
        </div>
      </div>
      <footer className="-mx-6 grid grid-cols-1 lg:items-center px-6 sm:flex-row sm:space-x-6 sm:space-y-0 space-y-4">
        <div className="flex justify-end space-y-2">
          <button
            type="button"
            className="inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-center text-white bg-primary-500 rounded-[16px] hover:bg-primary-600 focus:ring-0 dark:bg-primary-400 dark:hover:bg-primary-500 dark:focus:ring-0 dark:text-primary-900 font-[600]"
            disabled={isLoading}
            onClick={onSubmit}
          >
            {isLoading ? 'Signing In...' : 'Sign In'}
            {isLoading ? <LoadingIcon /> : ''}
          </button>
        </div>
      </footer>
    </Modal>
  )
}
