import { api } from './api'
import { cache } from './cache'
import { Tag } from './tags'

export const seoApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSeoWebsite: build.query({
      query: (seoId) => ({
        url: 'cpanel/seo/seowebsite',
        method: 'POST',
        body: {
          seoId
        }
      }),
      providesTags: (response, error, id) => [{ type: Tag.SeoWebsite, id }],
      transformResponse: (response, _, arg) => {
        cache.seoWebsite.set(arg, response)
        return response
      }
    }),
    getSeoKeywords: build.query({
      query: (seoId) => ({
        url: 'cpanel/seo/seokeywords',
        method: 'POST',
        body: {
          seoId
        }
      }),
      providesTags: (response, error, id) => [{ type: Tag.SeoKeywords, id }],
      transformResponse: (response, _, arg) => {
        cache.seoKeywords.set(arg, response)
        return response
      }
    }),
    getSeoTop10Keywords: build.query({
      query: (seoId) => ({
        url: 'cpanel/seo/seotop10keywords',
        method: 'POST',
        body: {
          seoId
        }
      }),
      providesTags: (response, error, id) => [{ type: Tag.SeoTop10Keywords, id }],
      transformResponse: (response, _, arg) => {
        cache.seoTop10Keywords.set(arg, response)
        return response
      }
    })
  })
})

export const { useGetSeoWebsiteQuery, useGetSeoKeywordsQuery, useGetSeoTop10KeywordsQuery } = seoApi
