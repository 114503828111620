import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from './Modal'
import { ButtonClose } from '../Buttons'
import { CalendarIcon, EmailIcon } from '../Icons'
import { ContactAccountManagerModal } from './ContactAccountManagerModal'

export function SendMessageModal({ show, onClose }) {
  const [isExiting, setIsExiting] = useState(false)

  const handleClose = () => {
    setIsExiting(true) // start exit animation
    setTimeout(onClose, 150) // delay to match exit animation duration
  }

  const customer = useSelector((state) => state.global.customer)
  const brand = useSelector((state) => state.global.brand)
  const host = window.location.host.replace('clientpanel', 'report')
  const href = `${brand.proto}${host}/calendar/schedule/?recordId=${customer.id}`

  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)

  return (
    <>
      <Modal handleClose={handleClose} isExiting={isExiting}>
        {/* Card Container */}
        <div className="z-20 flex flex-col p-[24px]">
          {/* header */}
          <header className="flex justify-between">
            <h3 className="text-start text-gray-900 dark:text-gray-100 flex flex-grow font-inter font-[600] text-[24px] justify-start leading-7">
              Contact Account Manager
            </h3>
            <ButtonClose onClick={handleClose} />
          </header>
          {/* text */}
          <p className="dark:text-gray-400 text-gray-600 text-left text-[14px] font-[400] leading-5 pb-[20px]">
            Please reach out to your account manager<br></br>for any assistance or inquiries you may
            have.
          </p>
          {/* Buttons */}
          <footer className="flex flex-col sm:flex-row gap-[16px]">
            <a
              href={href}
              className="w-full sm:w-[238px] flex flex-col gap-[8px] items-center justify-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-primary-900 active:scale-95 p-[24px] font-inter rounded-[6px] transition bg-opacity-100 ease-cubic-bezier[.785,.135,.15,.86] duration-100 delay-0"
            >
              <CalendarIcon className="text-white p-[6px] bg-primary-600 border-[10.667px] border-primary-700 rounded-[37.333px] mb-[26px] w-[64px] h-[64px]" />
              <h3 className="text-center text-[20px] text-gray-900 dark:text-gray-100 font-[600] leading-7">
                Schedule a Call
              </h3>
              <span className="text-[16px] text-gray-600 dark:text-gray-400 font-[400] leading-6 text-center">
                Schedule a call with your account manager.
              </span>
            </a>

            <div
              onClick={openModal}
              className="w-full sm:w-[238px] flex flex-col gap-[8px] items-center justify-center bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-primary-900 active:scale-95 p-[24px] font-inter rounded-[6px] transition bg-opacity-100 ease-cubic-bezier[.785,.135,.15,.86] duration-100 delay-0 cursor-pointer select-none"
            >
              <EmailIcon className="text-white p-[6px] bg-primary-600 border-[10.667px] border-primary-700 rounded-[37.333px] mb-[26px] w-[64px] h-[64px]" />
              <h3 className="text-center text-[20px] text-gray-900 dark:text-gray-100 font-[600] leading-7">
                Send a Message
              </h3>
              <span className="text-[16px] text-gray-600 dark:text-gray-400 font-[400] leading-6 text-center">
                Send a detailed message to your account manager.
              </span>
            </div>
          </footer>
        </div>
      </Modal>
      {open && <ContactAccountManagerModal onClose={handleClose} />}
    </>
  )
}
