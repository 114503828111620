import React from 'react'

interface TabProps {
  title: string
  page: number
  isActive: boolean
  onClick: (page: number) => void
}

export function Tab({ title, page, isActive, onClick }: TabProps) {
  return (
    <div
      className={`flex flex-row gap-[17px] items-center p-[12px] sm:p-[19px] lg:p-[29px] border-b-2 ${
        isActive
          ? 'border-primary-400 dark:border-primary-500 text-neutral-800 dark:text-white'
          : 'border-primary-100 dark:border-primary-900 hover:bg-secondary-100 dark:hover:bg-primary-700 text-gray-400 hover:text-black active:scale-95 cursor-pointer selection:none hover:shadow-xl transition bg-opacity-100 ease-cubic-bezier[.785,.135,.15,.86] duration-100 delay-0'
      }`}
      onClick={() => onClick(page)}
    >
      <div
        className={`flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full text-black text-sm font-normal ${
          isActive ? 'bg-primary-400 dark:bg-primary-500' : 'bg-primary-100 dark:bg-primary-900'
        }`}
      >
        {page}
      </div>
      <span className="font-normal text-sm leading-5 lg:leading-4">{title}</span>
    </div>
  )
}
