import React from 'react'
import { useDispatch } from 'react-redux'
import { LoadingCardLong } from '../../Loading'
import { getContainerClassName, ProductMessageType } from '../../ProductMessage'
import { Foundations } from './Foundations'
import { FirstMonth } from './FirstMonth'
import { SecondMonth } from './SecondMonth'
import { ThirdMonth } from './ThirdMonth'
import { Journey } from './Journey'
import { hideMessages } from '../../../store/ads'

export function ProductMessages({ loading, messages }) {
  if (loading) {
    return (
      <div className={getContainerClassName()}>
        <LoadingCardLong />
      </div>
    )
  }

  const dispatch = useDispatch()

  const onHideClick = () => {
    dispatch(hideMessages(true))
  }

  const messagesMap = {
    [ProductMessageType.Foundations]: Foundations,
    [ProductMessageType.Thirty]: FirstMonth,
    [ProductMessageType.Sixty]: SecondMonth,
    [ProductMessageType.Ninety]: ThirdMonth,
    [ProductMessageType.Journey]: Journey
  }

  return messages.map((type) => {
    const Message = messagesMap[type]

    return <Message key={type} onHideClick={onHideClick} />
  })
}
