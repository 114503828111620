import React from 'react'

export function ArrowRightIcon({ className = 'h-6 w-6' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 17 10"
      className={className}
      stroke="white"
    >
      <path
        d="M12.6667 1.66669L16 5.00002M16 5.00002L12.6667 8.33335M16 5.00002L1 5.00002"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
