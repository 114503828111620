/**
 * Convert a keywords difficulty number to an associated color (string)
 *
 * @param {number} rating The difficulty rating
 * @returns {string} The formulated keyword difficulty color as a string
 */
export const keywordDifficultyToColor = (rating: number): string => {
  if (!rating) return ''
  if (rating > 71) {
    return 'text-[#e53935] dark:text-[#e53935]' // Red
  } else if (rating > 31) {
    return 'text-[#ff7043] dark:text-[#ff7043]' // Orange
  } else if (rating > 11) {
    return 'text-[#ffb74d] dark:text-[#ffb74d]' // Light Orange
  } else {
    return 'text-[#81c784] dark:text-[#4caf50]' // Darker Green (Light Mode), Dark Green (Dark Mode)
  }
}
