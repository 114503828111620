import React from 'react'
import { useDispatch } from 'react-redux'
import { removeMessage } from '../../../store/notifications'
import { ButtonClose } from '../../Buttons'

interface NotificationContentProps {
  id?: string
  priority: string
  title: string
  content: string
  expanded?: boolean
  buttonText?: string
  buttonLink?: string
}

export function NotificationContent({
  id,
  priority = 'med',
  title,
  content,
  expanded = false,
  buttonText = '',
  buttonLink = ''
}: NotificationContentProps) {
  const dispatch = useDispatch()
  const handleRemoveNotification = () => {
    dispatch(removeMessage(id))
  }

  return (
    <div
      className={`flex flex-col gap-[8px] py-[16px] ${expanded ? 'px-[24px] dark:border-gray-800 dark:hover:bg-gray-800' : 'p-[16px] dark:border-gray-600 dark:hover:bg-gray-600'} border-t-[1px] border-gray-200 hover:bg-gray-50`}
    >
      <div className="flex gap-[12px] items-center">
        <span
          className={`${priority === 'high' ? 'bg-error-500' : priority === 'med' ? 'bg-warning-500' : 'bg-success-500'} rounded-[5px] w-[8px] h-[8px]`}
        ></span>
        <span className="text-gray-900 dark:text-gray-100 font-inter text-[16px] font-[600] leading-[24px] grow">
          {title}
        </span>
        <div onClick={handleRemoveNotification}>
          <ButtonClose />
        </div>
      </div>
      <span className="text-gray-600 dark:text-gray-400 font-inter text-[14px] font-[400] leading-[20px] pl-[20px]">
        {content}
      </span>
      {buttonText && (
        <div className="flex pt-[8px] pl-[20px]">
          <a
            href={buttonLink}
            className="px-[14px] py-[6px] flex items-center justify-center bg-primary-700 hover:bg-primary-900 dark:bg-primary-300 dark:hover:bg-primary-500 shadow-xs rounded-[8px] text-white dark:text-primary-900 font-inter text-sm font-semibold transition-all bg-opacity-100 ease-cubic-bezier[.785,.135,.15,.86] active:scale-95 duration-100 delay-0"
          >
            {buttonText}
          </a>
        </div>
      )}
    </div>
  )
}
