import React from 'react'
import { NoDataIcon } from '../Icons'

export function NoData({
  title = 'Reporting Unavailable',
  subTextOne = 'No data was detected',
  subTextTwo = 'Please check back when more data is available',
  showIcon = true
}) {
  return (
    <div className="flex flex-col gap-[10px] items-center p-[25px]">
      {showIcon && <NoDataIcon />}
      <div className="w-[472px] text-center text-gray-900 dark:text-gray-100 text-xl font-semibold font-['Inter'] leading-[30px]">
        {title}
      </div>
      <div className="w-[472px] text-center text-slate-600 dark:text-gray-400 text-base font-normal font-['Inter'] leading-normal">
        {subTextOne}
        <br />
        {subTextTwo}
      </div>
    </div>
  )
}
