import React from 'react'
import { TableRowCell } from '../../Table'
import { addOrdinalSuffix } from '../../../helpers/addOrdinalSuffix'
import { downTrendStyling } from '../../../helpers/downTrendStyling'
import { StarIcon } from '../StarIcon'
import { TrendIcon } from '../TrendIcon'

export function TableRow({ keyword, rankings }) {
  const { Name, Google_Current__c, Google_Original__c, Google_DaysAgo28__c } = keyword
  const { starKeywordsOutline, starKeywordsFull } = rankings
  const move =
    Google_Current__c && Google_Current__c < 100 ? Google_Original__c - Google_Current__c : '~'
  const change =
    Google_Current__c && Google_Current__c < 100 ? Google_DaysAgo28__c - Google_Current__c : '~'
  const isNoChange = Google_Current__c === Google_Original__c
  const isDownTrend = move < 0

  const tooltip = change < 0 ? starKeywordsOutline : starKeywordsFull

  return (
    <tr className="table-tr">
      <td className="p-4 text-left flex gap-[9px]">
        {Google_Current__c && Google_Current__c < 10 && (
          <StarIcon isDownTrend={isDownTrend} tooltip={tooltip.replace(/{{position}}/g, move)} />
        )}
        <span className={Google_Current__c < 10 ? 'pl-2 md:pl-4' : ''}>{Name}</span>
        {Google_Original__c && Google_Current__c && <TrendIcon isDownTrend={isDownTrend} />}
      </td>
      <TableRowCell
        label={Google_Current__c >= 100 ? '' : addOrdinalSuffix(Google_Current__c) || ''}
      />
      <TableRowCell label={move} labelClassName={downTrendStyling(isDownTrend)} />
      <TableRowCell label={addOrdinalSuffix(Google_DaysAgo28__c) || ''} />
      <TableRowCell
        label={
          Google_Original__c === 100 || !Google_Original__c
            ? ''
            : addOrdinalSuffix(Google_Original__c)
        }
      />
      <TableRowCell
        label={
          !Google_DaysAgo28__c
            ? ''
            : isNoChange
              ? Google_Current__c
              : `${change > 0 ? '+' : ''}${change}`
        }
        labelClassName={
          Google_DaysAgo28__c
            ? downTrendStyling(change < 0 && Google_Current__c !== Google_Original__c)
            : ''
        }
      />
    </tr>
  )
}
