import { api } from './api'
import { Tag } from './tags'

export const billingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInvoices: build.query({
      query: (viewall = false) => ({
        url: 'cpanel/billing/invoices',
        method: 'POST',
        body: {
          viewall
        }
      }),
      providesTags: (response, error, id) => [{ type: Tag.BillingInvoices, id }]
    }),
    updateCC: build.mutation({
      query: (body) => ({
        url: 'cpanel/billing/updatecc',
        method: 'POST',
        body
      })
    })
  })
})

export const { useGetInvoicesQuery, useUpdateCCMutation } = billingApi
