import React from 'react'
import { ProductMessage } from '../../ProductMessage'

interface FoundationsProps {
  onHideClick: () => void
}

export function Foundations({ onHideClick }: FoundationsProps) {
  return (
    <ProductMessage title="Ads Setup in Progress" onHideClick={onHideClick}>
      <>
        <p className="paragraph-text">
          Congratulations! You're on your way to furthering your brand awareness through Google Ads.
          One of our onboarding tech specialists will be in touch with you shortly.
        </p>
        <p className="paragraph-text">
          During this catchup, we'll collect all the information we need to build or optimise your
          campaign, including looking into potential product titles and descriptions. After our
          call, we'll conduct some product research and set up conversion tracking. Over the month,
          we'll monitor your impressions and get in touch with you to discuss any new activity on
          your account.
        </p>
        <p className="paragraph-text">
          Make sure to check your dashboard regularly to see any updates or account changes.
        </p>
      </>
    </ProductMessage>
  )
}
