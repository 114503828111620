import React from 'react'

interface FacebookIconProps {
  className?: string
}

export function FacebookIcon({
  className = 'h-6 w-6 self-center inline mr-[15px]'
}: FacebookIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.38822 22.954 10.125 23.8542V15.4687H7.07813V12H10.125V9.35626C10.125 6.34874 11.9165 4.68751 14.6576 4.68751C15.9705 4.68751 17.3438 4.92187 17.3438 4.92187V7.875H15.8306C14.3399 7.875 13.875 8.80001 13.875 9.749V12H17.2031L16.6711 15.4687H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z"
        fill="#1877F2"
      />
    </svg>
  )
}
