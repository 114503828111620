import React from 'react'

interface ArrowRightProps {
  className?: string
}

export function ArrowRight({ className = 'cursor-pointer' }: ArrowRightProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
      <path
        d="M5 12H19M19 12L12 5M19 12L12 19"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="23" height="23" stroke="none" />
    </svg>
  )
}
