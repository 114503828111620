import React, { useState } from 'react'
import { DeviceIcon, SEOIcon } from '../../Icons'
import { Tooltip } from '../../Tooltip'
import { LoadingCardLong } from '../../Loading'
import { Container } from './Container'

export function DomainCheck({ loading, domain, websiteData, hasViewFullReportLink = false }) {
  const { image, icon, url, description } = domain

  const [iconError, setIconError] = useState(false)

  const setError = () => {
    setIconError(true)
  }

  if (loading) {
    return (
      <Container image={image}>
        <LoadingCardLong />
      </Container>
    )
  }

  if (!websiteData[0] || !websiteData[0].website_url__c) {
    return null
  }

  return (
    <Container image={image}>
      <div className="flex">
        <div className="flex flex-col flex-grow space-y-4">
          <div
            className={`text-lg lg:text-xl font-header leading-tight tracking-tight font-bold ${image ? 'text-base-50' : 'dark:text-base-50'}`}
          >
            SEO Website
          </div>
          <div
            className={`mb-1 text-sm font-normal flex items-center space-x-2 ${image ? 'text-base-50' : 'text-base-400 dark:text-gray-400'}`}
          >
            {!iconError && icon && (
              <img
                className="w-6 h-6 hover:scale-150 hidden md:flex"
                src={icon}
                onError={setError}
              />
            )}
            {url && (
              <a href={url} target="_blank" rel="noopener noreferrer">
                <Tooltip content={description} className="text-[#21A149] hover:text-primary-600">
                  {websiteData[0].Domain__c}
                </Tooltip>
              </a>
            )}
            {!url && (
              <span className="text-[#21A149] hover:text-primary-600">{websiteData[0].Name}</span>
            )}
          </div>
        </div>
        <div className="flex flex-col flex-grow items-end md:items-center space-y-4">
          <div
            className={`flex text-lg font-header leading-tight font-bold text-right tracking-tight ${image ? 'text-base-50' : 'dark:text-base-50'}`}
          >
            Geo-Location
          </div>
          {websiteData.length > 1 ? (
            <>
              {websiteData.map((item, key) => (
                <div
                  key={key}
                  className={`flex flex-col flex-grow font-normal items-end mb-1 text-right text-sm ${image ? 'text-base-50' : 'text-base-400 dark:text-gray-400'}`}
                >
                  <span>{(item.Geolocation__c || '').replace(/,/g, ', ')}</span>
                </div>
              ))}
            </>
          ) : (
            <div
              className={`flex flex-col flex-grow font-normal items-end mb-1 text-right text-sm ${image ? 'text-base-50' : 'text-base-400 dark:text-gray-400'}`}
            >
              <span>{(websiteData[0].Geolocation__c || '').replace(/,/g, ', ')}</span>
            </div>
          )}
        </div>
        <div className="flex flex-col flex-grow items-baseline md:items-center space-y-4 space-x-2">
          <div
            className={`text-lg font-header leading-tight tracking-tight font-bold ${image ? 'text-base-50' : 'dark:text-base-50'}`}
          >
            Device
          </div>
          <div
            className={`mb-1 text-sm font-normal w-6 h-6 ${image ? 'text-base-50' : 'text-base-400 dark:text-gray-400'}`}
          >
            <Tooltip content={websiteData[0].Device__c}>
              <DeviceIcon type={websiteData[0].Device__c} />
            </Tooltip>
          </div>
        </div>
      </div>
      <p className="text-sm font-semibold dark:text-base-50"></p>
      {hasViewFullReportLink && (
        <div className="space-y-6 sm:flex items-center">
          <a
            href="/cpanel/reports/seo"
            className="flex content-center mt-3.5 rounded-[16px] bg-[#21A149] px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-700 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-2 dark:focus:ring-primary-500"
          >
            <SEOIcon />
            <span className="ml-2 capitalize">view full report</span>
          </a>
        </div>
      )}
    </Container>
  )
}
