/**
 * Adds an ordinal suffix to a keyword position
 *
 * @param {number} position The keyword position number
 * @returns {string} The formulated keyword position with the ordinal suffix
 */
export const addOrdinalSuffix = (position: number): string => {
  if (!position) return ''
  let suffix = ''
  if (position % 10 === 1 && position % 100 !== 11) {
    suffix = 'st'
  } else if (position % 10 === 2 && position % 100 !== 12) {
    suffix = 'nd'
  } else if (position % 10 === 3 && position % 100 !== 13) {
    suffix = 'rd'
  } else {
    suffix = 'th'
  }
  const keywordWithSuffix = position + suffix
  return keywordWithSuffix
}
