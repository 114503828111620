import { rgbToHex } from './rgbToHex'

export const getColor = (str) => {
  const elem = document.createElement('div')
  elem.style.display = 'none'
  elem.classList.add(str)
  document.body.appendChild(elem)
  const rgb = window
    .getComputedStyle(elem, null)
    .getPropertyValue('color')
    .replace('rgb(', '')
    .replace(')', '')
    .replace(/ /gm, '')
    .split(',')

  document.body.removeChild(elem)

  return rgbToHex(rgb[0], rgb[1], rgb[2])
}
