import React from 'react'
import { TabItem } from './TabItem'

export function Tabs({ labels, activeTabIndex, onSelect, isNew }) {
  return (
    <div className="col-span-full flex items-center justify-between">
      <div className="flex mb-[15px] sm:mb-0 bg-opacity-30 sm:my-3 tracking-tight space-x-2 sm:space-x-0 overflow-x-auto dark:scrollbar-thumb-base-50 dark:scrollbar-track-base-700 scrollbar-thin scrollbar-height-[8px] scrollbar-thumb scrollbar-thumb-primary-500 scrollbar-thumb-rounded-full scrollbar-track-gray-100 scrollbar-track-rounded-full">
        {labels.map((label, index) => (
          <TabItem
            key={label}
            label={label}
            isActive={index === activeTabIndex}
            onClick={() => onSelect(index)}
            isNew={isNew ? isNew[index] : false}
          />
        ))}
      </div>
    </div>
  )
}
