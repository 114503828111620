import React from 'react'

export function CallIcon({ className = 'h-6 w-6 text-primary-500' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      x="0"
      y="0"
      className={className}
    >
      <path
        d="M742.229-167.309q-110.691-10-211.614-59.385-100.922-49.384-178.806-127.769-77.885-78.384-127.961-179.114-50.077-100.73-59.077-211.422-2-21 12.285-36.5 14.286-15.5 35.715-15.5H317.23q18.539 0 31.616 10.577 13.076 10.577 17.846 28.731L387.615-673q2.384 12.769-1 25.346-3.385 12.577-13.154 21.577l-92 89.923q47.385 84.462 110.501 147.385 63.115 62.923 146.039 107.231l94.384-92.923q10.769-10.769 21.418-12.884 10.648-2.116 22.043.269l80.075 17.615q18.154 4.385 28.731 18.054 10.577 13.669 10.577 32.408v103.69q0 27-19 38t-34 10ZM257.693-586.922l75.385-72.308q1.923-1.539 2.5-4.231.577-2.693-.193-5l-17.545-70.385q-.769-3.077-2.692-4.615-1.923-1.539-5.001-1.539H223.77q-2.308 0-3.847 1.539-1.538 1.538-1.538 3.846 3.076 38 13.423 76.308 10.346 38.308 25.885 76.385Zm332.46 329.383q35.869 15.782 75.012 23.968 39.142 8.186 72.681 12.032 2.308 0 3.846-1.538 1.539-1.539 1.539-3.847v-86.153q0-3.077-1.539-5.001-1.538-1.923-4.616-2.692l-64.999-14.077q-2.308-.769-4.039-.192-1.731.577-3.654 2.5l-74.231 75Zm-332.46-329.383Zm332.46 329.383Z"
        fill="currentColor"
      />
    </svg>
  )
}
