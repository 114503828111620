import React from 'react'
import { QuestionMarkIcon } from '../../Icons'

export type Option = {
  label: string
  value: string
}

interface SelectFieldProps {
  name: string
  value: string
  options: Option[]
  label: string
  onChange: (value: string) => void
  hasError?: boolean
  openModal?: () => void
}

export function SelectField({
  name,
  value,
  options,
  label,
  onChange,
  hasError = false,
  openModal = null
}: SelectFieldProps) {
  const onValueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value)
  }

  return (
    <div className="grid gap-[4px] grid-rows-[20,42px] relative">
      <label
        htmlFor={name}
        className="flex gap-[10px] font-semibold text-sm text-gray-600 dark:text-gray-300 leading-5"
      >
        <span>{label}</span>
        {openModal instanceof Function && (
          <div onClick={openModal} className="w-5 h-5 group cursor-pointer">
            <QuestionMarkIcon className="transition-all duration-100 group-hover:stroke-primary-500 stroke-[#98A2B3]" />
          </div>
        )}
      </label>
      <select
        id={name}
        name={name}
        value={value}
        required
        className={`w-full font-normal dark:bg-gray-900 text-base dark:text-neutral-50 leading-6 text-gray-900 py-[9px] px-[13px] border-[2px] border-gray-300 hover:border-gray-400 dark:border-gray-700 hover:dark:border-primary-800 rounded-md shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] transition-all duration-200 focus:border-[#1c64f2] ${
          hasError
            ? 'border-red-400 dark:border-red-500 hover:border-red-500 focus:border-red-500 hover:dark:border-red-600 focus:dark:border-600 focus:ring-0 focus:ring-offset-0'
            : 'border-gray-200 dark:border-base-500'
        }`}
        onChange={onValueChange}
      >
        <option value="" disabled>
          Select your option
        </option>
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  )
}
