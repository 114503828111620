import React, { createContext, useContext } from 'react'
import { useSelector } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material'

const ThemeContext = createContext()

export const ThemeProviderComponent = ({ children }) => {
  const isDarkMode = useSelector((store) => store.global.darkMode)

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light'
    }
  })

  return (
    <ThemeContext.Provider value={{ isDarkMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  )
}

export const useThemeContext = () => useContext(ThemeContext)
