/**
 * Convert a keywords difficulty number to a rating
 *
 * @param {number} rating The difficulty rating
 * @returns {string} The formulated difficulty as a string response
 */
export const keywordDifficulty = (rating: number): string => {
  if (!rating) return ''
  if (rating > 71) {
    return 'Super Hard'
  } else if (rating > 31) {
    return 'Hard'
  } else if (rating > 11) {
    return 'Medium'
  } else {
    return 'Easy'
  }
}
