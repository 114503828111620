import React from 'react'
import { useSelector } from 'react-redux'
import { currencyFormatter } from '../../helpers/currencyFormatter'
import { WarningIcon } from '../Icons'

export function StickyBanner() {
  const outstandingPayment = useSelector((store) => store.global.totalOutstanding)

  if (outstandingPayment === 0) {
    return null
  }

  return (
    <div
      className="animate-slide-left col-span-1 md:col-span-2 mb-[16px]
                flex w-full h-[57px] p-[42px] px-[24px] lg:p-[24px] items-center justify-between gap-[20px]
                top-[82px] z-10 rounded-[16px] shadow-md pointer-events-auto
                ring-1 ring-black ring-opacity-5
                font-sans font-[400] text-[14px] sm:text-[16px] tracking-normal
                text-red-700 bg-red-200 dark:text-red-700 dark:bg-red-300"
    >
      <div className="flex items-center justify-between gap-[20px] w-full">
        <div className="flex gap-[20px]">
          <WarningIcon className="w-8 lg:w-6 h-8 lg:h-6 text-red-700" />
          <span>
            <strong>Payment Due:</strong> You have an outstanding payment of{' '}
            <strong>{currencyFormatter(outstandingPayment)}</strong>. Please update your{' '}
            <a className="font-[600] underline" href="/cpanel/billing">
              Billing details
            </a>{' '}
            to avoid interruption.
          </span>
        </div>
      </div>
    </div>
  )
}
