import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InputField, SelectField } from '../../Onboarding/InputField'
import { Panel } from '../../Panel'
import { ClickIcon, EyeClosedIcon, EyeOpenIcon, LoadingIcon } from '../../Icons'
import { useCreateWebsiteMutation } from '../../../services/settings'
import { addToast } from '../../../store/global'
import { cmsOptions } from '../../Onboarding/LinkWebsite'
import { SubmitButton } from '../../Buttons/index'

export function WebsiteLogins() {
  const dispatch = useDispatch()
  const clientName = useSelector((state) => state.global.customer.name)

  const [website, setWebsite] = useState('')
  const [websiteUsername, setWebsiteUsername] = useState('')
  const [websitePassword, setWebsitePassword] = useState('')
  const [websiteCMS, setWebsiteCMS] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const [createWebsite, { isLoading, isError, isSuccess }] = useCreateWebsiteMutation()

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        addToast({
          title: 'Success',
          subtext: 'Submitted updated website login details.',
          type: 'success'
        })
      )
    }
  }, [isSuccess])

  const onCreateWebsiteClick = () => {
    if (website == '' || websiteUsername == '' || websitePassword == '' || websiteCMS == '') {
      return dispatch(
        addToast({
          title: 'Error detected',
          subtext: 'Please fill out all the website login fields before continuing',
          type: 'error'
        })
      )
    }
    createWebsite({
      username: websiteUsername,
      password: websitePassword,
      type: websiteCMS,
      loginURL: website
    })
  }

  return (
    <Panel title="Submit New Website Logins">
      <p className="-mt-4 mb-1 text-sm font-normal text-base-400 dark:text-gray-400 italic">
        Enter the login details for a new website, or updated details for an existing website.
      </p>
      <div className="flex flex-col gap-[24px] justify-self-center justify-items-center pt-[16px] self-center w-full max-w-[344px]">
        <InputField
          name="website"
          value={website}
          label="Website"
          type="text"
          onChange={setWebsite}
        />
        <InputField
          name="websiteUsername"
          value={websiteUsername}
          label="Website Username"
          type="text"
          onChange={setWebsiteUsername}
        />
        <InputField
          name="websitePassword"
          value={websitePassword}
          label="Website Password"
          type="password"
          onChange={setWebsitePassword}
        />
        <SelectField
          name="websiteCMS"
          value={websiteCMS}
          options={cmsOptions}
          label="Website CMS"
          onChange={setWebsiteCMS}
        />
        <SubmitButton label="Submit Login Details" onClick={onCreateWebsiteClick} isLoading={isLoading} />
      </div>
    </Panel>
  )
}
