import React from 'react'
import { LoadingIcon } from '../Icons'

interface LoadMoreButtonProps {
  loading: boolean
  text: string
  onClick: () => void
}

export function LoadMoreButton({ loading, text, onClick }: LoadMoreButtonProps) {
  return (
    <button
      onClick={onClick}
      className="w-fit flex gap-[10px] px-[32px] py-[8px] rounded-[24px] font-[600] text-[14px] text-gray-500 dark:text-gray-300
      bg-gray-100 hover:bg-gray-200 hover:text-gray-600 dark:bg-slate-700 hover:dark:bg-slate-600 hover:dark-text-gray-200
      transition duration-100 self-center mb-[8px] mt-[32px] active:scale-95 duration-100"
    >
      {text}{' '}
      {loading && (
        <LoadingIcon className="self-center animate-spin h-4 w-4 text-gray-500 hover:text-gray-600 dark:text-gray-300 hover:dark-text-gray-200" />
      )}
    </button>
  )
}
