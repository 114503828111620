import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTimezone } from '../../../store/global'
import { PreferenceOption } from './PreferenceOption'
import { timezones } from '../../../libs/common'

export function TimezoneOption() {
  const timezone = useSelector((store) => store.global.timezone)

  const dispatch = useDispatch()

  const onChange = (event) => {
    const { value } = event.target
    dispatch(setTimezone(value))
  }

  return (
    <PreferenceOption
      title="Timezone"
      description="Set your specific timezone in order to assist with accurate reporting timeframes"
    >
      <label htmlFor="timezone" className="relative flex cursor-pointer items-center">
        <select
          name="timezone"
          className="w-full font-normal dark:bg-gray-900 text-base dark:text-neutral-50 leading-6 text-gray-900 py-[9px] px-[13px] border-[2px] border-gray-300 hover:border-gray-400 dark:border-gray-700 hover:dark:border-primary-800 rounded-[8px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] transition-all duration-200 focus:border-[#1c64f2]"
          value={timezone}
          onChange={onChange}
        >
          {timezones.map((option) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      </label>
    </PreferenceOption>
  )
}
