import React from 'react'
import { AdsTable } from './AdsTable'
import { Panel } from '../Panel'
import { NewPagination, usePagination } from '../../Pagination'
import type { AdsMetric } from '../types'

interface AdsProps {
  list: AdsMetric[]
  loading: boolean
  dateFrom: string
}

export function Ads({ list, loading, dateFrom }: AdsProps) {
  const pagination = usePagination<AdsMetric>(list)

  return (
    <Panel title="Ads" loading={loading} dateFrom={dateFrom}>
      <>
        <AdsTable list={pagination.paginated} />
        <NewPagination
          total={pagination.total}
          page={pagination.page}
          itemsPerPage={pagination.itemsPerPage}
          setPage={pagination.setPage}
          setItemsPerPage={pagination.setItemsPerPage}
        />
      </>
    </Panel>
  )
}
