import React, { SyntheticEvent } from 'react'

interface EmailInputProps {
  value: string
  hasError: boolean
  onChange: (email: string) => void
}

export function EmailInput({ value, hasError, onChange }: EmailInputProps) {
  const onEmailChange = (event: SyntheticEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.value)
  }

  return (
    <div className="flex flex-col gap-[6px]">
      <label
        htmlFor="email"
        className="font-inter text-[14px] text-gray-700 dark:text-gray-300 font-[500] leading-[20px]"
      >
        Email
      </label>
      <input
        type="email"
        autoComplete="username"
        name="email"
        id="email"
        value={value}
        className={`px-[14px] py-[10px] bg-white dark:bg-gray-900 dark:text-white rounded-[8px] border-gray-300 hover:border-gray-400 dark:border-gray-700 hover:dark:border-primary-800 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] transition-all duration-200 focus:border-[#1c64f2] ${
          hasError
            ? 'border-[#FDA29B] shadow-[0px_0px_0px_3px_#FEE4E2,_0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] dark:shadow-[0px_0px_0px_2px_#FF0000,_0px_1px_2px_0px_rgba(16,_24,_40,_0.05)]'
            : 'border-base-300'
        }`}
        placeholder="Enter your email"
        onChange={onEmailChange}
      />
    </div>
  )
}
