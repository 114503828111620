import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  NoDataMessage,
  ProductInactiveMessage,
  ProductMessages
} from '../components/ads/ProductMessages'
import { getMessages } from '../components/ProductMessage'
import { CoreMetrics } from '../components/CoreMetrics'
import { cache } from '../services/cache'
import { useGetAdsReportsQuery } from '../services/reports'
import { LoadingCardLong } from '../components/Loading'
import { AccountSelector } from '../components/AccountSelector'
import { Copied } from '../components/Popover'
import { extractAccessKey } from '../helpers/extractAccessKey'
import { useCheckAccessKeyQuery } from '../services/cpanel'
import { isProductActive } from '../helpers/isProductActive'
import { BreadcrumbComponent } from '../components/Breacrumbs'
import { Panel } from '../components/ads/Panel'
import { SelectCampaignTable } from '../components/ads/Campaigns'

const removePrefix = (name) => {
  return name.replace(/^\*?(SLX|GMT|SME|JDM)( \| )?/, '')
}

const accessKey = extractAccessKey()

export function AdsPage() {
  const accessKeyValid = useCheckAccessKeyQuery(accessKey)

  const customer = useSelector((store) => store.global.customer)
  const dateFilter = useSelector((store) => store.global.dateFilter)
  const hideMessages = useSelector((store) => store.ads.messages).hide
  const adsId = useSelector((store) => store.ads.selectedAccountId)
  const activeProducts = useSelector((store) => store.global.customer.activeProducts)

  const adsProduct =
    activeProducts.find(
      (product) =>
        isProductActive(product?.adWordsManagement?.status || '') &&
        product?.adWordsManagement?.signupDate !== null &&
        product?.adWordsManagement?.adsId === adsId
    )?.adWordsManagement || null
  const { from, to } = dateFilter

  useEffect(() => {
    cache.adsReports.use({ adsId, from, to })
  }, [adsId, from, to])

  const { data, isLoading } = useGetAdsReportsQuery(
    { adsId, from, to },
    { skip: !(adsId && from && to) }
  )

  // Clean brand name traces
  const cleanedCampaignData = data?.campaignStats?.map((item) => ({
    ...item,
    campaign_name: removePrefix(item.campaignName)
  }))

  const product = Object.values(
    (data?.product || []).find((item) => {
      const details = Object.values(item)[0] || {}
      return details.adsId === adsId
    }) || {}
  )[0]

  if (!customer.adsActive && !accessKeyValid.data) {
    return <ProductInactiveMessage />
  }

  if (!customer.adsData) {
    return <NoDataMessage platform="Google" />
  }

  return (
    <>
      {!hideMessages && (
        <ProductMessages
          loading={isLoading}
          messages={getMessages(product?.setupComplete, product?.activeDays)}
        />
      )}
      {product?.status === 'Active' && (
        <>
          <BreadcrumbComponent />
          <div className="flex flex-row items-center pt-[16px] pb-[24px]">
            <h1 className="font-inter text-[24px] text-gray-900 dark:text-gray-100 font-[600] leading-[32px] pr-[16px]">
              Google Ads
            </h1>
            <AccountSelector type="google" />
            <Copied toCopy={adsId} />
          </div>
          <div className="w-full flex flex-col space-y-4">
            {isLoading && <LoadingCardLong />}
            {!isLoading && adsProduct && (
              <>
                <CoreMetrics fromDate={from} toDate={to} data={data} adsProduct={adsProduct} />
                <Panel title="Select A Campaign" loading={isLoading}>
                  <SelectCampaignTable adsId={adsId} campaigns={cleanedCampaignData} />
                </Panel>
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}
