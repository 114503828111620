import React from 'react'
import { TrendingDownIcon, TrendingUpIcon } from '../../Icons'

export function TrendIcon({ isDownTrend }) {
  const Icon = isDownTrend ? TrendingDownIcon : TrendingUpIcon

  return (
    <span
      className={
        isDownTrend ? 'text-red-500 dark:text-red-400' : 'text-green-500 dark:text-green-400'
      }
    >
      <Icon className="w-5 h-5" />
    </span>
  )
}
