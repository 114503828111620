/* global  customer */
import Alpine from 'alpinejs'
import { CommonFunc } from './libs/common'
import { icons } from './libs/icons'
import persist from '@alpinejs/persist'
import breakpoint from 'alpinejs-breakpoints'
import superTeleport from '@motekar/alpinejs-super-teleport'
import head from 'alpinejs-head'
import Tooltip from '@ryangjchandler/alpine-tooltip'
import spacetime from 'spacetime'
/* import data component */
import { data } from './modules/data'
import { localStore } from './modules/plugins/scripts/localstore'
import { initReact } from './initReact'

import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'

window._.isEmpty = isEmpty
window._.find = find
window.localStore = localStore
const localStorage = window.localStorage
const common = new CommonFunc()
window.common = common
window.icons = icons
window.spacetime = spacetime
/* Define AlpineJS x-data Attributes */
Alpine.data('data', data)

Alpine.plugin(superTeleport)
Alpine.plugin(breakpoint)
Alpine.plugin(persist)
Alpine.plugin(Tooltip)
Alpine.plugin(head)
/* Define AlpineJS global Functions */

Alpine.magic('parent', (el, { Alpine }) => {
  return Alpine.mergeProxies(Alpine.closestDataStack(el).slice(1))
})
Alpine.store('darkMode', {
  init() {
    if (localStore.getItem('darkMode')) {
      this.status = JSON.parse(localStore.getItem('darkMode'))
    }
    if (
      localStorage.getItem('darkMode') === null &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      localStore.setItem('darkMode', true)
      this.status = true
    }
    if (this.status) document.documentElement.classList.add('dark')
  },
  status: false,
  toggle() {
    this.status = !this.status
    localStore.setItem('darkMode', this.status)
    document.documentElement.classList.toggle('dark')
  }
})

/* Define AlpineJS window Component, to make accessible to html files */

window.Alpine = Alpine

function kickOff() {
  initReact()
  Alpine.start()
}

if (document.readyState !== 'loading') {
  kickOff()
} else {
  document.addEventListener('DOMContentLoaded', kickOff)
}
