import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { addToast } from '../../store/global'
import { useResetPasswordMutation } from '../../services/auth'
import { EmailInput, PasswordInput } from './FormElements/index'
import { validatePassword } from '../../../../utils/validators'
import { PasswordRules } from './PasswordRules'
import { SubmitButton } from '../Buttons/index'

export function ResetPasswordForm() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [resetPassword, { isLoading }] = useResetPasswordMutation()

  const [email, setEmail] = useState(searchParams.get('username') || '')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [emailValid, setEmailValid] = useState(false)

  const validateEmail = () => {
    const validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    setEmailValid(validEmail.test(email))
  }

  useEffect(() => {
    validateEmail()
  }, [email])

  const emailError = !emailValid
  const passwordError = !validatePassword(password)
  const passwordConfirmError = passwordConfirm !== password
  const hasErrors = emailError || passwordError || passwordConfirmError

  const onSubmit = async () => {
    if (hasErrors) {
      dispatch(
        addToast({
          title: 'Password update failed',
          subtext:
            'Please make sure that your password meets the minimum requirements before updating',
          type: 'error'
        })
      )
      return
    }

    const response = await resetPassword({
      username: email,
      newPassword: password,
      current: passwordConfirm
    })

    if (!response?.data || response.data.error) {
      dispatch(
        addToast({
          title: 'Password update failed',
          subtext: 'Please request a new password reset link to refresh your token',
          type: 'error'
        })
      )
      return
    }

    dispatch(
      addToast({
        title: 'Password Update',
        subtext: 'Your password has been updated',
        type: 'success'
      })
    )

    navigate('/')
  }

  return (
    <>
      <div className="flex flex-col text-center gap-[12px]">
        <h1 className="font-inter text-[30px] font-[600] leading-[38px] text-gray-900 dark:text-gray-100">
          Create New Password
        </h1>
        <PasswordRules password={password} />
      </div>
      <div className="flex flex-col gap-[20px]">
        <EmailInput value={email} hasError={email && emailError} onChange={setEmail} />
        <PasswordInput
          id="password"
          label="New Password"
          value={password}
          hasError={password && passwordError}
          onChange={setPassword}
        />
        <PasswordInput
          id="password-confirm"
          label="Confirm Password"
          value={passwordConfirm}
          hasError={passwordConfirm && passwordConfirmError}
          onChange={setPasswordConfirm}
        />
        <SubmitButton label="Update password" isLoading={isLoading} onClick={onSubmit} />
      </div>
      <div className="flex self-center">
        <span className="text-gray-600 dark:text-gray-300 text-[14px] font-[400] leading-[20px]">
          <a
            href="/"
            className="text-primary-700 dark:text-primary-400 text-[14px] font-[600] leading-[20px]"
          >
            Sign in to your account
          </a>
        </span>
      </div>
    </>
  )
}
