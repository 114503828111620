import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { ThemeProviderComponent } from '../ThemeContext'
import { storePub } from '../store'
import { ToastSystem } from '../components/Notifications/ToastSystem'
import {
  Header,
  CreatePassword,
  LinkWebsite,
  ConnectAccounts,
  Completed
} from '../components/Onboarding'

export function OnboardingPage() {
  const [page, setPage] = useState(1)

  const nextPage = () => setPage(page + 1)

  useEffect(() => {
    localStorage.setItem('onboardSteps', page.toString())
  }, [page])

  useEffect(() => {
    // Set the page to the last page the user was on
    setPage(Number(localStorage.getItem('onboardSteps') || 1))
    localStorage.removeItem('dynamic-import-error')
  }, [])

  return (
    <Provider store={storePub}>
      <ThemeProviderComponent>
        <div className="bg-gray-50 dark:bg-gray-900 font-secondary grid lg:grid-rows-[85px,1fr] grid-rows-[138px,1fr] gap-[40px] min-h-screen">
          <Header currentPage={page} setPage={setPage} />
          <ToastSystem />
          <div className="justify-self-center grid gap-[24px] justify-items-center content-start">
            {page === 1 && <CreatePassword onComplete={nextPage} />}
            {page === 2 && <LinkWebsite onComplete={nextPage} />}
            {page === 3 && <ConnectAccounts onComplete={nextPage} />}
            {page === 4 && <Completed />}
          </div>
        </div>
      </ThemeProviderComponent>
    </Provider>
  )
}
