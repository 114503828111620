import React from 'react'
import { ProductMessage } from '../../ProductMessage'

interface ThirdMonthProps {
  onHideClick: () => void
}

export function ThirdMonth({ onHideClick }: ThirdMonthProps) {
  return (
    <ProductMessage title="Third month of Ads" onHideClick={onHideClick}>
      <>
        <p className="paragraph-text">
          Month three is for reflection. It's here we'll look at your campaign's overall
          performance, including sales, clicks, conversions, costs etc.
        </p>
        <p className="paragraph-text">
          We'll revisit your product titles and descriptions, ensuring they're still relevant and
          accurate. We'll also reconfirm all your details, ensuring contact information, location,
          and target audience is all up to date.
        </p>
        <p className="paragraph-text">
          After analysing your ads' performance, we might separate your campaign to target
          higher-performing products, split-testing where possible. We'll report our findings back
          to you and adjust your shopping campaign accordingly. Make sure to check your dashboard
          regularly to see any updates or account changes.
        </p>
      </>
    </ProductMessage>
  )
}
