import React from 'react'
import { useSelector } from 'react-redux'
import { ProductMessages } from '../components/dashboard/ProductMessages'
import { SeoDashboard } from '../components/dashboard/SeoDashboard'
import { AdsDashboard } from '../components/dashboard/AdsDashboard'
import { extractAccessKey } from '../helpers/extractAccessKey'
import { useCheckAccessKeyQuery } from '../services/cpanel'

const accessKey = extractAccessKey()

export function DashboardPage() {
  const customer = useSelector((state) => state.global.customer)
  const { adsActive, seoActive } = customer
  const accessKeyValid = useCheckAccessKeyQuery(accessKey)

  return (
    <>
      <ProductMessages />
      {(adsActive || accessKeyValid.data) && <AdsDashboard />}
      {(seoActive || accessKeyValid.data) && (
        <div className={adsActive ? 'pt-4' : ''}>
          <SeoDashboard />
        </div>
      )}
    </>
  )
}
