import spacetime from 'spacetime'
import { api } from './api'
import { store } from '../store'

const applyCacheToApi = (endpoint, id, data) =>
  store.dispatch(api.util.upsertQueryData(endpoint, id, data))

const today = () => spacetime.now().format('iso-short')

const readFromStorage = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch (error) {
    return null
  }
}

const cacheAccessor = (name, apiName) => ({
  name,
  get: () => {
    const { data, date } = readFromStorage(name) || {}

    return data && date === today() ? data : null
  },
  getByArg: (arg) => {
    const id = JSON.stringify(arg)
    const cached = cacheAccessor(name, apiName).get()
    if (!Array.isArray(cached)) {
      return null
    }

    const { data } = cached.find((item) => item.id === id) || {}

    return data || null
  },
  set: (arg, data) => {
    const id = JSON.stringify(arg)
    const cached = cacheAccessor(name, apiName).get()
    const otherCache = Array.isArray(cached) ? cached.filter((item) => item.id !== id) : []

    localStorage.setItem(
      name,
      JSON.stringify({
        date: today(),
        data: [...otherCache, { id, data }]
      })
    )
  },
  use: (arg) => {
    const data = cacheAccessor(name, apiName).getByArg(arg)

    if (data) {
      applyCacheToApi(apiName, arg, data)
    }
  },
  clear: () => localStorage.removeItem(name)
})

export const cache = {
  seoWebsite: cacheAccessor('seo.website', 'getSeoWebsite'),
  seoKeywords: cacheAccessor('seo.keywords', 'getSeoKeywords'),
  seoTop10Keywords: cacheAccessor('seo.top10Keywords', 'getSeoTop10Keywords'),
  seoDomainReports: cacheAccessor('seo.domainReports', 'getSeoDomainReports'),
  seoDomain: cacheAccessor('seo.domain', 'getDomainStats'),
  adsReports: cacheAccessor('ads.reports', 'getAdsReports'),
  adsKeywords: cacheAccessor('ads.keywords', 'getAllAdsReports'),
  adsCampaigns: cacheAccessor('ads.campaigns', 'getAllAdsReports'),
  adsAdGroups: cacheAccessor('ads.adGroups', 'getAllAdsReports'),
  adsTopAds: cacheAccessor('ads.topAds', 'getAllAdsReports'),
  adsShopping: cacheAccessor('ads.shopping', 'getAllAdsReports'),
  searchTerms: cacheAccessor('ads.searchTerms', 'getAllAdsReports'),
  facebookReports: cacheAccessor('facebook.reports', 'getFacebookReports'),
  notifications: cacheAccessor('notifications', 'getNotifications')
}

export const useSeoCache = (seoAccountId) => {
  cache.seoWebsite.use(seoAccountId)
  cache.seoKeywords.use(seoAccountId)
  cache.seoTop10Keywords.use(seoAccountId)

  const host = cache.seoWebsite.getByArg(seoAccountId)?.records?.[0]?.website_url__c
  if (host) {
    cache.seoDomainReports.use(host)
    cache.seoDomain.use(host)
  }
}

export const clearAllCache = () => {
  Object.values(cache).forEach((accessor) => accessor.clear())
}
