import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PostHogProvider } from 'posthog-js/react'
import { POSTHOG_KEY, posthogOptions } from '../../../js/libs/posthog'
import { Header } from '../Header'
import { SideBar } from '../SideBar'
import { Footer } from './Footer'
import { Feedback } from './Feedback'
import { PurchasePopupHandler } from '../Modal/PurchasePopupHandler'
import { ToastSystem } from '../Notifications/ToastSystem'
import { NotificationSystem } from '../Notifications/NotificationSystem'
import { processNotifications } from '../Notifications/processNotifications'
import { useGetNotificationsQuery } from '../../../js/services/notifications'
import { LoadingPage } from '../Loading/LoadingPage'

interface LayoutProps {
  pageType: string
  loading: boolean
  children: React.ReactNode
}

export function Layout({ pageType, loading, children }: LayoutProps) {
  const dispatch = useDispatch()

  const { data: notifData } = useGetNotificationsQuery() // TODO: Save to redux store after query
  const [fullSize, setFullSize] = useState(false) 
  const { id, name } = useSelector((store) => store.global.customer)
  const brand = useSelector((store) => store.global.brand.name)

  useEffect(() => {
    if (notifData) {
      processNotifications(notifData, dispatch)
    }
  }, [notifData])

  // shortcut to clear localStorage + logout (clear cookies)
  useEffect(() => {
    const callback = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.code === 'KeyO') {
        localStorage.clear()
        window.location.href = '/auth/logout'
      }
    }
    document.addEventListener('keydown', callback)
    return () => {
      document.removeEventListener('keydown', callback)
    }
  }, [])
  return (
    <PostHogProvider
      apiKey={POSTHOG_KEY}
      options={posthogOptions(id, {
        name,
        salesforceID: id // TODO: Change id from salesforceID to companyId or similar
      })}
    >
      <div className="bg-white dark:bg-gray-800 flex flex-col w-full h-[100vh]">
        <Header pageType={pageType} setFullSize={setFullSize} />
        <SideBar setFullSize={setFullSize} />
        <main className="col-span-full mb-2 pt-24 md:pl-[112px] pl-[16px] md:pr-[24px] pr-[16px] items-center justify-evenly">
          <div className="mt-[-20px] ml-[-30px] mr-[-23px] p-[32px] rounded-none md:rounded-[24px] bg-[#F5F7F9] dark:bg-gray-900">
            <ToastSystem />
            {loading ? <LoadingPage /> : children}
          </div>
        </main>
        <Feedback
          className={`hidden md:block z-[10] fixed bottom-[30%] right-[-47px] py-[8px] px-[14px] rotate-[-90deg] origin-bottom rounded-t-lg dark:bg-primary-300 dark:hover:bg-primary-400 shadow-xs hover:shadow-lightElev5 hover:dark:shadow-darkElev5
                  font-inter text-[14px] font-[600]
                  transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-100
                  ${brand === 'jdm' ? 'text-black bg-primary-400 hover:bg-primary-500 dark:hover:bg-primary-500' : 'text-white dark:text-primary-900 bg-primary-700 hover:bg-primary-900'}`}
        />
        {fullSize && <NotificationSystem fullSize={fullSize} setFullSize={setFullSize} />}
        <Footer />
        <PurchasePopupHandler pageType={pageType} />
      </div>
    </PostHogProvider>
  )
}
