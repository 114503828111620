import React from 'react'
import { TableHeadCell } from '../../Table'
import { adsFindTerm } from '../../../helpers/adsFindTerm'

interface TableHeadProps {
  labels: string[]
  tooltips: string[]
}

export function TableHead({ labels, tooltips }: TableHeadProps) {
  return (
    <tr>
      <TableHeadCell label="Campaign" className="text-left" tooltip={adsFindTerm('campaign')} />
      {labels.map((label, index) => (
        <TableHeadCell label={label} tooltip={adsFindTerm(tooltips[index])} key={index} />
      ))}
    </tr>
  )
}
