import React from 'react'
import { ConversionMetrics } from './ConversionMetrics'
import { currencyFormatter } from '../../helpers/currencyFormatter'
import { percentageFormatter } from '../../helpers/percentageFormatter'
import { numberFormatter } from '../../helpers/numberFormatter'
import type { Metrics } from './ConversionMetrics/ConversionMetrics'

export function CoreMetrics({ data, adsProduct, fromDate, toDate }) {
  const metrics: Metrics[] = [
    {
      title: 'Clicks',
      active: true,
      lineColor: '#5C5CFF',
      areaColor: '#DDE4FF',
      value: numberFormatter(data?.clicks || 0),
      data: data?.charts?.clicksChart || []
    },
    {
      title: 'Impressions',
      active: true,
      lineColor: '#FAC515',
      areaColor: '#FEF7C3',
      value: numberFormatter(data?.impressions || 0),
      data: data?.charts?.impressionsChart || []
    },
    {
      title: 'Conversions',
      active: true,
      lineColor: '#17B26A',
      areaColor: '#DCFAE6',
      value: numberFormatter(data?.conversions || 0),
      data: data?.charts?.conversionChart || []
    },
    {
      title: 'CTR',
      active: false,
      lineColor: '#FF3379',
      areaColor: '#FFE2EA',
      value: percentageFormatter((data?.ctr || 0) / 100),
      data: (data?.charts?.ctrChart || []).map(({ date, value }) => ({
        date,
        value: Number((value || 0).toFixed(2))
      })),
      formatter: (value: number) => `${Number(value.toFixed(2))}%`
    },
    {
      title: 'Spend',
      active: false,
      lineColor: '#9635E8',
      areaColor: '#F3E8FF',
      value: currencyFormatter(data?.adSpend || data?.totalCost || 0),
      data: (data?.charts?.adSpendChart || []).map(({ date, value }) => ({
        date,
        value: Number((value || 0).toFixed(2))
      })),
      formatter: (value: number) => `$${Number(value.toFixed(2))}`
    }
  ]
  if (data?.convValue && data.convValue > 0) {
    metrics.push({
      title: 'Conversions Value',
      active: false,
      lineColor: '#17B26A',
      areaColor: '#DCFAE6',
      value: currencyFormatter(data?.convValue || 0),
      data: (data?.charts?.conversionValueChart || []).map(({ date, value }) => ({
        date,
        value: Number((value || 0).toFixed(2))
      })),
      formatter: (value: number) => `$${Number(value.toFixed(2))}`
    })
  }

  return (
    <ConversionMetrics
      fromDate={fromDate}
      toDate={toDate}
      metrics={metrics}
      adsProduct={adsProduct}
    />
  )
}
