import React from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { NavLink } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { PresentationChartIcon } from '../Icons'
import { formatGoogleAdsId } from '../../helpers/formatGoogleAdsId'

const baseSpanClass =
  'text-gray-600 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-100 hover:underline duration-100 transition'

export function BreadcrumbComponent() {
  const url = window.location.pathname
  const finalUrlParam = url.split('/').pop()
  const isAds = url.includes('ads')
  const isCampaign = url.includes('campaign')
  const isSeo = url.includes('seo')

  const adsId = isCampaign ? url.split('ads/')[1].split('/campaign')[0] : ''

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
      <NavLink key="1" to="/cpanel" underline="none">
        <PresentationChartIcon className={`w-5 h-5 ${baseSpanClass}`} />
      </NavLink>

      <NavLink key="2" to={isCampaign ? '/cpanel/reports/ads' : url} underline="none">
        <span className={`${baseSpanClass} ${isSeo ? 'uppercase' : 'capitalize'}`}>
          {isAds ? 'Google Ads' : finalUrlParam}
        </span>
      </NavLink>
      {isCampaign && (
        <NavLink key="3" to="/cpanel/reports/ads" underline="none">
          <span className={`${baseSpanClass} ${isSeo ? 'uppercase' : 'capitalize'}`}>
            {formatGoogleAdsId(adsId)}
          </span>
        </NavLink>
      )}
      {isCampaign && (
        <NavLink key="4" href={url} underline="none">
          <span className={baseSpanClass}>Campaigns</span>
        </NavLink>
      )}
    </Breadcrumbs>
  )
}
