import React from 'react'

export function LoadingChart() {
  return (
    <div role="status" className="p-4 rounded animate-pulse md:p-6 w-full">
      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-500 w-32 mb-2.5" />
      <div className="mb-10 w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-500" />
      <div className="flex items-baseline mt-4 space-x-6">
        {['h-72', 'h-56', 'h-72', 'h-64', 'h-80', 'h-72', 'h-80'].map((value, i) => (
          <div key={i} className={`${value} w-full bg-gray-200 rounded-t-lg dark:bg-gray-500`} />
        ))}
      </div>
      <span className="sr-only">Loading Contents</span>
    </div>
  )
}
