import React from 'react'
import { Tooltip } from '@mui/material'

interface TableHeadCellProps {
  label: string
  className?: string
  tooltip?: string
}

export function TableHeadCell({
  label,
  className = 'table-th p-4',
  tooltip = ''
}: TableHeadCellProps) {
  return (
    <th scope="col" className={`table-th p-4 ${className}`}>
      <Tooltip title={tooltip}>
        <span>{label}</span>
      </Tooltip>
    </th>
  )
}
