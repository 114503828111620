import React from 'react'
import { ShoppingProductsTable } from './ShoppingProductsTable'
import { Panel } from '../Panel'
import { NewPagination, usePagination } from '../../Pagination'
import type { ShoppingMetric } from '../types'

interface ShoppingProductsProps {
  loading: boolean
  list: ShoppingMetric[]
  dateFrom?: string | null
}

export function ShoppingProducts({ loading, list, dateFrom = null }: ShoppingProductsProps) {
  const pagination = usePagination(list)

  return (
    <Panel title="Shopping Products" loading={loading} dateFrom={dateFrom}>
      <>
        <ShoppingProductsTable list={pagination.paginated} />
        <NewPagination
          total={pagination.total}
          page={pagination.page}
          itemsPerPage={pagination.itemsPerPage}
          setPage={pagination.setPage}
          setItemsPerPage={pagination.setItemsPerPage}
        />
      </>
    </Panel>
  )
}
