import React, { useEffect, useState } from 'react'
import { ArrowDownIcon, ArrowUpIcon } from '../../Icons'
import { Tooltip } from '../../Tooltip'
import { LoadingCard } from '../../Loading'
import { seoRankingTooltip } from '../../../helpers/seoRankingTooltip'
import { showConfetti } from '../../../helpers/showConfetti'

export function KeywordRatingPanel({
  loading,
  tooltip,
  title,
  value,
  description,
  isUpTrend,
  trendValue,
  animate = false
}) {
  const [animatedValue, setAnimatedValue] = useState(0)

  const animateCounter = (target, iterations = 20, start = 0) => {
    if (!target) {
      return
    }

    let current = start
    const increment = (target - start) / iterations

    const handle = setInterval(() => {
      if (current >= target) {
        setAnimatedValue(target)
        clearInterval(handle)
        return
      }

      current += increment
      setAnimatedValue(current)
    }, 50)
  }

  useEffect(() => {
    animateCounter(value)
  }, [value])

  const Icon = isUpTrend ? ArrowUpIcon : ArrowDownIcon

  return (
    <div className="p-4 md:p-6 xl:p-6">
      {loading && <LoadingCard />}
      {!loading && (
        <div className="flex justify-between items-end">
          <div>
            <Tooltip
              element="h3"
              content={seoRankingTooltip(tooltip)}
              className="flex items-center text-sm md:text-base font-normal dark:text-base-50 tracking-tight"
            >
              {title}
            </Tooltip>
            <span className="block text-2xl font-bold leading-none sm:text-2xl text-primary-500 dark:text-base-50">
              {Math.round(animatedValue)}
            </span>
          </div>
          <div className="flex-shrink-0">
            <div
              className={`flex items-center px-2.5 py-0.5 mb-2 rounded-[16px] border-[1px] dark:border-none  text-sm font-medium md:mt-2 lg:mt-0 ${
                isUpTrend
                  ? 'text-success-700 bg-success-50 border-success-200 dark:text-success-700 dark:bg-success-200'
                  : 'text-error-700 bg-error-50 border-error-200 dark:text-error-900 dark:bg-error-300'
              }`}
              onMouseOver={animate ? showConfetti : null}
            >
              <Icon className="-ml-1 mr-0.5 h-4 w-4 justify-end" />
              <span className="sr-only">{description}</span>
              <span>{trendValue}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
