import React from 'react'

interface LockIconProps {
  className?: string
}

export function LockIcon({ className = 'w-[16px] h-[16px]' }: LockIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" className={className}>
      <path
        d="M4.39548 14.4C4.06529 14.4 3.78353 14.2825 3.5502 14.0475C3.31686 13.8125 3.2002 13.53 3.2002 13.2V6.79996C3.2002 6.46996 3.3177 6.18746 3.5527 5.95246C3.7877 5.71746 4.0702 5.59996 4.4002 5.59996H4.8002V3.99996C4.8002 3.11462 5.11256 2.35996 5.7373 1.73596C6.36203 1.11196 7.11758 0.799957 8.00396 0.799957C8.89034 0.799957 9.64464 1.11196 10.2669 1.73596C10.8891 2.35996 11.2002 3.11462 11.2002 3.99996V5.59996H11.6002C11.9302 5.59996 12.2127 5.71746 12.4477 5.95246C12.6827 6.18746 12.8002 6.46996 12.8002 6.79996V13.2C12.8002 13.53 12.6826 13.8125 12.4475 14.0475C12.2124 14.2825 11.9297 14.4 11.5995 14.4H4.39548ZM8.00373 11.2C8.33471 11.2 8.61686 11.0821 8.8502 10.8464C9.08353 10.6107 9.2002 10.3274 9.2002 9.99642C9.2002 9.66545 9.08235 9.38329 8.84666 9.14996C8.61096 8.91662 8.32763 8.79996 7.99666 8.79996C7.66568 8.79996 7.38353 8.9178 7.1502 9.15349C6.91686 9.38919 6.8002 9.67252 6.8002 10.0035C6.8002 10.3345 6.91804 10.6166 7.15373 10.85C7.38943 11.0833 7.67276 11.2 8.00373 11.2ZM6.0002 5.59996H10.0002V3.99996C10.0002 3.4444 9.80575 2.97218 9.41686 2.58329C9.02797 2.1944 8.55575 1.99996 8.0002 1.99996C7.44464 1.99996 6.97242 2.1944 6.58353 2.58329C6.19464 2.97218 6.0002 3.4444 6.0002 3.99996V5.59996Z"
        fill="currentColor"
      />
    </svg>
  )
}
