import React from 'react'
import { Field, ErrorMessage } from 'formik'

interface FormikInputProps {
  id: string
  label: string
  className?: string
  placeholder?: string
  maxlength?: string
  icon?: React.ReactNode
}

export function FormikInput({
  errors,
  touched,
  id,
  label,
  className = 'form-row',
  placeholder = '',
  maxlength = '255',
  icon = null
}: FormikInputProps) {
  return (
    <div className={className}>
      <label
        className="flex justify-start mb-2 gap-[10px] font-semibold text-sm text-gray-600 dark:text-gray-300 leading-5"
        htmlFor={id}
      >
        {label}
        {icon}
      </label>
      <Field
        type="text"
        name={id}
        id={id}
        placeholder={placeholder}
        maxLength={parseInt(maxlength)}
        className={`
            uppercase w-full font-[400] text-[16px] font-inter leading-[24px] dark:bg-gray-900 text-base dark:text-neutral-50 text-gray-900 py-[10px] px-[14px] border-[2px] rounded-[8px] shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] transition-all duration-200
            ${
              errors[id] && touched[id]
                ? 'border-error-300 hover:border-error-500 focus:border-error-600 dark:border-error-500  dark:hover:border-error-600 dark:focus:border-error-700 focus:ring-0 focus:ring-offset-0'
                : 'border-gray-300 hover:border-gray-400 dark:border-gray-700 hover:dark:border-primary-800 focus:border-[#1c64f2]'
            }`}
      />
      <ErrorMessage
        name={id}
        component="span"
        className={`${errors[id] && touched[id] ? 'animate-fade-in' : ''} font-inter text-[14px] font-[400] leading-[20px] text-error-500 dark:text-error-400`}
      />
    </div>
  )
}
