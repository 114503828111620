import React from 'react'

export function PageButton({ page, isCurrent, onClick }) {
  const currentClassName = [
    'dark:text-base-50',
    'rounded-md',
    'bg-gray-50',
    'hover:bg-gray-300',
    'text-base-900',
    'dark:border-base-600',
    'dark:bg-base-600',
    'focus:shadow-outline-cool-gray'
  ]

  const className = [
    'px-1',
    'rounded-md',
    'hover:shadow-outline-cool-gray',
    'focus:shadow-outline-cool-gray',
    ...(isCurrent ? currentClassName : [])
  ].join(' ')

  return (
    <button type="button" className={className} onClick={() => onClick(page)}>
      {page}
    </button>
  )
}
