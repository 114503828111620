import { api } from './api'
import { Tag } from './tags'

export const notificationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getNotifications: build.query({
      query: () => ({
        url: 'cpanel/notifications',
        method: 'POST'
      }),
      providesTags: (response, error, id) => [{ type: Tag.Notifications, id }]
    })
  })
})

export const { useGetNotificationsQuery } = notificationsApi
