import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { AppRoot, AccountsRoot, withAuth, ErrorPage, PageType } from './pages'
import { DashboardPage, AdsPage, CampaignPage, SeoPage, FacebookPage, BillingPage, SettingsPage } from './pages'
import { OnboardingPage } from './pages/OnboardingPage'
import { LoginForm } from './components/account/LoginForm'
import { ResetPasswordForm } from './components/account/ResetPasswordForm'
import { PasswordForgottenForm } from './components/account/PasswordForgottenForm'

const SettingsWithAuth = withAuth(SettingsPage)

const accountRoutes = [
  {
    path: '/',
    element: <AccountsRoot />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <LoginForm /> },
      { path: 'reset-password', element: <ResetPasswordForm /> },
      { path: 'forgot-password', element: <PasswordForgottenForm /> }
    ]
  }
]

const appRoutes = [
  {
    path: '/cpanel',
    element: <AppRoot />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, id: PageType.Dashboard, element: <DashboardPage /> },
      { path: 'reports/ads', id: PageType.Ads, element: <AdsPage /> },
      { path: 'reports/ads/:adsId/campaign/:campaignId', id: PageType.Campaign, element: <CampaignPage /> },
      { path: 'reports/seo', id: PageType.Seo, element: <SeoPage /> },
      { path: 'reports/facebook', id: PageType.Facebook, element: <FacebookPage /> },
      { path: 'billing', id: PageType.Billing, element: <BillingPage /> },
      { path: 'settings', id: PageType.Settings, element: <SettingsWithAuth /> }
    ]
  }
]

const onboardingRoutes = [
  { path: '/onboarding', element: <OnboardingPage /> }
]

export const appRouter = createBrowserRouter([...accountRoutes, ...appRoutes, ...onboardingRoutes])
