import React, { useState, useRef, useEffect } from 'react'
import { modalEventHandler } from '../../../js/helpers/modalEventHandler'
import { CommonFunc } from '../../libs/common'
import { SelectionOption } from './SelectionOption'
import { CalendarIcon } from '../Icons'
import { ButtonClose } from '../Buttons'

const pluralize = new CommonFunc().pluralize

interface DateFilterProps {
  onSelect: (data: { units: number; period: string }) => void
  selected: { units: number; period: string }
}

export function DateFilter({ onSelect, selected }: DateFilterProps) {
  const [open, setOpen] = useState(false)
  const { units = 1, period = 'month' } = selected
  const popupRef = useRef(null)

  const handleOpenClick = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    return modalEventHandler(open, handleOpenClick)
  }, [open])

  const selectDateRange = (selectedUnit, selectedPeriod) => {
    setOpen(false)
    onSelect({
      units: Number(selectedUnit),
      period: selectedPeriod
    })
  }

  const selectDate = (event) => {
    const { name, value } = event.target

    const data = {
      units,
      period,
      [name]: value
    }
    selectDateRange(data.units, data.period)
  }

  return (
    <div
      onClick={() => setOpen(!open)}
      ref={popupRef}
      className="group px-[20px] py-[10px] relative flex gap-[8px] rounded-[24px] bg-gray-100 dark:bg-gray-700 hover:bg-secondary-100 dark:hover:bg-primary-600 font-inter text-[14px] font-[600] text-gray-500 dark:text-gray-200 hover:dark:text-gray-100 hover:text-primary-500 cursor-pointer transition duration-100"
    >
      <span className="text-gray-700 dark:text-gray-200 group-hover:text-primary-500 group-hover:dark:text-gray-100">
        <CalendarIcon className="w-5 h-5" />
      </span>
      <div className="flex items-center w-full">
        <button type="button" className="w-full text-start">
          <span className="capitalize">
            {pluralize(units, ` ${period}`) === '60 months'
              ? 'All time'
              : pluralize(units, ` ${period}`)}
          </span>
        </button>
        {open && (
          <>
            <div className="visible sm:hidden transition-opacity ease-in-out duration-500 fixed bg-[#00000099] backdrop-blur inset-0 z-[25] opacity-100" />
            <div className="w-full rounded-[12px] fixed sm:absolute m-0 right-0 z-50 px-[16px] sm:px-0 pb-[32px] sm:pb-0 bottom-[0px] sm:bottom-auto top-auto sm:top-[50px] bg-white dark:bg-gray-700 font-inter font-[500] text-[16px] leading-[24px] text-gray-700 dark:text-gray-100 shadow-lightElev1 dark:shadow-darkElev1 animate-slide-up sm:animate-fade-in">
              <div className="visible sm:hidden flex justify-between font-inter text-[18px] font-[600] text-gray-900 dark:text-gray-100 leading-[28px] px-[16px] pt-[20px]">
                <span>Date</span>
                <ButtonClose />
              </div>
              <ul className="py-1 rounded-[12px]" role="none">
                <SelectionOption text="All time" onClick={() => selectDateRange(5, 'year')} />
                <SelectionOption text="6 months" onClick={() => selectDateRange(6, 'month')} />
                <SelectionOption text="3 months" onClick={() => selectDateRange(3, 'month')} />
                <SelectionOption text="1 month" onClick={() => selectDateRange(1, 'month')} />
                <SelectionOption text="7 days" onClick={() => selectDateRange(7, 'day')} />
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
