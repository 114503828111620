import React from 'react'
import { ProductMessage } from '../../ProductMessage'

export function ProductInactiveMessage() {
  return (
    <ProductMessage title="Have you considered SEO?">
      <>
        <p className="paragraph-text">
          As Australia's leading SEO specialists, we know that every SEO strategy is as unique as
          its business, and sometimes, you just need a little something extra. To find out how we
          can help your business blast off today, contact one of our SEO specialists about a
          tailored package that is customised to your specific business needs.
        </p>
        <p className="paragraph-text">
          If you'd like to take your digital marketing to the next level, reach out and we'll be
          happy to help you get to the next level!
        </p>
      </>
    </ProductMessage>
  )
}
