import React from 'react'
import { LoadingTable } from '../Loading'

type PanelProps = {
  title: string
  children?: React.ReactNode
  loading?: boolean
  toggleText?: string | null
  onToggleClick?: () => void
}

export function Panel({
  title,
  children = null,
  loading = false,
  toggleText = null,
  onToggleClick = () => {}
}: PanelProps) {
  return (
    <div className="animate-slide-left bg-white dark:bg-gray-800 dark:text-white rounded-[16px] p-4 s xl:p-6 h-full">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg md:text-xl font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </h3>

        {toggleText && (
          <div className="flex-shrink-0">
            <button
              type="button"
              className="p-2 text-sm font-medium rounded-[16px] text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-base-700"
              onClick={onToggleClick}
            >
              {toggleText}
            </button>
          </div>
        )}
      </div>
      <div className="flex flex-col">
        {loading && <LoadingTable />}
        {!loading && children}
      </div>
    </div>
  )
}
