import { createSlice } from '@reduxjs/toolkit'

const getMessagesStateFromLocalStorage = () => {
  const messages = localStorage.getItem('messages')
    ? JSON.parse(localStorage.getItem('messages'))
    : {}

  return !!messages?.facebook?.hide
}

const setMessagesStateInLocalStorage = (hide) => {
  const messages = localStorage.getItem('messages')
    ? JSON.parse(localStorage.getItem('messages'))
    : {}

  localStorage.setItem(
    'messages',
    JSON.stringify({
      ...messages,
      facebook: { hide }
    })
  )
}

const initialState = {
  selectedAccountId:
    JSON.parse(localStorage.getItem('selectedFacebookId')) ||
    window.customer?.facebookAccounts?.[0],
  messages: {
    hide: getMessagesStateFromLocalStorage()
  },
  pagination: {
    campaigns: {
      page: 1,
      perPage: 10
    },
    adSets: {
      page: 1,
      perPage: 10
    }
  }
}

const changePage = (state, name, page, perPage) => ({
  ...state,
  pagination: {
    ...state.pagination,
    [name]: {
      perPage,
      page
    }
  }
})

const slice = createSlice({
  name: 'facebook',
  initialState,
  reducers: {
    updatePagination: (state, { payload }) => {
      const { name, page, perPage } = payload
      return changePage(state, name, page, perPage)
    },
    hideMessages: (state, { payload }) => {
      const hide = !!payload

      setMessagesStateInLocalStorage(hide)
      return {
        ...state,
        messages: {
          hide
        }
      }
    },
    selectAccount: (state, { payload }) => ({
      ...state,
      selectedAccountId: payload
    })
  }
})

export const { updatePagination, hideMessages, selectAccount } = slice.actions

export const { reducer: facebook } = slice
