import React, { useState } from 'react'
import { EyeClosedIcon, EyeOpenIcon, QuestionMarkIcon } from '../../Icons'

interface InputFieldProps {
  name: string
  value: string
  label: string
  type: string
  onChange: (value: string) => void
  autocomplete?: string
  placeholder?: string
  hasError?: boolean
  openModal?: () => void
  disabled?: boolean
}

export function InputField({
  name,
  value,
  label,
  type,
  onChange,
  autocomplete = null,
  placeholder = null,
  hasError = false,
  openModal = null,
  disabled = false,
}: InputFieldProps) {
  const [showPassword, setShowPassword] = useState(false)

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  return (
    <div className="grid gap-[4px] grid-rows-[20,42px] relative">
      <label
        htmlFor={name}
        className="flex gap-[10px] font-semibold text-sm text-gray-600 dark:text-gray-300 leading-5"
      >
        <span>{label}</span>
        {openModal instanceof Function && (
          <div onClick={openModal} className="w-5 h-5 group cursor-pointer">
            <QuestionMarkIcon className="transition-all duration-100 group-hover:stroke-primary-500 stroke-[#98A2B3]" />
          </div>
        )}
      </label>
      <input
        id={name}
        name={name}
        value={value}
        type={showPassword ? 'text' : type}
        disabled={disabled}
        className={`w-full font-normal text-base dark:text-neutral-50 leading-6 text-gray-900 py-[9px] px-[13px] border-[2px] border-gray-300 hover:border-gray-400 dark:border-gray-700 hover:dark:border-primary-800 rounded-[8px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] transition-all duration-200 focus:border-[#1c64f2] ${
          hasError
            ? 'border-red-400 dark:border-red-500 hover:border-red-500 focus:border-red-500 hover:dark:border-red-600 focus:dark:border-600 focus:ring-0 focus:ring-offset-0'
            : 'border-gray-200 dark:border-base-500'
        } ${disabled ? 'bg-gray-300 dark:bg-gray-600' : 'dark:bg-gray-900'}`}
        autoComplete={autocomplete}
        placeholder={placeholder}
        onChange={onValueChange}
      />
      {type === 'password' && (
        <div
          className="absolute top-[34px] right-[15px] cursor-pointer active:scale-90 body text-show-hide fill-base-900 hover:fill-base-400 dark:fill-primary-600 dark:hover:fill-primary-700 transition-all duration-100"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
        </div>
      )}
    </div>
  )
}
