import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Tabs } from '../components/Tabs'
import { selectCampaign } from '../store/ads'
import {
  NoDataMessage,
  ProductInactiveMessage,
  ProductMessages
} from '../components/ads/ProductMessages'
import { getMessages } from '../components/ProductMessage'
import { GettingDataReady } from '../components/GettingDataReady'
import { CoreMetrics } from '../components/CoreMetrics'
import { cache } from '../services/cache'
import { useGetAdsReportsQuery } from '../services/reports'
import { Keywords } from '../components/ads/Keywords'
import { AdGroups } from '../components/ads/AdGroups'
import { Ads } from '../components/ads/Ads'
import { AdSchedule } from '../components/ads/AdSchedule'
import { ShoppingProducts } from '../components/ads/ShoppingProducts'
import { LoadingCardLong } from '../components/Loading'
import { AccountSelector } from '../components/AccountSelector'
import { Copied } from '../components/Popover'
import { extractAccessKey } from '../helpers/extractAccessKey'
import { useCheckAccessKeyQuery } from '../services/cpanel'
import { isProductActive } from '../helpers/isProductActive'
import { BreadcrumbComponent } from '../components/Breacrumbs'
import { SearchTerms } from '../components/ads/SearchTerms'

const removePrefix = (name) => {
  return name.replace(/^\*?(SLX|GMT|SME|JDM)( \| )?/, '')
}

const accessKey = extractAccessKey()

const TAB_ALL = 'All'
const TAB_AD_SCHEDULE = 'Ad Schedule'
const TAB_SHOPPING_PRODUCTS = 'Shopping Products'
const TAB_SEARCH_TERMS = 'Search Terms'
const TAB_AD_GROUPS = 'Ad Groups'
const TAB_ADS = 'Top Ads'
const TAB_KEYWORDS = 'Top Keywords'

export function CampaignPage() {
  const { campaignId } = useParams()
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const accessKeyValid = useCheckAccessKeyQuery(accessKey)
  const dispatch = useDispatch()

  const customer = useSelector((store) => store.global.customer)
  const reduxCampaignId = useSelector((store) => store.ads.selectedCampaign)
  const dateFilter = useSelector((store) => store.global.dateFilter)
  const budgets = useSelector((store) => store.global.budgets)
  const hideMessages = useSelector((store) => store.ads.messages).hide
  const adsId = useSelector((store) => store.ads.selectedAccountId)
  const activeProducts = useSelector((store) => store.global.customer.activeProducts)

  const adsProduct =
    activeProducts.find(
      (product) =>
        isProductActive(product?.adWordsManagement?.status || '') &&
        product?.adWordsManagement?.signupDate !== null &&
        product?.adWordsManagement?.adsId === adsId
    )?.adWordsManagement || null
  const { from, to } = dateFilter

  useEffect(() => {
    cache.adsReports.use({ adsId, from, to })
  }, [adsId, from, to])

  const { data, isLoading } = useGetAdsReportsQuery(
    { adsId, from, to },
    { skip: !(adsId && from && to) }
  )

  //* Campaign Specific Data
  const selectedCampaignId = reduxCampaignId ? reduxCampaignId : campaignId
  const cleanedCampaignData = data?.campaignStats?.map((item) => ({
    ...item,
    campaign_name: removePrefix(item.campaignName)
  }))
  const campaignIds = cleanedCampaignData?.map((key) => key.campaignId) || []
  const selectedCampaign = cleanedCampaignData?.find(
    (key) => key.campaignId === Number(selectedCampaignId)
  )
  const campaignShoppingStats =
    data?.shoppingStats?.filter((key) => key.campaignId === Number(selectedCampaignId)) || []
  const campaignAds =
    data?.adStats?.filter((key) => key.campaignId === Number(selectedCampaignId)) || []
  const campaignAdGroups =
    data?.adGroupStats?.filter((key) => key.campaignId === Number(selectedCampaignId)) || []
  const campaignKeywords =
    data?.keywordStats?.filter((key) => key.campaignId === Number(selectedCampaignId)) || []
  const campaignSearchTermStats =
    data?.searchTermStats?.filter((key) => key.campaignId === Number(selectedCampaignId)) || []
  const campaignAdScheduleStats =
    data?.adScheduleViewStats?.filter((key) => key.campaignId === Number(selectedCampaignId)) || []

  const product = Object.values(
    (data?.product || []).find((item) => {
      const details = Object.values(item)[0] || {}
      return details.adsId === adsId
    }) || {}
  )[0]

  const tabs = [
    TAB_ALL,
    campaignAdGroups.length > 0 && TAB_AD_GROUPS,
    campaignKeywords.length > 0 && TAB_KEYWORDS,
    campaignSearchTermStats.length > 0 && TAB_SEARCH_TERMS,
    campaignAds.length > 0 && TAB_ADS,
    campaignShoppingStats.length > 0 && TAB_SHOPPING_PRODUCTS,
    campaignAdScheduleStats.length > 0 && TAB_AD_SCHEDULE
  ].filter(Boolean)

  if (!customer.adsActive && !accessKeyValid.data) {
    return <ProductInactiveMessage />
  }

  if (!customer.adsData) {
    return <NoDataMessage platform="Google" />
  }

  useEffect(() => {
    dispatch(selectCampaign(campaignId))
  }, [campaignId])

  const tabIsActive = (tabLabel: string) => [TAB_ALL, tabLabel].includes(tabs[activeTabIndex])

  return (
    <>
      {!hideMessages && (
        <ProductMessages
          loading={isLoading}
          messages={getMessages(product?.setupComplete, product?.activeDays)}
        />
      )}
      {product?.status === 'Active' && (
        <>
          <BreadcrumbComponent />
          <div className="flex flex-row items-center pt-[16px] pb-[24px]">
            <h1 className="font-inter text-[24px] text-gray-900 dark:text-gray-100 font-[600] leading-[32px] pr-[16px]">
              Campaign {selectedCampaign?.campaign_name}
            </h1>
            <AccountSelector type="campaign" campaignIds={campaignIds} />
            <Copied toCopy={selectedCampaignId} />
          </div>
          <div
            className={`gap-4 grid grid-cols-1 ${isLoading ? 'bg-white dark:bg-gray-800 dark:text-white rounded-[16px] p-[16px] sm:p-[24px]' : 'md:grid-cols-2 '} w-full`}
          >
            {isLoading && <LoadingCardLong />}
            {!isLoading && adsProduct && (
              <CoreMetrics
                fromDate={from}
                toDate={to}
                data={selectedCampaign}
                adsProduct={adsProduct}
              />
            )}
          </div>
        </>
      )}

      <div className="col-span-full flex items-center justify-between overflow-x-auto dark:scrollbar-thumb-base-50 dark:scrollbar-track-base-700 flex-1 scrollbar-thin scrollbar-thumb scrollbar-thumb-primary-500 scrollbar-thumb-rounded-full scrollbar-track-gray-200 scrollbar-track-rounded-full scrollbar-width-10">
        <div className="hidden md:flex place-items-start">
          <h1 className="font-inter text-[24px] text-gray-900 dark:text-gray-100 font-[600] leading-[32px] pt-[40px] pb-[24px]">
            Campaign Metrics
          </h1>
        </div>
        {tabs.length > 2 && (
          <div className="flex bg-opacity-30 sm:my-3 tracking-tight space-x-2 md:space-x-4 place-items-end">
            <Tabs labels={tabs} activeTabIndex={activeTabIndex} onSelect={setActiveTabIndex} />
          </div>
        )}
      </div>
      <div className="gap-4 grid grid-cols-1 w-full">
        {isLoading && <GettingDataReady />}
        {campaignAdGroups.length > 0 && tabIsActive(TAB_AD_GROUPS) && (
          <AdGroups loading={isLoading} list={campaignAdGroups} dateFrom={dateFilter?.from} />
        )}
        {campaignKeywords.length > 0 && tabIsActive(TAB_KEYWORDS) && (
          <Keywords loading={isLoading} list={campaignKeywords} dateFrom={dateFilter?.from} />
        )}
        {campaignSearchTermStats.length > 0 && tabIsActive(TAB_SEARCH_TERMS) && (
          <SearchTerms
            loading={isLoading}
            list={campaignSearchTermStats}
            dateFrom={dateFilter?.from}
          />
        )}
        {campaignAds.length > 0 && tabIsActive(TAB_ADS) && (
          <Ads loading={isLoading} list={campaignAds} dateFrom={dateFilter?.from} />
        )}
        {campaignShoppingStats.length > 0 && tabIsActive(TAB_SHOPPING_PRODUCTS) && (
          <ShoppingProducts
            loading={isLoading}
            list={campaignShoppingStats}
            dateFrom={dateFilter?.from}
          />
        )}
        {campaignAdScheduleStats.length > 0 && tabIsActive(TAB_AD_SCHEDULE) && (
          <AdSchedule
            loading={isLoading}
            list={campaignAdScheduleStats}
            dateFrom={dateFilter?.from}
          />
        )}
      </div>
    </>
  )
}
