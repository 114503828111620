import React, { useEffect } from 'react'
import { ButtonClose } from '../../Buttons'
import { NotificationContent } from './NotificationContent'

interface NotifDataProps {
  id: string
  priority: string
  title: string
  content: string
  buttonText: string
  buttonLink: string
}

interface FullNotificationProps {
  setFullSize: (fullSize: boolean) => void
  notifData: NotifDataProps[]
}

export function FullNotification({ setFullSize, notifData }: FullNotificationProps) {
  // disable scrolling of background ! TODO: Remove this
  useEffect(() => {
    document.body.classList.add('overflow-hidden')
    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [])

  return (
    <>
      <div
        onClick={() => setFullSize(false)}
        className="visible transition-opacity ease-in-out duration-500 fixed bg-[#00000099] backdrop-blur inset-0 z-[25] opacity-100"
      />
      <div className="animate-slide-up sm:animate-slide-right w-full h-full sm:h-[100vh] sm:w-[400px] fixed left-0 m-0 right-0 z-50 px-[16px] sm:px-0 pb-[32px] sm:pb-0 bottom-[0px] sm:bottom-auto top-auto sm:right-[26px] sm:top-0 bg-white dark:bg-gray-900 font-inter font-[500] text-[16px] leading-[24px] text-gray-700 dark:text-gray-100 shadow-lightElev1 dark:shadow-darkElev1 overflow-y-auto">
        <div className="flex justify-between items-center p-[24px]">
          <span className="font-inter text-[18px] font-[600] leading-[28px] text-gray-900 dark:text-gray-100 text-start">
            Notifications
          </span>
          <div
            onClick={() => setFullSize(false)}
            className="flex font-inter text-[18px] font-600 dark:text-gray-100 leading-[28px]"
          >
            <ButtonClose />
          </div>
        </div>
        {notifData.length > 0 ? (
          <ul className="py-1 rounded-[8px] overflow-y-auto" role="none">
            {notifData.map((data, index) => (
              <NotificationContent
                key={index}
                id={data.id}
                priority={data.priority}
                title={data.title}
                content={data.content}
                buttonText={data.buttonText}
                buttonLink={data.buttonLink}
              />
            ))}
          </ul>
        ) : (
          <div className="flex flex-col gap-[15px] p-[26px] items-center justify-center">
            <h2 className="text-gray-900 text-[16px] font-[600] leading-[24px]">Nothing yet</h2>
            <span className="font-inter text-[14px] font-[400] leading-[20px] text-gray-600 dark:text-white">
              We'll notify you when there is something new
            </span>
          </div>
        )}
      </div>
    </>
  )
}
