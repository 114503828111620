import React from 'react'
import { useSelector } from 'react-redux'
import ReactECharts from 'echarts-for-react'
import { getGraphColorSets } from './getGraphColorSets'

const ops = {
  renderer: 'canvas',
  useCoarsePointer: null
}

const chartStyle = {
  height: '58vh'
}

const getOptions = (darkMode, seriesData, coloured) => ({
  tooltip: {
    trigger: 'item'
  },
  legend: {
    top: '5%',
    left: 'center',
    textStyle: {
      fontFamily: 'inter',
      fontSize: '13px',
      color: darkMode ? '#fff' : '#000'
    }
  },
  color: coloured.map((e) => e.color),
  backgroundColor: 'transparent',
  series: [
    {
      name: 'Aquisition by device',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: darkMode ? 0 : 2
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        opacity: 0.55,
        label: {
          show: true,
          fontSize: 30,
          fontWeight: 'bold',
          color: darkMode ? '#fff' : '#000'
        }
      },
      labelLine: {
        show: false
      },
      data: seriesData
    }
  ]
})

export function AcquisitionByDeviceChart({ data }) {
  const darkMode = useSelector((store) => store.global.darkMode)

  const seriesData = data.map((item) => ({
    value: item.value,
    name: item.category
  }))

  // Set chart colors based on device
  const coloured = seriesData.map((e) => {
    const colorMap = {
      Desktop: '#17B26A',
      Mobile: '#38bdf8',
      Tablet: '#F97066'
    }
    return {
      ...e,
      color: colorMap[e.name] || '#fff'
    }
  })

  return (
    <ReactECharts
      className="w-full"
      option={getOptions(darkMode, seriesData, coloured)}
      ops={ops}
      style={chartStyle}
    />
  )
}
