import React from 'react'
import { ButtonClose } from '../../Buttons'
import { NotificationContent } from './NotificationContent'

interface NotifDataProps {
  id: string
  priority: string
  title: string
  content: string
  buttonText: string
  buttonLink: string
}

interface SmallNotificationProps {
  setFullSize: (fullSize: boolean) => void
  notifData: NotifDataProps[]
}

export function SmallNotification({ setFullSize, notifData }: SmallNotificationProps) {
  return (
    <>
      <div className="visible sm:hidden transition-opacity ease-in-out duration-500 fixed bg-[#00000099] backdrop-blur inset-0 z-[25] opacity-100" />
      <div className="w-full sm:w-[352px] rounded-[12px] fixed sm:absolute m-0 right-0 z-50 px-[16px] sm:px-0 pb-[32px] sm:pb-0 bottom-[0px] sm:bottom-auto top-auto sm:right-[26px] sm:top-[68px] bg-white dark:bg-gray-700 font-inter font-[500] text-[16px] leading-[24px] text-gray-700 dark:text-gray-100 shadow-lightElev1 dark:shadow-darkElev1 animate-slide-up sm:animate-fade-in">
        <div className="flex justify-between items-center">
          <span className="font-inter text-[18px] font-[600] leading-[28px] text-gray-900 dark:text-gray-100 text-start p-[16px]">
            Notifications
          </span>
          <div className="visible sm:hidden flex justify-between font-inter text-[18px] font-[600] text-gray-900 dark:text-gray-100 leading-[28px] p-[16px]">
            <ButtonClose />
          </div>
        </div>
        {notifData.length > 0 ? (
          <ul className="py-1 rounded-[12px] overflow-y-auto" role="none">
            {notifData.slice(0, 3).map((data, index) => (
              <NotificationContent
                key={index}
                id={data.id}
                priority={data.priority}
                title={data.title}
                content={data.content}
                buttonText={data.buttonText}
                buttonLink={data.buttonLink}
              />
            ))}
          </ul>
        ) : (
          <div className="flex flex-col gap-[15px] p-[26px] items-center justify-center border-t-[1px] border-gray-200 dark:border-gray-600">
            <h2 className="text-gray-900 dark:text-gray-100 text-[16px] font-[600] leading-[24px]">
              Nothing Yet
            </h2>
            <span className="font-inter text-[14px] font-[400] leading-[20px] text-gray-600 dark:text-gray-300 dark:text-white">
              We'll notify you when there is something new
            </span>
          </div>
        )}
        <div
          onClick={() => setFullSize(true)}
          className="font-inter border-t-[1px] border-gray-200 dark:border-gray-600 text-center font-[600] leading-[20px] text-[14px] text-primary-700 dark:text-primary-300 p-[16px] select-none cursor-pointer"
        >
          View all notifications
        </div>
      </div>
    </>
  )
}
