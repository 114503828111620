import React from 'react'
import { ConnectedTick } from './ConnectedTick'

interface ConnectedAccountsProps {
  accounts: string[]
}

export function ConnectedAccounts({ accounts }: ConnectedAccountsProps) {
  return (
    <div className="w-full flex flex-row rounded-[8px] bg-gray-100 dark:bg-gray-900 justify-between p-[16px]">
      <div className="flex flex-col gap-[5px]">
        <span className="text-[14px] text-gray-700 dark:text-gray-200 font-[600] leading-[20px]">
          Ad Accounts
        </span>
        {accounts.map((account, index) => (
          <span
            key={index}
            className="text-[14px] font-[400] leading-[20px] text-gray-700 dark:text-gray-200"
          >
            {account}
          </span>
        ))}
      </div>
      <ConnectedTick />
    </div>
  )
}
