import { createSlice } from '@reduxjs/toolkit'

const getMessagesStateFromLocalStorage = () => {
  const messages = localStorage.getItem('messages')
    ? JSON.parse(localStorage.getItem('messages'))
    : {}

  return !!messages?.dashboard?.hide
}

const setMessagesStateInLocalStorage = (hide) => {
  const messages = localStorage.getItem('messages')
    ? JSON.parse(localStorage.getItem('messages'))
    : {}

  localStorage.setItem(
    'messages',
    JSON.stringify({
      ...messages,
      dashboard: { hide }
    })
  )
}

const initialState = {
  messages: {
    hide: getMessagesStateFromLocalStorage()
  }
}

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    hideMessages: (state, { payload }) => {
      const hide = !!payload

      setMessagesStateInLocalStorage(hide)
      return {
        ...state,
        messages: {
          hide
        }
      }
    }
  }
})

export const { hideMessages } = slice.actions

export const { reducer: dashboard } = slice
