import React from 'react'
import { useDispatch } from 'react-redux'
import { addToast } from '../../store/global'
import { EyeClosedIcon } from '../Icons'
import { Tooltip } from '@mui/material'

interface ProductMessageProps {
  title: string
  children: React.ReactNode
  onHideClick?: () => void
  className?: string
}

export const getContainerClassName = (extraClassNames = []) =>
  [
    'animate-grow-in',
    'flex',
    'flex-1',
    'flex-shrink-0',
    'grow',
    'justify-self-start',
    'font-sans',
    'font-[400]',
    'text-[14px]',
    'text-gray-600',
    'dark:text-white',
    'leading-[20px]',
    'bg-white',
    'dark:bg-gray-800',
    'rounded-[16px]',
    'dark:text-base-50',
    'items-center',
    'justify-center',
    'p-4',
    'text-sm',
    'sm:text-base',
    'xl:p-6',
    'h-full',
    'mb-4',
    ...extraClassNames
  ].join(' ')

export function ProductMessage({
  title,
  children,
  onHideClick = null,
  className = ''
}: ProductMessageProps) {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(
      addToast({
        title: 'Messages disabled',
        subtext: 'You can switch messages back on in the settings menu',
        type: 'notice'
      })
    )
    onHideClick()
  }

  return (
    <div className={getContainerClassName()}>
      <article className={`prose max-w-none w-full ${className}`}>
        <div className="flex justify-between">
          <span className="font-inter text-[18px] font-[600] leading-[28px] text-gray-900 dark:text-base-50">
            {title}
          </span>
          {onHideClick instanceof Function && (
            <Tooltip title="Hide message">
              <span
                onClick={handleClick}
                className="h-[40px] w-[40px] flex items-center justify-center rounded-[24px] bg-gray-100 dark:bg-gray-300 hover:bg-secondary-100 dark:hover:bg-primary-60 self-center cursor-pointer fill-gray-700 dark:fill-gray-900 focus:outline-none focus:ring-0 hover:fill-primary-500 dark:focus:ring-0 dark:hover:bg-primary-600 active:scale-95 transition duration-100 "
              >
                <EyeClosedIcon />
              </span>
            </Tooltip>
          )}
        </div>
        {children}
      </article>
    </div>
  )
}
