import React from 'react'
import { Table, TableRowEmpty } from '../../Table'
import { TableRow } from './TableRow'
import { TableHead } from './TableHead'

export function CampaignsTable({ list, platform, labels, tooltips, keys }) {
  const rows = list.map((campaign, index) => (
    <TableRow key={`${campaign.campaignName}-${index}`} campaign={campaign} keys={keys} />
  ))

  return (
    <Table
      head={<TableHead platform={platform} labels={labels} tooltips={tooltips} />}
      rows={
        rows.length > 0 ? (
          rows
        ) : (
          <TableRowEmpty
            colSpan={6}
            text="No data for the selected period or no active campaigns."
          />
        )
      }
    />
  )
}
