import React from 'react'
import { KeywordsTable } from './KeywordsTable'
import { Panel } from '../Panel'
import { NewPagination, usePagination } from '../../Pagination'
import type { KeywordsMetric } from '../types'

interface KeywordsProps {
  list: KeywordsMetric[]
  loading: boolean
  dateFrom: string
}

export function Keywords({ loading, list, dateFrom }: KeywordsProps) {
  const pagination = usePagination<KeywordsMetric>(list)

  return (
    <Panel title="Top Keywords" loading={loading} dateFrom={dateFrom}>
      <>
        <KeywordsTable list={pagination.paginated} />
        <NewPagination
          total={pagination.total}
          page={pagination.page}
          itemsPerPage={pagination.itemsPerPage}
          setPage={pagination.setPage}
          setItemsPerPage={pagination.setItemsPerPage}
        />
      </>
    </Panel>
  )
}
