import React, { useState } from 'react'
import spacetime from 'spacetime'
import { Modal } from './Modal'
import { ButtonClose } from '../Buttons'
import { PdfViewer } from '../PdfViewer'
import { DownloadIcon, PrintIcon } from '../Icons'

export function PdfViewerModal({ pdfBlob, headerText, onClose }) {
  const [isExiting, setIsExiting] = useState(false)

  const handleClose = () => {
    setIsExiting(true) // start exit animation
    setTimeout(onClose, 150) // delay to match exit animation duration
  }

  const print = () => {
    window.open(window.URL.createObjectURL(pdfBlob))
  }

  const download = () => {
    const link = document.createElement('a')
    link.setAttribute('href', window.URL.createObjectURL(pdfBlob))
    link.setAttribute(
      'download',
      `invoice-${spacetime.now().format('{year}-{month-pad}-{date-pad}')}.pdf`
    )
    link.click()
  }

  return (
    <Modal handleClose={handleClose} isExiting={isExiting}>
      <>
        <header className="flex justify-between items-center">
          <h3 className="ml-[10px] text-gray-900 dark:text-gray-100 text-[18px] font-[600] leading-[18px]">
            {headerText}
          </h3>
          <ButtonClose onClick={handleClose} />
        </header>
        <div className="w-full my-4 overflow-y-scroll space-y-2 text-base-600 bg-white dark:bg-base-800 dark:text-base-400 items-center justify-center">
          <PdfViewer pdfBlob={pdfBlob} />
        </div>
        <footer className="flex flex-row justify-center gap-[12px] font-inter  text-[16px] font-[600] leading-[24px]">
          <button
            type="button"
            className="text-gray-700 dark:text-gray-300 flex items-center justify-center gap-[8px] w-full bg-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-600 active:scale-95 py-[10px] px-[18px] font-[600] font-inter rounded-[8px] leading-6 transition-all bg-opacity-100 ease-cubic-bezier[.785,.135,.15,.86] duration-100 delay-0"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="text-white dark:text-primary-900 flex items-center justify-center gap-[8px] w-full bg-primary-700 dark:bg-primary-300 hover:bg-primary-900 dark:hover:bg-primary-500 active:scale-95 py-[10px] px-[18px] font-[600] font-inter rounded-[8px] leading-6 transition-all bg-opacity-100 ease-cubic-bezier[.785,.135,.15,.86] duration-100 delay-0"
            onClick={print}
          >
            <PrintIcon />
            <span>Print</span>
          </button>
          <button
            type="button"
            className="text-white dark:text-primary-900 flex items-center justify-center gap-[8px] w-full bg-primary-700 dark:bg-primary-300 hover:bg-primary-900 dark:hover:bg-primary-500 active:scale-95 py-[10px] px-[18px] font-[600] font-inter rounded-[8px] leading-6 transition-all bg-opacity-100 ease-cubic-bezier[.785,.135,.15,.86] duration-100 delay-0"
            onClick={download}
          >
            <DownloadIcon />
            <span>Download</span>
          </button>
        </footer>
      </>
    </Modal>
  )
}
