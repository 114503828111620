import React from 'react'

export function LoadingCardLong() {
  return (
    <div role="status" className="animate-pulse">
      <div className="h-2.5 bg-gray-600 dark:bg-gray-500 rounded-full max-w-[640px] mb-2.5 mx-auto" />
      <div className="h-2.5 mx-auto bg-gray-600 dark:bg-gray-500 rounded-full max-w-[540px]" />
      <div className="flex justify-center items-center mt-4">
        <div className="w-20 h-2.5 bg-gray-600 dark:bg-gray-500 rounded-full mr-3" />
        <div className="w-24 h-2 bg-gray-600 dark:bg-gray-500 rounded-full" />
      </div>
      <span className="sr-only">Loading Contents</span>
    </div>
  )
}
