import React from 'react'
import { getColor } from '../../helpers/getColor'

const defaultFillColors = [
  [getColor('text-primary-200').replace(/0x/g, '#'), 50],
  [getColor('text-primary-300').replace(/0x/g, '#'), 80],
  [getColor('text-primary-500').replace(/0x/g, '#'), 100]
]

export function DonutChart({
  percent,
  bgColor = 'transparent',
  centerCircleRadiusPct = 0.615 /* ~ 40 / 65 */,
  size = 65,
  fillColors = defaultFillColors
}) {
  const percentage = percent > 100 ? 100 : percent
  const halfSize = size / 2 // Utility value
  const unit = (Math.PI * 2) / 100 // 1 degree in radians
  const startangle = 0
  const endangle = percentage * unit - 0.001 // Prevent path from going >= 360 degrees
  // Determine drawing points
  const x1 = halfSize
  const y1 = 0
  const x2 = halfSize + halfSize * Math.sin(endangle)
  const y2 = halfSize - halfSize * Math.cos(endangle)
  // Need extra value if arc percentage is > 180 degrees (in radians)
  const big = endangle - startangle > Math.PI ? 1 : 0

  // Determine color
  let color
  for (let i = 0; i < fillColors.length; i++) {
    color = fillColors[i][0]

    if (percentage <= fillColors[i][1]) {
      break
    }
  }

  // Path
  const d = [
    `M ${halfSize},${halfSize}`, // Start at circle center
    `L ${x1},${y1}`, // Draw line to (x1,y1)
    `A ${halfSize},${halfSize}`, // Draw an arc of radius r
    `0 ${big} 1 `, // Arc details...
    `${x2},${y2}`, // Arc goes to to (x2,y2)
    'Z' // Close wedge back to (cx,cy)
  ].join(' ')

  return (
    <div style={{ width: `${size}px`, height: `${size}px` }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${size} ${size}`}
        width={size}
        height={size}
        className="donut-chart-svg"
      >
        <circle
          xmlns="http://www.w3.org/2000/svg"
          cx={size / 2}
          cy={size / 2}
          r={size / 2}
          className="donut-chart-background"
        />
        <path xmlns="http://www.w3.org/2000/svg" d={d} fill={color} className="donut-chart-fill" />
        <circle
          xmlns="http://www.w3.org/2000/svg"
          cx={halfSize}
          cy={halfSize}
          r={halfSize * centerCircleRadiusPct}
          className="donut-chart-foreground"
        />
        <text
          xmlns="http://www.w3.org/2000/svg"
          x="50%"
          y="50%"
          dy=".3em"
          fontSize={0.27 * size}
          className="donut-chart-text"
        >
          {percentage}
        </text>
      </svg>
    </div>
  )
}
