import React from 'react'

interface ConnectButtonProps {
  isLoading: boolean
  onClick: () => void
  size?: 'small' | 'medium'
  disabled?: boolean
}

export function ConnectButton({
  isLoading,
  disabled,
  onClick,
  size = 'medium'
}: ConnectButtonProps) {
  return (
    <div
      onClick={disabled || isLoading ? null : onClick}
      className={`justify-self-end grid grid-cols-1 justify-items-center w-[96px] bg-primary-400 dark:bg-primary-500 px-[12px] text-black font-normal text-sm rounded-md leading-4 shadow-[0px_1px_2px_rgba(0,0,0,0.05)] transition bg-opacity-100 ease-cubic-bezier[.785,.135,.15,.86] duration-100 delay-0 ${[
        size === 'small' ? 'py-[6px]' : 'py-[9px]',
        isLoading ? 'bg-primary-500 opacity-50' : '',
        disabled || isLoading
          ? 'cursor-not-allowed'
          : 'cursor-pointer hover:bg-primary-500 dark:hover:bg-primary-600 active:scale-95'
      ].join(' ')}`}
    >
      {isLoading ? 'Connecting' : 'Connect'}
    </div>
  )
}
