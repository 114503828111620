import React from 'react'
import { Table, TableRowEmpty } from '../../Table'
import { TableRow } from './TableRow'
import { TableHead } from './TableHead'
import type { AdsMetric } from '../types'

interface AdsTableProps {
  list: AdsMetric[]
}

export function AdsTable({ list }: AdsTableProps) {
  const rows = list.map((item, key) => <TableRow key={key} item={item} />)

  return (
    <Table
      head={<TableHead />}
      rows={
        rows.length > 0 ? (
          rows
        ) : (
          <TableRowEmpty colSpan={8} text="No data for the selected period or no active ads." />
        )
      }
    />
  )
}
