import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CampaignsTable } from './CampaignsTable'
import { Panel } from '../Panel'
import { Pagination, paginate } from '../../Pagination'
import { useGetAllAdsReportsQuery } from '../../../services/reports'
import { cache } from '../../../services/cache'
import { updatePagination as updatePaginationFacebook } from '../../../store/facebook'
import { updatePagination as updatePaginationGoogle } from '../../../store/ads'
import { LoadMoreButton } from '../../Buttons'

export function Campaigns({ adsId, loading, list, pagination, dateFilter, platform, dataKeys }) {
  const [showAll, setShowAll] = useState(false)
  const dispatch = useDispatch()

  // Determine the appropriate updatePagination function based on the platform
  const updatePagination =
    platform === 'facebook' ? updatePaginationFacebook : updatePaginationGoogle

  const changePage = (page) => {
    dispatch(
      updatePagination({
        name: 'campaigns',
        page,
        perPage
      })
    )
  }

  const handlePerPageChange = (newPerPage) => {
    dispatch(
      updatePagination({
        name: 'campaigns',
        page,
        perPage: newPerPage
      })
    )
  }

  const toggleView = () => {
    changePage(1)
    setShowAll(!showAll)
  }

  const payload = {
    adsId,
    from: dateFilter.from,
    to: dateFilter.to,
    stat: 'campaignStats'
  }

  useEffect(() => {
    cache.adsCampaigns.use(payload)
  }, [adsId, dateFilter])

  const allResultsGoogle = useGetAllAdsReportsQuery(payload, { skip: !showAll })
  let allResults = {}
  if (platform === 'google') {
    allResults = allResultsGoogle
  } else if (platform === 'facebook') {
    // since facebook query returns all data by default
    allResults = list // otherwise return all data, for pagination
    list = list.slice(0, 10) // for top 10
  }

  const { page, perPage } = pagination
  const { total, paginated } = paginate(
    platform === 'google' ? allResults?.data?.campaignStats || [] : allResults,
    page,
    perPage
  )

  return (
    <Panel
      title={showAll ? 'Campaign - All Time' : 'Campaign Summary'}
      loading={showAll ? allResults.isLoading : loading}
      toggleText={showAll ? 'Show Top 10' : 'View All'}
      onToggleClick={toggleView}
    >
      <>
        <CampaignsTable
          list={showAll ? paginated : list}
          platform={platform}
          labels={dataKeys.map((data) => data.label)}
          tooltips={dataKeys.map((data) => data.tooltip)}
          keys={dataKeys.map((data) => data.key)}
        />
        {showAll && (
          <Pagination
            total={total}
            page={page}
            perPage={perPage}
            onClick={changePage}
            handlePerPageChange={handlePerPageChange}
          />
        )}
        <LoadMoreButton
          loading={showAll ? allResults.isLoading : loading}
          text={showAll ? 'Show less' : 'Load more'}
          onClick={toggleView}
        />
      </>
    </Panel>
  )
}
