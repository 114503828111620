import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleDarkMode } from '../../store/global'
import { Tooltip } from '@mui/material'
import { DarkModeIcon, LightModeIcon } from '../Icons'

export function ThemeToggler() {
  const darkMode = useSelector((store) => store.global.darkMode)
  const brand = useSelector((store) => store.global.brand.name)
  const dispatch = useDispatch()

  const toggle = () => {
    dispatch(toggleDarkMode())
  }

  return (
    <Tooltip title="Theme Toggle">
      <button
        type="button"
        className={`
          block self-center rounded-[24px] p-2.5 text-sm text-gray-400 focus:outline-none focus:ring-0 hover:bg-secondary-100 hover:text-primary-500 dark:text-base-300 dark:focus:ring-0 dark:hover:bg-primary-600 active:scale-95 transition duration-100 
          ${brand === 'jdm' ? 'dark:hover:text-black' : 'dark:hover:text-base-50'}`}
        onClick={toggle}
      >
        {darkMode ? <DarkModeIcon /> : <LightModeIcon />}
      </button>
    </Tooltip>
  )
}
