import { store } from '../store'

export const currencyFormatter = (value, currencyOverwrite = null) => {
  const { locale, currency } = store.getState().global

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyOverwrite || currency,
    currencyDisplay: 'symbol'
  }).format(value)
}
