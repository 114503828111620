import React from 'react'

export function EmojiNeutral({
  className = 'group hover:bg-gray-100 dark:hover:bg-gray-700 active:scale-90 rounded-[8px] transition-all ease-cubic-bezier[.785,.135,.15,.86] duration-100 delay-0 cursor-pointer',
  selected = false
}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
    >
      <rect
        width="56"
        height="56"
        rx="8"
        className={`group-hover:fill-gray-50 dark:group-hover:fill-gray-700 ${selected ? 'fill-gray-50 dark:fill-gray-700' : ''} transition-all ease-cubic-bezier[.785,.135,.15,.86] duration-100 delay-0`}
      />
      <circle
        cx="28"
        cy="28"
        r="16"
        className={`group-hover:fill-warning-200 ${selected ? 'fill-warning-200' : ''} transition-all ease-cubic-bezier[.785,.135,.15,.86] duration-100 delay-0`}
      />
      <path
        d="M33.8618 25.7241C34.4351 25.7241 34.924 25.5206 35.3285 25.1136C35.733 24.7066 35.9352 24.2164 35.9352 23.6432C35.9352 23.0699 35.7317 22.581 35.3247 22.1766C34.9176 21.7721 34.4275 21.5698 33.8542 21.5698C33.281 21.5698 32.7921 21.7733 32.3876 22.1803C31.9831 22.5874 31.7808 23.0775 31.7808 23.6508C31.7808 24.224 31.9844 24.7129 32.3914 25.1174C32.7984 25.5219 33.2886 25.7241 33.8618 25.7241ZM22.1458 25.7241C22.719 25.7241 23.2079 25.5206 23.6124 25.1136C24.0169 24.7066 24.2191 24.2164 24.2191 23.6432C24.2191 23.0699 24.0156 22.581 23.6086 22.1766C23.2016 21.7721 22.7114 21.5698 22.1382 21.5698C21.5649 21.5698 21.076 21.7733 20.6715 22.1803C20.267 22.5874 20.0648 23.0775 20.0648 23.6508C20.0648 24.224 20.2683 24.7129 20.6753 25.1174C21.0824 25.5219 21.5725 25.7241 22.1458 25.7241ZM22.9517 33.6529H33.0656V31.8781H22.9517V33.6529ZM28.0028 44.0001C25.7899 44.0001 23.7098 43.5801 21.7626 42.7403C19.8154 41.9004 18.1216 40.7606 16.6813 39.3209C15.2409 37.8811 14.1006 36.1881 13.2603 34.2417C12.4201 32.2954 12 30.2158 12 28.0029C12 25.7899 12.4199 23.7098 13.2598 21.7627C14.0997 19.8154 15.2394 18.1217 16.6792 16.6813C18.1189 15.2409 19.812 14.1006 21.7583 13.2603C23.7046 12.4201 25.7842 12 27.9972 12C30.2101 12 32.2902 12.4199 34.2374 13.2598C36.1846 14.0997 37.8784 15.2395 39.3187 16.6792C40.7591 18.1189 41.8994 19.812 42.7397 21.7583C43.5799 23.7046 44 25.7843 44 27.9972C44 30.2102 43.5801 32.2902 42.7402 34.2374C41.9003 36.1846 40.7606 37.8784 39.3208 39.3188C37.8811 40.7592 36.188 41.8995 34.2417 42.7397C32.2954 43.5799 30.2158 44.0001 28.0028 44.0001ZM27.9989 41.8841C31.8746 41.8841 35.1577 40.5391 37.8482 37.8493C40.5387 35.1595 41.884 31.8767 41.884 28.0011C41.884 24.1254 40.5391 20.8423 37.8492 18.1518C35.1594 15.4613 31.8767 14.116 28.0011 14.116C24.1254 14.116 20.8423 15.4609 18.1518 18.1508C15.4613 20.8406 14.116 24.1233 14.116 27.999C14.116 31.8746 15.4609 35.1577 18.1508 37.8482C20.8406 40.5388 24.1233 41.8841 27.9989 41.8841Z"
        fill="#475467"
      />
    </svg>
  )
}
