import React from 'react'

export function Container({ image, children }) {
  return (
    <div
      className={`p-4 sm:p-6 xl:p-6 h-full mb-4 bg-neutral-800 dark:bg-gray-800 rounded-[16px] ${
        image ? 'bg-left bg-blend-multiply bg-no-repeat md:bg-center md:bg-repeat' : 'bg-white'
      }`}
      style={{
        backgroundImage: image ? `url(${image})` : ''
      }}
    >
      {children}
    </div>
  )
}
