import { addMessage } from '../../../js/store/notifications'
import { isExpiryValid } from '../../../js/helpers/creditCard'
import { isProductActive } from '../../../js/helpers/isProductActive'
import { v4 as uuidv4 } from 'uuid'

function dispatchNotification(
  dispatch,
  id,
  priority,
  title,
  content,
  buttonText,
  buttonLink,
  clearable
) {
  dispatch(addMessage({ priority, id, title, content, buttonText, buttonLink, clearable }))
}

export async function processNotifications(notifData, dispatch) {
  const brandURL = window.location.href.split('cpanel')[0]
  const today = new Date()

  if (notifData?.status === 200) {
    //* Check if the account is overdue
    if (parseFloat(notifData.totalOutstanding) > 0) {
      dispatchNotification(
        dispatch,
        uuidv4(), // Generate a random ID
        'high', // Priority
        'Your account is overdue', // Title
        'Update your billing details to avoid account suspension and additional fees', // Content
        'Update Details', // Button Text (if any)
        `${brandURL}cpanel/billing`, // Button Link (if any)
        false // Clearable (if true, the notification will be removed when the user clicks the button)
      )
    }

    //* Check if the card has expired
    if (
      !isExpiryValid(
        notifData.cardExpiry.split('-')[1] + '/' + notifData.cardExpiry.split('-')[0].slice(2, 4)
      )
    ) {
      dispatchNotification(
        dispatch,
        uuidv4(),
        'high',
        'Your card has expired',
        'Update your billing details to avoid account suspension and additional fees',
        'Update Details',
        `${brandURL}cpanel/billing`,
        false
      )
    }

    //* Check if there are any Active google accounts with Empty ads id fields
    if (
      notifData.products.filter(
        (a) => a.productType === 'Google Ads' && isProductActive(a.status) && !a.googleAdsId
      ).length > 0
    ) {
      dispatchNotification(
        dispatch,
        uuidv4(),
        'med',
        'Connect Google Ads',
        'We noticed that your Google Ads Account has not yet been connected yet.',
        'Connect Account',
        `${brandURL}cpanel/settings`,
        false
      )
    }

    //* Check if there are any Active facebook accounts with Empty ads id fields
    if (
      notifData.products.filter(
        (a) => a.productType === 'Facebook' && isProductActive(a.status) && !a.facebookId
      ).length > 0
    ) {
      dispatchNotification(
        dispatch,
        uuidv4(),
        'med',
        'Connect Meta Ads',
        'We noticed that your Meta Ads Account has not yet been connected yet.',
        'Connect Account',
        `${brandURL}cpanel/settings`,
        false
      )
    }
  }
}
