import React from 'react'

export function AppointmentIcon({ className = 'h-6 w-6 text-primary-500' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      x="0"
      y="0"
      className={className}
    >
      <path
        d="M438-222.616 315.847-344.769l36.384-36.384L438-295.384l169.769-170.769 36.384 36.384L438-222.616ZM228.309-116.001q-27.008 0-45.658-19-18.65-19-18.65-45.308v-503.382q0-26.308 18.65-45.308t45.658-19h87.385v-100.615h53.537v100.615h223.076v-100.615h51.999v100.615h87.385q27.008 0 45.658 19 18.65 19 18.65 45.308v503.382q0 26.308-18.65 45.308t-45.658 19H228.309Zm0-51.999h503.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-335.382H216v335.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846ZM216-567.69h528v-116.001q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H228.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v116.001Zm0 0V-696v128.31Z"
        fill="currentColor"
      />
    </svg>
  )
}
