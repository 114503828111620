import React from 'react'
import { Tooltip } from '../../Tooltip'
import { StarIcon as BaseStarIcon } from '../../Icons'

export function StarIcon({ isDownTrend, tooltip }) {
  return (
    <Tooltip content={tooltip}>
      <BaseStarIcon
        className={`w-5 h-5 text-yellow-200 dark:text-yellow-400 ${isDownTrend ? '' : 'fill-yellow-200 dark:fill-yellow-400'}`}
        fill={isDownTrend ? 'none' : 'currentColor'}
      />
    </Tooltip>
  )
}
