import React from 'react'
import { useSelector } from 'react-redux'
import { TickIcon } from '../Icons'
import { LoadingCardLong } from '../Loading'

export function PurchaseModalCard({
  title,
  price = null,
  disclaimer,
  description,
  link,
  recommended = false
}) {
  const {
    brand,
    customer: { registeredForTax },
    currency
  } = useSelector((store) => store.global)

  const getTaxMultiplier = () => {
    switch (currency) {
      case 'AUD':
        return 1.1
      case 'NZD':
        return registeredForTax ? 1 : 1.15
      default:
        return 1
    }
  }

  return (
    <div className="flex flex-col h-full min-w-[232px] justify-between rounded-[16px] border-[1px] border-gray-200 dark:border-base-500 bg-white dark:bg-gray-800">
      <div className="h-[210px] w-[250px] flex flex-col p-[20px] pb-[24px] border-b-[1px] border-gray-200 dark:border-gray-700">
        <h2 className="text-gray-900 dark:text-gray-100 font-inter font-[600] text-[20px] justify-start leading-7 pb-[16px]">
          <span className="mr-[6px]">{title}</span>
          {recommended && (
            <span className="rounded-[16px] border-[1px] border-primary-200 dark:border-gray-700 bg-primary-50 dark:bg-primary-200 px-[10px] py-[2px] font-[500] text-[12px] text-primary-700">
              Recommended
            </span>
          )}
        </h2>
        <h1 className="text-gray-900 dark:text-gray-100 font-inter font-[600] text-[36px] justify-start leading-10">
          {price ? (
            <>
              ${Math.round(Math.round(price) / getTaxMultiplier())}
              <span className="text-gray-700 dark:text-gray-200 font-inter font-[500] text-[18px] justify-start leading-7">
                /mo
              </span>
            </>
          ) : (
            <LoadingCardLong />
          )}
        </h1>
        <span className="flex flex-col dark:text-gray-300 text-gray-500 text-start text-[12px] font-[500] leading-4">
          {(currency === 'AUD' || !registeredForTax) && <span>GST inclusive.</span>}
          <span className="mb-6">Excludes Setup Fee</span>
          <span className="mb-2">Charged in advance.</span>
          <span>{disclaimer}</span>
        </span>
      </div>
      <div className="flex flex-col gap-[16px] pt-[24px] px-[20px] pb-[32px]">
        {description.map((desc, index) => (
          <div className="flex flex-row gap-[12px]" key={index}>
            <TickIcon className="min-w-[24px]" />
            <span className="text-start dark:text-gray-400 text-gray-600 text-[16px] font-[400] leading-6">
              {desc}
            </span>
          </div>
        ))}
      </div>
      <div className="p-[20px]">
        <a
          href={link}
          target="_blank"
          className={`
            cursor-pointer px-[20px] py-[12px] flex items-center justify-center w-full dark:bg-primary-300 dark:hover:bg-primary-500 shadow-xs rounded-[8px] font-inter text-sm font-semibold transition-all bg-opacity-100 ease-cubic-bezier[.785,.135,.15,.86] duration-100 delay-0 active:scale-95
            ${brand === 'jdm' ? 'text-black bg-primary-400 hover:bg-primary-500' : 'text-white dark:text-primary-900 bg-primary-700 hover:bg-primary-900'}`}
        >
          Get Started
        </a>
      </div>
    </div>
  )
}
