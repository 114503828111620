import { api } from './api'

export const settingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    updatePassword: build.mutation({
      query: (newPassword) => ({
        url: 'cpanel/settings/updatepass',
        method: 'POST',
        body: {
          newPassword
        }
      })
    }),
    createWebsite: build.mutation({
      query: ({ username, password, type, loginURL }) => ({
        url: 'cpanel/settings/weblogins',
        method: 'POST',
        body: {
          username,
          password,
          type,
          loginURL
        }
      })
    }),
    getContact: build.mutation({
      query: () => ({
        url: 'checkout/getContact',
        method: 'POST'
      })
    }),
    updateContact: build.mutation({
      // build.mutation is for POST,UPDATE,DELETE and build.query is for GET
      query: ({ firstName, lastName, email, phone }) => ({
        url: 'checkout/updateContact',
        method: 'POST',
        body: {
          firstName,
          lastName,
          email,
          phone
        }
      })
    })
  })
})

export const {
  useUpdatePasswordMutation,
  useCreateWebsiteMutation,
  useGetContactMutation,
  useUpdateContactMutation
} = settingsApi
