export const validateEmail = (email) => {
  const validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  return validEmail.test(email)
}

export const passwordRules = [
  {
    label: 'At least one lowercase character & one uppercase',
    pattern: /^(?=.*[a-z])(?=.*[A-Z])/
  },
  {
    label: 'At least one number',
    pattern: /^(?=.*[0-9])/
  },
  {
    label: 'At least 8 characters',
    pattern: /^(.{8,})/
  },
  {
    label: 'At least one special character, e.g., ! @ # ?',
    pattern: /^(?=.*[!@#+()^%$^&*])/
  }
]

export const validatePassword = (password) => {
  return passwordRules.every((rule) => rule.pattern.test(password))
}

export const validatePhone = (phone) => {
  const normalizedPhone = phone.replace(/[\s()-]/g, '')
  const validPhone = /^(0|\+61|\+64)[1-9][0-9]{7,10}$/
  return validPhone.test(normalizedPhone)
}

export const validateName = (name) => {
  const validName = /^[a-zA-Z]+(?:[ '-][a-zA-Z]+)*$/
  return name.length >= 2 && validName.test(name)
}
