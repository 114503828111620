import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { format, parseISO } from 'date-fns'

interface DateButtonProps {
  dates: { fromDate: string; toDate: string }
  setDates: (dates: { fromDate: string; toDate: string }) => void
}

export function DateButton({ dates, setDates }: DateButtonProps) {
  const [dateRange, setDateRange] = useState([parseISO(dates.fromDate), parseISO(dates.toDate)])
  const [startDate, endDate] = dateRange

  useEffect(() => {
    if (dateRange.some((date) => date === null)) {
      return
    }
    const fromDate = new Date(dateRange[0])
    const isoShortFrom = fromDate.toISOString().substr(0, 10)
    const toDate = new Date(dateRange[1])
    const isoShortTo = toDate.toISOString().substr(0, 10)
    const dates = {
      fromDate: isoShortFrom,
      toDate: isoShortTo
    }
    setDates(dates)
  }, [dateRange])

  return (
    <DatePicker.default
      className="text-center md:text-start flex-grow flex-auto w-full md:w-[250px] h-[40px] flex flex-row gap-[8px] rounded-[8px] justify-center items-center font-inter bg-white hover:bg-gray-100 dark:bg-gray-700 hover:dark:bg-gray-800 shadow-xs font-inter text-[14px] font-[600] text-gray-900 dark:text-gray-300 border-[1px] dark:border-none border-gray-300 cursor-pointer"
      showIcon={false}
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      dateFormat="MMMM, dd, yyyy"
      onChange={(update) => {
        setDateRange(update)
      }}
    />
  )
}
