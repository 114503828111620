import React from 'react'
import { PageButton } from './PageButton'
import { PageIcon } from './PageIcon'
import { getPaginationRange } from './getPaginationRange'

const visiblePages = (page, totalPages, pad) => {
  const pages = []

  let from = page - pad
  if (from < 1) {
    from = 1
  }
  let to = from + pad
  if (to > totalPages) {
    to = totalPages
  }
  while (from <= to) {
    pages.push(from)
    from++
  }

  return pages
}

export function Pagination({ total, page, perPage, onClick, handlePerPageChange }) {
  const { from, to } = getPaginationRange(page, perPage, total)

  const totalPages = Math.ceil(total / perPage)
  const pages = visiblePages(page, totalPages, Math.ceil(perPage / 2))

  const handleOnChange = (e) => {
    const newPerPage = parseInt(e.target.value, 10)
    handlePerPageChange(newPerPage)
  }

  return (
    <div className="flex items-center  justify-between pt-5">
      <div className="flex items-center col-span-3 dark:text-base-50">
        <span className="flex px-1">{`${from + 1} - ${to} of `}</span>
        <span className="flex px-1 font-bold">{total}</span>
      </div>

      <div className="flex flex-col sm:flex-row gap-[10px] sm:gap-[40px] col-span-4 mt-2 sm:mt-auto sm:justify-end items-center">
        <select
          className="w-fit flex gap-[10px] pl-[16px] pr-[12px] py-[6px] rounded-[24px] font-[500] text-[14px]
          text-gray-500 dark:text-gray-300 bg-gray-100 hover:bg-gray-200 hover:text-gray-600 dark:bg-slate-700 hover:dark:bg-slate-600 hover:dark-text-gray-200
          transition duration-100 border-0 cursor-pointer"
          value={perPage}
          onChange={handleOnChange}
        >
          <option value="10">Show 10 Results</option>
          <option value="50">Show 50 Results</option>
          <option value="100">Show 100 Results</option>
          <option value="200">Show 200 Results</option>
        </select>
        <nav aria-label="Table navigation">
          <div className="inline-flex items-start space-x-1 dark:text-base-50">
            {page > 1 && (
              <>
                <PageIcon type="first" onClick={() => onClick(1)} />
                <PageIcon type="prev" onClick={() => onClick(page - 1)} />
              </>
            )}
            {pages.map((item) => (
              <PageButton key={item} page={item} isCurrent={item === page} onClick={onClick} />
            ))}
            {page < totalPages && (
              <>
                <PageIcon type="next" onClick={() => onClick(page + 1)} />
                <PageIcon type="last" onClick={() => onClick(totalPages)} />
              </>
            )}
          </div>
        </nav>
      </div>
    </div>
  )
}
