import React from 'react'
import spacetime from 'spacetime'
import { currencyFormatter } from '../../../helpers/currencyFormatter'
import { LoadingIcon, PDFIcon } from '../../Icons'

export function TableRow({ invoice, loading, onInvoiceClick }) {
  const {
    id,
    invoiceNumber,
    productName,
    status,
    totalDisplay,
    currencyIsoCode,
    issueDate,
    paymentDate,
    dueDate,
    runningBalance
  } = invoice

  const tz = spacetime.now().tz
  const getDate = (value) => spacetime(value, tz)
  const formatDate = (value) => getDate(value).format('numeric-uk')

  return (
    <tr className="font-inter dark:text-base-400 border-b-[1px] border-b-gray-200 dark:border-b-gray-700">
      <td className="flex gap-[12px] items-center justify-items-start space-x-2 whitespace-nowrap px-[24px] py-[16px]">
        {loading ? (
          <LoadingIcon className="h-[40px] w-[28px] text-primary-600 dark:text-primary-300 animate-spin" />
        ) : (
          <PDFIcon />
        )}
        <span
          className="cursor-pointer text-[14px] font-[500] leading-[20px] text-gray-900 dark:text-gray-100 hover:underline"
          onClick={() => onInvoiceClick(id)}
        >
          {invoiceNumber}
        </span>
      </td>
      <td className="whitespace-nowrap px-[24px] py-[16px] text-[14px] font-[400] leading-[20px] text-gray-600 dark:text-gray-400">
        {productName}
      </td>
      <td className="whitespace-nowrap px-[24px] py-[16px] text-[14px] font-[400] leading-[20px] text-gray-600 dark:text-gray-400">
        {formatDate(issueDate)}
      </td>
      <td
        className={`whitespace-nowrap px-[24px] py-[16px] text-[14px] font-[400] leading-[20px]
          ${status === 'OVERDUE' ? 'text-error-600 dark:text-error-400' : ''}
          ${getDate(paymentDate).isAfter(getDate(dueDate)) ? 'text-error-600 dark:text-error-400' : 'text-success-600 dark:text-success-400'}
        `}
      >
        {formatDate(dueDate)}
      </td>
      <td className="whitespace-nowrap px-[24px] py-[16px] text-[14px] font-[400] leading-[20px] text-gray-600 dark:text-gray-400">
        {currencyFormatter(totalDisplay, currencyIsoCode)}
      </td>
      <td className="whitespace-nowrap px-[24px] py-[16px]">
        <span
          className={`font-inter px-[8px] py-[2px] rounded-[16px] bg-gray-50 dark:bg-gray-300 text-gray-700 border-[1px] border-gray-200 text-[12px] font-[500] leading-[18px] ${
            status === 'PAID'
              ? 'dark:border-none bg-success-100 border-success-300 dark:bg-success-300 dark:text-success-900 text-success-700'
              : ''
          }`}
        >
          {status}
        </span>
      </td>
      <td className="whitespace-nowrap px-[24px] py-[16px] text-[14px] font-[400] leading-[20px] text-gray-600 dark:text-gray-400">
        {currencyFormatter(runningBalance, currencyIsoCode)}
      </td>
    </tr>
  )
}
