import React from 'react'
import { ProductMessage } from '../../ProductMessage'

export function Foundations({ onHideClick }) {
  return (
    <ProductMessage title="The SEO Foundations" onHideClick={onHideClick}>
      <>
        <p className="text-body xl:lead dark:text-base-50">
          Initial setup is in progress and this is all about assessing your website's overall
          health. We'll research and investigate its current performance, looking for any areas that
          will help improve SEO.
        </p>
        <p className="paragraph-text">
          This is an entire site audit, analysing both the back and front end of your website.
          During this time, we'll identify load speed and device compatibility. Our team will look
          for any duplication, including content, title tags, and other existing websites.
          Duplicates will then be logged and assessed for level of importance relating to the
          keywords. We'll also take a look at any search engine competitor websites to gain a
          thorough understanding of their performance and map potential keywords. You can see all
          this happening in real-time on your dashboard below.
        </p>
        <p className="paragraph-text">
          Remember, search rankings don't change overnight, but it will happen! Typically our SEO
          work will take several months for search engine results to really change, and that's when
          the real magic begins.
        </p>
      </>
    </ProductMessage>
  )
}
