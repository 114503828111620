import React from 'react'

export function LoadingCard() {
  return (
    <div role="status" className="animate-pulse">
      <div className="flex items-center space-y-2">
        <div className="flex-shrink-0 space-y-2">
          <span className="block">
            <span className="bg-gray-200 dark:bg-gray-500 rounded-full h-4 w-4" />
            <h3 className="inline-flex items-center bg-gray-200 dark:bg-gray-500 rounded-full h-10 w-20 pt-4" />
          </span>
        </div>
        <div className="ml-5 flex flex-1 items-center justify-end">
          <span className="w-6 h-6 bg-gray-200 dark:bg-gray-500 rounded-full" />
        </div>
      </div>
      <span className="sr-only">Loading Contents</span>
    </div>
  )
}
