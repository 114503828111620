import React from 'react'

interface TableRowEmptyProps {
  colSpan: number
  text: string
}

export function TableRowEmpty({ colSpan, text }: TableRowEmptyProps) {
  return (
    <tr className="table-tr">
      <td colSpan={colSpan} className="p-7 text-center">
        <span className="text-gray-500 dark:text-gray-400 normal-case">{text}</span>
      </td>
    </tr>
  )
}
