import React from 'react'

interface SkipButtonProps {
  text?: string
  onClick: () => void
}

export function SkipButton({ text, onClick }: SkipButtonProps) {
  return (
    <span
      className="font-medium text-sm leading-5 text-primary-500 hover:underline text-center cursor-pointer"
      onClick={onClick}
    >
      {text || `I'll do it later`}
    </span>
  )
}
