import { api } from './api'
import { cache } from './cache'
import { Tag } from './tags'

export const checkoutApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDomainStats: build.query({
      query: (domain) => ({
        url: 'checkout/get/domainStats',
        method: 'POST',
        body: {
          domainMatch: /(http(s?)):\/\//i.test(domain) ? domain : `https://${domain}`
        }
      }),
      providesTags: (response, error, id) => [{ type: Tag.Domain, id }],
      transformResponse: (response, _, arg) => {
        cache.seoDomain.set(arg, response)
        return response
      }
    }),
    checkWebsite: build.mutation({
      query: ({ url, textArr }) => ({
        url: 'checkout/webscrape',
        method: 'POST',
        body: JSON.stringify({
          // FIXME: get rid of JSON.stringify
          url,
          textArr
        })
      })
    })
  })
})

export const { useGetDomainStatsQuery, useCheckWebsiteMutation } = checkoutApi
