import { configureStore } from '@reduxjs/toolkit'
import { api } from '../services/api'
import { rtkQueryErrorLogger } from '../middlewares'
import { global } from './global'
import { dashboard } from './dashboard'
import { ads } from './ads'
import { facebook } from './facebook'
import { seo } from './seo'
import { notifications } from './notifications'

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    global,
    dashboard,
    ads,
    facebook,
    seo,
    notifications
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    api.middleware,
    rtkQueryErrorLogger
  ]
})

export const storePub = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    global
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    api.middleware,
    rtkQueryErrorLogger
  ]
})

export type RootState = ReturnType<typeof store.getState>
export type RootStatePub = ReturnType<typeof storePub.getState>
