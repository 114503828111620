import React from 'react'
import { CommonFunc } from '../../../libs/common'
import { BacklinkProfilePanel } from './BacklinkProfilePanel'
import { DonutChart } from '../../Chart'
import { numberFormatter } from '../../../helpers/numberFormatter'

const { kFormatter } = new CommonFunc()

export function BacklinkProfile({ backLink, domainRating, brandName }) {
  const {
    live,
    live_refdomains,
    compare_backLinks,
    compare_referrers,
    all_time,
    all_time_refdomains,
    traffic,
    compare_traffic
  } = backLink

  const { domain_rating, ahrefs_rank } = domainRating

  return (
    <dl className="bg-white dark:bg-gray-800 dark:text-white rounded-[16px] mt-5 grid grid-cols-1 divide-y divide-gray-200 dark:divide-base-700 overflow-hidden md:grid-cols-4 md:divide-y-0 md:divide-x">
      <BacklinkProfilePanel
        title="Domain Rating"
        tooltip="domainRating"
        className="-space-y-6 justify-between w-full"
      >
        <>
          <div className="flex items-baseline md:block font-semibold shrink-0 text-2xl">
            {domain_rating}
            <div className="-mt-2 flex flex-1 items-baseline justify-start lg:block md:block">
              <span className="ml-2 md:ml-0 text-sm font-medium text-gray-500 dark:text-secondary-500 uppercase">
                {`${brandName}.R ${numberFormatter(ahrefs_rank)}`}
              </span>
            </div>
          </div>
          <div className="flex justify-start md:block lg:block">
            <DonutChart percent={domain_rating} />
          </div>
        </>
      </BacklinkProfilePanel>
      <BacklinkProfilePanel
        title="Backlinks"
        tooltip="backlinks"
        className="items-baseline md:block -space-y-0.5"
      >
        <>
          <div className="flex items-baseline text-2xl font-semibold space-x-2">
            <span className="dark:text-base-50">{numberFormatter(live)}</span>
            <span
              className={`text-xs ${compare_backLinks > 1 ? 'text-success-500' : 'text-error-400'}`}
            >
              {compare_backLinks > 1 ? '+' : ''}
              {compare_backLinks ? numberFormatter(compare_backLinks) : ''}
            </span>
          </div>
          <div className="mt-1 flex flex-1 items-baseline justify-start md:block lg:block">
            <span className="ml-2 md:ml-0 text-sm font-medium text-gray-500 dark:text-secondary-500">
              {`of all time ${numberFormatter(all_time)}`}
            </span>
          </div>
        </>
      </BacklinkProfilePanel>
      <BacklinkProfilePanel
        title="Referring Domains"
        tooltip="referringDomains"
        className="items-baseline md:block -space-y-0.5"
      >
        <>
          <div className="flex items-baseline text-2xl font-semibold space-x-2">
            <span className="dark:text-base-50">{numberFormatter(live_refdomains)}</span>
            <span
              className={`text-xs ${compare_referrers > 1 ? 'text-success-500' : 'text-error-400'}`}
            >
              {compare_referrers > 1 ? '+' : ''}
              {compare_referrers ? numberFormatter(compare_referrers) : ''}
            </span>
          </div>
          <div className="mt-1 flex flex-1 items-baseline justify-start md:block lg:block">
            <span className="ml-2 md:ml-0 text-sm font-medium text-gray-500 dark:text-secondary-500">
              {`of all time ${numberFormatter(all_time_refdomains)}`}
            </span>
          </div>
        </>
      </BacklinkProfilePanel>
      <BacklinkProfilePanel
        title="Traffic"
        tooltip="trafficBacklinks"
        className="items-baseline md:block -space-y-0.5"
      >
        <>
          <div className="flex items-baseline text-2xl font-semibold space-x-2">
            <span className="dark:text-base-50">{numberFormatter(traffic.org_traffic)}</span>
            <span
              className={`text-xs ${compare_traffic.org_traffic > 1 ? 'text-success-500' : 'text-error-400'}`}
            >
              {compare_traffic.org_traffic > 1 ? '+' : ''}
              {numberFormatter(Math.ceil(compare_traffic.org_traffic))}
            </span>
          </div>
          <div className="mt-1 flex flex-1 items-baseline justify-start md:block lg:block">
            <span className="ml-2 md:ml-0 text-sm font-medium text-gray-500 dark:text-secondary-500">
              {`value $${traffic.value ? kFormatter(Math.ceil(traffic.value / 100)) : 0}`}
            </span>
            <span
              className={`ml-2 text-sm font-medium ${compare_traffic.value > 1 ? 'text-success-500' : 'text-error-400'}`}
            >
              {compare_traffic.value > 1 ? '+$' : '$'}
              {compare_traffic.value ? Math.ceil(compare_traffic.value / 100) : ''}
            </span>
          </div>
        </>
      </BacklinkProfilePanel>
    </dl>
  )
}
