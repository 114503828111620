import React from 'react'

interface ArrowLeftProps {
  className?: string
}

export function ArrowLeft({ className = 'cursor-pointer' }: ArrowLeftProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
      <path
        d="M20 12H4M4 12L10 18M4 12L10 6"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="23" height="23" stroke="none" />
    </svg>
  )
}
