import React from 'react'
import { Panel } from '../../Panel'
import { TimezoneOption } from './TimezoneOption'
import { DarkModeOption } from './DarkModeOption'
import { HideMessagesOption } from './HideMessagesOption'
import { CampaignConversionsOption } from './CampaignConversionsOption'
import { KeywordBudgetAlertOption } from './KeywordBudgetAlertOption'

export function Preferences() {
  const isDev = window.dev

  return (
    <Panel title="Application Settings">
      <p className="-mt-4 mb-1 text-sm font-normal text-base-400 dark:text-gray-400 italic">
        Set your personal preferences
      </p>
      <div className="divide-y divide-gray-200 dark:divide-base-700">
        <TimezoneOption />
        <DarkModeOption />
        <HideMessagesOption />
        {isDev && <CampaignConversionsOption />}
        {isDev && <KeywordBudgetAlertOption />}
      </div>
    </Panel>
  )
}
