import posthog from 'posthog-js'
import { api } from './api'

export const logout = () => {
  localStorage.clear()
  posthog.reset()
}

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: ({ username, password }) => ({
        url: 'auth/login',
        method: 'POST',
        body: {
          username,
          password
        },
        responseHandler: (response) => response.text()
      }),
      transformErrorResponse: (response) => ({
        status: response.status,
        data: JSON.parse(response.data)
      })
    }),
    resetPassword: build.mutation({
      query: (body) => ({
        url: 'auth/resetpassword',
        method: 'POST',
        body
      })
    }),
    passwordForgotten: build.mutation({
      query: ({ username }) => ({
        url: 'auth/password/forgot',
        method: 'POST',
        body: {
          username
        }
      })
    })
  })
})

export const { useLoginMutation, useResetPasswordMutation, usePasswordForgottenMutation } = authApi
