import React from 'react'
import { Tooltip } from '@mui/material'
import { QuestionMarkIcon } from '../../Icons'

export function ReportHeader({
  title,
  help = '',
  headerClass = 'font-inter text-[18px] font-[600] leading-[28px]',
  classList = 'bg-gray-50 dark:bg-base-50'
}) {
  return (
    <div className="flex justify-between items-center mb-4">
      <h3 className={`text-gray-900 dark:text-base-50 ${headerClass}`}>{title}</h3>
      <div className="flex-shrink-0 cursor-pointer">
        <Tooltip title={help}>
          <span>
            <QuestionMarkIcon className="w-5 h-5 stroke-[#98A2B3] hover:stroke-[#575d69]" />
          </span>
        </Tooltip>
      </div>
    </div>
  )
}
