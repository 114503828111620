import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setBudgets } from '../../../store/global'
import { PreferenceOption } from './PreferenceOption'
import { currencyFormatter } from '../../../helpers/currencyFormatter'

export function KeywordBudgetAlertOption() {
  const { cpc } = useSelector((store) => store.global.budgets)

  const dispatch = useDispatch()

  const onChange = (event) => {
    const { value } = event.target

    dispatch(
      setBudgets({
        type: 'cpc',
        value
      })
    )
  }

  return (
    <PreferenceOption
      title="Keyword Budget Alert"
      description={
        <>
          If keyword CPC raises above{' '}
          <span className="font-bold text-red-400">{currencyFormatter(cpc)}</span>, we will
          highlight the CPC within the reports
        </>
      }
    >
      <label htmlFor="cpc" className="relative flex cursor-pointer items-center">
        <input
          type="number"
          value={cpc}
          name="cpc"
          onChange={onChange}
          className="w-full font-normal dark:bg-gray-900 text-base dark:text-neutral-50 leading-6 text-gray-900 py-[9px] px-[13px] border-[2px] border-gray-300 hover:border-gray-400 dark:border-gray-700 hover:dark:border-primary-800 rounded-[8px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] transition-all duration-200 focus:border-[#1c64f2]"
          placeholder="5.00"
          min="0"
        />
      </label>
    </PreferenceOption>
  )
}
