import React from 'react'

export function NoDataIcon() {
  return (
    <svg
      width="229"
      height="165"
      viewBox="0 0 229 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="117" cy="80" r="80" fill="#EAECF0" />
      <g filter="url(#filter0_dd_8374_14792)">
        <path
          d="M63.078 124.214L115.529 99.7553C118.163 98.5273 119.302 95.3969 118.074 92.7635L87.0985 26.3361L66.1232 18.7017L23.2085 38.7132C20.575 39.9412 19.4357 43.0715 20.6637 45.705L56.0862 121.669C57.3142 124.302 60.4446 125.442 63.078 124.214Z"
          fill="url(#paint0_linear_8374_14792)"
        />
        <path
          d="M66.1229 18.7017L87.0983 26.3361L72.7934 33.0066L66.1229 18.7017Z"
          fill="#D0D5DD"
        />
      </g>
      <g filter="url(#filter1_dd_8374_14792)">
        <path
          d="M88.1516 106.339H146.025C148.931 106.339 151.286 103.984 151.286 101.078V27.7837L135.503 12H88.1516C85.2459 12 82.8904 14.3555 82.8904 17.2612V101.078C82.8904 103.984 85.2459 106.339 88.1516 106.339Z"
          fill="url(#paint1_linear_8374_14792)"
        />
        <path d="M135.503 11.9999L151.286 27.7836H135.503V11.9999Z" fill="#D0D5DD" />
      </g>
      <g filter="url(#filter2_dd_8374_14792)">
        <path
          d="M117.911 99.724L170.362 124.182C172.996 125.41 176.126 124.271 177.354 121.638L208.33 55.2103L200.695 34.2349L157.781 14.2235C155.147 12.9955 152.017 14.1348 150.789 16.7683L115.366 92.7322C114.138 95.3657 115.278 98.496 117.911 99.724Z"
          fill="url(#paint2_linear_8374_14792)"
        />
        <path
          d="M200.695 34.2349L208.329 55.2102L194.025 48.5398L200.695 34.2349Z"
          fill="#D0D5DD"
        />
      </g>
      <g filter="url(#filter3_b_8374_14792)">
        <rect x="89" y="88" width="56" height="56" rx="28" fill="#344054" fillOpacity="0.3" />
        <path
          d="M119.333 104.648V109.467C119.333 110.12 119.333 110.447 119.46 110.696C119.572 110.916 119.751 111.094 119.97 111.206C120.22 111.333 120.547 111.333 121.2 111.333H126.019M121.667 117.167H112.333M121.667 121.833H112.333M114.667 112.5H112.333M119.333 104.333H113.267C111.306 104.333 110.326 104.333 109.578 104.715C108.919 105.05 108.384 105.586 108.048 106.244C107.667 106.993 107.667 107.973 107.667 109.933V122.067C107.667 124.027 107.667 125.007 108.048 125.756C108.384 126.414 108.919 126.95 109.578 127.285C110.326 127.667 111.306 127.667 113.267 127.667H120.733C122.693 127.667 123.674 127.667 124.422 127.285C125.081 126.95 125.616 126.414 125.952 125.756C126.333 125.007 126.333 124.027 126.333 122.067V111.333L119.333 104.333Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_8374_14792"
          x="-1.55981"
          y="12.0312"
          width="141.857"
          height="154.406"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_8374_14792"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8374_14792" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_8374_14792"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8374_14792"
            result="effect2_dropShadow_8374_14792"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8374_14792"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_8374_14792"
          x="62.8904"
          y="11.9999"
          width="108.396"
          height="134.339"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_8374_14792"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8374_14792" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_8374_14792"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8374_14792"
            result="effect2_dropShadow_8374_14792"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8374_14792"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_dd_8374_14792"
          x="93.1427"
          y="12"
          width="141.857"
          height="154.406"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_8374_14792"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8374_14792" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_8374_14792"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8374_14792"
            result="effect2_dropShadow_8374_14792"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8374_14792"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_b_8374_14792"
          x="81"
          y="80"
          width="72"
          height="72"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_8374_14792" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_8374_14792"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8374_14792"
          x1="59.9965"
          y1="124.418"
          x2="18.197"
          y2="49.0089"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4E7EC" />
          <stop offset="1" stopColor="#F9FAFB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8374_14792"
          x1="85.2723"
          y1="105.223"
          x2="79.2585"
          y2="19.2131"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4E7EC" />
          <stop offset="1" stopColor="#F9FAFB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8374_14792"
          x1="115.773"
          y1="97.495"
          x2="146.672"
          y2="17.0024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4E7EC" />
          <stop offset="1" stopColor="#F9FAFB" />
        </linearGradient>
      </defs>
    </svg>
  )
}
