import React, { useState } from 'react'
import { FeedbackModal } from '../Modal'

export function Feedback({ className = '' }) {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <>
      <button type="button" onClick={openModal} className={className}>
        Feedback
      </button>
      {open && <FeedbackModal onClose={closeModal} />}
    </>
  )
}
