import React, { useState, useEffect } from 'react'

interface TableProps {
  head: React.ReactElement
  rows: React.ReactElement | React.ReactElement[]
}

export function Table({ head, rows }: TableProps) {
  const [animationKey, setAnimationKey] = useState(0)

  useEffect(() => {
    setAnimationKey(animationKey + 1)
  }, [head, rows])

  return (
    <div
      key={animationKey}
      className="animate-slide-left2 overflow-x-auto bg-white dark:bg-gray-800 rounded-[8px] align-middle dark:scrollbar-thumb-base-50 dark:scrollbar-track-base-700 scrollbar-thin scrollbar-thumb scrollbar-thumb-primary-500 scrollbar-thumb-rounded-full scrollbar-track-gray-100 scrollbar-track-rounded-full scrollbar-width-10 scrollbar-thumb scrollbar-width-10"
    >
      <table className="table-main m-auto">
        <thead className="table-thead">{head}</thead>
        <tbody className="table-body">{rows}</tbody>
      </table>
    </div>
  )
}
