import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import {
  genericCard,
  isNumberValid,
  isNameValid,
  isExpiryValid,
  isCodeValid,
  getCardDetails
} from '../../../helpers/creditCard'
import * as Yup from 'yup'
import { FormikInput } from './FormikInput'
import { LoadingIcon } from '../../Icons'
import { useUpdateCCMutation } from '../../../services/billing'
import { Tooltip } from '../../Tooltip'
import { addToast } from '../../../store/global'

const initialValues = {
  cardNumber: '',
  cardName: '',
  cardExpiry: '',
  cardCvv: ''
}

export function PaymentDetails() {
  const [updateCC, { isLoading }] = useUpdateCCMutation()
  const [cardDetails, setCardDetails] = useState(genericCard)

  const dispatch = useDispatch()
  const brand = useSelector((store) => store.global.brand.name)

  const signInSchema = Yup.object().shape({
    cardNumber: Yup.string()
      .required('Card Number is required')
      .min(15, 'Card Number is too short')
      .max(24, 'Card Number is too long')
      .test('cardNumber', 'Card Number is not a valid number', (value) =>
        isNumberValid(value.replaceAll(' ', ''))
      ),
    cardName: Yup.string()
      .required('Name is required')
      .min(3, 'Card Name is too short')
      .max(40, 'Card Name is too long')
      .test('cardName', 'Card Name is not valid', (value) => isNameValid(value)),
    cardExpiry: Yup.string()
      .required('Card Expiry is required')
      .min(2, 'Expiry Date is too short')
      .max(5, 'Expiry Date is too long')
      .test('cardExpiry', 'Card Expiry is not valid', (value) => isExpiryValid(value)),
    cardCvv: Yup.string()
      .required('Card CVV is required')
      .min(3, 'Card CVV is too short')
      .max(4, 'Card CVV is too long')
      .test('cardCvv', 'Card CVV is not valid', (value) =>
        isCodeValid(value, cardDetails.type || null)
      )
  })

  const onChangeHandler = (e, setFieldValue) => {
    const { name, value } = e.target
    if (name === 'cardNumber') {
      const cleanValue = value.replace(/\s/g, '').replace(/\D/g, '') // remove any existing spaces and non-numeric characters
      const formattedValue = cleanValue.replace(/\d{4}(?=\d)/g, '$& ') // add a space every 4 characters
      setFieldValue('cardNumber', formattedValue) // set the formatted value back to the input field
      setCardDetails(getCardDetails(cleanValue))
    }
    if (name === 'cardExpiry' && !value.includes('/')) {
      const formattedValue = value.replace(/^(.{2})/, '$1/') // add a slash after 2 characters
      setFieldValue('cardExpiry', formattedValue) // set the formatted value back to the input field
    }
  }

  const submitHandler = async (values) => {
    const response = await updateCC(values)
    if (response.data.error) {
      return dispatch(
        addToast({
          title: 'Card Update Failed',
          subtext: response.data.error, // 'Please make sure that your card details are correct before updating',
          type: 'error'
        })
      )
    }
    dispatch(
      addToast({
        title: 'Update Successful',
        subtext: 'Your card details have been updated successfully',
        type: 'success'
      })
    )
  }

  const cardIcon = (
    <img
      className="w-[40px] h-[20px] dark:bg-white rounded-md"
      src={`/images/checkout/${cardDetails.logo}`}
      alt="Card Flag"
    />
  )

  return (
    <div className="font-inter flex flex-col gap-[20px]">
      <div className="flex flex-col gap-[8px]">
        <h3 className="text-gray-900 dark:text-gray-100 text-[18px] font-[600] leading-[18px]">
          Update payment method
        </h3>
        <span className="text-gray-600 dark:text-gray-300 text-[14px] font-[400] leading-[20px]">
          Update your card details.
        </span>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={signInSchema}
        onSubmit={submitHandler}
      >
        {(formik) => {
          const { errors, touched, isValid, dirty, setFieldValue } = formik
          return (
            <Form
              className="flex flex-col gap-[16px]"
              onChange={(e) => onChangeHandler(e, setFieldValue)}
            >
              <div className="flex flex-row gap-[16px]">
                <FormikInput
                  errors={errors}
                  touched={touched}
                  id="cardNumber"
                  label="Card Number"
                  className="form-row w-[60%]"
                  placeholder="1234 5678 9012 3456"
                  maxlength={
                    cardDetails.blocks[0].reduce((a, b) => a + b, 0) +
                    (cardDetails.type !== 'amex'
                      ? cardDetails.blocks[0].length - 1
                      : cardDetails.blocks[0].length)
                  }
                  icon={cardIcon}
                />
                <FormikInput
                  errors={errors}
                  touched={touched}
                  id="cardName"
                  label="Card Name"
                  className="form-row w-[40%]"
                  placeholder="JOHN DOE"
                  maxlength="40"
                />
              </div>

              <div className="flex flex-row gap-[16px]">
                <FormikInput
                  errors={errors}
                  touched={touched}
                  id="cardExpiry"
                  label="Expiry Date"
                  className="form-row w-[60%]"
                  placeholder="06/24"
                  maxlength="5"
                />
                <FormikInput
                  errors={errors}
                  touched={touched}
                  id="cardCvv"
                  label={`Card ${cardDetails.code.name}`}
                  className="form-row w-[40%]"
                  placeholder={cardDetails.code.size === 3 ? '123' : '1234'}
                  maxlength={cardDetails.code.size}
                />
              </div>

              <Tooltip
                className="sm:self-center"
                content={
                  !(dirty && isValid) ? 'Please fix the highlighted fields before proceeding.' : ''
                }
              >
                <button
                  type="submit"
                  className={`
                  flex w-full sm:w-fit sm:px-[70px] xl:px-[100px] py-[10px] items-center justify-center gap-[10px] rounded-[8px] shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] text-center font-[600] text-[16px] leading-[24px]
                  ${
                    !(dirty && isValid)
                      ? 'cursor-not-allowed bg-primary-200 dark:bg-primary-600 hover:bg-primary-300 dark:hover:bg-primary-500 shadow-none'
                      : 'shadow-xs'
                  } ${brand === 'jdm' ? 'dark:hover:text-black bg-primary-400 hover:bg-primary-500 dark:bg-primary-300 dark:hover:bg-primary-500' : 'text-white bg-primary-700 dark:bg-primary-300 hover:bg-primary-900 dark:hover:bg-primary-500'}`}
                  disabled={!(dirty && isValid)}
                >
                  {isLoading ? 'Updating...' : 'Update'}
                  {isLoading ? <LoadingIcon /> : ''}
                </button>
              </Tooltip>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
