import React from 'react'
import { ConnectButton, ConnectedButton } from './Button'

interface ConnectItemProps {
  name: string
  icon: React.ReactElement
  onClick: () => void
  isLoading: boolean
  connected?: boolean
  disabled?: boolean
}

export function ConnectItem({
  name,
  icon,
  onClick,
  isLoading,
  connected = false,
  disabled = false
}: ConnectItemProps) {
  return (
    <div
      className={`flex flex-row p-[25px] justify-between items-center w-full ${
        disabled ? 'blur-sm cursor-not-allowed' : ''
      }`}
    >
      <div className="flex">
        {icon}
        <span className="font-medium font-sans text-base dark:text-white text-gray-900 leading-6 tracking-[-0.5px]">
          {name}
        </span>
      </div>
      {connected ? (
        <ConnectedButton />
      ) : (
        <ConnectButton disabled={disabled} isLoading={isLoading} onClick={onClick} />
      )}
    </div>
  )
}
