import React from 'react'
import { ProductMessage } from '../../ProductMessage'

export function SecondMonth({ onHideClick }) {
  return (
    <ProductMessage
      title="Second month of SEO"
      className="text-body dark:text-base-50"
      onHideClick={onHideClick}
    >
      <>
        <p className="paragraph-text">
          Branching off of month one, month two dives into the nitty gritty.
        </p>
        <p className="paragraph-text">
          We'll incorporate a series of backlinks, revisit business directories, identify and fix
          any crawl errors. During this period, and using similar SEO strategies from month one,
          we'll commence optimisation on other important pages of your site, improving user
          experience where possible. Watch it happen in real-time on your dashboard below.
        </p>
        <p className="paragraph-text">
          Remember, search rankings don't change overnight, but it will happen! Typically our SEO
          work will take several months for search engine results to really change, and that's when
          the real magic beings.
        </p>
      </>
    </ProductMessage>
  )
}
