import React from 'react'
import { TableHeadCell } from '../../Table'
import { seoRankingTooltip } from '../../../helpers/seoRankingTooltip'

export function TableHead() {
  return (
    <tr>
      <TableHeadCell label="Keyword" className="text-left" tooltip={seoRankingTooltip('keyword')} />
      <TableHeadCell label="Search Volume" tooltip={seoRankingTooltip('searchVolume')} />
      <TableHeadCell label="Difficulty" tooltip={seoRankingTooltip('keywordDifficulty')} />
      <TableHeadCell label="Current Pos." tooltip={seoRankingTooltip('currentPosition')} />
      <TableHeadCell label="Pos. Change" tooltip={seoRankingTooltip('positionChange')} />
      <TableHeadCell label="Traffic (Current)" tooltip={seoRankingTooltip('trafficCurrent')} />
      <TableHeadCell label="Traffic (Prev.)" tooltip={seoRankingTooltip('trafficPrev')} />
      <TableHeadCell label="Link" tooltip={seoRankingTooltip('url')} />
    </tr>
  )
}
