import React from 'react'
import { GoogleIcon } from '../Icons/GoogleIcon'
import { FacebookAdsIcon } from '../Icons/FacebookAdsIcon'
import { MicrosoftIcon } from '../Icons/MicrosoftIcon'

interface PlatformLogoProps {
  platform: string
}

export function PlatformLogo({ platform }: PlatformLogoProps) {
  return (
    <div className="flex flex-row justify-start items-center w-full">
      {platform === 'Google' && <GoogleIcon />}
      {platform === 'Facebook' && <FacebookAdsIcon className={'mr-[15px]'} />}
      {platform === 'Microsoft' && <MicrosoftIcon />}
      <span className="font-medium font-sans text-base dark:text-white text-gray-900 leading-6 tracking-[-0.5px]">
        {platform === 'Facebook' ? 'Meta' : platform}
      </span>
    </div>
  )
}
