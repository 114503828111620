import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getSeoProductName } from '../../helpers/getSeoProductName'
import { selectAccount as selectAdsAccount } from '../../store/ads'
import { selectCampaign } from '../../store/ads'
import { selectAccount as selectSeoAccount } from '../../store/seo'
import { ButtonClose } from '../Buttons'
import { UpDownIcon } from '../Icons'
import { modalEventHandler } from '../../helpers/modalEventHandler'

interface AccountSelectorProps {
  type: string
  campaignIds: string[]
}

export function AccountSelector({ type, campaignIds = [] }: AccountSelectorProps) {
  const [open, setOpen] = useState(false)

  const popupRef = useRef(null)
  const navigate = useNavigate()
  const { adsId } = useParams()

  const handleOpenClick = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setOpen(false)
    }
  }

  const { facebookAccounts, seoAccounts, activeProducts } = useSelector(
    (store) => store.global.customer
  )
  const selectedAdsId = useSelector((store) => store.ads.selectedAccountId)
  const selectedAdsCampaign = useSelector((store) => store.ads.selectedCampaign)
  const selectedFbAdsId = useSelector((store) => store.facebook.selectedAccountId)
  const selectedSeoId = useSelector((store) => store.seo.selectedAccountId)
  const dispatch = useDispatch()

  const onlyActiveAds = () =>
    activeProducts
      .map((product) =>
        product?.adWordsManagement?.status === 'Active' ? product.adWordsManagement.adsId : null
      )
      .filter(Boolean)

  const accountConfig = {
    ['google']: {
      name: 'Ads',
      selectedAccountId: selectedAdsId,
      accounts: onlyActiveAds(),
      label: 'Ads ID',
      transformName: (accountId) => accountId,
      selectAccount: selectAdsAccount
    },
    ['campaign']: {
      name: 'Ads Campaign',
      selectedAccountId: selectedAdsCampaign,
      accounts: campaignIds || [],
      label: 'Campaign',
      transformName: (accountId) => accountId,
      selectAccount: selectAdsAccount
    },
    ['facebook']: {
      name: 'FbAds',
      selectedAccountId: selectedFbAdsId,
      accounts: facebookAccounts || [],
      label: 'Meta Ads ID',
      transformName: (accountId) => accountId,
      selectAccount: selectAdsAccount
    },
    ['seo']: {
      name: 'SEO',
      selectedAccountId: selectedSeoId,
      accounts: seoAccounts || [],
      label: 'Website',
      transformName: (subscriptionId) => getSeoProductName(subscriptionId, activeProducts || []),
      selectAccount: selectSeoAccount
    }
  }

  const { selectedAccountId, accounts, name, label, transformName, selectAccount } =
    accountConfig[type]

  const onSelectAccount = (accountId) => {
    if (type === 'campaign') {
      dispatch(selectCampaign(accountId))
      navigate(`/cpanel/reports/ads/${adsId}/campaign/${accountId}`)
    } else {
      dispatch(selectAccount(accountId))
    }
    setOpen(false)
  }

  useEffect(() => {
    if (accounts.length > 1) {
      return modalEventHandler(open, handleOpenClick)
    }
  }, [open, accounts])

  return (
    <div
      onClick={() => setOpen(!open)}
      ref={popupRef}
      className="group pl-[20px] pr-[16px] py-[6px] relative flex gap-[12px] rounded-[16px] bg-white hover:bg-secondary-100 dark:bg-gray-700 dark:hover:bg-primary-600 hover:text-primary-500 font-inter text-[14px] text-gray-900 dark:group-hover:text-black dark:text-gray-300 cursor-pointer"
    >
      <div className="flex items-center w-max gap-[12px]">
        <button type="button" className="flex w-full gap-[12px]">
          <span className="font-inter text-[14px] font-[500] leading-[20px] text-gray-900 dark:group-hover:text-gray-100 dark:text-gray-100">
            {transformName(selectedAccountId)}
          </span>
          <span className="hidden sm:block font-inter text-[14px] font-[400] leading-[20px] text-gray-600 dark:group-hover:text-gray-100 dark:text-gray-300">
            {label}
          </span>
        </button>
        {accounts.length > 1 && <UpDownIcon />}
        {open && (
          <>
            <div className="visible sm:hidden transition-opacity ease-in-out duration-500 fixed bg-[#00000099] backdrop-blur inset-0 z-[25] opacity-100" />
            <div className="w-full sm:w-fit rounded-[12px] left-auto sm:left-[0px] fixed sm:absolute m-0 right-0 z-50 px-[16px] sm:px-0 pb-[32px] sm:pb-0 bottom-[0px] sm:bottom-auto top-auto sm:top-[39px] bg-white dark:bg-gray-700 font-inter font-[500] text-[16px] leading-[24px] text-gray-700 dark:text-gray-100 shadow-lg animate-slide-up sm:animate-fade-in">
              <div className="visible sm:hidden flex justify-between font-inter text-[18px] font-[600] text-gray-900 dark:text-gray-100 leading-[28px] px-[16px] pt-[20px]">
                <span>{label}</span>
                <ButtonClose />
              </div>
              {accounts.length > 1 && (
                <ul className="py-1 rounded-[12px]" role="none">
                  {accounts.map((account) => (
                    <li key={account} className="px-[6px] py-[2px]">
                      <button
                        type="button"
                        className="flex justify-between gap-[8px] py-[10px] px-[6px] w-full text-left rounded-[7px] group transition duration-100 dark:text-base-50 hover:bg-gray-100 hover:dark:bg-gray-600 active:scale-95"
                        role="menuitem"
                        onClick={() => onSelectAccount(account)}
                      >
                        <span className="font-inter text-[14px] font-[500] leading-[20px] text-gray-900 dark:text-gray-100">
                          {transformName(account)}
                        </span>
                        <span className="font-inter text-[14px] font-[400] leading-[20px] text-gray-600 dark:text-gray-300">
                          {label}
                        </span>
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
