import { getColor } from '../../helpers/getColor'

export const getGraphColorSets = (isDarkMode = false) =>
  [
    isDarkMode ? 'text-[#475467]' : 'text-[#D0D5DD]', // series 1 (greyed)
    isDarkMode ? 'text-[#3E4A5E]' : 'text-[#E0E3E9]', // series 2 (greyed)
    isDarkMode ? 'text-[#354155]' : 'text-[#ECEEF2]', // series 3 (greyed)
    isDarkMode ? 'text-[#354155]' : 'text-[#F2F4F7]', // series 4 (greyed)
    isDarkMode ? 'text-indigo-400' : 'text-indigo-300', // series 1 (colored)
    isDarkMode ? 'text-warning-200' : 'text-warning-300', // series 2 (colored)
    isDarkMode ? 'text-success-300' : 'text-success-400', // series 3 (colored)
    isDarkMode ? 'text-purple-300' : 'text-purple-400', // series 4 (colored)
    isDarkMode ? 'text-gray-600' : 'text-gray-200', // vertical break line
    isDarkMode ? 'text-indigo-100' : 'text-indigo-100', // pin circle padding
    isDarkMode ? 'text-indigo-500' : 'text-indigo-500', // pin circle
    isDarkMode ? 'text-indigo-600' : 'text-indigo-600', // pin text
    isDarkMode ? 'text-primary-500' : 'text-primary-500', // pie 1
    isDarkMode ? 'text-sky-400' : 'text-sky-400', // pie 2
    isDarkMode ? 'text-error-400' : 'text-error-400', // pie 3
    isDarkMode ? 'text-primary-200' : 'text-primary-400', // seo series 1
    isDarkMode ? 'text-primary-400' : 'text-primary-600', // seo series 2
    isDarkMode ? 'text-primary-600' : 'text-primary-800' // seo series 3
  ].map((color) => `#${getColor(color).substring(2)}`)

export const convertGraphColor = (color) => `#${getColor(color).substring(2)}`
