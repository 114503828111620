import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormTitle } from './FormTitle'
import { InputField } from './InputField'
import { PrivacyPolicy } from './PrivacyPolicy'
import { SubmitButton } from '../Buttons/index'
import { PasswordRules } from '../account/PasswordRules'
import { validatePassword } from '../../../../utils/validators'
import { useUpdatePasswordMutation } from '../../services/settings'
import { addToast } from '../../store/global'

interface CreatePasswordProps {
  onComplete: () => void
}

export function CreatePassword({ onComplete }: CreatePasswordProps) {
  const dispatch = useDispatch()
  const clientName = useSelector((state) => state.global.customer.name)

  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  const [updatePassword, { isLoading }] = useUpdatePasswordMutation()

  const passwordConfirmError = password !== passwordConfirm
  const hasErrors = !validatePassword(password) || passwordConfirmError

  const onUpdatePasswordClick = async () => {
    if (hasErrors) {
      dispatch(
        addToast({
          title: 'Error',
          subtext:
            'Please make sure that your password meets the minimum requirements before updating',
          type: 'error'
        })
      )
      return
    }

    const response = await updatePassword(password)

    if (!response?.data || response.data.error) {
      dispatch(
        addToast({
          title: 'Password creation failed',
          subtext: 'Please try again or contact support',
          type: 'error'
        })
      )
      return
    }

    onComplete()
  }

  return (
    <>
      <FormTitle
        title={`Thanks, ${clientName}!`}
        description="Let's finish setting up your account"
      />
      <div className="w-[344px] grid grid-rows-[66px,66px,1fr] gap-[24px] pt-[16px]">
        <InputField
          name="password"
          value={password}
          label="Create Password"
          type="password"
          onChange={setPassword}
          hasError={password && !validatePassword(password)}
        />
        <InputField
          name="passwordConfirm"
          value={passwordConfirm}
          label="Confirm Password"
          type="password"
          onChange={setPasswordConfirm}
          hasError={passwordConfirm && passwordConfirmError}
        />
        <PasswordRules password={password} />
        <SubmitButton
          showNext
          label="Continue to Step 2"
          isLoading={isLoading}
          onClick={onUpdatePasswordClick}
        />
      </div>
      <PrivacyPolicy />
    </>
  )
}
