import React, { useState } from 'react'
import { ProductMessage } from '../../ProductMessage'
import { SendMessageModal } from '../../Modal'

interface WelcomeMessageProps {
  onHideClick: () => void
}

export function WelcomeMessage({ onHideClick }: WelcomeMessageProps) {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <>
      <ProductMessage title="Welcome" onHideClick={onHideClick}>
        <p className="paragraph-text">
          This dashboard offers personalised insight into your account activity, outlining a monthly
          forecast of all the completed and upcoming work you can expect from our digital marketing
          specialists. Your dashboard updates as we work, so don't forget to check back regularly to
          gain an in-depth understanding of your account's performance. As always, if you have any
          questions or need any assistance understanding your dashboard, get in touch with us via
          the{' '}
          <a
            className="text-primary-600 hover:text-primary-700 hover:cursor-pointer"
            onClick={openModal}
          >
            booking link
          </a>{' '}
          our team can assist you with all your needs.
        </p>
      </ProductMessage>

      {open && <SendMessageModal onClose={closeModal} />}
    </>
  )
}
