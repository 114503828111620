import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addToast } from '../../store/global'
import { GoogleIcon } from '../Icons'
import { ConnectItem } from './ConnectItem'
import { ConnectAccountId } from './ConnectAccountId'
import { useOAuthURLMutation, useConnectGoogleAdsMutation } from '../../services/onboarding'

interface ConnectGoogleProps {
  brand: string
}

type GoogleAdsState = Record<
  string,
  {
    connected: boolean
    isLoading: boolean
  }
>

type GoogleParams = {
  adsIds: string[]
  gaToken: string
}

const getGoogleParams = (): GoogleParams => {
  const params = new URLSearchParams(window.location.search)

  return {
    gaToken: params.get('gatoken') || '',
    adsIds: (params.get('accountList') || '').split(',').filter(Boolean)
  }
}

export function ConnectGoogle({ brand }: ConnectGoogleProps) {
  const dispatch = useDispatch()

  const [mainAccountConnecting, setMainAccountConnecting] = useState(false)
  const [googleAds, setGoogleAds] = useState<GoogleAdsState>({})

  const [oAuthURL] = useOAuthURLMutation()
  const [connectGoogleAds] = useConnectGoogleAdsMutation()

  const { adsIds, gaToken } = getGoogleParams()

  useEffect(() => {
    const adsIdsDetails = adsIds.reduce((acc, id) => {
      acc[id] = {
        connected: false,
        isLoading: false
      }
      return acc
    }, {})
    setGoogleAds(adsIdsDetails)
  }, [])

  const updateGoogleAdsStatus = (id: string, name: string, value: boolean) => {
    setGoogleAds((state) => ({
      ...state,
      [id]: {
        ...state[id],
        [name]: value
      }
    }))
  }

  const connectMainAccount = async () => {
    setMainAccountConnecting(true)

    const response = await oAuthURL({ brand, type: 'glogin' })
    const url = response.data?.url || ''

    window.location.href = url
  }

  const connectAccount = async (id: string) => {
    const accountId = id.replace(/\D/g, '')
    updateGoogleAdsStatus(accountId, 'isLoading', true)

    try {
      const { error } = await connectGoogleAds({
        gaToken,
        accountList: accountId,
        bscope: brand
      })

      if (error) {
        throw new Error(
          error?.data?.error ||
            'An error occurred while trying to connect your account. Please try again'
        )
      }

      updateGoogleAdsStatus(accountId, 'connected', true)
      dispatch(
        addToast({
          title: 'Success',
          subtext: `Your Google Ads account "${id}" has been successfully connected`,
          type: 'success'
        })
      )
    } catch (error) {
      dispatch(
        addToast({
          title: 'Error',
          subtext: error.message,
          type: 'error'
        })
      )
    }

    updateGoogleAdsStatus(accountId, 'isLoading', false)
  }

  return (
    <div>
      <ConnectItem
        name="Google"
        connected={!!gaToken}
        icon={<GoogleIcon />}
        isLoading={mainAccountConnecting}
        onClick={connectMainAccount}
      />
      {!!gaToken && Object.keys(googleAds).length > 0 && (
        <div className="pb-4">
          {Object.entries(googleAds).map(([id, { connected, isLoading }]) => (
            <ConnectAccountId
              key={id}
              name={id.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
              connected={connected}
              isLoading={isLoading}
              onClick={connectAccount}
            />
          ))}
        </div>
      )}
    </div>
  )
}
