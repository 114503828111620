import React from 'react'

export function GoogleAdsOutlineIcon({ className = 'h-6 w-6' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 50 50"
      strokeWidth="2.5"
      stroke="currentColor"
      className={className}
    >
      <path
        d="M 25.167969 5.0058594 C 24.781877 4.9968865 24.394532 5.014912 24.007812 5.0625 C 22.976561 5.1894012 21.954004 5.5214624 21 6.0722656 C 19.738126 6.8008205 18.867614 7.8795934 18.189453 9.0625 L 18.074219 8.9960938 L 4.0742188 32.996094 L 4.1894531 33.0625 C 3.5013617 34.243015 3 35.539748 3 37 C 3 41.406432 6.5935644 45 11 45 C 13.946182 45 16.422265 43.319288 17.810547 40.9375 L 17.925781 41.003906 L 25 28.876953 L 32.074219 41.003906 C 34.278505 44.817 39.185255 46.130214 43 43.927734 C 46.816027 41.724515 48.130947 36.816015 45.927734 33 L 45.927734 32.998047 L 31.927734 9 C 30.481872 6.4957324 27.870611 5.0686695 25.167969 5.0058594 z M 25.130859 6.9960938 C 27.155284 7.0392142 29.104113 8.1100176 30.195312 10 L 30.197266 10.001953 L 30.197266 10.003906 L 44.197266 34.003906 C 45.856861 36.883192 44.878669 40.533286 42 42.195312 C 39.120015 43.8581 35.467467 42.879973 33.804688 40 L 33.802734 39.998047 L 19.804688 16 L 19.804688 15.998047 L 19.802734 15.996094 C 18.143139 13.116808 19.121331 9.4667144 22 7.8046875 C 22.719996 7.3889907 23.488252 7.1380832 24.261719 7.0410156 C 24.551769 7.0046153 24.841656 6.9899337 25.130859 6.9960938 z M 17.212891 14.441406 C 17.372969 15.315284 17.603702 16.188437 18.072266 17 L 18.072266 17.001953 L 23.841797 26.892578 L 18.728516 35.658203 C 18.065728 31.912371 14.931107 29 11 29 C 10.084587 29 9.236334 29.238188 8.4160156 29.523438 L 17.212891 14.441406 z M 11 31 C 14.325556 31 17 33.674446 17 37 C 17 40.325554 14.325556 43 11 43 C 7.6744439 43 5 40.325554 5 37 C 5 33.674446 7.6744439 31 11 31 z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
