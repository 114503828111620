import React from 'react'
import { ConnectButton, ConnectedButton } from './Button'

interface ConnectAccountIdProps {
  name: string
  isLoading: boolean
  onClick: (name: string) => void
  connected?: boolean
}

export function ConnectAccountId({
  name,
  isLoading,
  onClick,
  connected = false
}: ConnectAccountIdProps) {
  return (
    <div className={`flex flex-row px-[25px] pt-0 pb-3 justify-between items-center w-full`}>
      <span className="text-sm dark:text-white text-gray-900 leading-6 tracking-[-0.5px]">
        {name}
      </span>
      {connected ? (
        <ConnectedButton />
      ) : (
        <ConnectButton size="small" isLoading={isLoading} onClick={() => onClick(name)} />
      )}
    </div>
  )
}
