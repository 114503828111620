import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { SideBarNav } from './SideBarNav'
import { modalEventHandler } from '../../helpers/modalEventHandler'

interface SideBarProps {
  setFullSize: (fullSize: boolean) => void
}

export function SideBar({ setFullSize }: SideBarProps) {
  const [expanded, setExpanded] = useState(false)

  const brand = useSelector((store) => store.global.brand)
  const darkMode = useSelector((store) => store.global.darkMode)

  const popupRef = useRef(null)

  const handleOpenClick = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setExpanded(false)
    }
  }

  useEffect(() => {
    return modalEventHandler(expanded, handleOpenClick)
  }, [expanded])

  return (
    <>
      <div
        className={`flex flex-col gap-[24px] bg-white dark:bg-gray-800 h-full inset-y-0 left-0 z-30 fixed
          ${expanded ? 'w-[280px] py-[32px] animate-slide-right2 overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-primary-500 scrollbar-track-transparent scrollbar-thumb-rounded-full scrollbar-track-rounded-full transition-all duration-300' : 'w-[0]'}
        `}
      >
        {expanded && (
          <a href="/" className="h-[36px] pl-[24px] pr-[94px] w-full">
            <img
              className="fade-in opacity-100 items-center justify-center h-full"
              src={darkMode ? brand.logoDark : brand.logo}
              alt={`${brand.name} Logo`}
            />
          </a>
        )}
        <div
          className={`top-4 md:top-0 items-center justify-start md:block w-12 bg-transparent ${expanded ? 'absolute' : ''}`}
        >
          <button
            className="is-published group relative ml-1 md:ml-[16px] top-[32px]"
            onClick={() => setExpanded(!expanded)}
          >
            <div
              className={`transition-all duration-100 relative flex h-12 w-12 transform items-baseline md:items-center justify-center overflow-hidden ${
                expanded ? 'md:-top-2 left-[280px]' : 'top-[-24px]'
              }`}
            >
              <div
                className={`flex w-5 md:h-6 md:w-6 origin-center transform flex-col justify-between overflow-hidden transition-all duration-300 ${
                  expanded ? 'h-6' : 'h-5'
                }`}
              >
                <div
                  className={`h-0.5 w-6 origin-left transform transition-all duration-300 dark:bg-base-50 group-hover:bg-primary-500 ${
                    expanded ? 'translate-y-6 bg-white' : 'translate-y-0 delay-100 bg-base-900'
                  }`}
                />
                <div
                  className={`h-0.5 w-4 transform rounded transition-all duration-300 dark:bg-base-50 group-hover:bg-primary-500 ${
                    expanded
                      ? 'translate-y-5 md:translate-y-6 bg-white'
                      : 'translate-y-0 delay-75 bg-base-900'
                  }`}
                />
                <div
                  className={`h-0.5 w-6 origin-left transform transition-all duration-300 dark:bg-base-50 group-hover:bg-primary-500 ${
                    expanded
                      ? 'translate-y-5 md:translate-y-6 bg-white'
                      : 'translate-y-0 bg-base-900'
                  }`}
                />
                <div
                  className={`absolute top-4 md:top-4 w-8 h-8 transform items-center justify-between transition-all duration-300 ${
                    expanded ? 'translate-x-0 w-0 h-0' : 'flex -translate-x-10 md:-translate-x-10'
                  }`}
                >
                  <div
                    className={`absolute h-0.5 transform transition-all duration-300 dark:bg-base-50 group-hover:bg-primary-500 ${
                      expanded ? 'rotate-45 delay-300 w-5 bg-white' : 'rotate-0 bg-base-900'
                    }`}
                  />
                  <div
                    className={`absolute h-0.5 transform transition-all duration-300 dark:bg-base-50 group-hover:bg-primary-500 ${
                      expanded ? '-rotate-45 delay-300 w-5 bg-white' : '-rotate-0 bg-base-900'
                    }`}
                  />
                </div>
              </div>
            </div>
          </button>
        </div>
        <SideBarNav expanded={expanded} setExpanded={setExpanded} setFullSize={setFullSize} />
      </div>
      {expanded && (
        <div
          className={`transition-opacity ease-in-out duration-500 fixed bg-[#00000099] backdrop-blur inset-0 z-[25] ${expanded ? 'opacity-100' : 'opacity-0'}`}
          onClick={() => setExpanded(false)}
        />
      )}
    </>
  )
}
