import React from 'react'
import { AdGroupsTable } from './AdGroupsTable'
import { Panel } from '../Panel'
import { NewPagination, usePagination } from '../../Pagination'
import type { AdGroupMetric } from '../types'

interface AdGroupsProps {
  list: AdGroupMetric[]
  loading: boolean
  dateFrom: string
}

export function AdGroups({ loading, list, dateFrom }: AdGroupsProps) {
  const pagination = usePagination<AdGroupMetric>(list)

  return (
    <Panel title="Ad Groups" loading={loading} dateFrom={dateFrom}>
      <>
        <AdGroupsTable list={pagination.paginated} />
        <NewPagination
          total={pagination.total}
          page={pagination.page}
          itemsPerPage={pagination.itemsPerPage}
          setPage={pagination.setPage}
          setItemsPerPage={pagination.setItemsPerPage}
        />
      </>
    </Panel>
  )
}
