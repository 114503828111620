import React from 'react'
import { ProgressBar } from '../../ProgressBar'

interface TileProps {
  title: string
  value: string
  isActive: boolean
  onClick: (title: string) => void
  subValue?: number
  color?: string
}

export function Tile({ title, value, isActive, onClick, subValue = 0, color = '' }: TileProps) {
  return (
    <div
      onClick={() => onClick(title)}
      style={{
        ...(isActive ? { backgroundColor: color } : {})
      }}
      className={`
        cursor-pointer grow h-[104px] p-5 hover:opacity-[70%] text-[#0f1728] rounded-2xl flex flex-col gap-2 active:scale-[.97] 
        ${
          isActive
            ? 'dark:text-[#0f1728]'
            : 'dark:text-white bg-white hover:bg-gray-50 dark:bg-gray-800 hover:dark:bg-gray-700'
        }
        ${title === 'Spend' ? 'gap-0' : ''}
      `}
    >
      <div className="text-xs font-semibold leading-[18px]">{title}</div>
      <div className="flex items-end gap-4 flex-grow text-3xl font-semibold leading-[38px]">
        {value}
      </div>
      {title === 'Spend' && <ProgressBar value={subValue} selected={isActive} />}
    </div>
  )
}
