import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WelcomeMessage } from './WelcomeMessage'
import { ProductInactiveMessage } from './ProductInactiveMessage'
import { hideMessages } from '../../../store/dashboard'

export function ProductMessages() {
  const customer = useSelector((state) => state.global.customer)
  const message = useSelector((state) => state.dashboard.messages)

  const dispatch = useDispatch()

  const onHideClick = () => {
    dispatch(hideMessages(true))
  }

  if (!customer.adsActive && !customer.seoActive) {
    return <ProductInactiveMessage />
  }

  if (!message.hide) {
    return <WelcomeMessage onHideClick={onHideClick} />
  }
}
