export const modalEventHandler = (open, handler) => {
  if (open) {
    document.body.classList.add('overflow-hidden') // disable scrolling on body
    document.addEventListener('click', handler)
  } else {
    document.body.classList.remove('overflow-hidden') // re-enable scrolling on body
    document.removeEventListener('click', handler)
  }

  return () => document.removeEventListener('click', handler)
}
