import React, { useState, useEffect } from 'react'
import { useGetInvoicesQuery } from '../services/billing'
import { useDispatch, useSelector } from 'react-redux'
import { setTotalOutstanding } from '../store/global'
import { StickyBanner } from '../components/Notifications'
import { InvoiceHistory } from '../components/billing/InvoiceHistory'
import { PaymentDetails } from '../components/billing/PaymentDetails'
import { BreadcrumbComponent } from '../components/Breacrumbs'

export function BillingPage() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [viewAll, setViewAll] = useState(true)
  const { data, isLoading } = useGetInvoicesQuery(viewAll)
  const [invoices, setInvoices] = useState([])

  const customer = useSelector((store) => store.global.customer)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setTotalOutstanding(data?.totalOutstanding || 0))
  }, [data?.totalOutstanding])

  useEffect(() => {
    if (!customer.temp) {
      setLoggedIn(true)
    }
  }, [])

  useEffect(() => {
    if (data?.invoices) {
      // Sort invoices by issue date
      const sortedInvoices = data.invoices
        .slice()
        .sort((a, b) => new Date(a.issueDate) - new Date(b.issueDate))

      // Calculate running balances
      const invoices = sortedInvoices.reduce((acc, inv) => {
        let runningBalance = 0
        if (acc.length > 0) {
          runningBalance =
            inv.status === 'PAID'
              ? acc[acc.length - 1].runningBalance - inv.totalDisplay
              : inv.status === 'NOT PAID'
                ? acc[acc.length - 1].runningBalance + inv.totalDisplay
                : inv.totalDisplay
        }
        if (runningBalance < 0) runningBalance = 0 // reset running balance to 0 if negative

        // Append current invoice with running balance to accumulator
        return [...acc, { ...inv, runningBalance }]
      }, [])

      setInvoices(invoices)
    }
  }, [data])

  return (
    <>
      <StickyBanner />
      <BreadcrumbComponent />
      <div className="flex flex-col gap-[4px] py-[32px] border-b-[1px] border-b-gray-200 dark:border-b-gray-700">
        <h1 className="font-inter text-[30px] text-gray-900 dark:text-gray-100 font-[600] leading-[38px]">
          Billing
        </h1>
        <span className="font-inter text-[16px] text-gray-600 dark:text-gray-400 font-[400] leading-[24px]">
          Manage your billing and payment details
        </span>
      </div>
      <div className="pt-[24px] pb-[20px] border-b-[1px] border-b-gray-200 dark:border-b-gray-700">
        <PaymentDetails />
      </div>
      <div className="flex flex-col gap-[8px] pt-[32px] pb-[24px]">
        <h2 className="font-inter text-[18px] text-gray-900 dark:text-gray-100 font-[600] leading-[28px]">
          Billing and invoicing
        </h2>
        <span className="font-inter text-[14px] text-gray-600 dark:text-gray-400 font-[400] leading-[20px]">
          Manage your finances effortlessly. Access billing details and invoices here.
        </span>
      </div>
      {/* {loggedIn && ( */}
      <InvoiceHistory
        loading={isLoading}
        viewAll={viewAll}
        invoices={invoices}
        onToggleView={() => setViewAll(!viewAll)}
      />
      {/* )} */}
    </>
  )
}
