import React from 'react'
import { PasswordUpdate } from '../components/settings/PasswordUpdate'
import { Preferences } from '../components/settings/Preferences'
import { WebsiteLogins } from '../components/settings/WebsiteLogins'
import { Connections } from '../components/Connections'
import { BreadcrumbComponent } from '../components/Breacrumbs'
import { ContactUpdate } from '../components/settings/ContactUpdate'

export function SettingsPage() {
  return (
    <>
      <BreadcrumbComponent />
      <div className="font-secondary flex flex-col lg:grid px-2 md:ml-0 pt-3 lg:grid-cols-2 gap-4">
        <div className="lg:col-span-full xl:col-auto xl:row-auto">
          <ContactUpdate />
        </div>
        <div className="lg:col-span-full xl:col-auto xl:row-auto">
          <PasswordUpdate />
        </div>
        <div className="lg:col-span-2">
          <Preferences />
        </div>
        <div className="lg:col-span-full xl:col-auto xl:row-auto">
          <Connections />
        </div>
        <div className="lg:col-span-full xl:col-auto xl:row-auto">
          <WebsiteLogins />
        </div>
      </div>
    </>
  )
}
