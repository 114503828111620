import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  messages: []
}

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addMessage: (state, { payload }) => ({
      ...state,
      messages: [...state.messages, payload]
    }),
    removeMessage: (state, { payload }) => ({
      ...state,
      messages: state.messages.filter((msg) => msg.id !== payload)
    })
  }
})

export const { addMessage, removeMessage } = slice.actions

export const { reducer: notifications } = slice
