import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CommonFunc } from '../../libs/common'
import { Modal } from './Modal'
import { ButtonClose } from '../Buttons'
import { EmojiExcite, EmojiHappy, EmojiNeutral, EmojiSad, EmojiAngry, LoadingIcon } from '../Icons'
import { addToast } from '../../store/global'
import { useSubmitTicketMutation } from '../../services/tickets'

const capitalizeString = new CommonFunc().capitalizeString

export function FeedbackModal({ onClose }) {
  const [formData, setFormData] = useState({ subject: 'excite', description: '' })
  const [errors, setErrors] = useState({ subject: '', description: '' })
  const [isExiting, setIsExiting] = useState(false)
  const [submitTicket, submitTicketResult] = useSubmitTicketMutation()

  const handleClose = () => {
    setIsExiting(true) // start exit animation
    setTimeout(onClose, 150) // delay to match exit animation duration
  }

  const onOptionChange = (e) => {
    setFormData({ ...formData, subject: e.target.value })
  }

  const page = useSelector((store) => store.global.page)
  const brand = useSelector((store) => store.global.brand.name)
  const { ticketsubmit } = window.t?.dashboard || {}

  const dispatch = useDispatch()

  const onChange = (event) => {
    const { name, value } = event.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const validateForm = () => {
    const formErrors = {
      subject: formData.subject === '',
      description: formData.description === ''
    }

    setErrors(formErrors)

    return Object.values(formErrors).some((value) => !value)
  }

  useEffect(() => {
    const { data, isUninitialized, isLoading } = submitTicketResult
    if (isUninitialized || isLoading) {
      return
    }

    if (!data || data.error) {
      dispatch(
        addToast({
          title: ticketsubmit.error,
          subtext: ticketsubmit.errorSubtext,
          type: 'error'
        })
      )
      return
    }

    dispatch(
      addToast({
        title: ticketsubmit.success,
        subtext: ticketsubmit.successSubtext,
        type: 'success'
      })
    )
    handleClose()
  }, [submitTicketResult.data])

  const onSubmit = async () => {
    if (!validateForm()) {
      return
    }

    const subject = !formData.subject.includes(capitalizeString(page.name || ''))
      ? `${capitalizeString(page.name || '')} Request: ${formData.subject}`
      : formData.subject

    submitTicket({ ...formData, subject })
  }

  return (
    <Modal handleClose={handleClose} isExiting={isExiting}>
      <div className="z-20 flex flex-col pt-[4px]">
        <header className="flex justify-between">
          <h3 className="text-gray-900 dark:text-gray-100 flex flex-grow font-inter font-[600] text-[24px] justify-start leading-7">
            Give feedback
          </h3>
          <ButtonClose onClick={handleClose} />
        </header>
        <p className="dark:text-gray-300 text-gray-600 text-left text-[14px] font-[500] leading-5 pb-[20px]">
          How happy are you with the the service provided?
        </p>
        {/* emoji */}
        <div className="flex flex-row justify-center gap-[16px] pb-[20px]">
          <label>
            <input
              type="radio"
              name="emoji"
              value="excite"
              className="hidden"
              checked={formData.subject === 'excite'}
              onChange={onOptionChange}
            />
            <EmojiExcite selected={formData.subject === 'excite'} />
          </label>
          <label>
            <input
              type="radio"
              name="emoji"
              value="happy"
              className="hidden"
              checked={formData.subject === 'happy'}
              onChange={onOptionChange}
            />
            <EmojiHappy selected={formData.subject === 'happy'} />
          </label>
          <label>
            <input
              type="radio"
              name="emoji"
              value="neutral"
              className="hidden"
              checked={formData.subject === 'neutral'}
              onChange={onOptionChange}
            />
            <EmojiNeutral selected={formData.subject === 'neutral'} />
          </label>
          <label>
            <input
              type="radio"
              name="emoji"
              value="sad"
              className="hidden"
              checked={formData.subject === 'sad'}
              onChange={onOptionChange}
            />
            <EmojiSad selected={formData.subject === 'sad'} />
          </label>
          <label>
            <input
              type="radio"
              name="emoji"
              value="angry"
              className="hidden"
              checked={formData.subject === 'angry'}
              onChange={onOptionChange}
            />
            <EmojiAngry selected={formData.subject === 'angry'} />
          </label>
        </div>
        {/* input */}
        <div className="flex flex-col gap-[6px]">
          <label
            htmlFor="description"
            className="group text-[14px] font-inter font-[500] text-gray-600 dark:text-gray-300"
          >
            Do you have any thoughts you'd like to share?
          </label>
          <textarea
            type="text"
            name="description"
            placeholder="Feedback..."
            value={formData.description}
            onChange={onChange}
            className={`block w-full dark:bg-gray-900 font-inter text-gray-700 dark:text-gray-300 placeholder:text-gray-400 placeholder:dark:text-gray-600 text-[16px] font-[400] leading-6 py-[9px] px-[13px] border-[1px] hover:border-gray-400 dark:border-gray-700 hover:dark:border-primary-800 rounded-[8px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] transition-all duration-200 focus:border-[#1c64f2] border-gray-200 dark:border-base-500 ${
              errors.subject
                ? 'border-red-400 dark:border-red-400'
                : 'border-gray-200 dark:border-base-500'
            }`}
          />
        </div>
        <footer className="flex flex-col-reverse sm:flex-row gap-[12px] pt-[24px] sm:pt-[32px]">
          <button
            type="button"
            className="flex items-center justify-center w-full bg-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-primary-900 active:scale-95 py-[10px] px-[18px] font-[600] font-inter text-gray-600 dark:text-gray-400 rounded-[8px] leading-6 transition-all bg-opacity-100 ease-cubic-bezier[.785,.135,.15,.86] duration-100 delay-0"
            disabled={submitTicketResult.loading}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`
                flex items-center justify-center gap-[8px] w-full active:scale-95 py-[10px] px-[18px] font-[600] font-inter dark:text-primary-900 rounded-[8px] leading-6 transition-all bg-opacity-100 ease-cubic-bezier[.785,.135,.15,.86] duration-100 delay-0
                ${brand === 'jdm' ? 'dark:hover:text-black bg-primary-400 hover:bg-primary-500 dark:bg-primary-300 dark:hover:bg-primary-500' : 'text-white bg-primary-700 dark:bg-primary-300 hover:bg-primary-900 dark:hover:bg-primary-500'}`}
            disabled={submitTicketResult.loading}
            onClick={onSubmit}
          >
            Send
            {submitTicketResult.loading ? <LoadingIcon /> : ''}
          </button>
        </footer>
      </div>
    </Modal>
  )
}
