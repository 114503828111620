import { ProductMessageType } from './ProductMessageType'

export const getMessages = (setupComplete, activeDays) => {
  const messages = []

  if (!setupComplete && activeDays < 14) {
    messages.push(ProductMessageType.Foundations)
  } else {
    if (activeDays <= 30) {
      messages.push(ProductMessageType.Thirty)
    }
    if (activeDays > 30 && activeDays <= 60) {
      messages.push(ProductMessageType.Sixty)
    }
    if (activeDays > 60 && activeDays <= 90) {
      messages.push(ProductMessageType.Ninety)
    }
  }

  if (activeDays > 90) {
    messages.push(ProductMessageType.Journey)
  }

  return messages
}
