import React from 'react'
import { useSelector } from 'react-redux'
import { Toast } from './Toast'

export function ToastSystem() {
  const notifications = useSelector((state) => state.global.toasts)

  return (
    <div className="fixed flex flex-col items-stretch md:items-end gap-[10px] w-full md:w-fit mt-[8px] right-0 sm:right-[-25px] z-50">
      {notifications.map((notification) => (
        <Toast
          key={notification.id}
          title={notification.title}
          subtext={notification.subtext}
          type={notification.type}
        />
      ))}
    </div>
  )
}
