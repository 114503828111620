import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '../Icons'

const iconTypes = {
  first: ChevronLeftIcon,
  prev: ChevronLeftIcon,
  next: ChevronRightIcon,
  last: ChevronRightIcon
}

export function PageIcon({ type, onClick }) {
  const Icon = iconTypes[type]

  const className = [
    'px-1',
    'py-1',
    'bg-gray-50',
    'rounded-md',
    'hover:bg-gray-200',
    'hover:shadow-outline-cool-gray',
    'hover:text-primary-500',
    'focus:shadow-outline-grey',
    'text-base-900',
    'dark:text-base-50',
    'dark:border-base-600',
    'dark:bg-base-700',
    'focus:shadow-outline-cool-gray'
  ].join(' ')

  return (
    <button type="button" onClick={onClick} className={className}>
      <Icon />
    </button>
  )
}
