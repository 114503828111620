import React, { useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { LoadingIcon } from '../Icons'
import { addToast } from '../../store/global'
import { addMessage, removeMessage } from '../../../js/store/notifications'
import { basicApi } from '../../services/api'
import { isProductActive } from '../../helpers/isProductActive'
import type { RootState } from '../../store'

interface AddAnAccountProps {
  platform: string
  adAccounts: string[]
  setAdAccounts: () => void
}

const sendInviteRequest = async (provider, platform, gadsId) => {
  try {
    const response = await basicApi('/cpanel/invitecustomer', {
      provider,
      platform,
      ids: gadsId
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
  }
}

const checkGadsCustomerLink = async (provider, platform, gadsId) => {
  try {
    const response = await basicApi('/cpanel/connectionstatus', {
      provider,
      platform,
      ids: gadsId
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
  }
}

const updateAdsId = async (id, subscriptionId, gadsId, fbadsId) => {
  try {
    const response = await basicApi('/cpanel/adsid', {
      id,
      subscriptionId,
      googleAdsId: gadsId,
      facebookAdsId: fbadsId
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
  }
}

export function AddAnAccount({
  platform,
  adAccounts = null,
  setAdAccounts = null
}: AddAnAccountProps) {
  const [inputBox, setInputBox] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [inviteSent, setInviteSent] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const brand = useSelector((store: RootState) => store.global.brand.name)
  const messages = useSelector((store: RootState) => store.notifications.messages)
  const adsAccounts = useSelector(
    (store: RootState) => store.global.customer.adsAccounts,
    shallowEqual
  )
  const activeProducts = useSelector((store: RootState) => store.global.customer.activeProducts)

  const dispatch = useDispatch()

  const sendInvite = async () => {
    setSpinner(true)
    const gadsId = inputValue.replace(/-/g, '')
    const domain = process.env.NODE_ENV !== 'production' ? 'cp.' : 'clientpanel.'
    const domainTop = process.env.NODE_ENV !== 'production' ? '.local' : '.com'
    const provider = window.location.href.split(domain)[1].split(domainTop)[0]
    const invitationSent = await sendInviteRequest(provider, platform, gadsId)

    if (invitationSent) {
      dispatch(
        addToast({
          title: 'Successfully Sent Invite',
          subtext: `We have successfully sent an invite to the Ads Account (${inputValue}). Please check your email to accept the invite, and finish connecting your Google Ads Account.`,
          type: 'success'
        })
      )
      dispatch(
        addMessage({
          priority: 'low',
          title: 'Successfully Sent Invite',
          content: `We have successfully sent an invite to the Ads Account (${inputValue}). Please check your email to accept the invite, and finish connecting your Google Ads Account.`
        })
      )
      setInviteSent(true)
    } else {
      dispatch(
        addToast({
          title: 'Google Ads Invite Failed',
          subtext: `We were unable to send an invite to the Ads Account (${inputValue}). Please try again later.`,
          type: 'error'
        })
      )
      dispatch(
        addMessage({
          priority: 'med',
          title: 'Invite Failed',
          content: `We were unable to send an invite to Account ${inputValue}. Please try again later.`
        })
      )
    }
    setSpinner(false)
  }

  const checkStatus = async () => {
    setSpinner(true)
    const gadsId = [inputValue]
    const domain = process.env.NODE_ENV !== 'production' ? 'cp.' : 'clientpanel.'
    const domainTop = process.env.NODE_ENV !== 'production' ? '.local' : '.com'
    const provider = window.location.href.split(domain)[1].split(domainTop)[0]
    const gadStatusObj = await checkGadsCustomerLink(provider, 'Google', gadsId)

    for (const key of Object.keys(gadStatusObj)) {
      if (gadStatusObj[key] === true) {
        // update the first empty, active, google ads package with the ads id
        activeProducts.forEach(async (product, idx) => {
          if (
            product.adWordsManagement &&
            isProductActive(product.adWordsManagement.status) &&
            !product.adWordsManagement.adsId
          ) {
            await updateAdsId(
              product.adWordsManagement.productId,
              product.adWordsManagement.subscriptionId || null,
              gadsId[0],
              null
            )
          }
        })

        // remove outdated notifications
        dispatch(
          removeMessage(messages.filter((msg) => msg.title === 'Successfully Sent Invite')[0].id)
        )
        dispatch(removeMessage(messages.filter((msg) => msg.title === 'Connect Google Ads')[0].id))

        // bubble up react props, for immediate frontend feedback on ads id
        setAdAccounts({ ...adAccounts, google: [...adAccounts.google, gadsId[0]] })

        // create deep copy to remove store reference
        const activeProductsCopy = activeProducts.map((product) => {
          if (!product.adWordsManagement || product.adWordsManagement.adsId) {
            return product
          }

          return {
            ...product,
            adWordsManagement: {
              ...product.adWordsManagement,
              adsId: gadsId[0]
            }
          }
        })

        // save to localstorage for persistence with redux store
        localStorage.setItem('adsAccounts', JSON.stringify([...adsAccounts, gadsId[0]])) // also saving in local storage, so that on page reload it persists
        localStorage.setItem('activeProducts', JSON.stringify(activeProductsCopy)) // also saving in local storage, so that on page reload it persists

        // fire success toast
        dispatch(
          addToast({
            title: 'Google Ads Account Connected',
            subtext: `Your Google Ads Account (${key.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}) was successfully connected!`,
            type: 'success'
          })
        )
        // fire success notification
        dispatch(
          addMessage({
            priority: 'low',
            title: 'Ads Successfully Connected',
            content: `Your Google Ads Account (${key.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}) was successfully connected!`
          })
        )
      } else {
        // fire warning toast
        dispatch(
          addToast({
            title: 'Google Ads Account Not Connected',
            subtext: `Please check your inbox for an email from Google to accept connecting your Google Ads account (${key.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}).`,
            type: 'warning'
          })
        )
        // fire warning notification
        dispatch(
          addMessage({
            priority: 'med',
            title: 'Ads Account Not Connected',
            content: `Please check your inbox for an email from Google to accept connecting your Google Ads account (${key.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}).`
          })
        )
      }
    }
    setSpinner(false)
  }

  return (
    <div
      className={`self-center ${inputBox ? 'w-full' : ''}`}
      onClick={() => {
        setInputBox(true)
      }}
    >
      {!inputBox && (
        <div className="h-auto flex justify-end items-center gap-x-[4px] cursor-pointer text-primary-600 dark:text-primary-300 hover:text-primary-700 dark:hover:text-primary-400 active:scale-95">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M7.99992 3.33325V12.6666M3.33325 7.99992H12.6666"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="w-max text-[14px] font-inter font-[500] leading-[16px] user-select-none">
            Add Account
          </span>
        </div>
      )}
      {inputBox && (
        <div className="flex flex-col gap-[5px] rounded-[8px] bg-gray-100 dark:bg-gray-900 justify-between p-[16px]">
          {(platform === 'Google' || platform === 'Microsoft') && ( //* Google/Microsoft logic (invite customer with api call)
            <>
              <span className="text-[14px] text-gray-700 dark:text-gray-200 font-[600] leading-[20px]">
                {platform} Ads ID
              </span>
              <div className="flex flex-row gap-[8px] items-center justify-between">
                {!inviteSent ? (
                  <input
                    className="w-full min-w-[0] px-[13px] py-[5px] rounded-[6px] border-[1px] border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-700 text-[14px]"
                    placeholder={
                      platform === 'Google'
                        ? '123-456-7890'
                        : platform === 'Facebook'
                          ? '1234567890123456'
                          : 'Enter your ads account ID'
                    }
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                ) : (
                  <span className="text-[14px] font-[400] leading-[20px] text-gray-700 dark:text-gray-200">
                    {inputValue}
                  </span>
                )}
                {!inviteSent ? (
                  <div
                    className="cursor-pointer w-auto flex items-center justify-center gap-[8px] rounded-[8px] dark: bg-primary-700 dark:bg-primary-300 hover:bg-primary-800 dark:hover:bg-primary-400 active:scale-95 px-[18px] py-[10px] shadow-primary-200 hover:shadow-primary-500 dark:shadow-primary-500 dark:hover:shadow-primary-800 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] transition bg-opacity-100 ease-cubic-bezier[.785,.135,.15,.86] duration-100 delay-0"
                    onClick={sendInvite}
                  >
                    <span className="font-inter text-[12px] font-[500] leading-[16px] text-white dark:text-primary-900">
                      Connect
                    </span>
                    {spinner && <LoadingIcon />}
                  </div>
                ) : (
                  <div
                    className="cursor-pointer w-auto flex items-center justify-center gap-[8px] rounded-[8px] dark: bg-primary-700 dark:bg-primary-300 hover:bg-primary-800 dark:hover:bg-primary-400 active:scale-95 px-[18px] py-[10px] shadow-primary-200 hover:shadow-primary-500 dark:shadow-primary-500 dark:hover:shadow-primary-800 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] transition bg-opacity-100 ease-cubic-bezier[.785,.135,.15,.86] duration-100 delay-0"
                    onClick={checkStatus}
                  >
                    <span className="font-inter text-[12px] font-[500] leading-[16px] text-white dark:text-primary-900">
                      Check Status
                    </span>
                    {spinner && <LoadingIcon />}
                  </div>
                )}
              </div>
              {inviteSent && (
                <span className="pt-[10px] text-[14px] text-gray-500 dark:text-gray-200 font-[400] leading-[20px]">
                  <b>Next Step:</b> Please check your inbox for an email from Google to accept
                  connecting your Google Ads account.
                </span>
              )}
            </>
          )}
          {inputBox &&
            platform === 'Facebook' && ( //! Facebook logic is different to Google/Microsoft (no invite customer api call)
              <>
                <span className="text-[14px] text-gray-700 dark:text-gray-200 font-[600] leading-[20px]">
                  Connect Meta Ads Account
                </span>
                <span className="pt-[10px] text-[14px] text-gray-500 dark:text-gray-200 font-[400] leading-[20px]">
                  <b>Step 1:</b> Go to the Ad Account tab, in your Facebook Business Manager.
                  <br />
                  <b>Step 2:</b> Click the <b>Assign</b> button.
                  <br />
                  <b>Step 3:</b> Enter the following number to connect to our business account:{' '}
                  <br />
                  <b>
                    {brand === 'slx'
                      ? '10152392107268922'
                      : brand === 'gmt'
                        ? '1469043719820096'
                        : '515195195954055'}
                  </b>
                </span>
              </>
            )}
        </div>
      )}
    </div>
  )
}
