import React from 'react'
import { PuzzleIcon } from './PuzzleIcon'
import { MobileIcon } from './MobileIcon'
import { TabletIcon } from './TabletIcon'
import { DesktopIcon } from './DesktopIcon'
import { ConnectedTVIcon } from './ConnectedTVIcon'

export function DeviceIcon({ type, className = 'h-6 w-6' }) {
  const iconTypes = {
    mobile: MobileIcon,
    tablet: TabletIcon,
    desktop: DesktopIcon,
    connected_tv: ConnectedTVIcon
  }

  const Icon = iconTypes[type.toLowerCase()] || PuzzleIcon

  return <Icon className={className} />
}
