import React, { useState } from 'react'
import { ProductMessage } from '../../ProductMessage'
import { BookingButton } from '../../BookingButton'
import { SendMessageModal } from '../../Modal'

export function ProductInactiveMessage() {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  const { brandName } = window.t?.branding || {}

  return (
    <>
      <ProductMessage title="Have you considered Paid Search or SEO?">
        <>
          <p className="paragraph-text">
            As Australia's leading digital marketing experts, we specialise in championing small to
            medium business online.
          </p>
          <p className="paragraph-text">
            Google Ads gets your business in front of thousands of potential customers daily.{' '}
            {brandName} are here do the work of creating and managing your complex Google
            Advertising campaigns, so you can focus on launching your business for success.
          </p>
          <p className="paragraph-text">
            Interested in also working with Australia's leading SEO specialists? We know that every
            SEO strategy is as unique as its business, and sometimes, you just need a little
            something extra. To find out how we can help your business blast off today, contact one
            of our SEO specialists about a tailored package that is customised to your specific
            business needs.
          </p>
          <p className="paragraph-text">
            If you'd like to take your digital marketing to the next level, reach out and we'll be
            happy to help you get to the next level!
          </p>
          <div className="space-y-6 sm:flex">
            <BookingButton onClick={openModal} />
          </div>
        </>
      </ProductMessage>

      {open && <SendMessageModal onClose={closeModal} />}
    </>
  )
}
