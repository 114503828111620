import React from 'react'

export function Completed() {
  return (
    <>
      <svg
        viewBox="0 0 24 24"
        className="text-primary-400 dark:text-primary-500 w-20 h-20 mx-auto my-6 transition bg-opacity-100 ease-cubic-bezier[.785,.135,.15,.86] duration-200 delay-0"
      >
        <path
          fill="currentColor"
          d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
        />
      </svg>
      <div className="dark:text-white grid gap-[7px] text-center">
        <h3 className="font-bold text-2xl leading-8 tracking-[-0.6px]">Setup Complete!</h3>
        <p className="font-normal text-lg leading-6">
          Thank you for completing setup.
          <br />A member of our team will be in contact shortly.
        </p>
      </div>
    </>
  )
}
