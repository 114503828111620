import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { menuItems } from './menuItems'
import { SideBarItem } from './SideBarItem'
import { BookingButton } from '../BookingButton'
import { SendMessageModal } from '../Modal'
import { ProfileBadge } from './ProfileBadge'
import { FeedbackModal } from '../Modal/FeedbackModal'
import { PurchaseModal } from '../Modal/PurchaseModal'
import { UnlockProfileModal } from '../Modal/UnlockProfileModal'
import { useGetProductsQuery } from '../../services/products'
import { isProductActive } from '../../helpers/isProductActive'
import type { RootState } from '../../store'

interface SideBarNavProps {
  expanded: boolean
  setExpanded: (expanded: boolean) => void
  setFullSize: (fullSize: boolean) => void
}

export function SideBarNav({ expanded, setExpanded, setFullSize }: SideBarNavProps) {
  const [packages, setPackages] = useState({
    google: [],
    facebook: [],
    seo: []
  })

  const page = useSelector((store: RootState) => store.global.page)
  const username = useSelector((store: RootState) => store.global.customer.name)
  const brand = useSelector((store: RootState) => store.global.brand.displayName)

  const activeProducts = useSelector((store: RootState) => store.global.customer.activeProducts)
  const activeSeoProduct = activeProducts.find(
    (product) => product.seo && isProductActive(product.seo.status)
  )
  const email = activeSeoProduct
    ? activeSeoProduct?.seo?.productDescription?.replace(/^https?:\/\//, '').replace(/\/$/, '')
    : null

  const [openContactPopup, setOpenContactPopup] = useState(false)
  const [openPurchasePopup, setOpenPurchasePopup] = useState(false)
  const [openAuthPopup, setOpenAuthPopup] = useState(false)
  const [openFeedbackPopup, setOpenFeedbackPopup] = useState(false)
  const [platform, setPlatform] = useState('google')

  const googlePackages = useGetProductsQuery({ platform: 'Google Ads', brand })
  const facebookPackages = useGetProductsQuery({ platform: 'Meta', brand })
  const seoPackages = useGetProductsQuery({ platform: 'SEO', brand })

  useEffect(() => {
    if (googlePackages.isLoading || facebookPackages.isLoading || seoPackages.isLoading) {
      return
    }

    setPackages({
      google: googlePackages.data,
      facebook: facebookPackages.data,
      seo: seoPackages.data
    })
  }, [googlePackages, facebookPackages, seoPackages])

  useEffect(() => {
    // useEffect to close the navbar underneath, if open
    setExpanded(false)
  }, [openContactPopup, openPurchasePopup, openAuthPopup, openFeedbackPopup])

  return (
    <>
      <aside
        className={`flex flex-col grow left-0 transition-width duration-75 bg-white dark:bg-gray-800 ${
          expanded
            ? 'w-[280px] px-[16px] animate-slide-right2'
            : 'hidden md:flex md:w-[81px] px-[16px] pt-[24px] pb-[32px]'
        }`}
        aria-label="Sidebar"
      >
        <div
          className={`flex flex-1 flex-col justify-between pb-[22px] ${expanded ? '' : 'border-r-transparent border-r-[6px]'}`}
        >
          {menuItems.map((items, i) => (
            <ul key={i}>
              {items.map((item) => {
                if (brand === 'Just Digital Marketing' && item.id === 'facebook') {
                  // exclude rendering if the brand is 'jdm' and item.id is 'facebook'
                  return null
                }
                return (
                  item.enabled && (
                    <li key={item.id}>
                      <SideBarItem
                        expanded={expanded}
                        item={item}
                        locked={openPurchasePopup}
                        setPlatform={setPlatform}
                        setPriceLock={setOpenPurchasePopup}
                        setAuthLock={setOpenAuthPopup}
                        authLock={openAuthPopup}
                        setFullSize={setFullSize}
                        onClick={() => setExpanded(false)}
                      />
                    </li>
                  )
                )
              })}
            </ul>
          ))}
        </div>
        <div>
          <div className="flex flex-row flex-1 gap-[16px] border-t-[1px] border-gray-200 dark:border-gray-700 py-[24px] justify-center">
            <button
              type="button"
              onClick={() => setOpenFeedbackPopup(true)}
              className="block md:hidden w-full z-[10] py-[8px] px-[14px] rounded-[8px] bg-white dark:bg-gray-700 shadow-xs
                        border-[1px] border-gray-300 dark:border-none text-gray-700 dark:text-gray-200 font-inter text-[14px] font-[600]
                        transition-all bg-opacity-100 ease-cubic-bezier[.785,.135,.15,.86] duration-100 delay-0 hover:bg-gray-100 dark:hover:bg-gray-900 active:scale-95"
            >
              Feedback
            </button>
            <BookingButton onClick={() => setOpenContactPopup(true)} mini={!expanded} />
          </div>
          <ProfileBadge username={username} email={email} expanded={expanded} />
        </div>
      </aside>
      {openContactPopup && <SendMessageModal onClose={() => setOpenContactPopup(false)} />}
      {openPurchasePopup && (
        <PurchaseModal
          onClose={() => setOpenPurchasePopup(false)}
          onClick={() => setOpenContactPopup(true)}
          packageList={packages[platform.replace('meta', 'facebook')]}
          platform={platform.replace('meta', 'facebook')}
        />
      )}
      {openFeedbackPopup && <FeedbackModal onClose={() => setOpenFeedbackPopup(false)} />}
      {openAuthPopup && <UnlockProfileModal onClose={() => setOpenAuthPopup(false)} />}
    </>
  )
}
