import React, { useState } from 'react'
import { ProductMessage } from '../../ProductMessage'
import { BookingButton } from '../../BookingButton'
import { SendMessageModal } from '../../Modal'

export function ProductInactiveMessage() {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  const { brandName } = window.t?.branding || {}

  return (
    <>
      <ProductMessage title="Have you considered Paid Search?">
        <>
          <p className="paragraph-text">
            As Australia's leading digital marketing experts, we specialise in championing small to
            medium business online.
          </p>
          <p className="paragraph-text">
            Google Ads gets your business in front of thousands of potential customers daily.{' '}
            {brandName} are here do the work of creating and managing your complex Google
            Advertising campaigns, so you can focus on launching your business for success.
          </p>
          <div className="space-y-6 sm:flex">
            <BookingButton onClick={openModal} />
          </div>
        </>
      </ProductMessage>

      {open && <SendMessageModal onClose={closeModal} />}
    </>
  )
}
