import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

export function withAuthGuard(WrappedComponent) {
  const WithAuthGuard = (props) => {
    const [params] = useSearchParams()
    const { id } = useSelector((store) => store.global.customer)

    useEffect(() => {
      const authKey = params.get('authKey')
      const paramsCustomerId = params.get('customerId')

      if (id !== paramsCustomerId && authKey) {
        window.location.reload()
      }
    }, [params, id])

    return <WrappedComponent {...props} />
  }

  WithAuthGuard.displayName = `withAuthGuard(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`
  return WithAuthGuard
}
