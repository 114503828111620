import React from 'react'

interface GoogleIconProps {
  className?: string
}

export function GoogleIcon({ className = 'self-center inline mr-[15px]' }: GoogleIconProps) {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.56 11.25C21.56 10.47 21.49 9.72 21.36 9H11V13.255H16.92C16.665 14.63 15.89 15.795 14.725 16.575V19.335H18.28C20.36 17.42 21.56 14.6 21.56 11.25Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9999 22C13.9699 22 16.4599 21.015 18.2799 19.335L14.7249 16.575C13.7399 17.235 12.4799 17.625 10.9999 17.625C8.13492 17.625 5.70992 15.69 4.84492 13.09H1.16992V15.94C2.97992 19.535 6.69992 22 10.9999 22Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.845 13.09C4.625 12.43 4.5 11.725 4.5 11C4.5 10.275 4.625 9.57 4.845 8.91V6.06H1.17C0.425 7.545 0 9.225 0 11C0 12.775 0.425 14.455 1.17 15.94L4.845 13.09Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9999 4.375C12.6149 4.375 14.0649 4.93 15.2049 6.02L18.3599 2.865C16.4549 1.09 13.9649 0 10.9999 0C6.69992 0 2.97992 2.465 1.16992 6.06L4.84492 8.91C5.70992 6.31 8.13492 4.375 10.9999 4.375Z"
        fill="#EA4335"
      />
    </svg>
  )
}
