import spacetime from 'spacetime'

export const getPeriodFromDateRange = (from, to) => {
  const diffDays = spacetime(from).diff(to, 'day')
  if (diffDays < 29) {
    return {
      period: 'day',
      units: diffDays
    }
  }

  return {
    period: 'month',
    units: spacetime(from).diff(to, 'month') || 1
  }
}
