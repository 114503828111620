import React from 'react'

const EyeIcon = ({ children }: { children: React.ReactNode }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 96 960 960"
    height="24"
    width="24"
    className="absolute top-[34px] right-[15px] cursor-pointer active:scale-90 text-gray-600 dark:text-gray-300"
  >
    {children}
  </svg>
)

export const EyeClosedIcon = () => (
  <EyeIcon>
    <path
      fill="#92929D"
      d="M650 611 549 510q2-11-7.5-22t-23.5-9l-93-93q14-5 28-7.5t27-2.5q75 0 127.5 52.5T660 556q0 11-2.5 27t-7.5 28Zm154 154-78-78q31-27 56-58t48-73q-52-100-143.5-160T480 336q-29 0-51.5 3t-42.5 8l-88-88q41-17 88-25.5t94-8.5q157 0 283.5 88.5T951 556q-23 61-62.5 115.5T804 765Zm-24 247L630 863q-33 12-70.5 18t-79.5 6q-158 0-284.5-89.5T9 556q20-51 53-100t72-89L28 261l67-67 751 752-66 66ZM213 443q-27 28-45.5 53T131 556q50 101 142 160.5T480 776q11 0 25.5-1t34.5-4l-36-38q-6 2-12 2.5t-12 .5q-75 0-127.5-52.5T300 556v-11.5q0-6.5 1-12.5l-88-89Zm343 74Zm-180 90Z"
    />
  </EyeIcon>
)

export const EyeOpenIcon = () => (
  <EyeIcon>
    <path
      fill="#92929D"
      d="M480 736q75 0 127.5-52.5T660 556q0-75-52.5-127.5T480 376q-75 0-127.5 52.5T300 556q0 75 52.5 127.5T480 736Zm.176-93Q444 643 418.5 617.676 393 592.353 393 556.177 393 520 418.324 494.5q25.323-25.5 61.5-25.5Q516 469 541.5 494.323q25.5 25.324 25.5 61.5Q567 592 541.677 617.5 516.353 643 480.176 643ZM480 887q-158 0-285.5-91.5T9 556q58-148 185.5-239.5T480 225q158 0 285.5 91.5T951 556q-58 148-185.5 239.5T480 887Zm0-331Zm.089 220Q592 776 685.5 716T829 556q-50-100-143.589-160t-205.5-60Q368 336 274.5 396T131 556q50 100 143.589 160t205.5 60Z"
    />
  </EyeIcon>
)
