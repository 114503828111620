/**
 * Convert a date text to a formualate realtive date in string
 *
 * @param {number} dateText The date in DD-MM-YYYY string format
 * @returns {string} The formulated date in relative format
 */
export const dateToRelativeFormat = (dateText): string => {
  const diff = new Date() - new Date(dateText)
  const days = diff / (1000 * 60 * 60 * 24)
  let plural = 's'
  if (days < 30) {
    plural = days > 1 ? 's' : ''
    return `Last ${Math.round(days)} day${plural}`
  } else if (days < 365) {
    const months = Math.round(days / 30)
    plural = months > 1 ? 's' : ''
    return `Last ${months} month${plural}`
  } else {
    const years = Math.round(days / 365)
    plural = years > 1 ? 's' : ''
    return `Last ${years} year${plural}`
  }
}
