import React, { useRef, useState, useEffect } from 'react'
import pdfJS from 'pdfjs-dist'
import { LoadingChartLTR } from '../Loading'

export function PdfViewer({ pdfBlob }) {
  const canvasRef = useRef(null)
  const [loading, setLoading] = useState(true)

  const renderPdf = async () => {
    const data = await pdfBlob.arrayBuffer()
    pdfJS.GlobalWorkerOptions.workerSrc = `${window.location.origin}/js/libs/pdf.worker.min.js`
    const pdf = await pdfJS.getDocument({ data }).promise
    const page = await pdf.getPage(1)
    const viewport = page.getViewport({ scale: 1.5 })

    const canvas = canvasRef.current
    const canvasContext = canvas.getContext('2d')
    canvas.width = viewport.width
    canvas.height = viewport.height
    const renderContext = { canvasContext, viewport }
    page.render(renderContext).promise
  }

  useEffect(() => {
    renderPdf()
    setLoading(false)
  }, [])

  return (
    <>
      {loading ? (
        <LoadingChartLTR />
      ) : (
        <canvas
          ref={canvasRef}
          className="inline justify-center w-full border-[1px] border-gray-300 dark:border-gray-500 rounded-[16px] shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)]"
        />
      )}
    </>
  )
}
