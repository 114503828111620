import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FormTitle } from './FormTitle'
import { InputField, SelectField } from './InputField'
import { SkipButton } from './Button'
import { SubmitButton } from '../Buttons/index'
import { ButtonClose } from '../Buttons'
import { Modal } from '../Modal'
import { LoadingCard } from '../Loading'
import { useCreateWebsiteMutation } from '../../services/settings'
import { useCheckWebsiteMutation } from '../../services/checkout'
import { useGetCmsMutation } from '../../services/onboarding'
import { addToast } from '../../store/global'
import type { Option } from './InputField/SelectField'

export const cmsOptions: Option[] = [
  {
    label: '--None--',
    value: 'none'
  },
  {
    label: 'BigCommerce',
    value: 'bigcommerce'
  },
  {
    label: 'Custom CMS',
    value: 'customcms'
  },
  {
    label: 'DIFM',
    value: 'difm'
  },
  {
    label: 'Drupal',
    value: 'drupal'
  },
  {
    label: 'GoDaddy',
    value: 'godaddy'
  },
  {
    label: 'HubSpot CMS',
    value: 'hubspot'
  },
  {
    label: 'Joomla',
    value: 'joomla'
  },
  {
    label: 'Magento',
    value: 'magento'
  },
  {
    label: 'Neto',
    value: 'neto'
  },
  {
    label: 'Sensis',
    value: 'sensis'
  },
  {
    label: 'Shopify',
    value: 'shopify'
  },
  {
    label: 'Squarespace',
    value: 'squarespace'
  },
  {
    label: 'Weebly',
    value: 'weebly'
  },
  {
    label: 'WIX',
    value: 'wix'
  },
  {
    label: 'WordPress',
    value: 'wordpress'
  },
  {
    label: 'Other',
    value: 'other'
  }
]

const modalData = {
  username: {
    title: 'Website Username',
    content: (
      <>
        <p className="pb-2">
          If you're looking to find your website username, here are a few methods you can try:
        </p>
        <p className="pb-2">
          <strong>1. Check your email:</strong> Search your email inbox for any registration or
          account confirmation emails from the website. These emails often contain your username or
          a link to your account.
        </p>
        <p className="pb-2">
          <strong>2. Personal profile:</strong> Log in to your website's administrative dashboard
          and navigate to your personal profile or account settings. Look for a section that
          displays your username.
        </p>
        <p className="pb-2">
          <strong>3. Contact support:</strong> If you can't find your username using the above
          methods, reach out to the website's support team. Provide them with relevant details such
          as your email address and any other information associated with your account. They can
          assist you in retrieving your username.
        </p>
      </>
    )
  },
  password: {
    title: 'Website Password',
    content: (
      <>
        <p className="pb-2">
          If you need to find your website password, there are a few ways you can try:
        </p>
        <p className="pb-2">
          <strong>1. Check your email:</strong> Sometimes, website platforms send password reset
          emails when you forget your password. Search your email inbox for any messages from the
          website or platform you're using.
        </p>
        <p className="pb-2">
          <strong>2. Password manager:</strong> If you use a password manager, such as LastPass or
          1Password, check if it has stored the password for your website. Look for the website name
          in your password manager's vault.
        </p>
        <p className="pb-2">
          <strong>3. Website settings:</strong> Login to your website's administrative dashboard and
          navigate to the settings section. Look for a "Password" or "Security" tab where you might
          find options to view or change your password.
        </p>
        <p className="pb-2">
          <strong>4. Contact support:</strong> If you've tried the above steps and still can't find
          your password, reach out to the website's support team. They can assist you in recovering
          or resetting your password.
        </p>
      </>
    )
  },
  cms: {
    title: 'Website CMS',
    content: (
      <>
        <p className="pb-2">
          To identify your CMS, you can usually check the <strong>administrative dashboard</strong>{' '}
          or the <strong>footer</strong> of your website.
        </p>
        <p className="pb-2">
          Look for familiar logos or names such as WordPress, Joomla, Drupal, Shopify, or Wix.
        </p>
        <p className="pb-2">
          If you're unsure or can't find it, don't worry! Just select 'I don't know' from the
          options provided, and we'll assist you further.
        </p>
      </>
    )
  }
}

type ModalType = keyof typeof modalData

interface LinkWebsiteProps {
  onComplete: () => void
}

export function LinkWebsite({ onComplete }: LinkWebsiteProps) {
  const dispatch = useDispatch()

  const [website, setWebsite] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [cms, setCms] = useState('')
  const [modalType, setModalType] = useState<ModalType | null>(null)
  const [isExiting, setIsExiting] = useState(false)

  const [checkWebsite, checkWebsiteDetails] = useCheckWebsiteMutation()
  const [getCms] = useGetCmsMutation()

  let debouncedCms = null

  const checkIfStepSkipped = async () => {
    try {
      const url = JSON.parse(localStorage.getItem('userDetails'))?.website
      if (!url) return

      const { data } = await checkWebsite({
        url,
        textArr: ['GTM', 'UA', 'googletagmanager']
      })

      if (data === 200) {
        onComplete()
      }
    } catch (error) {
      // Do nothing
    }
  }

  const checkCms = async () => {
    const { data } = await getCms(website)
    const cmsName = data?.name || ''
    const inList = cmsOptions.some((option) => option.value === cmsName)
    if (inList) {
      setCms(cmsName)
    }
  }

  useEffect(() => {
    checkIfStepSkipped()
  }, [])

  useEffect(() => {
    const urlRegex = /(http(s)?:\/\/.)?(www\.)?[a-z0-9-]{2,}\.[a-z]{2,6}/i
    if (!urlRegex.test(website)) {
      return
    }

    clearTimeout(debouncedCms)
    debouncedCms = setTimeout(checkCms, 2000)

    return () => clearTimeout(debouncedCms)
  }, [website])

  const closeModal = () => {
    setIsExiting(true) // start exit animation
    setTimeout(() => {}, 150) // delay to match exit animation duration
    setModalType(null)}

  const [createWebsite, { isLoading }] = useCreateWebsiteMutation()

  const onCreateWebsiteClick = async () => {
    if (website == '' || username == '' || password == '' || cms == '') {
      dispatch(
        addToast({
          title: 'Error',
          subtext: 'Please fill out all fields before continuing',
          type: 'error'
        })
      )
      return
    }

    const response = await createWebsite({
      username,
      password,
      type: cms,
      loginURL: website
    })

    if (!response?.data || response.data.error) {
      dispatch(
        addToast({
          title: 'Can not save website details',
          subtext: 'Please try again or contact support',
          type: 'error'
        })
      )
      return
    }

    onComplete()
  }

  if (checkWebsiteDetails.isLoading) {
    return <LoadingCard />
  }

  return (
    <>
      <FormTitle
        title="Let's link your website to your dashboard"
        description="Here, you can see how your website's SEO is performing"
      />
      <div className="w-[344px] grid grid-rows-[66px,66px,1fr] gap-[24px] pt-[16px]">
        <InputField
          name="website"
          value={website}
          label="Website"
          type="text"
          onChange={setWebsite}
        />
        <InputField
          name="username"
          value={username}
          label="Website Username"
          type="text"
          onChange={setUsername}
          openModal={() => setModalType('username')}
        />
        <InputField
          name="password"
          value={password}
          label="Website Password"
          type="password"
          onChange={setPassword}
          openModal={() => setModalType('password')}
        />
        <SelectField
          name="cms"
          value={cms}
          options={cmsOptions}
          label="Website CMS"
          onChange={setCms}
          openModal={() => setModalType('cms')}
        />
        <SubmitButton
          showNext={true}
          label="Continue to Step 3"
          isLoading={isLoading}
          onClick={onCreateWebsiteClick}
        />
      </div>
      <SkipButton onClick={onComplete} />

      {modalType && (
        <Modal handleClose={closeModal} isExiting={isExiting}>
          <div className="z-20 flex flex-col p-[24px]">
            <header className="flex justify-between mb-5">
              <h3 className="text-start text-gray-900 dark:text-gray-100 flex flex-grow font-inter font-[600] text-[24px] justify-start leading-7">
                {modalData[modalType].title}
              </h3>
              <ButtonClose onClick={closeModal} />
            </header>
            <div className="dark:text-gray-400 text-gray-600 text-left text-[14px] font-[400] leading-5">
              {modalData[modalType].content}
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
