import React from 'react'
import { TableRowCell } from '../../Table'
import { addOrdinalSuffix } from '../../../helpers/addOrdinalSuffix'
import { StarIcon } from '../StarIcon'
import { TrendIcon } from '../TrendIcon'
import { downTrendStyling } from '../../../helpers/downTrendStyling'

export function TableRow({ keyword, rankings }) {
  const { Name, Google_Current__c, Google_DaysAgo7__c, Google_DaysAgo28__c } = keyword
  const { starKeywordsOutlineTop10, starKeywordsFullTop10 } = rankings

  const isDownTrend = Google_DaysAgo28__c - Google_Current__c < 0
  const tooltip = isDownTrend ? starKeywordsOutlineTop10 : starKeywordsFullTop10

  return (
    <tr className="table-tr">
      <td className="p-4 text-left flex gap-[9px]">
        {Google_Current__c && Google_Current__c < 10 && (
          <StarIcon
            isDownTrend={isDownTrend}
            tooltip={tooltip.replace(/{{position}}/g, Google_Current__c)}
          />
        )}
        <span>{Name}</span>
        <TrendIcon isDownTrend={isDownTrend} />
      </td>
      <TableRowCell label={Google_Current__c >= 100 ? '' : addOrdinalSuffix(Google_Current__c)} />
      <TableRowCell label={addOrdinalSuffix(Google_DaysAgo7__c)} />
      <TableRowCell
        label={
          Google_DaysAgo28__c === 100 || !Google_DaysAgo28__c
            ? ''
            : addOrdinalSuffix(Google_DaysAgo28__c)
        }
      />
      <TableRowCell
        label={Google_DaysAgo28__c - Google_Current__c}
        labelClassName={downTrendStyling(isDownTrend)}
      />
    </tr>
  )
}
