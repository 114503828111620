import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NoDataMessage } from '../ads/ProductMessages'
import { LoadingCardLong } from '../Loading'
import { CoreMetrics } from '../CoreMetrics'
import { AccountSelector } from '../AccountSelector'
import { Copied } from '../Popover'
import { cache } from '../../services/cache'
import { useGetAdsReportsQuery } from '../../services/reports'
import { isProductActive } from '../../helpers/isProductActive'

export function AdsDashboard() {
  const customer = useSelector((state) => state.global.customer)
  const dateFilter = useSelector((store) => store.global.dateFilter)
  const adsId = useSelector((store) => store.ads.selectedAccountId)
  const activeProducts = useSelector((store) => store.global.customer.activeProducts)

  const adsProduct =
    activeProducts.find(
      (product) =>
        isProductActive(product?.adWordsManagement?.status || '') &&
        product?.adWordsManagement?.signupDate !== null &&
        product?.adWordsManagement?.adsId === adsId
    )?.adWordsManagement || null

  const { from, to } = dateFilter

  if (!customer.adsData) {
    return <NoDataMessage platform="Google" />
  }

  useEffect(() => {
    cache.adsReports.use({ adsId, from, to })
  }, [adsId, from, to])

  const { data, isLoading } = useGetAdsReportsQuery(
    { adsId, from, to },
    { skip: !(adsId && from && to) }
  )

  return (
    <>
      <div className="flex flex-row items-center pt-[16px] pb-[24px]">
        <h1 className="font-inter text-[24px] text-gray-900 dark:text-gray-100 font-[600] leading-[32px] pr-[16px]">
          Google Ads Summary
        </h1>
        <AccountSelector type="google" />
        <Copied toCopy={adsId} />
      </div>
      <div
        className={`gap-4 grid grid-cols-1 ${isLoading ? 'bg-white dark:bg-gray-800 dark:text-white rounded-[16px] p-[16px] sm:p-[24px]' : 'md:grid-cols-2 '} w-full`}
      >
        {isLoading && <LoadingCardLong />}
        {!isLoading && adsProduct && (
          <CoreMetrics fromDate={from} toDate={to} data={data} adsProduct={adsProduct} />
        )}
      </div>
    </>
  )
}
