import React from 'react'
import { Box, Pagination } from '@mui/material'

interface PaginationProps {
  total: number
  page: number
  itemsPerPage: number
  setPage: (value: number) => void
  setItemsPerPage: (value: number) => void
}

export function NewPagination({
  total,
  page,
  itemsPerPage,
  setPage,
  setItemsPerPage
}: PaginationProps) {
  const handleItemChange = (e) => {
    setItemsPerPage(e.target.value)
    setPage(1) // reset page otherwise they will stay on an unreachable page
  }

  return (
    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span className="pr-[14px] font-[400] text-gray-500 dark:text-gray-400 text-[14px]">
        {total} total result{total > 1 ? 's' : ''}
      </span>
      <div className="flex justify-end items-center">
        <select
          className="w-fit flex gap-[10px] pl-[16px] pr-[12px] py-[6px] rounded-[24px] font-[500] text-[14px]
            text-gray-500 dark:text-gray-300 bg-gray-100 hover:bg-gray-200 hover:text-gray-600 dark:bg-slate-700 hover:dark:bg-slate-600 hover:dark-text-gray-200
            transition duration-100 border-0 cursor-pointer"
          value={itemsPerPage}
          onChange={handleItemChange}
        >
          {[10, 50, 100, 200].map((value) => (
            <option key={value} value={value}>
              Show {value} Results
            </option>
          ))}
        </select>
        <Pagination
          count={Math.ceil(total / itemsPerPage)}
          page={page}
          onChange={(event, value) => setPage(value)}
        />
      </div>
    </Box>
  )
}
