import React from 'react'
import { useSelector } from 'react-redux'

export function PrivacyPolicy() {
  const brand = useSelector((state) => state.global.brand)

  return (
    <span className="font-normal text-sm leading-5 text-gray-600 dark:text-primary-600 w-[344px] lg:w-full">
      By continuing, you agree to our&nbsp;
      <a
        className="underline hover:text-blue-700"
        rel="noreferrer"
        target="_blank"
        href={`https://www.${brand.domain}/privacy-policy/`}
      >
        Privacy Policy
      </a>
      &nbsp;and&nbsp;
      <a
        className="underline hover:text-blue-700"
        rel="noreferrer"
        target="_blank"
        href={`https://www.${brand.domain}/terms/`}
      >
        Terms of Service
      </a>
    </span>
  )
}
