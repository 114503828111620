import React from 'react'
import { useSelector } from 'react-redux'
import { SmallNotification } from './SmallNotification'
import { FullNotification } from './FullNotification'

interface NotificationSystemProps {
  fullSize: boolean
  setFullSize: (fullSize: boolean) => void
}

export function NotificationSystem({ fullSize = false, setFullSize }: NotificationSystemProps) {
  const notifData = useSelector((store) => store.notifications.messages) || []

  return fullSize ? (
    <FullNotification setFullSize={setFullSize} notifData={notifData} />
  ) : (
    <SmallNotification setFullSize={setFullSize} notifData={notifData} />
  )
}
