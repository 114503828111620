import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ConversionChart } from '../../Chart/ConversionChart'
import { adsFindTerm } from '../../../helpers/adsFindTerm'
import { QuestionMarkIcon } from '../../Icons'
import { Tooltip } from '@mui/material'
import { Tile } from '../Tile'
import type { ConversionChartMetric } from '../../Chart/ConversionChart'

export type Metrics = ConversionChartMetric & {
  value: string
  subValue?: number
}

interface ConversionMetricsProps {
  metrics: Metrics[]
  adsProduct: {
    signupDate: string
    setupCompletedDate: string
  }
  fromDate: string
  toDate: string
}

export function ConversionMetrics({
  metrics,
  adsProduct,
  fromDate,
  toDate
}: ConversionMetricsProps) {
  const [chartFilter, setChartFilter] = useState(
    metrics.reduce((acc, metric) => {
      acc[metric.title] = metric.active
      return acc
    }, {})
  )
  const [animationKey, setAnimationKey] = useState(0)
  const { adsId, campaignId } = useParams()

  useEffect(() => {
    setAnimationKey(animationKey + 1)
  }, [adsId, campaignId])

  const toggleChartFilter = (filter: string) => {
    setChartFilter({
      ...chartFilter,
      [filter]: !chartFilter[filter]
    })
  }

  const filteredMetrics = metrics.map((metric) => ({
    ...metric,
    active: !!chartFilter[metric.title]
  }))

  return (
    <div
      key={animationKey}
      className="animate-slide-left col-span-full bg-white dark:bg-gray-800 dark:text-white rounded-xl p-[16px] sm:p-[24px] flex gap-[24px] flex-col"
    >
      <div className="flex items-center justify-items-end justify-between">
        <div className="flex flex-row gap-[5px]">
          <span className="flex-1 font-inter font-[600] text-[18px] text-gray-900 dark:text-gray-100">
            Conversion Metrics
          </span>
          <Tooltip title={adsFindTerm('conversions')}>
            <span className="content-center cursor-pointer">
              <QuestionMarkIcon className="w-5 h-5 stroke-[#98A2B3] hover:stroke-[#575d69]" />
            </span>
          </Tooltip>
        </div>
      </div>
      <div className="flex flex-row flex-grow gap-4 flex-wrap">
        {filteredMetrics.map((metric, index) => (
          <Tile
            key={metric.title}
            title={metric.title}
            value={metric.value}
            subValue={metric.subValue || 0}
            isActive={metric.active}
            onClick={toggleChartFilter}
            color={metric.areaColor}
          />
        ))}
      </div>
      <ConversionChart
        metrics={filteredMetrics}
        adsProduct={adsProduct}
        fromDate={fromDate}
        toDate={toDate}
      />
    </div>
  )
}
