import React from 'react'

export function ConnectedTick() {
  return (
    <div className="flex flex-row items-center gap-[5px]">
      <svg
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.1665 5.83334L4.49984 9.16668L12.8332 0.833344"
          stroke="#3CB371"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span className="text-gray-900 text-base dark:text-white font-semibold leading-6">
        Connected
      </span>
    </div>
  )
}
