import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BellIcon } from '../Icons'
import { NotificationSystem } from './NotificationSystem'
import { Tooltip } from '@mui/material'
import { modalEventHandler } from '../../../js/helpers/modalEventHandler'

interface BellButtonProps {
  setFullSize: (fullSize: boolean) => void
}

export function BellButton({ setFullSize }: BellButtonProps) {
  const [open, setOpen] = useState(false)
  const popupRef = useRef(null)

  const notifCount = useSelector((store) => store.notifications.messages.length)
  const brand = useSelector((store) => store.global.brand.name)

  const handleOpenClick = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    return modalEventHandler(open, handleOpenClick)
  }, [open])

  return (
    <>
      <Tooltip title="Notifications">
        <div
          onClick={() => setOpen(!open)}
          ref={popupRef}
          className={`
              h-[40px] w-[40px] self-center cursor-pointer rounded-[24px] text-gray-700 focus:outline-none focus:ring-0 hover:bg-secondary-100 hover:text-primary-500 dark:text-base-300 dark:focus:ring-0 dark:hover:bg-primary-600 active:scale-95 transition duration-100 
              ${brand === 'jdm' ? 'dark:hover:text-black' : 'dark:hover:text-base-50'}`}
        >
          <BellIcon className="relative left-[10px] top-[10px] w-[20px] h-[20px]" />
          {notifCount > 0 && (
            <span className="relative left-[20px] bottom-[22px] block select-none w-fit h-auto font-inter text-white text-[12px] font-[500] leading-[18px] user-select-none rounded-full px-[5px] py-0 border-[1px] border-error-600 bg-error-600">
              {notifCount}
            </span>
          )}
        </div>
      </Tooltip>
      {open && <NotificationSystem setFullSize={setFullSize} />}
    </>
  )
}
