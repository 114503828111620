import React, { useId } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip as ReactTooltip } from 'react-tooltip'

interface TooltipProps {
  content: string
  children: React.ReactNode
  element?: React.ElementType
  className?: string
}

export function Tooltip({ content, children, element = 'span', className = '' }: TooltipProps) {
  const id = useId()
  const darkMode = useSelector((store) => store.global.darkMode)

  const Container = element

  return (
    <>
      <Container
        data-tooltip-id={id}
        className={`cursor-pointer relative ${className}`}
        style={{ position: 'relative', zIndex: 1 }}
      >
        {children}
      </Container>
      <ReactTooltip
        id={id}
        content={content}
        variant={darkMode ? 'light' : 'dark'}
        wrapper="span"
        className="max-w-xs whitespace-normal z-[1000]"
      />
    </>
  )
}
