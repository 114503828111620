import React from 'react'
import { useSelector } from 'react-redux'
import { PhoneIcon } from '../Icons'

export function BookingButton({ onClick, mini = false }) {
  const { buttonText } = window?.t?.dashboard?.modal || {}
  const brand = useSelector((store) => store.global.brand.name)

  return (
    <button
      onClick={onClick}
      className={`
      ${mini ? 'p-[10px] rounded-[12px] w-fit' : 'w-full  py-[10px] px-[16px] rounded-[8px]'} flex items-center justify-center dark:bg-primary-300 dark:hover:bg-primary-500 shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] font-inter text-sm font-semibold transition-all bg-opacity-100 ease-cubic-bezier[.785,.135,.15,.86] active:scale-95 duration-100 delay-0
      ${brand === 'jdm' ? 'text-black bg-primary-400 hover:bg-primary-500' : 'text-white dark:text-primary-900 bg-primary-700 hover:bg-primary-900'}`}
    >
      {mini && <PhoneIcon />}
      {!mini && buttonText}
    </button>
  )
}
