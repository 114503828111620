import React from 'react'

export function FilterIcon({ className = 'h-6 w-6 dark:text-base-50' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      x="0"
      y="0"
      className={className}
    >
      <path
        d="M384-264v-72h192v72H384ZM240-444v-72h480v72H240Zm-96-180v-72h672v72H144Z"
        fill="currentColor"
      />
    </svg>
  )
}
