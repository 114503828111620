import React from 'react'

interface ConnectFacebookItemProps {
  name: string
  isLoading: boolean
  items: {
    name: string
    connected: boolean
  }[]
}
const Spinner = (
  <svg
    className="self-center animate-spin h-4 w-4 text-gray-500 dark:text-gray-200"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
)

const Tick = (
  <svg
    className="mr-[2px]"
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.1665 5.83334L4.49984 9.16668L12.8332 0.833344"
      stroke="#3CB371"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const Cross = (
  <svg
    width="19"
    height="19"
    viewBox="0 -960 960 960"
    fill="#FF4500"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z" />
  </svg>
)

export function ConnectFacebookItem({ name, isLoading, items }: ConnectFacebookItemProps) {
  return (
    <div>
      <span className="font-bold">{name}</span>
      {items.length === 0 && <p>No {name.toLowerCase()} detected</p>}
      {items.map(({ name, connected }) => (
        <div className="flex items-center justify-between">
          <span>{name}</span>
          {isLoading ? Spinner : connected ? Tick : Cross}
        </div>
      ))}
    </div>
  )
}
