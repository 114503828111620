import React from 'react'
import { ProductMessage } from '../../ProductMessage'

interface SecondMonthProps {
  onHideClick: () => void
}

export function SecondMonth({ onHideClick }: SecondMonthProps) {
  return (
    <ProductMessage title="Second month of Ads" onHideClick={onHideClick}>
      <>
        <p className="paragraph-text">
          Month two is all about optimisation and strategy. We'll look at what's working well and
          identify any areas for improvement.
        </p>
        <p className="paragraph-text">
          Our team will conduct multiple bid analyses across devices, including looking at
          geolocation, audience, ad schedule, and tracking. We'll take another look at your products
          and begin sorting them into appropriate ad groups. During this process, we'll take a look
          at your lowest and highest-performing products, identifying any opportunities for
          improvement.
        </p>
        <p className="paragraph-text">
          Then, we'll touch base with you to discuss any potential adjustments. If you have anything
          new you'd like to highlight to your audience, we can go over that, too, ensuring your
          shopping campaign reflects your overall business goals. Make sure to check your dashboard
          regularly to see any updates or account changes.
        </p>
      </>
    </ProductMessage>
  )
}
