import React from 'react'
import { Table, TableRowEmpty } from '../../Table'
import { TableRow } from './TableRow'
import { TableHead } from './TableHead'
import type { SearchTermMetric } from '../types'

interface SearchTermsTableProps {
  list: SearchTermMetric[]
}

export function SearchTermsTable({ list }: SearchTermsTableProps) {
  const rows = list.map((item, key) => <TableRow key={key} item={item} />)

  return (
    <Table
      head={<TableHead />}
      rows={
        rows.length > 0 ? (
          rows
        ) : (
          <TableRowEmpty colSpan={8} text="No data for the selected period or no active ads." />
        )
      }
    />
  )
}
