import React from 'react'
import { TableRowCell } from '../../Table'
import { downTrendStyling } from '../../../helpers/downTrendStyling'
import { convertAdScheduleTime } from '../../../helpers/convertAdScheduleTime'
import type { AdScheduleMetric } from '../types'

interface TableRowProps {
  item: AdScheduleMetric
}

export function TableRow({ item }: TableRowProps) {
  const {
    dayOfWeek,
    startHour,
    startMinute,
    endHour,
    endMinute,
    bidAdjustment,
    adSpend,
    ctr,
    cpc,
    cr,
    clicks,
    conversions,
    conversionsValue,
    impressions,
    costPerConv
  } = item

  return (
    <tr className="table-tr">
      <TableRowCell
        label={convertAdScheduleTime(dayOfWeek, startHour, startMinute, endHour, endMinute)}
        className="p-4 text-left normal-case"
      />
      <TableRowCell label={(bidAdjustment || 0).toLocaleString()} />
      <TableRowCell label={(clicks || 0).toLocaleString()} />
      <TableRowCell label={(impressions || 0).toLocaleString()} />
      <TableRowCell label={`${ctr || 0}%`} />
      <TableRowCell label={`$${cpc || 0}`} />
      <TableRowCell label={`$${adSpend || 0}`} />
      <TableRowCell label={`${cr || 0}%`} />
      <TableRowCell
        label={Math.round(conversions || 0).toLocaleString()}
        labelClassName={downTrendStyling((conversions || 0) === 0)}
      />
      <TableRowCell label={`$${(conversionsValue || 0).toLocaleString()}`} />
      <TableRowCell label={`$${(costPerConv || 0).toLocaleString()}`} />
    </tr>
  )
}
