import React, { useState, useEffect } from 'react'

export function TemporaryButton() {
  return (
    <a
      href={`${window.location.href.split('cpanel')[0]}onboarding`}
      className="cursor-pointer select-none font-[600] ml-[10px] text-[19px] py-[10px] px-[20px] bg-blue-600 text-white rounded-[12px] shadow-sm active:scale-95 hover:bg-blue-800"
    >
      Connect A Facebook Account
    </a>
  )
}
