import React, { useState } from 'react'
import { Panel } from '../Panel'
import { useSelector } from 'react-redux'
import { PlatformLogo } from './PlatformLogo'
import { AddAnAccount } from './AddAnAccount'
import { ConnectedAccounts } from './ConnectedAccounts'

export function Connections() {
  const [adAccounts, setAdAccounts] = useState({
    google: useSelector((store) => store.global.customer.adsAccounts),
    facebook: useSelector((store) => store.global.customer.facebookAccounts)
    // microsoft: '' //TODO: enable when microsoft has been connected to dashboard
  })
  const [activeAccounts, setActiveAccounts] = useState({
    google: useSelector((store) => store.global.customer.adsActive),
    facebook: useSelector((store) => store.global.customer.facebookActive)
    // microsoft: '' //TODO: enable when microsoft has been connected to dashboard
  })
  const notifications = useSelector((store) => store.notifications.messages) || []

  return (
    <Panel title="Connections">
      <p className="-mt-4 mb-1 text-sm font-normal text-base-400 dark:text-gray-400 italic">
        Connect your Ads Accounts
      </p>
      {/* Google */}
      <div
        className={`flex flex-wrap justify-between gap-[20px] border-b-[1px] border-gray-300 dark:border-gray-700 border-opacity-[40%] py-[24px] ${!activeAccounts.google ? 'blur-sm cursor-not-allowed disabled select-none' : ''}`}
      >
        <div className="flex flex-row mx-[16px] items-center">
          <PlatformLogo platform="Google" />
        </div>
        {activeAccounts.google && (
          <>
            {notifications.filter((notif) => notif.title === 'Connect Google Ads').length > 0 ? ( // add another account btn, and also display connected accounts
              <>
                {adAccounts.google.length > 0 && <ConnectedAccounts accounts={adAccounts.google} />}
                <AddAnAccount
                  platform="Google"
                  adAccounts={adAccounts}
                  setAdAccounts={setAdAccounts}
                />
              </>
            ) : (
              // else show connected accounts
              <ConnectedAccounts accounts={adAccounts.google} />
            )}
          </>
        )}
      </div>
      {/* Facebook */}
      <div
        className={`flex flex-wrap justify-between gap-[20px] border-b-[1px] border-gray-300 dark:border-gray-700 border-opacity-[40%] py-[24px] ${!activeAccounts.facebook ? 'blur-sm cursor-not-allowed disabled select-none' : ''}`}
      >
        <div className="flex flex-row mx-[16px] items-center">
          <PlatformLogo platform="Facebook" />
        </div>
        {activeAccounts.facebook && (
          <>
            {notifications.filter((notif) => notif.title === 'Connect Meta Ads').length > 0 ? ( // add another account btn, and also display connected accounts
              <>
                {adAccounts.facebook.length > 0 && (
                  <ConnectedAccounts accounts={adAccounts.facebook} />
                )}
                <AddAnAccount platform="Facebook" />
              </>
            ) : (
              // else show connected accounts
              <ConnectedAccounts accounts={adAccounts.facebook} />
            )}
          </>
        )}
      </div>
      {/* Microsoft (not enabled yet) */}
      <div className="flex flex-col gap-[20px] border-b-[1px] border-gray-300 dark:border-gray-700 border-opacity-[40%] py-[24px] blur-sm cursor-not-allowed disabled select-none">
        <div className="flex flex-row mx-[16px] items-center">
          <PlatformLogo platform="Microsoft" />
        </div>
      </div>
    </Panel>
  )
}
