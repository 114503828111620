import React from 'react'
import { passwordRules } from '../../../../utils/validators'

interface PasswordRulesProps {
  password: string
}

export function PasswordRules({ password }: PasswordRulesProps) {
  return (
    <>
      <span className="font-inter text-[16px] font-[400] leading-[24px] text-gray-600 dark:text-gray-400">
        In order to protect your account, make sure your password:
      </span>
      <ol className="space-y-2 text-base-900 dark:text-base-50">
        {passwordRules.map(({ label, pattern }) => (
          <li key={label} className="flex text-4xl font-normal items-center">
            <span
              className={`mr-2 flex h-4 w-full max-w-[16px] items-center justify-center rounded-full text-base text-white dark:text-primary-900
              ${password && pattern.test(password) ? 'bg-primary-500' : 'bg-secondary-200'}
            `}
            />
            <span className="text-xs items-center">{label}</span>
          </li>
        ))}
      </ol>
    </>
  )
}
