import React from 'react'
import { useSelector } from 'react-redux'
import { Tab } from '.'
import { ThemeToggler } from '../ThemeToggler'
import { useThemeContext } from '../../ThemeContext'

interface HeaderProps {
  currentPage: number
  setPage: (page: number) => void
}

const pages = ['Create Password', 'Link Website', 'Connect Accounts']

export function Header({ currentPage, setPage }: HeaderProps) {
  const brand = useSelector((state) => state.global.brand)
  const theme = useThemeContext()

  return (
    <header className="h-138px lg:h-85px bg-white dark:bg-neutral-900 grid lg:grid-cols-[1fr] grid-rows-[1fr,1fr] lg:grid-rows-[1fr] items-center border-b-[1px] cursor-default">
      <img
        className="w-[110px] lg:w-[131px] lg:absolute mx-[24px] lg:mx-[32px] mt-[17px] mb-[0px] lg:mb-[17px]"
        src={theme.isDarkMode ? brand.logoDark : brand.logo}
        alt={`${brand.name} Logo`}
      />
      <div className="animate-fade-in justify-self-center self-end flex lg:row-end-[2]">
        {pages.map((title, index) => (
          <Tab
            key={title}
            page={index + 1}
            isActive={currentPage === index + 1}
            onClick={setPage}
            title={title}
          />
        ))}
      </div>
      <div className="absolute top-5 right-5">
        <ThemeToggler />
      </div>
    </header>
  )
}
