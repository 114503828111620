import React from 'react'

interface TableRowCellProps {
  label: string | number
  className?: string | null
  labelClassName?: string | null
  isLink?: boolean
}

const LinkableContent = ({ url }: { url: string }) => (
  <a
    target="_blank"
    rel="noreferrer"
    href={url}
    className="w-fit text-primary-600 dark:text-primary-500 underline font-[400] text-[14px] leading-[18px] normal-case truncate block max-w-[200px] md:max-[300px]"
  >
    {url}
  </a>
)

export function TableRowCell({
  label,
  className = 'p-4',
  labelClassName = '',
  isLink = false
}: TableRowCellProps) {
  return (
    <td className={className}>
      {isLink ? (
        <LinkableContent url={label.toString()} />
      ) : (
        <span className={labelClassName}>{label}</span>
      )}
    </td>
  )
}
