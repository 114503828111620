import React from 'react'
import { Tooltip } from '../../Tooltip'
import { seoRankingTooltip } from '../../../helpers/seoRankingTooltip'

export function BacklinkProfilePanel({ title, tooltip, className, children }) {
  return (
    <div className="p-4 md:p-6">
      <dt className="tracking-tight text-sm md:text-base font-normal text-gray-900 dark:text-base-50">
        <Tooltip content={seoRankingTooltip(tooltip)}>{title}</Tooltip>
      </dt>
      <dd className={`flex mt-1 ${className}`}>{children}</dd>
    </div>
  )
}
