import React from 'react'
import { PreferenceOption } from './PreferenceOption'
import { ThemeToggler } from '../../ThemeToggler'

export function DarkModeOption() {
  return (
    <PreferenceOption title="Dark Mode" description="Adjust theme to use dark mode">
      <ThemeToggler />
    </PreferenceOption>
  )
}
