import { createSlice } from '@reduxjs/toolkit'
import { getDateRangeFromPeriod } from '../helpers/getDateRangeFromPeriod'

const isDarkMode = () => {
  if (localStorage.getItem('darkMode')) {
    return JSON.parse(localStorage.getItem('darkMode'))
  }

  return window.matchMedia('(prefers-color-scheme: dark)').matches
}

const getDatePeriod = () => {
  if (localStorage.getItem('datePeriod.selected')) {
    const { period, period_many } = JSON.parse(localStorage.getItem('datePeriod.selected'))
    return getDateRangeFromPeriod(period_many, period)
  }

  return getDateRangeFromPeriod(1, 'month')
}

const initialState = {
  customer: {
    id: window.customer.id,
    name: window.customer.name,
    seoActive: window.customer.seoActive,
    facebookActive: window.customer.facebookActive,
    facebookData: window.customer.facebookData,
    adsActive: window.customer.adsActive,
    adsData: window.customer.adsData,
    seoAccounts: window.customer.seoAccounts,
    adsAccounts: localStorage.getItem('adsAccounts')
      ? JSON.parse(localStorage.getItem('adsAccounts'))
      : window.customer.adsAccounts,
    facebookAccounts: localStorage.getItem('facebookAccounts')
      ? JSON.parse(localStorage.getItem('facebookAccounts'))
      : window.customer.facebookAccounts,
    activeProducts: localStorage.getItem('activeProducts')
      ? JSON.parse(localStorage.getItem('activeProducts'))
      : window.customer.activeProducts,
    temp: window.customer.temp,
    registeredForTax: window.customer.registeredForTax
  },
  brand: window.brand,
  page: window.page,
  locale:
    window.customer?.locale ||
    window.page?.reports?.customer?.locale ||
    navigator?.language ||
    'en-AU',
  currency: window.customer?.currencyCode || window.page?.reports?.customer?.currencyCode || 'AUD',
  darkMode: isDarkMode(),
  dateFilter: getDatePeriod(),
  toasts: [],
  timezone: localStorage.getItem('selectedTimezone') || 'Australia/Brisbane',
  budgets: {
    conversions: localStorage.getItem('budgets.conversions') || 5.0,
    cpc: localStorage.getItem('budgets.cpc') || 5.0
  },
  totalOutstanding: localStorage.getItem('totalOutstanding') || 0
}

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setLocale: (state, { payload }) => ({
      ...state,
      locale: payload
    }),
    setCurrency: (state, { payload }) => ({
      ...state,
      currency: payload
    }),
    toggleDarkMode: (state) => {
      const darkMode = !state.darkMode

      localStorage.setItem('darkMode', darkMode)

      if (document) {
        document.documentElement.classList.toggle('dark')
      }

      return {
        ...state,
        darkMode
      }
    },
    setDateFilter: (state, { payload }) => {
      const { period_many, period } = payload
      localStorage.setItem('datePeriod.selected', JSON.stringify(payload))

      return {
        ...state,
        dateFilter: getDateRangeFromPeriod(period_many, period)
      }
    },
    setTotalOutstanding: (state, { payload }) => {
      localStorage.setItem('totalOutstanding', JSON.stringify(payload))

      return {
        ...state,
        totalOutstanding: payload
      }
    },
    addToast: (state, { payload }) => {
      const id = Math.random().toString(36).substr(2, 9)

      return {
        ...state,
        toasts: [...state.toasts, { id, ...payload }]
      }
    },
    removeToast: (state, { payload }) => ({
      ...state,
      toasts: state.toasts.filter((alert) => alert.id !== payload)
    }),
    addGoogleAdsAccount: (state, { payload }) => ({
      ...state,
      customer: {
        ...state.customer,
        adsAccounts: [...state.customer.adsAccounts, payload]
      }
    }),
    setTimezone: (state, { payload }) => {
      localStorage.setItem('selectedTimezone', payload)

      return {
        ...state,
        timezone: payload
      }
    },
    setBudgets: (state, { payload }) => {
      const { type, value } = payload
      const valueAsNumber = Number(value)

      localStorage.setItem(`budgets.${type}`, valueAsNumber)

      return {
        ...state,
        budgets: {
          ...state.budgets,
          [type]: valueAsNumber
        }
      }
    }
  }
})

export const {
  setProductData,
  setLocale,
  setCurrency,
  toggleDarkMode,
  setDateFilter,
  setTotalOutstanding,
  addToast,
  setTimezone,
  setBudgets,
  addGoogleAdsAccount
} = slice.actions

export const { reducer: global } = slice
