import React from 'react'
import { ProductMessage } from '../../ProductMessage'

export function FirstMonth({ onHideClick }) {
  return (
    <ProductMessage title="Welcome to the First month of SEO" onHideClick={onHideClick}>
      <>
        <p className="paragraph-text">
          Now that we understand the ins and outs of your site, we're now implementing successful
          SEO strategies on your homepage, as it's often the strongest page on your site.
        </p>
        <p className="paragraph-text">
          We'll fix or add your title tag and meta description, optimise image tags, begin
          link-building and conduct any necessary keyword fixes across the site. We'll develop
          meaningful, relevant, and high-quality content, incorporating chosen keywords throughout.
          Depending on our research, this content will either expand on existing copy or replace it
          entirely. Additionally, we'll ensure all page headers are accurate and up-to-date. You can
          see all this happening in real-time on your dashboard below.
        </p>
        <p className="paragraph-text">
          Remember, search rankings don't change overnight, but it will happen! Typically our SEO
          work will take several months for search engine results to really change, and that's when
          the real magic beings.
        </p>
      </>
    </ProductMessage>
  )
}
