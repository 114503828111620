import React from 'react'
import { ReportHeader } from '../ReportHeader'
import { LoadingTable } from '../../Loading'

export function Panel({ title, help, loading, children }) {
  return (
    <div className="rounded-[16px] bg-white p-4 dark:border-none dark:bg-gray-800 sm:p-6 xl:p-8 mb-4">
      <ReportHeader title={title} help={help} />
      <div className="flex flex-col">
        {loading && <LoadingTable />}
        {!loading && children}
      </div>
    </div>
  )
}
