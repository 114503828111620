import React from 'react'

interface PhoneIconProps {
  className?: string
}

export function PhoneIcon({ className = 'h-[20px] w-[20px]' }: PhoneIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" fill="none" className={className}>
      <path
        d="M7.48356 7.37767C8.06356 8.58569 8.85422 9.71789 9.85553 10.7192C10.8568 11.7205 11.9891 12.5112 13.1971 13.0912C13.301 13.1411 13.3529 13.166 13.4187 13.1852C13.6523 13.2533 13.9392 13.2044 14.137 13.0627C14.1927 13.0228 14.2403 12.9752 14.3356 12.8799C14.6269 12.5886 14.7726 12.4429 14.9191 12.3477C15.4715 11.9885 16.1837 11.9885 16.7361 12.3477C16.8825 12.4429 17.0282 12.5886 17.3196 12.8799L17.4819 13.0423C17.9248 13.4852 18.1462 13.7066 18.2665 13.9444C18.5058 14.4174 18.5058 14.976 18.2665 15.4489C18.1462 15.6867 17.9248 15.9082 17.4819 16.351L17.3506 16.4824C16.9092 16.9238 16.6886 17.1444 16.3885 17.313C16.0556 17.5 15.5385 17.6345 15.1567 17.6333C14.8126 17.6323 14.5774 17.5655 14.107 17.432C11.5792 16.7146 9.19387 15.3608 7.20388 13.3709C5.2139 11.3809 3.86017 8.99557 3.1427 6.46774C3.00919 5.99737 2.94244 5.76218 2.94141 5.41806C2.94028 5.03621 3.07475 4.51913 3.26176 4.18621C3.4303 3.88618 3.65098 3.66551 4.09233 3.22416L4.22369 3.09279C4.66656 2.64992 4.888 2.42849 5.12581 2.3082C5.59878 2.06898 6.15734 2.06898 6.6303 2.3082C6.86812 2.42849 7.08955 2.64992 7.53242 3.09279L7.69481 3.25518C7.98615 3.54652 8.13182 3.69219 8.22706 3.83867C8.58622 4.39108 8.58622 5.10323 8.22706 5.65564C8.13182 5.80212 7.98615 5.94779 7.69481 6.23913C7.59955 6.33439 7.55192 6.38202 7.51206 6.4377C7.37038 6.63556 7.32146 6.92244 7.38957 7.15607C7.40873 7.22181 7.43367 7.27376 7.48356 7.37767Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
