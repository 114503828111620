import { api } from './api'

export const ticketsApi = api.injectEndpoints({
  endpoints: (build) => ({
    submitTicket: build.mutation({
      query: (body) => ({
        url: 'cpanel/tickets/submit',
        method: 'POST',
        body
      })
    })
  })
})

export const { useSubmitTicketMutation } = ticketsApi
