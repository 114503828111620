import React from 'react'
import { TableRowCell } from '../../Table'
import { downTrendStyling } from '../../../helpers/downTrendStyling'
import type { AdsMetric } from '../types'

interface TableRowProps {
  item: AdsMetric
}

export function TableRow({ item }: TableRowProps) {
  const {
    adGroupName,
    adLink,
    adHeadline1,
    adHeadline2,
    adHeadline3,
    adDescription,
    adDescription2,
    impressions,
    adSpend,
    clicks,
    ctr,
    cpc,
    cr,
    conversions,
    conversionsValue,
    costPerConv
  } = item

  return (
    <tr className="table-tr">
      <td className="flex flex-col text-start p-4">
        <span className="text-[14px] font-[400] leading-[20px] text-gray-900 dark:text-white">
          {[adHeadline1, adHeadline2, adHeadline3].filter(Boolean).join(' | ')}
        </span>
        {adLink.length > 0 && (
          <a
            target="_blank"
            rel="noreferrer"
            href={adLink[0]}
            className="w-fit text-primary-500 underline font-[400] text-[12px] leading-[18px] normal-case block"
          >
            {adLink[0]}
          </a>
        )}
        {(adDescription || adDescription2) && (
          <span className="text-[12px] font-[400] leading-[18px] text-wrap">
            {[adDescription, adDescription2].filter(Boolean).join(' ')}
          </span>
        )}
      </td>
      <TableRowCell label={adGroupName || ''} />
      <TableRowCell label={(clicks || 0).toLocaleString()} />
      <TableRowCell label={(impressions || 0).toLocaleString()} />
      <TableRowCell label={`${ctr || 0}%`} />
      <TableRowCell label={`${cr || 0}%`} />
      <TableRowCell
        label={Math.round(conversions || 0).toLocaleString()}
        labelClassName={downTrendStyling((conversions || 0) === 0)}
      />
      <TableRowCell label={`$${(conversionsValue || 0).toLocaleString()}`} />
      <TableRowCell label={`$${(costPerConv || 0).toLocaleString()}`} />
    </tr>
  )
}
