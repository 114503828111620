import React from 'react'
import { BacklinkProfile } from './BacklinkProfile'
import { Tooltip } from '../../Tooltip'
import { LoadingCard } from '../../Loading'
import { GettingDataReady } from '../../GettingDataReady'
import { KeywordRating } from './KeywordRating'
import { PerformanceChartPanel } from './PerformanceChartPanel'
import { KeywordsChartPanel } from './KeywordsChartPanel'
import { seoRankingTooltip } from '../../../helpers/seoRankingTooltip'
import { NoData } from '../../NoData'

const NoSeoDataMessage = () => {
  return (
    <div className=" px-[70px] py-[25px] text-gray-600 rounded-[16px] bg-white dark:bg-gray-800 dark:text-base-50">
      <NoData title="SEO Summary Unavailable" subTextOne="No data was detected" />
    </div>
  )
}

export function Summary({
  rankings,
  domainStats,
  keywordsSummary,
  performance,
  keywords = [],
  newlyRanking,
  isLoadingDomainStats,
  isLoadingKeywords,
  seoProduct,
  domainStatStatus
}) {
  if (domainStatStatus === 'pending' || isLoadingKeywords) {
    return <GettingDataReady />
  }
  if (
    !isLoadingDomainStats &&
    !isLoadingKeywords &&
    (!domainStats.backLink || !domainStats.domainRating)
  ) {
    return <NoSeoDataMessage />
  }

  return (
    <div>
      <Tooltip className="flex w-fit" content={seoRankingTooltip('backlinkProfile')}>
        <h3 className="text-lg lg:text-xl font-header leading-tight font-bold text-gray-900 dark:text-base-50 w-fit">
          Backlink Profile
        </h3>
      </Tooltip>
      {isLoadingDomainStats && <LoadingCard />}
      {!isLoadingDomainStats && (
        <BacklinkProfile
          brandName={window.brand?.name}
          backLink={domainStats.backLink}
          domainRating={domainStats.domainRating}
        />
      )}
      <Tooltip className="flex w-fit" content={seoRankingTooltip('keywordProfile')}>
        <h3 className="py-4 text-lg lg:text-xl font-header leading-tight font-bold text-gray-900 dark:text-base-50 w-fit">
          Keyword Profile
        </h3>
      </Tooltip>
      <KeywordRating
        keywordsSummary={keywordsSummary}
        newlyRanking={newlyRanking}
        loading={isLoadingKeywords}
      />
      {/* Organic Traffic Chart */}
      <div className="pt-4">
        <PerformanceChartPanel
          loading={isLoadingDomainStats}
          title="Organic Traffic"
          tooltip={rankings.organicTrafficAndBackLinksHelp}
          list={performance}
          seoProduct={seoProduct}
        />
      </div>
      {/* Organic Keywords Chart */}
      {keywords.length > 0 && (
        <div className="pt-4">
          <KeywordsChartPanel
            loading={isLoadingDomainStats}
            title="Organic Keywords"
            tooltip={rankings.organicKeywordsHelp}
            list={keywords}
            seoProduct={seoProduct}
          />
        </div>
      )}
    </div>
  )
}
