import {
  PresentationChartIcon,
  GoogleAdsOutlineIcon,
  FacebookAdsIcon,
  SEOIcon,
  CreditCardIcon,
  CogIcon,
  PowerIcon,
  BellIcon
} from '../Icons'

const withQueryString = (href) => {
  const query = new URLSearchParams(window?.page?.query || '').toString()

  return query === '' ? href : `${href}?${query}`
}

const topSection = [
  {
    id: 'dashboard',
    enabled: true,
    name: 'Dashboard',
    href: withQueryString('/cpanel'),
    icon: PresentationChartIcon
  },
  {
    id: 'ads',
    enabled: true,
    name: 'Google Ads',
    href: withQueryString('/cpanel/reports/ads'),
    icon: GoogleAdsOutlineIcon
  },
  {
    id: 'seo',
    enabled: true,
    name: 'SEO',
    href: withQueryString('/cpanel/reports/seo'),
    icon: SEOIcon
  },
  {
    id: 'facebook',
    enabled: true,
    name: 'Meta Ads',
    href: withQueryString('/cpanel/reports/facebook'),
    icon: FacebookAdsIcon
  },
  {
    id: 'notification',
    enabled: true,
    name: 'Notifications',
    href: withQueryString('/cpanel/notification'),
    icon: BellIcon
  }
]

const middleSection = [
  {
    id: 'billing',
    enabled: true,
    name: 'Billing',
    href: withQueryString('/cpanel/billing'),
    icon: CreditCardIcon
  },
  {
    id: 'settings',
    enabled: true,
    name: 'Settings',
    href: '/cpanel/settings',
    icon: CogIcon
  }
]

export const logoutItem = {
  id: 'logout',
  enabled: true,
  name: '',
  href: '/auth/logout',
  icon: PowerIcon
}

export const menuItems = [topSection, middleSection]
