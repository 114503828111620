import React, { useState, useEffect } from 'react'
import { Tile } from './Tile'
import { TileWithProgressBar } from './TileWithProgressBar'
import { ConversionMetrics } from './ConversionMetrics'
import { currencyFormatter } from '../../helpers/currencyFormatter'
import { percentageFormatter } from '../../helpers/percentageFormatter'
import { numberFormatter } from '../../helpers/numberFormatter'
import { ProgressBarMini } from '../ProgressBar'
import { Layout } from '../Layout'
import { NoDataMessage } from '../ads/ProductMessages'
import { PageType } from '../../pages/PageType'
import type { Metrics } from './ConversionMetrics/ConversionMetrics'

const removePrefix = (name) => {
  return name.replace(/^\*?(SLX|GMT|SME|JDM)( \| )?/, '')
}

export function FbCoreMetrics({ fromDate, toDate, data, handleViewMore, adsProduct }) {
  const [sortedCampaigns, setSortedCampaigns] = useState([])

  const metrics: Metrics[] = [
    {
      title: 'Impressions',
      active: true,
      lineColor: '#FAC515',
      areaColor: '#FEF7C3',
      value: numberFormatter(data?.performanceData?.impressions || 0),
      data: data?.performanceData?.charts?.impressionChart
    },
    {
      title: 'Clicks',
      active: true,
      lineColor: '#5C5CFF',
      areaColor: '#DDE4FF',
      value: numberFormatter(data?.performanceData?.clicks || 0),
      data: data?.performanceData?.charts?.clicksChart
    },
    {
      title: 'Ad Spend',
      active: true,
      lineColor: '#9635E8',
      areaColor: '#F3E8FF',
      value: `$${numberFormatter(parseFloat(data.performanceData.spend || 0) / 100)}`,
      data: data?.performanceData?.charts?.adSpendChart
    }
  ]

  // Clean brand name traces
  const cleanedCampaignData = data?.campaignData?.map((item) => ({
    ...item,
    campaign_name: removePrefix(item.campaign_name)
  }))

  useEffect(() => {
    if (cleanedCampaignData) {
      const sortedCampaignsData = cleanedCampaignData
        .map((entry) => ({
          clicks: parseInt(entry.clicks),
          campaignName: entry.campaign_name
        }))
        .sort((a, b) => b.clicks - a.clicks)
      setSortedCampaigns(sortedCampaignsData)
    }
  }, [data])

  if (!data) {
    return (
      <Layout pageType={PageType.Facebook}>
        <NoDataMessage platform="Facebook" />
      </Layout>
    )
  }

  return (
    <>
      <ConversionMetrics
        fromDate={fromDate}
        toDate={toDate}
        metrics={metrics}
        adsProduct={adsProduct}
      />
      <TileWithProgressBar
        title="Ad Spend"
        subTitle="Spend to date"
        customerName={data.customer.descriptiveName}
        value={
          data.performanceData.spend
            ? `$${numberFormatter(parseFloat(data.performanceData.spend))}`
            : 0
        }
        trendingValue={0}
        progressValue="" // TODO: Figure this out
      />
      <div className="animate-grow-in grid grid-cols-2 gap-x-[8px] sm:gap-x-[16px] gap-y-[12px] sm:gap-y-[16px]">
        <Tile
          title="Cost per 1,000 Impressions (CPM)"
          value={currencyFormatter(data.performanceData.cpm || 0)}
          trendingValue={data.compareData.cpmDiff ? data.compareData.cpmDiff : 0}
          tooltip="cpm"
          inverseColor
        />
        <Tile
          title="Average Cost Per Click (CPC)"
          value={currencyFormatter(data.performanceData.cpc || 0)}
          trendingValue={data.compareData.cpcDiff ? data.compareData.cpcDiff : 0}
          tooltip="averageCpc"
          inverseColor
        />
        <Tile
          title="Page Reach"
          value={numberFormatter(Math.round(parseInt(data.performanceData.reach || 0) / 100))}
          trendingValue={data.compareData.reachDiff ? data.compareData.reachDiff : 0}
          tooltip="reach"
        />
        <Tile
          title="Click Through Rate (CTR)"
          value={percentageFormatter(parseFloat(data.performanceData.ctr || 0) / 100)}
          trendingValue={data.compareData.ctrDiff ? data.compareData.ctrDiff : 0}
          tooltip="ctr"
        />
      </div>
      <div className="w-full flex flex-col gap-[8px] bg-white dark:bg-gray-800 dark:text-white rounded-[16px] p-[24px]">
        <div className="flex flex-row items-center justify-between">
          <h1 className="font-inter font-[600] text-[18px] text-gray-900 dark:text-gray-100 pt-[6px]">
            Top Campaigns by Clicks
          </h1>
          <span
            onClick={handleViewMore}
            className="p-2 text-[12px] font-medium rounded-[16px] text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-base-700 cursor-pointer selection:none"
          >
            View more
          </span>
        </div>
        {sortedCampaigns.slice(0, 3).map((campaign, index) => (
          <ProgressBarMini
            title={campaign.campaignName}
            value={campaign.clicks}
            target={sortedCampaigns.slice(0, 3)[0].clicks * 1.2}
            key={index}
          />
        ))}
      </div>
    </>
  )
}
