import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addToast } from '../../store/global'
import { useLoginMutation } from '../../services/auth'
import { EmailInput, PasswordInput } from './FormElements/index'
import { SubmitButton } from '../Buttons/index'

export function LoginForm() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailValid, setEmailValid] = useState(false)
  const [passwordValid, setPasswordValid] = useState(false)

  const dispatch = useDispatch()
  const [login, { isLoading }] = useLoginMutation()

  const validateEmail = () => {
    const validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    setEmailValid(validEmail.test(email))
  }

  const validatePassword = () => {
    setPasswordValid(password.length >= 3)
  }

  useEffect(() => {
    validateEmail()
  }, [email])

  useEffect(() => {
    validatePassword()
  }, [password])

  const hasErrors = !emailValid || !passwordValid

  const onSubmit = async () => {
    if (hasErrors) {
      dispatch(
        addToast({
          title: 'Invalid email address or password',
          subtext: 'Please check your email address and password and try again.',
          type: 'error'
        })
      )
      return
    }

    const response = await login({
      username: email,
      password
    })

    if (!response?.data || response.data.error) {
      dispatch(
        addToast({
          title: 'Invalid email address or password',
          subtext: 'Please check your email address and password and try again.',
          type: 'error'
        })
      )
      return
    }

    window.location.href = '/cpanel'
  }

  return (
    <>
      <div className="flex flex-col text-center gap-[12px]">
        <h1 className="font-inter text-[30px] font-[600] leading-[38px] text-gray-900 dark:text-gray-100">
          Account Login
        </h1>
        <span className="font-inter text-[16px] font-[400] leading-[24px] text-gray-600 dark:text-gray-400">
          Welcome back! Please enter your details.
        </span>
      </div>
      <div className="flex flex-col gap-[20px]">
        <EmailInput value={email} hasError={email && hasErrors} onChange={setEmail} />
        <PasswordInput
          id="password"
          label="Password"
          value={password}
          hasError={password && hasErrors}
          onChange={setPassword}
        />
        <a
          href="/forgot-password"
          className="flex justify-end font-inter text-[14px] font-[600] leading-[20px] text-primary-700 dark:text-primary-400 place-self-end"
        >
          Forgot password?
        </a>
        <SubmitButton label="Sign in" isLoading={isLoading} onClick={onSubmit} />
      </div>
      {/* TEMPORARY HIDE */}
      {/* <div className="flex self-center">
        <span className="text-gray-600 dark:text-gray-300 text-[14px] font-[400] leading-[20px]">
          Don't have an account?
          <a
            href="/checkout"
            className="text-primary-700 dark:text-primary-400 text-[14px] font-[600] leading-[20px] pl-1"
          >
            Sign up
          </a>
        </span>
      </div> */}
    </>
  )
}
