import React from 'react'
import { ProgressBar } from '../../ProgressBar'
import { useSelector } from 'react-redux'

export function TileWithProgressBar({
  title,
  subTitle,
  customerName,
  trendingValue,
  value,
  progressValue,
  isSummary = false
}) {
  const dateFilter = useSelector((store) => store.global.dateFilter)
  return (
    <div
      className={`animate-grow-in grid grid-rows-[108px,66px] ${!isSummary ? 'row-start-2 row-end-3' : ''} gap-[28px] bg-white dark:bg-gray-800 dark:text-white rounded-[16px] p-[24px]`}
    >
      <div className="grid grid-rows-[28px,20px,44px] gap-[8px]">
        <div className="flex justify-between font-inter font-[600] text-[18px] text-gray-900 dark:text-gray-100 leading-[28px]">
          <span>{title}</span>
          <span className="text-sm italic">{customerName}</span>
        </div>
        <div className="flex justify-between">
          <span className="font-inter font-[500] text-[14px] text-gray-600 dark:text-gray-400 leading-[20px]">
            {subTitle}
          </span>
          <span className="text-gray-400 text-xs">
            {new Date(dateFilter.from).toLocaleDateString('en-AU')} -{' '}
            {new Date(dateFilter.to).toLocaleDateString('en-AU')}
          </span>
        </div>
        <div className="flex items-center justify-between">
          <div className="animate-grow-in font-inter font-[600] text-[36px] text-gray-900 dark:text-gray-100 leading-[44px]">
            {value}
          </div>
        </div>
      </div>
      {progressValue && <ProgressBar value={progressValue} />}
    </div>
  )
}
