import { api } from './api'

export const onboardingApi = api.injectEndpoints({
  endpoints: (build) => ({
    oAuthURL: build.mutation({
      query: ({ type, brand }) => ({
        url: 'onboarding/oauth',
        method: 'POST',
        body: {
          type,
          brandDetails: brand
        }
      })
    }),
    connectGoogleAds: build.mutation({
      query: (params) => ({
        url: 'onboarding/gadselector',
        params
      })
    }),
    connectFacebookAds: build.mutation({
      query: (params) => ({
        url: 'onboarding/fbselector',
        params
      })
    }),
    getCms: build.mutation({
      query: (url) => ({
        url: 'onboarding/cms',
        params: {
          url
        }
      })
    })
  })
})

export const {
  useOAuthURLMutation,
  useConnectGoogleAdsMutation,
  useConnectFacebookAdsMutation,
  useGetCmsMutation
} = onboardingApi
