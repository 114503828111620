import React, { useEffect, useState } from 'react'

export function Modal({
  handleClose = null,
  isExiting,
  children = null,
  blur = true,
  extraRules = 'w-full sm:w-[540px] bg-white dark:bg-gray-800'
}) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(true)
    document.addEventListener('keyup', onKeyUp)
    return () => {
      document.removeEventListener('keyup', onKeyUp)
    }
  }, [])

  const onKeyUp = (event) => {
    if (event.key === 'Escape') {
      handleClose()
    }
  }

  return (
    <div
      className={`fixed inset-0 z-40 flex items-end ${blur ? 'bg-[#00000099] backdrop-blur ' : 'bg-none'} sm:items-center justify-center transition ease duration-200 ${
        open ? 'opacity-100' : 'opacity-0'
      }`}
      onClick={handleClose}
    >
      <div
        className={`p-[24px] ${extraRules} shadow-xl rounded-[16px] transition ease duration-200 ${
          open ? 'opacity-100' : 'opacity-0 transform translate-y-1/2'
        } ${isExiting ? 'translate-y-1/2 opacity-100' : 'opacity-0'}`} // apply exit animation classes
        role="dialog"
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        {children}
      </div>
    </div>
  )
}
