import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GettingDataReady } from '../GettingDataReady'
import { DomainCheck } from '../seo/DomainCheck'
import { Summary } from '../seo/Summary'
import { AccountSelector } from '../AccountSelector'
import { LocationSelector } from '../LocationSelector'
import { useGetSeoWebsiteQuery, useGetSeoKeywordsQuery } from '../../services/seo'
import { useGetDomainStatsQuery } from '../../services/checkout'
import { useGetSeoDomainReportsQuery } from '../../services/reports'
import { useSeoCache } from '../../services/cache'
import { NoData } from '../NoData'

const NoSeoDataMessage = () => {
  return (
    <div className=" px-[70px] py-[25px] text-gray-600 rounded-[16px] bg-white dark:bg-gray-800 dark:text-base-50">
      <NoData title="SEO Summary Unavailable" subTextOne="No data was detected" />
    </div>
  )
}

export function SeoDashboard() {
  const [location, setLocation] = useState('All')

  const rankings = window.t.dashboard.seo.titles.rankings

  const selectedSeoId = useSelector((store) => store.seo.selectedAccountId)

  useEffect(() => {
    useSeoCache(selectedSeoId)
  }, [selectedSeoId])

  const website = useGetSeoWebsiteQuery(selectedSeoId, { skip: !selectedSeoId })
  const keywords = useGetSeoKeywordsQuery(selectedSeoId, { skip: !selectedSeoId })

  const websiteData = website.data?.records || []
  const webDataFiltered = websiteData.filter(
    (v, i, a) => a.findIndex((t) => t.Geolocation__c === v.Geolocation__c) === i
  ) // filter duplicate geolocations
  const selectedWebsite =
    location !== 'All'
      ? [
          webDataFiltered.find((item) =>
            item.Geolocation__c.toUpperCase().includes(location.toUpperCase())
          )
        ]
      : webDataFiltered
  const websiteHost = websiteData[0]?.website_url__c
  const product = website.data?.product || []

  const domain = useGetDomainStatsQuery(websiteHost, { skip: !websiteHost })
  const url = domain?.data?.url || null
  const domainStatsData = useGetSeoDomainReportsQuery(url, { skip: !url })

  const { performanceCharts, keywordCharts, ...domainStats } = domainStatsData.data || {}
  const performanceChartsFiltered = performanceCharts
    ? performanceCharts.map(({ date, org_traffic }) => ({ date, org_traffic }))
    : {} // remove unrelated properties (and only keep org_traffic)

  const keywordsSummary = keywords?.data?.summary || {}

  let newlyRanked = keywords?.data?.newrankings || []
  if (location !== 'All') {
    newlyRanked = newlyRanked.filter((item) =>
      item.Geolocation_Effective__c.toUpperCase().includes(location.toUpperCase())
    )
  }

  const isSeoLoading = website.isLoading || domainStatsData.isLoading || keywords.isLoading

  if (isSeoLoading) {
    return <GettingDataReady />
  }

  if (!isSeoLoading && (!domainStats.backLink || !domainStats.domainRating)) {
    return <NoSeoDataMessage />
  }

  return (
    <>
      <div className="flex flex-col sm:flex-row gap-[16px] items-start sm:items-center pt-[5px] sm:pt-[16px] pb-0 pb-[5px] sm:b-[24px]">
        <h1 className="font-inter text-[24px] text-gray-900 dark:text-gray-100 font-[600] leading-[32px]">
          SEO Summary
        </h1>
        <div className="flex flex-col sm:flex-row gap-[16px] items-start sm:items-center">
          <AccountSelector type="seo" />
          <LocationSelector
            location={location}
            setLocation={setLocation}
            websiteData={websiteData}
          />
        </div>
      </div>
      <DomainCheck
        hasViewFullReportLink
        loading={website.isLoading}
        websiteData={selectedWebsite}
        domain={domain.data || {}}
      />
      <Summary
        loading={website.isLoading}
        rankings={rankings}
        domainStats={domainStats}
        keywordsSummary={keywordsSummary}
        performance={performanceChartsFiltered}
        newlyRanking={newlyRanked}
        isLoadingDomainStats={domainStatsData.isLoading}
        isLoadingKeywords={keywords.isLoading}
        seoProduct={product}
        domainStatStatus={domainStatsData.status}
      />
    </>
  )
}
