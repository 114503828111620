import type { DayOfWeek, MinuteOfHour } from '../components/ads/types'

export const convertAdScheduleTime = (
  dayOfWeek: DayOfWeek,
  startHour: number,
  startMinute: MinuteOfHour,
  endHour: number,
  endMinute: MinuteOfHour
): string => {
  const minute = (minuteOfHour: MinuteOfHour): string => {
    if (minuteOfHour === 'FIFTEEN') return '15'
    if (minuteOfHour === 'THIRTY') return '30'
    if (minuteOfHour === 'FORTY_FIVE') return '45'

    return '00'
  }

  const format = (hour: number, minuteOfHour: MinuteOfHour) =>
    `${hour % 12 || 12}:${minute(minuteOfHour)}${hour >= 12 ? 'PM' : 'AM'}`

  const day = dayOfWeek.charAt(0) + dayOfWeek.slice(1).toLowerCase()

  return `${day}s, ${format(startHour, startMinute)} - ${format(endHour, endMinute)}`
}
