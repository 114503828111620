import { getPaginationRange } from './getPaginationRange'

export const paginate = (list, page, perPage) => {
  const total = list.length
  const { from, to } = getPaginationRange(page, perPage, total)

  return {
    from,
    to,
    total,
    paginated: list.slice(from, to)
  }
}
