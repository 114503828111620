import React from 'react'
import { ProductMessage } from '../../ProductMessage'

export function ThirdMonth({ onHideClick }) {
  return (
    <ProductMessage
      title="Third month of SEO"
      className="text-body dark:text-base-50"
      onHideClick={onHideClick}
    >
      <>
        <p className="paragraph-text">
          Month three — it's all happening! During this time, we'll take a look at your site's
          performance to gain an understanding of its SEO movement over the last 120 days. This will
          help us determine areas that are working well and areas that require improvement.
        </p>
        <p className="paragraph-text">Based on individual results, we might:</p>
        <ul>
          <li className="paragraph-text">fix any duplication or keyword cannibalisation</li>
          <li className="paragraph-text">adjust title tags and metas where necessary</li>
          <li className="paragraph-text">ensure links are working properly</li>
          <li className="paragraph-text">
            optimise your Google My Business and other directory profiles
          </li>
          <li className="paragraph-text">Revisit search engine competitors</li>
        </ul>
        <p className="paragraph-text">
          Our specialists will also perform search engine optimisation across remaining pages. Make
          sure to check your dashboard regularly to see any updates or account changes.
        </p>
      </>
    </ProductMessage>
  )
}
