import React from 'react'

export function ErrorIcon({ className = 'w-6 h-6 fill-white dark:fill-neutral-800' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      className={className}
    >
      <path
        d="M480-72.065q-17.913 0-34.443-6.873-16.53-6.874-30.209-19.475L98.413-415.348q-12.601-13.679-19.475-30.209-6.873-16.53-6.873-34.443 0-17.913 6.837-34.946 6.837-17.032 19.511-29.706l316.935-316.935q13.679-13.747 30.209-20.047 16.53-6.301 34.443-6.301 17.913 0 34.946 6.337 17.032 6.337 29.706 20.011l316.935 316.935q13.674 12.674 20.011 29.706 6.337 17.033 6.337 34.946 0 17.913-6.301 34.443-6.3 16.53-20.047 30.209L544.652-98.413q-12.674 12.674-29.706 19.511-17.033 6.837-34.946 6.837Zm0-91.239L796.696-480 480-796.696 163.304-480 480-163.304Zm-43.587-274.783h87.174v-240h-87.174v240ZM480-310.913q18.524 0 31.056-12.531 12.531-12.531 12.531-31.056 0-18.524-12.531-31.056-12.532-12.531-31.056-12.531t-31.056 12.531q-12.531 12.532-12.531 31.056 0 18.525 12.531 31.056 12.532 12.531 31.056 12.531ZM480-480Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
