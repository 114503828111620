import React from 'react'
import { useSelector } from 'react-redux'
import * as echarts from 'echarts/core'
import ReactECharts from 'echarts-for-react'
import { getGraphColorSets, convertGraphColor } from './getGraphColorSets'

export type ConversionChartData = {
  date: string
  value: number
}

export type ConversionChartMetric = {
  title: string
  active: boolean
  lineColor: string
  areaColor: string
  data: ConversionChartData[]
  formatter?: (value: number) => string
}

interface ConversionChartProps {
  metrics: ConversionChartMetric[]
  adsProduct: {
    signupDate: string
    setupCompletedDate: string
  }
  fromDate: string
  toDate: string
}

type OptionsConfig = {
  darkMode: boolean
  signupIndex: number
  setupIndex: number
  metrics: (Omit<ConversionChartMetric, "data"> & {
    data: [string, number][]
  })[]
  dates: string[]
}

const getOptions = (config: OptionsConfig) => {
  const { darkMode, signupIndex, setupIndex, metrics, dates } = config

  const firstActiveMetricIndex = metrics.findIndex((metric) => metric.active)
  const firstActiveMetric = firstActiveMetricIndex !== -1 ? firstActiveMetricIndex : 0

  const signupTime = signupIndex !== -1 ? new Date(`${dates[signupIndex]} 00:00:00`).getTime() : 0
  const setupTime = setupIndex !== -1 ? new Date(`${dates[setupIndex]} 00:00:00`).getTime() : 0

  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: darkMode ? '#282828' : '#6a7985',
          formatter: (params) => {
            if (params.axisDimension === 'x') {
              return new Date(params.value).toLocaleDateString('en-AU')
            }

            return metrics[params.axisIndex].formatter
              ? metrics[params.axisIndex].formatter(params.value)
              : Math.round(params.value).toLocaleString()
          }
        }
      },
      formatter: (params) => {
        const date = params[0]?.axisValueLabel || ''

        const items = params
          .map((param) => {
            const formatter = metrics[param.seriesIndex].formatter
            const value = formatter ? formatter(param.value[1]) : param.value[1].toLocaleString()

            return [
              param.marker,
              param.seriesName,
              `<span style="float:right;margin-left:20px"><b>${value}</b></span>`
            ].join('')
          })
          .join('<br />')

        return `${date}<br />${items}`
      }
    },
    backgroundColor: 'transparent',
    grid: {
      containLabel: true,
      left: 10,
      right: 10,
      top: 10,
      bottom: 0
    },
    xAxis: [
      {
        type: 'time',
        data: dates,
        axistick: {
          alignWithLabel: true
        },
        axisline: {
          onZero: true
        },
        axisLabel: {
          color: darkMode ? '#fff' : '#4b5563',
          fontFamily: 'inter',
          fontSize: '13px',
          fontWeight: 500,
          lineHeight: 18
        }
      }
    ],
    yAxis: metrics.map((metric, idx) => ({
      show: firstActiveMetric === idx,
      type: 'value',
      axisLabel: {
        show: false
      },
      splitLine: {
        lineStyle: {
          color: darkMode ? '#303740' : '#f4f5f7'
        }
      }
    })),
    visualMap: metrics.map((metric, idx) => ({
      show: false,
      dimension: 0,
      type: 'piecewise',
      seriesIndex: idx,
      pieces: [
        // If the account is created but the setup is in progress, show the account creation section
        ...(signupTime > 0 && setupTime === 0
          ? [
              { max: signupTime - 1, color: getGraphColorSets(darkMode)[2] },
              { min: signupTime, color: metric.lineColor, colorAlpha: 0.3 }
            ]
          : []),
        // If the setup is complete, show the account creation and setup sections
        ...(signupTime > 0 && setupTime > 0 && setupTime > signupTime
          ? [
              { max: signupTime - 1, color: getGraphColorSets(darkMode)[2] },
              { min: signupTime, max: setupTime - 1, color: metric.lineColor, colorAlpha: 0.3 },
              { min: setupTime, color: metric.lineColor }
            ]
          : []),
        // If the account is created and setup is complete, show the setup section
        ...(signupTime > 0 && setupTime > 0 && setupTime === signupTime
          ? [
              { max: setupTime - 1, color: getGraphColorSets(darkMode)[2] },
              { min: setupTime, color: metric.lineColor }
            ]
          : []),
        // Fallback to showing the account completion section
        ...(signupTime === 0 && setupTime > 0
          ? [
              { max: setupTime - 1, color: metric.lineColor, colorAlpha: 0.3 },
              { min: setupTime, color: metric.lineColor }
            ]
          : [])
        // Show nothing if signupTime and setupTime are both 0
      ].filter(Boolean)
    })),
    series: [
      // CHART LINES
      ...metrics.map((metric, idx) => ({
        name: metric.title,
        yAxisIndex: idx,
        data: metric.active ? metric.data : [], // Is it good way to handle this?
        showSymbol: false,
        type: 'line',
        smooth: true,
        animationEasing: 'quadraticIn',
        areaStyle: {
          opacity: darkMode ? 0.15 : 0.27,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: metric.areaColor
            },
            {
              offset: 1,
              color: convertGraphColor(darkMode ? 'bg-gray-100' : 'text-white')
            }
          ])
        },
        emphasis: {
          focus: 'series'
        }
      })),

      // VERTICAL LINES
      {
        type: 'line',
        symbol: 'none',
        label: { show: false },
        markLine: {
          symbol: 'none',
          data: [signupTime, setupTime].filter(Boolean).map((xAxis) => ({
            symbol: 'none',
            xAxis,
            lineStyle: {
              type: 'solid',
              width: 2,
              color: convertGraphColor(darkMode ? 'text-gray-600' : 'text-gray-200')
            }
          })),
          label: {
            show: false
          }
        }
      },

      // CIRCLE LABELS
      {
        type: 'line',
        yAxisIndex: firstActiveMetric,
        markPoint: {
          data: [
            {
              label: 'Account Created',
              date: signupIndex > 0 ? signupTime : 0, // Do not show label if date is the first data in the graph
              value: metrics[firstActiveMetric].data[signupIndex]?.[1] || 0
            },
            {
              label: 'Setup Complete',
              date: setupIndex > 0 ? setupTime : 0, // Do not show label if date is the first data in the graph
              value: metrics[firstActiveMetric].data[setupIndex]?.[1] || 0
            }
          ]
            .filter((label) => label.date > 0)
            .map((item) => ({
              coord: [item.date, item.value],
              symbol: 'circle',
              symbolSize: 16,
              label: {
                show: false,
                // If the date is in the first half of the graph, position the label to the right of the circle
                position:
                  item.date >
                  new Date(`${dates[Math.round((dates.length - 1) / 2)]} 00:00:00`).getTime()
                    ? [-90, 7]
                    : [10, 7]
              },
              itemStyle: {
                color: convertGraphColor('text-indigo-500'),
                borderWidth: 8,
                borderColor: convertGraphColor('text-indigo-100'),
                shadowColor: 'rgba(16, 24, 40, 0.10)',
                shadowBlur: 3,
                shadowOffsetY: 1
              },
              emphasis: {
                label: {
                  backgroundColor: convertGraphColor('text-indigo-100'),
                  padding: [0, 7, 0, 5],
                  borderWidth: 7,
                  borderColor: convertGraphColor('text-indigo-100'),
                  borderRadius: 8,
                  formatter: item.label,
                  show: true,
                  color: convertGraphColor('text-indigo-600'),
                  fontSize: 12,
                  fontFamily: 'inter',
                  fontWeight: 500,
                  shadowColor: 'rgba(16, 24, 40, 0.10)',
                  shadowBlur: 3,
                  shadowOffsetY: 1
                }
              }
            }))
        }
      }
    ],
    animationEasing: 'elasticOut'
  }
}

const createDateRange = (fromDate: string, toDate: string): string[] => {
  const dateRange = []
  const from = new Date(fromDate)
  const to = new Date(toDate)

  while (from <= to) {
    dateRange.push(from.toISOString().split('T')[0])
    from.setDate(from.getDate() + 1)
  }

  return dateRange
}

const getDateRangeValues = (
  dates: string[],
  chartData: ConversionChartData[]
): [string, number][] => {
  const dateValue = chartData.reduce((acc, item) => {
    acc[item.date] = item.value
    return acc
  }, {})

  return dates.filter(Boolean).map((date) => [date, dateValue[date] || 0])
}

const findDateIndex = (date: string | null, dates: string[]): number => {
  if (!date) {
    return -1
  }

  if (new Date(date) < new Date(dates[0] || '1970-01-01')) {
    return 0
  }

  return dates.findIndex((d) => d === date)
}

export function ConversionChart({ metrics, adsProduct, fromDate, toDate }: ConversionChartProps) {
  const darkMode = useSelector((store) => store.global.darkMode)

  const dates = createDateRange(fromDate, toDate)
  const signupIndex = findDateIndex(adsProduct.signupDate, dates)
  const setupIndex = findDateIndex(adsProduct.setupCompletedDate, dates)

  const options = getOptions({
    darkMode,
    dates,
    signupIndex,
    setupIndex,
    metrics: metrics.map((metric, idx) => ({
      ...metric,
      data: getDateRangeValues(dates, metric.data)
    }))
  })

  return <ReactECharts className="items-center w-full h-[250px] mt-5" option={options} />
}
