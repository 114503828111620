import React from 'react'
import { Table } from '../../Table'
import { TableRow } from './TableRow'
import { TableHead } from './TableHead'
import { Panel } from '../Panel'

export function Top10Keywords({ rankings, list, loading }) {
  const { top10keywords, top10keywordsHelp } = rankings

  return (
    <Panel title={top10keywords} help={top10keywordsHelp} loading={loading}>
      <Table
        head={<TableHead />}
        rows={list.map((keyword) => (
          <TableRow key={keyword.Id} keyword={keyword} rankings={rankings} />
        ))}
      />
    </Panel>
  )
}
