import React from 'react'

export function DoneIcon({ className = 'h-6 w-6 text-primary-500' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      x="0"
      y="0"
      className={className}
    >
      <path
        d="m695.078-135.846-249.23-249.232q-20.925 9.154-42.771 13.116-21.846 3.961-44.385 3.961-96.185 0-163.592-67.407Q127.693-502.814 127.693-599q0-30 7.884-59.769 7.885-29.768 24.423-55.307l142.692 142.923L385.539-654 243.386-796.923q26.538-15.538 55.922-23.807 29.385-8.269 59.384-8.269 95.769 0 162.884 67.115Q588.691-694.769 588.691-599q0 23.308-3.769 45.654-3.769 22.346-13.308 42.536l249.425 249.644q10.268 10.344 10.268 25.256 0 14.911-10.711 25.747l-74.211 75.086Q734.924-124.616 720.731-125q-14.192-.385-25.653-10.846Zm25.538-64.999 35.616-35.616-263.385-263.385q17.846-21.769 25.346-47.269t7.5-52.885q0-63.539-45.039-113.77-45.039-50.231-114.81-51.231l82.078 83.078q10.462 11.462 11.346 25.538.885 14.077-10.577 25.539L327.615-509.77q-11.461 11.461-25.923 10.961-14.461-.5-25.923-10.961l-83.078-82.078q2.154 74.002 52.77 116.925 50.615 42.924 112.231 42.924 26.092 0 51.991-7.808 25.9-7.808 47.432-24.423l263.501 263.385ZM471-487.461Z"
        fill="currentColor"
      />
    </svg>
  )
}
