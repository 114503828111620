import React, { useState, useEffect } from 'react'
import { SuccessIcon, ErrorIcon, WarningIcon } from '../../Icons'
import { NotificationType } from '../NotificationType'

export function Toast({ title, subtext, type }) {
  const [popUp, dismountPopUp] = useState(false) // setting the popup to display none to dismount
  const [showPopUp, setShowPopUp] = useState(false) // display from an opacity perspective
  const [isInitialRender, setIsInitialRender] = useState(true)

  useEffect(() => {
    // timer for the popup to stay open
    const timer = setTimeout(() => {
      handleUnmount()
    }, 8000)

    // a very short timer to quickly allow the entrance animation,
    // then remove it to allow for the exit animation to play later
    const popupDelay = setTimeout(() => {
      setIsInitialRender(false)
      setShowPopUp(true)
    }, 10)

    return () => {
      clearTimeout(timer)
      clearTimeout(popupDelay)
    }
  }, [])

  const handleUnmount = () => {
    setShowPopUp(false)
    setTimeout(() => {
      dismountPopUp(true)
    }, 300)
  }

  const getNotificationStyle = () => {
    switch (type) {
      case NotificationType.Success:
        return 'bg-green-400 dark:bg-green-500 text-white dark:text-neutral-800'
      case NotificationType.Error:
        return 'bg-red-600 dark:bg-[#DE350B] text-white dark:text-neutral-800'
      case NotificationType.Warning:
      case NotificationType.Notice:
        return 'bg-[#FFAB00] text-white dark:text-neutral-800'
      default:
        return 'bg-gray-400 dark:bg-gray-500 text-white dark:text-neutral-800'
    }
  }

  return (
    <div
      className={`${isInitialRender ? 'opacity-0' : 'opacity-100'} 
        ${popUp ? 'hidden' : ''} relative md:left-auto md:right-10 p-[16px] mx-[16px] rounded-[16px]
        shadow-lightElev3 dark:shadow-darkElev5 select-none cursor-pointer
        w-auto md:w-[384px] ${getNotificationStyle()}`}
      style={{
        transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
        opacity: showPopUp ? 1 : 0,
        transform: showPopUp ? 'translateX(0)' : 'translateX(1.25rem)'
      }}
      onClick={handleUnmount}
    >
      {/* Timeout bar */}
      <div className="animate-bar w-full h-1 mb-[10px] bg-white dark:bg-neutral-800 rounded-sm" />
      <div className="flex flex-row gap-[16px]">
        <div className="self-start">
          {type === NotificationType.Error && <ErrorIcon />}
          {type === NotificationType.Success && <SuccessIcon />}
          {(type === NotificationType.Warning || type === NotificationType.Notice) && (
            <WarningIcon />
          )}
        </div>
        <div className="flex flex-col items-start w-full gap-[8px]">
          <div className="font-header text-[14px] font-[700] leading-[20px] uppercase">{title}</div>
          <div className="font-header text-[14px] font-[400] leading-[20px]">
            {subtext &&
              String(subtext)
                ?.split('\n')
                .map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
          </div>
        </div>
      </div>
    </div>
  )
}
