import React from 'react'
import { useSelector } from 'react-redux'
import ReactECharts from 'echarts-for-react'
import { CommonFunc } from '../../libs/common'
import { convertAdScheduleTime } from '../../helpers/convertAdScheduleTime'
import type { AdScheduleMetric } from '../ads/types'

const capitalizeString = new CommonFunc().capitalizeString

interface AdScheduleChartProps {
  list: AdScheduleMetric[]
  attribute?: string
}

const ops = {
  renderer: 'canvas',
  useCoarsePointer: null
}

const chartStyle = {
  height: '58vh'
}

const getOptions = (darkMode, attribute, seriesData, stackedSeries) => ({
  tooltip: {
    trigger: 'item',
    axisPointer: {
      type: 'none'
    },
    formatter: function (tar) {
      if (tar?.data?.attributeValue) {
        return `<b>${tar.name?.split(',')[0]}<br/>${tar.name?.split(',')[1]}</b><br/>${capitalizeString(attribute)}: ${tar?.data?.attributeValue}`
      }
      return null
    },
    extraCssText: 'border: none;'
  },
  grid: {
    left: '3%',
    right: '4%',
    top: '10%',
    bottom: '3%',
    containLabel: true
  },
  yAxis: {
    type: 'category',
    inverse: true,
    data: ['M', 'T', 'W', 'T', 'F', 'S', 'S'], // Abbreviated day names
    splitLine: {
      show: true,
      lineStyle: {
        color: darkMode ? '#475467' : '#F2F4F7'
      }
    },
    axisTick: { show: false },
    axisLine: { show: false },
    axisLabel: {
      fontSize: 12,
      fontWeight: 400,
      color: darkMode ? '#D0D5DD' : '#475467',
      fontFamily: 'Inter'
    }
  },
  xAxis: {
    type: 'value', // Use 'value' to represent time as a number (hours)
    splitLine: { show: false },
    axisLabel: {
      formatter: function (value) {
        const hour = value % 24
        const suffix = hour < 12 ? 'am' : 'pm'
        const displayHour = hour === 0 || hour === 12 ? 12 : hour % 12
        return `${displayHour}${suffix}`
      },
      fontSize: 12,
      fontWeight: 400,
      color: darkMode ? '#D0D5DD' : '#475467',
      fontFamily: 'Inter'
    },
    min: 0, // Start at 0 (midnight)
    max: 24, // End at 24 (midnight)
    interval: 3
  },
  series: [
    {
      name: 'Gap between 12am and first time',
      type: 'bar',
      stack: 'Total',
      itemStyle: {
        borderColor: 'transparent',
        color: 'transparent'
      },
      data: seriesData.map((arr) =>
        arr.length ? Math.min(...arr.map((item) => item.startHour)) : null
      )
    },
    ...stackedSeries
  ]
})

const dayOfWeekMap = {
  MONDAY: 0,
  TUESDAY: 1,
  WEDNESDAY: 2,
  THURSDAY: 3,
  FRIDAY: 4,
  SATURDAY: 5,
  SUNDAY: 6
}

export function AdScheduleChart({ list, attribute = 'clicks' }: AdScheduleChartProps) {
  const darkMode = useSelector((store) => store.global.darkMode)

  const emptyArr = Array.from({ length: 7 }, () => [])
  list.forEach((item) => {
    const dayIndex = dayOfWeekMap[item.dayOfWeek]
    if (dayIndex === undefined) return

    emptyArr[dayIndex]?.push({
      startHour: item.startHour,
      hoursActive: item.endHour - item.startHour,
      dayAndTime: convertAdScheduleTime(
        item.dayOfWeek,
        item.startHour,
        item.startMinute,
        item.endHour,
        item.endMinute
      ),
      value: item[attribute]
    })
  })
  const seriesData = emptyArr.map((subArr) => subArr.sort((a, b) => a.startHour - b.startHour))

  // Create a dataset that contains the horizontally stacked bars
  const noOfStackedBars = seriesData.reduce((max, subArray) => Math.max(max, subArray.length), 0)
  const maxValue = list.reduce((max, obj) => (obj[attribute] > max ? obj[attribute] : max), 0)
  const stackedSeries = []
  for (let i = 0; i < noOfStackedBars; i++) {
    stackedSeries.push({
      name: 'Bars',
      type: 'bar',
      stack: 'Total',
      itemStyle: {
        color: function (params) {
          const opacity = params.data.attributeValue / maxValue
          return darkMode
            ? `rgba(194, 206, 255, ${Math.min(opacity, 1)})`
            : `rgba(65, 42, 216, ${Math.min(opacity, 1)})`
        },
        borderRadius: 12
      },
      data: seriesData.map((s) => ({
        value: s[i]?.hoursActive,
        name: s[i]?.dayAndTime,
        attributeValue: s[i]?.value
      }))
    })
  }

  return (
    <ReactECharts
      className="w-full pb-[32px]"
      option={getOptions(darkMode, attribute, seriesData, stackedSeries)}
      ops={ops}
      style={chartStyle}
    />
  )
}
