import React, { useState } from 'react'
import { ProductMessage } from '../../ProductMessage'
import { BookingButton } from '../../BookingButton'
import { SendMessageModal } from '../../Modal'

interface NoDataMessageProps {
  platform: string
}

export function NoDataMessage({ platform }: NoDataMessageProps) {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <>
      <ProductMessage title={`${platform} reporting not yet available`}>
        <>
          <p className="paragraph-text">
            We're sorry, the {`${platform} Ads`} data is not yet available to generate your report.
          </p>
          <p className="paragraph-text">
            This may be because your setup is still in progress or your {`${platform} Ads`} account
            has not been linked to our system.
          </p>
          <p className="paragraph-text">
            If this message persists, please contact us to advise that your reporting is not
            functioning correctly, and we'll be more than happy to assist.
          </p>
          <div className="space-y-6 sm:flex">
            <BookingButton onClick={openModal} />
          </div>
        </>
      </ProductMessage>

      {open && <SendMessageModal onClose={closeModal} />}
    </>
  )
}
