import React, { useState, useRef, useEffect } from 'react'
import { ButtonClose } from '../Buttons'
import { UpDownIcon } from '../Icons'
import { modalEventHandler } from '../../helpers/modalEventHandler'

type WebsiteData = {
  Id: string
  Geolocation__c: string
}

interface LocationSelectorProps {
  location: string
  setLocation: (location: string) => void
  websiteData: WebsiteData[]
}

export function LocationSelector({ location, setLocation, websiteData }: LocationSelectorProps) {
  const [open, setOpen] = useState(false)
  const popupRef = useRef(null)

  const handleOpenClick = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (websiteData.length > 1) {
      return modalEventHandler(open, handleOpenClick)
    }
  }, [open, websiteData])

  return (
    <div
      onClick={() => setOpen(!open)}
      ref={popupRef}
      className="group pl-[20px] pr-[16px] py-[6px] relative flex gap-[12px] rounded-[16px] bg-white hover:bg-secondary-100 dark:bg-gray-700 dark:hover:bg-primary-600 hover:text-primary-500 font-inter text-[14px] text-gray-900 dark:group-hover:text-black dark:text-gray-300 cursor-pointer"
    >
      <div className="flex items-center w-max gap-[12px]">
        <button type="button" className="flex w-full gap-[12px]">
          <span className="font-inter text-[14px] font-[500] leading-[20px] text-gray-900 dark:group-hover:text-gray-100 dark:text-gray-100">
            {location}
          </span>
          <span className="hidden sm:block font-inter text-[14px] font-[400] leading-[20px] text-gray-600 dark:group-hover:text-gray-100 dark:text-gray-300">
            {websiteData.length > 1 ? 'Location' : 'Geo-Locations'}
          </span>
        </button>
        {websiteData.length > 1 && <UpDownIcon />}
        {open && (
          <>
            <div className="visible sm:hidden transition-opacity ease-in-out duration-500 fixed bg-[#00000099] backdrop-blur inset-0 z-[25] opacity-100" />
            <div className="w-full sm:w-fit rounded-[12px] left-auto sm:left-[0px] fixed sm:absolute m-0 right-0 z-50 px-[16px] sm:px-0 pb-[32px] sm:pb-0 bottom-[0px] sm:bottom-auto top-auto sm:top-[39px] bg-white dark:bg-gray-700 font-inter font-[500] text-[16px] leading-[24px] text-gray-700 dark:text-gray-100 shadow-lg animate-slide-up sm:animate-fade-in">
              <div className="visible sm:hidden flex justify-between font-inter text-[18px] font-[600] text-gray-900 dark:text-gray-100 leading-[28px] px-[16px] pt-[20px]">
                <span>Website</span>
                <ButtonClose />
              </div>
              {websiteData.length > 1 && (
                <ul className="py-1 rounded-[12px]" role="none">
                  <li key="all" className="px-[6px] py-[2px]">
                    <button
                      type="button"
                      className="flex justify-between gap-[8px] py-[10px] px-[6px] w-full text-left rounded-[7px] group transition duration-100 dark:text-base-50 hover:bg-gray-100 hover:dark:bg-gray-600 active:scale-95"
                      role="menuitem"
                      onClick={() => setLocation('All')}
                    >
                      <span className="font-inter text-[14px] font-[500] leading-[20px] text-gray-900 dark:text-gray-100">
                        All
                      </span>
                      <span className="font-inter text-[14px] font-[400] leading-[20px] text-gray-600 dark:text-gray-300">
                        Location
                      </span>
                    </button>
                  </li>
                  {websiteData.map((location) => (
                    <li key={location.Id} className="px-[6px] py-[2px]">
                      <button
                        type="button"
                        className="flex justify-between gap-[8px] py-[10px] px-[6px] w-full text-left rounded-[6px] group transition duration-100 dark:text-base-50 hover:bg-gray-100 hover:dark:bg-gray-600 active:scale-95"
                        role="menuitem"
                        onClick={() => setLocation(location.Geolocation__c.split(',')[0])}
                      >
                        <span className="font-inter text-[14px] font-[500] leading-[20px] text-gray-900 dark:text-gray-100">
                          {location.Geolocation__c.split(',')[0]}
                        </span>
                        <span className="font-inter text-[14px] font-[400] leading-[20px] text-gray-600 dark:text-gray-300">
                          Location
                        </span>
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
