import React from 'react'

interface MicrosoftIconProps {
  className?: string
}

export function MicrosoftIcon({ className = 'self-center inline mr-[15px]' }: MicrosoftIconProps) {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H10.4543V10.4542H0V0Z" fill="#F25022" />
      <path d="M11.5454 0H21.9997V10.4542H11.5454V0Z" fill="#7FBA00" />
      <path d="M0 11.5457H10.4543V21.9999H0V11.5457Z" fill="#00A4EF" />
      <path d="M11.5454 11.5457H21.9997V21.9999H11.5454V11.5457Z" fill="#FFB900" />
    </svg>
  )
}
