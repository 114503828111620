import React from 'react'
import { TableHeadCell } from '../../Table'
import { seoRankingTooltip } from '../../../helpers/seoRankingTooltip'

export function TableHead() {
  return (
    <tr>
      <TableHeadCell label="Keyword" className="text-left" tooltip={seoRankingTooltip('keyword')} />
      <TableHeadCell label="Current Pos." tooltip={seoRankingTooltip('currentPositionTargeted')} />
      <TableHeadCell label="28d Ago" tooltip={seoRankingTooltip('28daysago')} />
      <TableHeadCell label="Original Pos." tooltip={seoRankingTooltip('originalPosition')} />
      <TableHeadCell label="Pos. Change" tooltip={seoRankingTooltip('positionChange28')} />
    </tr>
  )
}
