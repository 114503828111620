import React from 'react'
import { TableHeadCell } from '../../Table'
import { seoRankingTooltip } from '../../../helpers/seoRankingTooltip'

export function TableHead() {
  return (
    <tr>
      <TableHeadCell label="Keyword" className="text-left" tooltip={seoRankingTooltip('keyword')} />
      <TableHeadCell label="Current Pos." tooltip={seoRankingTooltip('currentPositionTargeted')} />
      <TableHeadCell label="All-time" />
      <TableHeadCell label="28d Ago" tooltip={seoRankingTooltip('prev28Days')} />
      <TableHeadCell label="Original Pos." tooltip={seoRankingTooltip('originalPosition')} />
      <TableHeadCell label="Pos. Change" tooltip={seoRankingTooltip('currentVs28Days')} />
    </tr>
  )
}
