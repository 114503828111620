import React from 'react'

export function DateFilterButton({ label, isActive, onClick, first = false, last = false }) {
  let extendedClassName = isActive
    ? 'bg-gray-200 dark:bg-neutral-950'
    : 'bg-white dark:bg-neutral-700'
  extendedClassName += first ? ' rounded-l-lg w-[103px]' : ''
  extendedClassName += last ? ' rounded-r-lg w-[77px]' : ''
  extendedClassName += first || last ? ' border-[1px]' : ' border-y-[1px] w-[87px]'

  return (
    <button
      type="button"
      className={`h-[40px] text-slate-800 dark:text-white font-inter leading-[20px] font-[600] text-[13px] border-solid border-gray-300 dark:border-none py-[10px] px-[16px] hover:bg-base-700 hover:text-white dark:hover:bg-gray-300 dark:hover:text-black ${extendedClassName}`}
      role="menuitem"
      onClick={onClick}
    >
      {label}
    </button>
  )
}
