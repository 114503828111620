import React from 'react'
import { percentageFormatter } from '../../helpers/percentageFormatter'

interface ProgressBarProps {
  value: number
  selected: boolean
}

export function ProgressBar({ value, selected = false }: ProgressBarProps) {
  if (value <= 0) {
    return null
  }
  const percentage = percentageFormatter(value)

  return (
    <div className="flex flex-row gap-[12px]">
      <div className="mt-[4px] h-[8px] w-[-webkit-fill-available] bg-gray-200 dark:bg-slate-700 rounded-lg overflow-hidden">
        <div
          className="bg-indigo-500 h-full rounded-s-lg transition-all delay-500 duration-1000"
          style={{ width: percentage }}
        />
      </div>
      <div
        className={`text-center font-inter font-[600] text-[12px] ${selected ? 'dark:text-gray-700' : 'dark:text-gray-300'} text-gray-700 dark:text-gray-300 leading-[18px]`}
        style={{ left: `calc(${percentage} - 30px)` }}
      >
        {percentage}
      </div>
    </div>
  )
}
