import React from 'react'
import { useSelector } from 'react-redux'
import ReactECharts from 'echarts-for-react'
import { getGraphColorSets } from './getGraphColorSets'
import { NoData } from '../NoData'

const ops = {
  renderer: 'canvas',
  useCoarsePointer: null
}

const getOptions = (
  darkMode,
  seriesData,
  seoProduct,
  firstPart,
  secondPart,
  thirdPart,
  setUpIdx
) => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: darkMode ? '#282828' : '#6a7985',
        formatter: (params) =>
          params.axisDimension === 'x'
            ? new Date(params.value).toLocaleDateString('en-AU')
            : Math.round(params.value)
      }
    }
  },
  color: getGraphColorSets(darkMode)[10],
  backgroundColor: 'transparent',
  grid: {
    containLabel: true,
    left: -5,
    right: 10,
    top: 10,
    bottom: 0
  },
  xAxis: [
    {
      type: 'time',
      data: seriesData.x,
      axistick: {
        alignWithLabel: true
      },
      axisLine: {
        onZero: false,
        lineStyle: {
          color: '#D1D5DB' // controls the color of the x-axis
        }
      },
      axisLabel: {
        color: darkMode ? '#fff' : '#475467',
        fontFamily: 'inter',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: 18
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      alignticks: true,
      position: 'left',
      axisLabel: {
        color: darkMode ? '#fff' : '#344054',
        margin: 20,
        align: 'center',
        fontFamily: 'inter',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: 18
      },
      splitLine: {
        lineStyle: {
          color: darkMode ? '#303740' : '#f4f5f7' // Color of y-axis grid lines
        }
      }
    }
  ],
  series: [
    //* SERIES
    {
      // Greyed out, first half of the graph (before sign up date)
      name: 'Traffic',
      data: firstPart,
      symbol: 'none',
      type: 'line',
      connectNulls: true,
      smooth: false,
      animationEasing: 'quadraticIn',
      lineStyle: {
        width: 2,
        color: getGraphColorSets(darkMode)[0]
      },
      emphasis: {
        focus: 'series'
      }
    },
    {
      // Colored, second half of the graph (after sign up date)
      name: 'Traffic',
      data: secondPart,
      symbol: 'none',
      type: 'line',
      connectNulls: true,
      smooth: false,
      animationEasing: 'quadraticIn',
      lineStyle: {
        width: 2,
        color: getGraphColorSets(darkMode)[10],
        opacity: thirdPart ? 0.5 : 1
      },
      emphasis: {
        focus: 'series'
      }
    },
    {
      // Colored, third half of the graph (after set up completed date)
      name: 'Traffic',
      data: thirdPart,
      symbol: 'none',
      type: 'line',
      connectNulls: true,
      smooth: false,
      animationEasing: 'quadraticIn',
      lineStyle: {
        width: 2,
        color: getGraphColorSets(darkMode)[10]
      },
      emphasis: {
        focus: 'series'
      }
    },
    //* VERTICAL LINES
    {
      // vertical line label
      type: 'line',
      symbol: 'none',
      label: { show: false }, // hide the label
      data: [Math.min(seriesData.x), Math.max(seriesData.x)],
      markLine: {
        symbol: 'none', // hide the arrow at the top
        data: [
          // sign up line
          {
            symbol: 'none', // hide the circle at the bottom
            xAxis: firstPart.length === 0 ? 0 : firstPart[firstPart.length - 1][0],
            lineStyle: {
              type: 'solid',
              width: 2,
              color: getGraphColorSets(darkMode)[8]
            }
          },
          // set up complete vertical line
          {
            symbol: 'none', // hide the circle at the bottom
            xAxis: secondPart.length === 0 ? 0 : secondPart[secondPart.length - 1][0],
            lineStyle: {
              type: 'solid',
              width: setUpIdx === -1 ? null : 2,
              color: getGraphColorSets(darkMode)[8]
            }
          }
        ],
        label: {
          show: false
        }
      }
    },
    //* CIRCLE LABELS
    {
      // sign up date label
      type: 'line',
      markPoint: {
        data: [
          {
            coord:
              firstPart.length === 0
                ? 0
                : [
                    firstPart[firstPart.length - 1][0],
                    Math.max(...seriesData.y[0].map((item) => item[1])) / 2
                  ],
            symbol: 'circle',
            symbolSize: 16,
            label: {
              show: false,
              position:
                firstPart.length === 0
                  ? 0
                  : firstPart[firstPart.length - 1][0] >
                      seriesData.x[parseInt((seriesData.x.length - 1) / 2)]
                    ? [-90, 7]
                    : [7, 7]
            },
            itemStyle: {
              color: getGraphColorSets(darkMode)[10],
              borderWidth: 8,
              borderColor: getGraphColorSets(darkMode)[9],
              shadowColor: 'rgba(16, 24, 40, 0.10)',
              shadowBlur: 3,
              shadowOffsetY: 1
            },
            emphasis: {
              label: {
                backgroundColor: getGraphColorSets(darkMode)[9],
                padding: [0, 7, 0, 5],
                borderWidth: 7,
                borderColor: getGraphColorSets(darkMode)[9],
                borderRadius: 8,
                formatter: 'Account Created',
                show: true,
                color: getGraphColorSets(darkMode)[11],
                fontSize: 12,
                fontFamily: 'inter',
                fontWeight: 500,
                shadowColor: 'rgba(16, 24, 40, 0.10)',
                shadowBlur: 3,
                shadowOffsetY: 1
              }
            }
          },
          {
            coord:
              setUpIdx === -1
                ? null
                : secondPart.length === 0
                  ? 0
                  : [
                      secondPart[secondPart.length - 1][0],
                      Math.max(...seriesData.y[0].map((item) => item[1])) / 1.5
                    ],
            symbol: 'circle',
            symbolSize: 16,
            label: {
              show: false,
              position:
                setUpIdx === -1
                  ? null
                  : firstPart.length === 0
                    ? 0
                    : firstPart[firstPart.length - 1][0] >
                        seriesData.x[parseInt((seriesData.x.length - 1) / 2)]
                      ? [-90, 7]
                      : [7, 7]
            },
            itemStyle: {
              color: getGraphColorSets(darkMode)[10],
              borderWidth: 8,
              borderColor: getGraphColorSets(darkMode)[9],
              shadowColor: 'rgba(16, 24, 40, 0.10)',
              shadowBlur: 3,
              shadowOffsetY: 1
            },
            emphasis: {
              label: {
                backgroundColor: getGraphColorSets(darkMode)[9],
                padding: [0, 7, 0, 5],
                borderWidth: 7,
                borderColor: getGraphColorSets(darkMode)[9],
                borderRadius: 8,
                formatter: 'Setup Complete',
                show: true,
                color: getGraphColorSets(darkMode)[11],
                fontSize: 12,
                fontFamily: 'inter',
                fontWeight: 500,
                shadowColor: 'rgba(16, 24, 40, 0.10)',
                shadowBlur: 3,
                shadowOffsetY: 1
              }
            }
          }
        ]
      }
    }
  ],
  animationEasing: 'elasticOut'
})

export function PerformanceChart({ data = [], seoProduct }) {
  const darkMode = useSelector((store) => store.global.darkMode)

  const getData = () => {
    const yData = []
    if (data.length > 0) {
      for (let i = 0; i < Object.keys(data[0]).length; i++) {
        if (Object.keys(data[0])[i] === 'date') continue
        yData.push(data.map((item) => [item.date, parseInt(item[Object.keys(data[0])[i]] || null)]))
      }
    }
    return {
      x: data.map((item) => item.date),
      y: yData
    }
  }

  // split the data into three series based on sign up, and set up complete dates
  const seriesData = getData()
  const signUpIdx =
    seoProduct?.signupDate && seriesData.y.length > 0 && seriesData.y[0]
      ? seriesData.y[0].findIndex(
          ([dateString]) => new Date(dateString) >= new Date(seoProduct.signupDate)
        )
      : -1
  const setUpIdx =
    seoProduct?.setupCompletedDate && seriesData.y.length > 0 && seriesData.y[0]
      ? seriesData.y[0].findIndex(
          ([dateString]) => new Date(dateString) >= new Date(seoProduct.setupCompletedDate)
        )
      : -1
  const firstPart =
    signUpIdx !== -1
      ? seriesData.y[0].slice(0, signUpIdx)
      : seriesData.y.length > 0 && seriesData.y[0]
        ? seriesData.y[0]
        : []
  const secondPart =
    setUpIdx !== -1
      ? seriesData.y[0].slice(signUpIdx, setUpIdx)
      : seriesData.y.length > 0 && seriesData.y[0]
        ? seriesData.y[0].slice(signUpIdx)
        : []
  const thirdPart =
    setUpIdx !== -1
      ? seriesData.y[0].slice(setUpIdx)
      : seriesData.y.length > 0 && seriesData.y[0]
        ? seriesData.y[0]
        : []

  if (firstPart.length === 0 && secondPart.length === 0 && thirdPart.length === 0) {
    return (
      <NoData
        title="Organic Traffic Unavailable"
        subTextOne="No Organic Traffic was detected."
        showIcon={false}
      />
    )
  }

  const options = getOptions(
    darkMode,
    seriesData,
    seoProduct,
    firstPart,
    secondPart,
    thirdPart,
    setUpIdx
  )

  return <ReactECharts option={options} ops={ops} />
}
