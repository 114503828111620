import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { TableHeadCell, TableRowCell } from '../../Table'
import { adsFindTerm } from '../../../helpers/adsFindTerm'

interface CampaignProps {
  budget: number
  campaignName: string
  channel: string
  convValue: number
  convValueRoas: number
  conversions: number
  cpc: number
  cr: number
}

interface SelectCampaignProps {
  adsId: string
  campaigns: CampaignProps[]
}

export function SelectCampaignTable({ adsId, campaigns }: SelectCampaignProps) {
  const navigate = useNavigate()

  const [animationKey, setAnimationKey] = useState(0)
  const adsIdNumber = adsId.replace(/\D/g, '')

  useEffect(() => {
    setAnimationKey(animationKey + 1)
  }, [adsId, campaigns])

  return (
    <div
      key={animationKey}
      className="animate-slide-left2 overflow-x-auto bg-white dark:bg-gray-800 rounded-[8px] align-middle dark:scrollbar-thumb-base-50 dark:scrollbar-track-base-700 scrollbar-thin scrollbar-thumb scrollbar-thumb-primary-500 scrollbar-thumb-rounded-full scrollbar-track-gray-100 scrollbar-track-rounded-full scrollbar-width-10 scrollbar-thumb scrollbar-width-10"
    >
      <table className="table-main m-auto">
        <thead className="table-thead">
          <tr>
            <TableHeadCell
              label="Campaign Name"
              className="text-left"
              tooltip={adsFindTerm('campaignName')}
            />
            <TableHeadCell label="Type" tooltip={adsFindTerm('campaignType')} />
            <TableHeadCell label="Budget" tooltip={adsFindTerm('campaign')} />
            <TableHeadCell label="Conversions" tooltip={adsFindTerm('conversions')} />
            <TableHeadCell label="Conv. Value" tooltip={adsFindTerm('convValue')} />
            <TableHeadCell label="Cost" tooltip={adsFindTerm('adSpend')} />
          </tr>
        </thead>
        <tbody className="table-body">
          {campaigns.map((campaign, index) => (
            <tr
              className="table-tr group hover:bg-gray-100 hover:dark:bg-gray-600 hover:cursor-pointer transition duration-100"
              onClick={() => navigate(`${adsIdNumber}/campaign/${campaign.campaignId}`)}
              key={index}
            >
              <TableRowCell
                label={campaign.campaign_name}
                className="p-4 text-left w-fit text-primary-600 dark:text-primary-500 font-[400] text-[14px] leading-[18px] normal-case truncate block max-w-[200px] md:max-[300px] group-hover:underline group-hover:text-primary-700 dark:group-hover:text-primary-300 tranition duration-100"
              />
              <TableRowCell
                label={campaign?.channel ? campaign.channel.replaceAll('_', ' ') : '~'}
              />
              <TableRowCell label={`$${(campaign.budget || 0).toLocaleString()}`} />
              <TableRowCell label={Math.round(campaign?.conversions || 0).toLocaleString()} />
              <TableRowCell label={`$${(campaign.convValue || 0).toLocaleString()}`} />
              <TableRowCell label={`$${(campaign.adSpend || 0).toLocaleString()}`} />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
