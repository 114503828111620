import { api } from './api'
import { cache } from './cache'
import { Tag } from './tags'
import { getPeriodFromDateRange } from '../helpers/getPeriodFromDateRange'

export const reportsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSeoDomainReports: build.query({
      query: (domain) => ({
        url: 'cpanel/reports/seoDomainStats',
        method: 'POST',
        body: {
          domain: /(http(s?)):\/\//i.test(domain) ? domain : `https://${domain}`
        }
      }),
      providesTags: (response, error, id) => [{ type: Tag.ReportSeoDomain, id }],
      transformResponse: (response, _, arg) => {
        cache.seoDomainReports.set(arg, response)
        return response
      }
    }),
    getAdsReports: build.query({
      query: ({ adsId, from, to }) => {
        const { period, units } = getPeriodFromDateRange(from, to)

        return {
          url: 'cpanel/reports/gadstats',
          method: 'POST',
          body: {
            adsId,
            from,
            to,
            period,
            period_many: units
          }
        }
      },
      providesTags: (response, error, id) => [{ type: Tag.ReportAds, id }],
      transformResponse: (response, _, arg) => {
        cache.adsReports.set(arg, response)
        return response
      }
    }),
    getAllAdsReports: build.query({
      query: ({ adsId, from, to, stat }) => {
        const { period, units } = getPeriodFromDateRange(from, to)

        return {
          url: 'cpanel/reports/view-all',
          method: 'POST',
          body: {
            stat,
            adsId,
            from,
            to,
            period,
            period_many: units,
            viewAll: true
          }
        }
      },
      providesTags: (response, error, id) => [{ type: Tag.ReportAllAds, id }],
      transformResponse: (response, _, arg) => {
        const cacheAccessors = {
          keywordStats: cache.adsKeywords,
          campaignStats: cache.adsCampaigns,
          adGroupStats: cache.adsTopAds,
          adStats: cache.topAds,
          shoppingStats: cache.adsShopping,
          searchTermStats: cache.adsSearchTerms,
          adScheduleViewStats: cache.adScheduleViewStats
        }

        const cacheAccessor = cacheAccessors[arg.stat]
        if (cacheAccessor) {
          cacheAccessor.set(arg, response)
        }
        return response
      }
    }),
    getFacebookReports: build.query({
      query: ({ facebookId, from, to }) => {
        const { period, units } = getPeriodFromDateRange(from, to)
        return {
          url: 'cpanel/reports/facebookstats',
          method: 'POST',
          body: {
            facebookId,
            from,
            to,
            period,
            periodMany: units
          }
        }
      },
      providesTags: (response, error, id) => [{ type: Tag.ReportFacebook, id }],
      transformResponse: (response, _, arg) => {
        cache.facebookReports.set(arg, response)
        return response
      }
    })
  })
})

export const {
  useGetSeoDomainReportsQuery,
  useGetAdsReportsQuery,
  useGetFacebookReportsQuery,
  useGetAllAdsReportsQuery
} = reportsApi
