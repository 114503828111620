import React, { useState } from 'react'
import { ProductMessage } from '../../ProductMessage'
import { SendMessageModal } from '../../Modal'

interface JourneyProps {
  onHideClick: () => void
}

export function Journey({ onHideClick }: JourneyProps) {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <>
      <ProductMessage title="Your Google Ads Journey" onHideClick={onHideClick}>
        <p className="paragraph-text">
          This performance dashboard updates daily, so don't forget to check back regularly to gain
          an in-depth understanding of your google ads dashboard performance. As always, if you have
          any questions or need any assistance understanding your google ads dashboard, get in touch
          with us via the{' '}
          <a
            className="text-primary-600 hover:text-primary-700 hover:cursor-pointer"
            onClick={openModal}
          >
            booking link
          </a>{' '}
          our team can assist you with all your needs.
        </p>
      </ProductMessage>

      {open && <SendMessageModal onClose={closeModal} />}
    </>
  )
}
